import React from "react";

function P() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="35"
      version="1.1"
      viewBox="0 1 14.552 7.938"
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="0.5"
        d="M5.681 291.565v3.607M5.681 293.346H0M9.963 293.346h4.59M3.498 291.2c1.877.006 3.754-.017 5.63.012.37-.02.855.09.811.464.062.477-.002.957.022 1.435-.002.677.015 1.356-.019 2.032-.09.364-.626.375-.99.364-1.253.018-2.507 0-3.76.006H3.498"
        transform="translate(0 -289.063)"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth="0.5"
        d="M5.592 297.5l3.537-7.476h0v0"
        transform="translate(0 -289.063)"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth="0.5"
        d="M8.643 290.2l.595.233.008-.75z"
        transform="translate(0 -289.063)"
      ></path>

    </svg>
  );
}

export default P;
