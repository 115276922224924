export const coatingExamples = {
    a: {
        "fHz": [
          0.010000000000000009,
          0.012589254117941687,
          0.01584893192461116,
          0.01995262314968883,
          0.025118864315095846,
          0.03162277660168386,
          0.03981071705534982,
          0.05011872336272736,
          0.0630957344480195,
          0.07943282347242837,
          0.10000000000000028,
          0.12589254117941706,
          0.1584893192461118,
          0.19952623149688853,
          0.2511886431509587,
          0.3162277660168387,
          0.3981071705534983,
          0.5011872336272735,
          0.6309573444801948,
          0.7943282347242835,
          1.0000000000000024,
          1.2589254117941704,
          1.5848931924611174,
          1.9952623149688846,
          2.511886431509587,
          3.1622776601683875,
          3.981071705534982,
          5.011872336272735,
          6.309573444801948,
          7.9432823472428336,
          10.000000000000025,
          12.589254117941707,
          15.848931924611183,
          19.952623149688858,
          25.118864315095887,
          31.622776601683906,
          39.810717055349876,
          50.11872336272743,
          63.09573444801959,
          79.4328234724285,
          100.00000000000043,
          125.89254117941726,
          158.48931924611207,
          199.5262314968889,
          251.18864315095925,
          316.22776601683955,
          398.10717055349943,
          501.1872336272751,
          630.9573444801969,
          794.3282347242862,
          1000.0000000000061,
          1258.9254117941753,
          1584.8931924611238,
          1995.2623149688932,
          2511.8864315095975,
          3162.277660168402,
          3981.0717055350024,
          5011.872336272761,
          6309.573444801982,
          7943.282347242878,
          10000.000000000082,
          12589.254117941764,
          15848.931924611237,
          19952.62314968891,
          25118.86431509592,
          31622.77660168392,
          39810.71705534986,
          50118.72336272735,
          63095.73444801942,
          79432.82347242821,
          100000,
          125892.54117941661,
          158489.3192461111,
          199526.23149688746,
          251188.6431509572,
          316227.7660168366,
          398107.1705534953,
          501187.23362726945,
          630957.3444801891,
          794328.2347242757,
          999999.9999999919
        ],
        "Mreal": [
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787,
          199.52623149688787
        ],
        "Mimg": [
          -45472840.88339862,
          -36120361.426808156,
          -28691422.929759543,
          -22790407.327523667,
          -18103064.021119203,
          -14379774.886996264,
          -11422261.201720294,
          -9073024.57692213,
          -7206959.595796575,
          -5724691.493458315,
          -4547284.088339853,
          -3612036.1426808108,
          -2869142.2929759505,
          -2279040.732752364,
          -1810306.4021119184,
          -1437977.4886996257,
          -1142226.1201720291,
          -907302.4576922132,
          -720695.9595796576,
          -572469.1493458317,
          -454728.40883398557,
          -361203.6142680811,
          -286914.22929759516,
          -227904.07327523647,
          -181030.64021119184,
          -143797.74886996258,
          -114222.61201720293,
          -90730.2457692213,
          -72069.59595796578,
          -57246.91493458317,
          -45472.84088339855,
          -36120.3614268081,
          -28691.422929759505,
          -22790.407327523637,
          -18103.06402111917,
          -14379.774886996243,
          -11422.261201720277,
          -9073.024576922116,
          -7206.959595796564,
          -5724.6914934583065,
          -4547.284088339848,
          -3612.0361426808045,
          -2869.1422929759456,
          -2279.04073275236,
          -1810.3064021119146,
          -1437.9774886996222,
          -1142.226120172026,
          -907.3024576922103,
          -720.6959595796553,
          -572.4691493458297,
          -454.7284088339838,
          -361.2036142680797,
          -286.91422929759403,
          -227.9040732752355,
          -181.0306402111911,
          -143.79774886996194,
          -114.22261201720237,
          -90.73024576922086,
          -72.06959595796539,
          -57.246914934582854,
          -45.4728408833983,
          -36.12036142680793,
          -28.691422929759398,
          -22.790407327523575,
          -18.103064021119145,
          -14.379774886996238,
          -11.422261201720284,
          -9.07302457692213,
          -7.206959595796584,
          -5.724691493458327,
          -4.547284088339866,
          -3.612036142680823,
          -2.8691422929759636,
          -2.2790407327523763,
          -1.8103064021119293,
          -1.4379774886996355,
          -1.1422261201720376,
          -0.9073024576922205,
          -0.7206959595796641,
          -0.5724691493458373,
          -0.45472840883399035
        ],
        "fRad": [
          0.06283185307179592,
          0.0791006165022013,
          0.09958177620320631,
          0.12536602861381613,
          0.15782647919764783,
          0.19869176531592245,
          0.25013811247045775,
          0.3149052262472868,
          0.396442191629501,
          0.49909114934975174,
          0.6283185307179604,
          0.7910061650220143,
          0.9958177620320644,
          1.2536602861381627,
          1.5782647919764798,
          1.9869176531592252,
          2.501381124704578,
          3.149052262472867,
          3.964421916295009,
          4.990911493497515,
          6.283185307179601,
          7.9100616502201415,
          9.95817762032064,
          12.536602861381624,
          15.782647919764798,
          19.869176531592252,
          25.013811247045776,
          31.490522624728676,
          39.64421916295009,
          49.90911493497515,
          62.83185307179602,
          79.10061650220143,
          99.58177620320646,
          125.36602861381631,
          157.8264791976481,
          198.69176531592274,
          250.1381124704581,
          314.90522624728726,
          396.44219162950156,
          499.09114934975247,
          628.3185307179613,
          791.0061650220156,
          995.8177620320662,
          1253.660286138165,
          1578.2647919764834,
          1986.9176531592304,
          2501.381124704585,
          3149.0522624728774,
          3964.4219162950217,
          4990.911493497532,
          6283.185307179625,
          7910.061650220172,
          9958.177620320681,
          12536.602861381678,
          15782.647919764866,
          19869.176531592344,
          25013.811247045902,
          31490.522624728834,
          39644.2191629503,
          49909.11493497543,
          62831.85307179637,
          79100.6165022018,
          99581.77620320681,
          125366.02861381663,
          157826.47919764832,
          198691.76531592282,
          250138.112470458,
          314905.2262472868,
          396442.19162950054,
          499091.14934975066,
          628318.5307179586,
          791006.1650220115,
          995817.76203206,
          1253660.286138156,
          1578264.7919764705,
          1986917.6531592119,
          2501381.1247045593,
          3149052.262472842,
          3964421.9162949733,
          4990911.493497467,
          6283185.307179535
        ],
        "Mmod": [
          45472840.883836366,
          36120361.42735924,
          28691422.93045332,
          22790407.328397077,
          18103064.02221876,
          14379774.888380524,
          11422261.203462975,
          9073024.579116035,
          7206959.598558539,
          5724691.496935421,
          4547284.09271727,
          3612036.1481916523,
          2869142.2999136886,
          2279040.741486459,
          1810306.4131074927,
          1437977.5025422336,
          1142226.1375988398,
          907302.479631268,
          720695.987199291,
          572469.1841168896,
          454728.4526081531,
          361203.66937649145,
          286914.2986749703,
          227904.16061617088,
          181030.75016690133,
          143797.88729597483,
          114222.78628517836,
          90730.46515950613,
          72069.87215377514,
          57247.262644116505,
          45473.27862298845,
          36120.91250675018,
          28692.116695207147,
          22791.28072029956,
          18104.163545157084,
          14381.159081163909,
          11424.0037498847,
          9075.21821723798,
          7209.721030143921,
          5728.167544016956,
          4551.659400386206,
          3617.5427866284836,
          2876.071663640984,
          2287.758155618675,
          1821.2687848263186,
          1451.7541028225894,
          1159.5219819643737,
          928.9824900339482,
          747.8057122072483,
          606.2438816252808,
          496.5769254164317,
          412.6484799628776,
          349.4717328036447,
          302.9042483538216,
          269.4119703179935,
          245.94411892830112,
          229.90763830586846,
          219.1864378849497,
          212.14321510926075,
          207.57631446019332,
          204.64236197170064,
          202.76932106448677,
          201.5785574040156,
          200.8236034969598,
          200.34579601853997,
          200.04373267150956,
          199.85290867613116,
          199.73241306889355,
          199.6563480632788,
          199.60833937499947,
          199.5780419984367,
          199.55892327943073,
          199.54685924074835,
          199.53924697164524,
          199.53444380512158,
          199.53141315243496,
          199.52950091617853,
          199.52829436723863,
          199.52753308256945,
          199.52705274292165,
          199.52674966849807
        ],
        "Mphz": [
          -89.99974859694831,
          -89.99968350230964,
          -89.99960155301481,
          -89.99949838496512,
          -89.99936850408567,
          -89.99920499374602,
          -89.99899914642435,
          -89.99874000000021,
          -89.99841375398154,
          -89.99800303457832,
          -89.99748596948476,
          -89.99683502309952,
          -89.99601553015462,
          -89.99498384966384,
          -89.99368504088187,
          -89.99204993751057,
          -89.98999146434429,
          -89.98740000020312,
          -89.98413754021666,
          -89.9800303465836,
          -89.9748596964448,
          -89.96835023418215,
          -89.96015530790498,
          -89.94983850932604,
          -89.9368504341338,
          -89.92049942561597,
          -89.89991474422393,
          -89.87400020311587,
          -89.84137580338269,
          -89.8003042663652,
          -89.74859856170667,
          -89.68350552874978,
          -89.6015594377388,
          -89.49839778029538,
          -89.36852965461316,
          -89.20504476060964,
          -88.99924820498975,
          -88.74020305754136,
          -88.41415906547974,
          -88.00384260982042,
          -87.4875810333088,
          -86.83823639428145,
          -86.02193468102695,
          -84.99660681635223,
          -83.71042692853217,
          -82.10037661716815,
          -80.09143953025249,
          -77.59741892687853,
          -74.52513586108638,
          -70.78470376139538,
          -66.30904202432217,
          -61.08406093450573,
          -55.18443071937025,
          -48.79839212601656,
          -42.217535160324786,
          -35.7801401448154,
          -29.789806002937237,
          -24.452641740112643,
          -19.859902867261273,
          -16.008938629169677,
          -12.838658137449707,
          -10.261161541836287,
          -8.182910284821707,
          -6.51623284653144,
          -5.1842656646452525,
          -4.122156673994381,
          -3.2764305556036724,
          -2.603608291147236,
          -2.068644936784241,
          -1.6434465805430456,
          -1.3055681460253254,
          -1.037115879705504,
          -0.8238436302782027,
          -0.6544189000919086,
          -0.5198317518449441,
          -0.41292121898007217,
          -0.3279970785706999,
          -0.26053839073250457,
          -0.2069535264000164,
          -0.16438929312801417,
          -0.13057918924816417
        ],
        "tanDelta": [
          -227904.07327523694,
          -181030.64021119222,
          -143797.7488699628,
          -114222.61201720308,
          -90730.24576922141,
          -72069.59595796582,
          -57246.91493458319,
          -45472.84088339857,
          -36120.361426808115,
          -28691.42292975952,
          -22790.407327523648,
          -18103.064021119197,
          -14379.77488699626,
          -11422.261201720294,
          -9073.024576922131,
          -7206.959595796579,
          -5724.691493458318,
          -4547.284088339858,
          -3612.036142680812,
          -2869.1422929759533,
          -2279.0407327523662,
          -1810.3064021119199,
          -1437.9774886996265,
          -1142.2261201720298,
          -907.3024576922131,
          -720.6959595796579,
          -572.4691493458319,
          -454.7284088339857,
          -361.2036142680813,
          -286.9142292975953,
          -227.90407327523658,
          -181.03064021119192,
          -143.7977488699626,
          -114.22261201720292,
          -90.73024576922126,
          -72.06959595796572,
          -57.24691493458311,
          -45.4728408833985,
          -36.12036142680806,
          -28.69142292975948,
          -22.79040732752362,
          -18.103064021119167,
          -14.379774886996236,
          -11.422261201720273,
          -9.073024576922112,
          -7.206959595796562,
          -5.724691493458302,
          -4.547284088339843,
          -3.6120361426808003,
          -2.869142292975943,
          -2.279040732752357,
          -1.8103064021119128,
          -1.4379774886996208,
          -1.142226120172025,
          -0.9073024576922094,
          -0.7206959595796547,
          -0.572469149345829,
          -0.45472840883398347,
          -0.36120361426807934,
          -0.28691422929759375,
          -0.22790407327523532,
          -0.18103064021119109,
          -0.14379774886996208,
          -0.11422261201720262,
          -0.09073024576922112,
          -0.0720695959579657,
          -0.05724691493458314,
          -0.04547284088339857,
          -0.03612036142680816,
          -0.02869142292975958,
          -0.022790407327523715,
          -0.018103064021119258,
          -0.014379774886996325,
          -0.011422261201720355,
          -0.009073024576922186,
          -0.007206959595796628,
          -0.005724691493458361,
          -0.004547284088339895,
          -0.003612036142680845,
          -0.002869142292975981,
          -0.00227904073275239
        ],
        "nimg": [
          3175.5586019227494,
          2522.4358585288046,
          2003.6420227104122,
          1591.54943091895,
          1264.2126499382844,
          1004.199802541583,
          797.6642564633275,
          633.6072407391714,
          503.29212104486874,
          399.77914206020995,
          317.55586019227434,
          252.24358585288007,
          200.36420227104094,
          159.15494309189484,
          126.42126499382832,
          100.41998025415826,
          79.76642564633273,
          63.360724073917154,
          50.32921210448689,
          39.97791420602101,
          31.75558601922745,
          25.22435858528801,
          20.036420227104102,
          15.915494309189487,
          12.642126499382831,
          10.041998025415825,
          7.976642564633274,
          6.336072407391715,
          5.03292121044869,
          3.9977914206021015,
          3.1755586019227446,
          2.5224358585288003,
          2.0036420227104093,
          1.5915494309189477,
          1.2642126499382822,
          1.0041998025415815,
          0.7976642564633264,
          0.6336072407391704,
          0.5032921210448681,
          0.39977914206020937,
          0.31755586019227394,
          0.25224358585287965,
          0.2003642022710406,
          0.15915494309189454,
          0.12642126499382803,
          0.100419980254158,
          0.0797664256463325,
          0.06336072407391695,
          0.05032921210448673,
          0.03997791420602088,
          0.03175558601922733,
          0.025224358585287914,
          0.020036420227104022,
          0.01591549430918942,
          0.012642126499382777,
          0.01004199802541578,
          0.007976642564633235,
          0.006336072407391683,
          0.005032921210448662,
          0.00399779142060208,
          0.0031755586019227267,
          0.002522435858528789,
          0.002003642022710402,
          0.0015915494309189438,
          0.0012642126499382803,
          0.001004199802541581,
          0.0007976642564633267,
          0.0006336072407391714,
          0.0005032921210448694,
          0.0003997791420602108,
          0.0003175558601922753,
          0.00025224358585288094,
          0.00020036420227104184,
          0.00015915494309189568,
          0.00012642126499382906,
          0.00010041998025415893,
          0.00007976642564633333,
          0.00006336072407391766,
          0.00005032921210448734,
          0.000039977914206021405,
          0.00003175558601922778
        ],
        "nreal": [
          -723722740.3024113,
          -456638178.36113775,
          -288119212.40701616,
          -181790933.1540556,
          -114702324.4334592,
          -72372274.03024095,
          -45663817.83611366,
          -28811921.240701552,
          -18179093.315405518,
          -11470232.443345897,
          -7237227.403024085,
          -4566381.783611365,
          -2881192.1240701536,
          -1817909.3315405517,
          -1147023.24433459,
          -723722.7403024089,
          -456638.1783611364,
          -288119.2124070157,
          -181790.93315405527,
          -114702.32443345906,
          -72372.27403024092,
          -45663.81783611365,
          -28811.92124070156,
          -18179.093315405527,
          -11470.232443345898,
          -7237.22740302409,
          -4566.381783611366,
          -2881.1921240701554,
          -1817.9093315405532,
          -1147.0232443345906,
          -723.7227403024091,
          -456.6381783611363,
          -288.1192124070153,
          -181.79093315405507,
          -114.70232443345881,
          -72.37227403024075,
          -45.66381783611353,
          -28.811921240701466,
          -18.179093315405463,
          -11.470232443345862,
          -7.2372274030240655,
          -4.566381783611349,
          -2.8811921240701435,
          -1.817909331540545,
          -1.147023244334585,
          -0.7237227403024052,
          -0.45663817836113385,
          -0.28811921240701377,
          -0.1817909331540541,
          -0.11470232443345826,
          -0.07237227403024037,
          -0.045663817836113306,
          -0.028811921240701328,
          -0.018179093315405372,
          -0.011470232443345801,
          -0.007237227403024024,
          -0.00456638178361132,
          -0.002881192124070127,
          -0.0018179093315405337,
          -0.001147023244334578,
          -0.000723722740302401,
          -0.0004566381783611321,
          -0.00028811921240701324,
          -0.00018179093315405412,
          -0.00011470232443345849,
          -0.00007237227403024068,
          -0.000045663817836113576,
          -0.00002881192124070155,
          -0.000018179093315405563,
          -0.000011470232443345945,
          -0.000007237227403024127,
          -0.000004566381783611396,
          -0.00000288119212407018,
          -0.0000018179093315405712,
          -0.0000011470232443346035,
          -7.237227403024187e-7,
          -4.5663817836114324e-7,
          -2.8811921240702026e-7,
          -1.8179093315405855e-7,
          -1.1470232443346129e-7,
          -7.237227403024245e-8
        ],
        "nmod": [
          723722740.3093781,
          456638178.3681046,
          288119212.41398305,
          181790933.16102245,
          114702324.44042608,
          72372274.03720783,
          45663817.843080536,
          28811921.247668426,
          18179093.32237239,
          11470232.450312773,
          7237227.409990961,
          4566381.79057824,
          2881192.131037029,
          1817909.338507427,
          1147023.2513014653,
          723722.7472692843,
          456638.18532801187,
          288119.2193738911,
          181790.9401209306,
          114702.33140033434,
          72372.28099711606,
          45663.8248029886,
          28811.9282075762,
          18179.100282279676,
          11470.239410219267,
          7237.23436989622,
          4566.388750481536,
          2881.199090937217,
          1817.9162984026882,
          1147.0302111889175,
          723.729707144361,
          456.6451451834754,
          288.1261791982706,
          181.79789989604714,
          114.70929109737669,
          72.37924057042656,
          45.67078418021524,
          28.81888727407603,
          18.18605885642421,
          11.477197204318239,
          7.244190928421539,
          4.573343352548711,
          2.8881505967237127,
          1.8248629081694807,
          1.153969089398185,
          0.7306564016451701,
          0.4635527031496706,
          0.2950038337262907,
          0.18862919436842268,
          0.12146957172354844,
          0.07903267230540006,
          0.05216754283468723,
          0.035093944507528355,
          0.02416158920427332,
          0.017070137513528674,
          0.012378173727393563,
          0.009191227839500878,
          0.0069603937825035425,
          0.005351176529351857,
          0.004159086265717918,
          0.00325698434737968,
          0.002563435407091203,
          0.0020242514754172884,
          0.001601898103636881,
          0.0012694054700899975,
          0.0010068043451797667,
          0.0007989702436878121,
          0.000634261982403712,
          0.0005036203317377224,
          0.00039994365685519083,
          0.00031763831916653096,
          0.0002522849152179207,
          0.0002003849166473534,
          0.00015916532507089055,
          0.00012642646837178912,
          0.00010042258814056976,
          0.00007976773269197238,
          0.00006336137915048636,
          0.000050329540421126956,
          0.00003997807875432783,
          0.00003175566848880092
        ],
        "cimg": [
          -1.5357338504896788e-12,
          -1.9333743701121897e-12,
          -2.433974125002338e-12,
          -3.064191877528243e-12,
          -3.8575890210606494e-12,
          -4.856416846526382e-12,
          -6.113866577668884e-12,
          -7.696901997572708e-12,
          -9.6898255140927e-12,
          -1.2198767570074025e-11,
          -1.5357338475625182e-11,
          -1.933374364271733e-11,
          -2.433974113349094e-11,
          -3.064191854276961e-11,
          -3.857588974668239e-11,
          -4.856416753961351e-11,
          -6.113866392977362e-11,
          -7.696901629064674e-11,
          -9.689824778822535e-11,
          -1.219876610301724e-10,
          -1.5357335548462273e-10,
          -1.9333737802260168e-10,
          -2.4339729480248944e-10,
          -3.064189529150166e-10,
          -3.85758433543247e-10,
          -4.856407497475692e-10,
          -6.113847923881363e-10,
          -7.69686477843987e-10,
          -9.689751252369628e-10,
          -1.2198619399120857e-9,
          -1.535704283780688e-9,
          -1.9333153774362585e-9,
          -2.433856421239822e-9,
          -3.0639570342890366e-9,
          -3.857120468199348e-9,
          -4.8554820270718414e-9,
          -6.112001577615164e-9,
          -7.693181497054898e-9,
          -9.682404237790438e-9,
          -1.2183966807301715e-8,
          -1.532782801359823e-8,
          -1.9274928633620752e-8,
          -2.4222598194942746e-8,
          -3.040884375633278e-8,
          -3.8112904204737685e-8,
          -4.764682952678845e-8,
          -5.9328334811505494e-8,
          -7.341842694732067e-8,
          -9.000001361842522e-8,
          -1.0877407390026303e-7,
          -1.2877959686799893e-7,
          -1.481356652930806e-7,
          -1.640574756906908e-7,
          -1.7346401828556827e-7,
          -1.741752150794106e-7,
          -1.6601496856083567e-7,
          -1.5090837103162761e-7,
          -1.3188420146672498e-7,
          -1.1183090480658338e-7,
          -9.278217267777707e-8,
          -7.582790409095771e-8,
          -6.13501512309085e-8,
          -4.93095984554191e-8,
          -3.946304751657668e-8,
          -3.149630911137321e-8,
          -2.5094019578551682e-8,
          -1.9970971175247115e-8,
          -1.5882652489808944e-8,
          -1.2625654054616804e-8,
          -1.003373827464674e-8,
          -7.97250162603282e-9,
          -6.33399662414676e-9,
          -5.031880728588781e-9,
          -3.997269904588512e-9,
          -3.1752972121707856e-9,
          -2.52230484934246e-9,
          -2.003576361320191e-9,
          -1.5915165218704362e-9,
          -1.2641961562174423e-9,
          -1.0041915360594657e-9,
          -7.976601133954408e-10
        ],
        "creal": [
          3.499999999932615e-7,
          3.499999999893202e-7,
          3.499999999830736e-7,
          3.4999999997317355e-7,
          3.4999999995748295e-7,
          3.499999999326149e-7,
          3.499999998932019e-7,
          3.4999999983073633e-7,
          3.499999997317351e-7,
          3.499999995748287e-7,
          3.49999999326149e-7,
          3.499999989320181e-7,
          3.4999999830736293e-7,
          3.49999997317351e-7,
          3.4999999574828777e-7,
          3.4999999326149036e-7,
          3.4999998932018195e-7,
          3.4999998307362927e-7,
          3.4999997317351104e-7,
          3.4999995748288214e-7,
          3.4999993261491416e-7,
          3.499998932018482e-7,
          3.4999983073636645e-7,
          3.499997317352953e-7,
          3.4999957482928626e-7,
          3.4999932615030894e-7,
          3.4999893202141445e-7,
          3.49998307371031e-7,
          3.4999731737145766e-7,
          3.4999574833934556e-7,
          3.4999326161984865e-7,
          3.499893205074281e-7,
          3.4998307444698987e-7,
          3.4997317556497636e-7,
          3.499574880412206e-7,
          3.49932627872232e-7,
          3.4989323439377307e-7,
          3.4983081810268285e-7,
          3.497319405494486e-7,
          3.495753446304448e-7,
          3.4932744387613087e-7,
          3.489352670569394e-7,
          3.48315509221438e-7,
          3.4733775622713374e-7,
          3.457993165474631e-7,
          3.4338877526737094e-7,
          3.396364136164716e-7,
          3.3385444464849246e-7,
          3.2508330203151615e-7,
          3.1208829580653534e-7,
          2.9349394680959746e-7,
          2.681709432611713e-7,
          2.3591095689609868e-7,
          1.9813513259577383e-7,
          1.5802960071061842e-7,
          1.1964631707153767e-7,
          8.63903867936406e-8,
          5.997149308330436e-8,
          4.0393727003007443e-8,
          2.6620525566400666e-8,
          1.7281488210253143e-8,
          1.1106257154384757e-8,
          7.090609255571025e-9,
          4.50757236550237e-9,
          2.85766786649825e-9,
          1.8085158519875001e-9,
          1.1432764880303834e-9,
          7.222293294753962e-10,
          4.5604318770260485e-10,
          2.878822284044058e-10,
          1.816965594766331e-10,
          1.1466474639648205e-10,
          7.235731213532172e-11,
          4.565786094398578e-11,
          2.880954964505804e-11,
          1.8178149137493008e-11,
          1.1469856552143953e-11,
          7.237077756231486e-12,
          4.566322207695601e-12,
          2.8811684063567273e-12,
          1.8178998893201e-12
        ]
},
b: {
    "fHz": [
      0.010000000000000009,
      0.012589254117941687,
      0.01584893192461116,
      0.01995262314968883,
      0.025118864315095846,
      0.03162277660168386,
      0.03981071705534982,
      0.05011872336272736,
      0.0630957344480195,
      0.07943282347242837,
      0.10000000000000028,
      0.12589254117941706,
      0.1584893192461118,
      0.19952623149688853,
      0.2511886431509587,
      0.3162277660168387,
      0.3981071705534983,
      0.5011872336272735,
      0.6309573444801948,
      0.7943282347242835,
      1.0000000000000024,
      1.2589254117941704,
      1.5848931924611174,
      1.9952623149688846,
      2.511886431509587,
      3.1622776601683875,
      3.981071705534982,
      5.011872336272735,
      6.309573444801948,
      7.9432823472428336,
      10.000000000000025,
      12.589254117941707,
      15.848931924611183,
      19.952623149688858,
      25.118864315095887,
      31.622776601683906,
      39.810717055349876,
      50.11872336272743,
      63.09573444801959,
      79.4328234724285,
      100.00000000000043,
      125.89254117941726,
      158.48931924611207,
      199.5262314968889,
      251.18864315095925,
      316.22776601683955,
      398.10717055349943,
      501.1872336272751,
      630.9573444801969,
      794.3282347242862,
      1000.0000000000061,
      1258.9254117941753,
      1584.8931924611238,
      1995.2623149688932,
      2511.8864315095975,
      3162.277660168402,
      3981.0717055350024,
      5011.872336272761,
      6309.573444801982,
      7943.282347242878,
      10000.000000000082,
      12589.254117941764,
      15848.931924611237,
      19952.62314968891,
      25118.86431509592,
      31622.77660168392,
      39810.71705534986,
      50118.72336272735,
      63095.73444801942,
      79432.82347242821,
      100000,
      125892.54117941661,
      158489.3192461111,
      199526.23149688746,
      251188.6431509572,
      316227.7660168366,
      398107.1705534953,
      501187.23362726945,
      630957.3444801891,
      794328.2347242757,
      999999.9999999919
    ],
    "Mreal": [
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007,
      25.000000000000007
    ],
    "Mimg": [
      -51340304223.19195,
      -40781053223.81563,
      -32393542017.470432,
      -25731105047.20412,
      -20438943249.6507,
      -16235229711.124804,
      -12896101356.780968,
      -10243737425.557236,
      -8136889866.221932,
      -6463361363.581963,
      -5134030422.319186,
      -4078105322.3815565,
      -3239354201.7470384,
      -2573110504.720409,
      -2043894324.9650676,
      -1623522971.1124794,
      -1289610135.6780965,
      -1024373742.5557237,
      -813688986.6221936,
      -646336136.3581965,
      -513403042.2319188,
      -407810532.2381558,
      -323935420.17470396,
      -257311050.47204095,
      -204389432.49650678,
      -162352297.11124796,
      -128961013.56780967,
      -102437374.25557236,
      -81368898.66221936,
      -64633613.63581966,
      -51340304.22319188,
      -40781053.22381557,
      -32393542.017470382,
      -25731105.04720408,
      -20438943.24965066,
      -16235229.711124776,
      -12896101.35678095,
      -10243737.42555722,
      -8136889.866221922,
      -6463361.363581954,
      -5134030.4223191785,
      -4078105.32238155,
      -3239354.201747033,
      -2573110.5047204043,
      -2043894.3249650635,
      -1623522.9711124753,
      -1289610.1356780927,
      -1024373.7425557204,
      -813688.9866221909,
      -646336.1363581943,
      -513403.04223191686,
      -407810.53223815415,
      -323935.42017470265,
      -257311.05047203985,
      -204389.4324965059,
      -162352.2971112472,
      -128961.013567809,
      -102437.37425557185,
      -81368.89866221891,
      -64633.6136358193,
      -51340.30422319158,
      -40781.053223815376,
      -32393.542017470263,
      -25731.10504720402,
      -20438.943249650634,
      -16235.22971112477,
      -12896.101356780955,
      -10243.737425557236,
      -8136.889866221942,
      -6463.361363581977,
      -5134.030422319201,
      -4078.105322381571,
      -3239.354201747053,
      -2573.110504720423,
      -2043.89432496508,
      -1623.5229711124905,
      -1289.610135678106,
      -1024.3737425557322,
      -813.6889866222008,
      -646.3361363582028,
      -513.4030422319242
    ],
    "fRad": [
      0.06283185307179592,
      0.0791006165022013,
      0.09958177620320631,
      0.12536602861381613,
      0.15782647919764783,
      0.19869176531592245,
      0.25013811247045775,
      0.3149052262472868,
      0.396442191629501,
      0.49909114934975174,
      0.6283185307179604,
      0.7910061650220143,
      0.9958177620320644,
      1.2536602861381627,
      1.5782647919764798,
      1.9869176531592252,
      2.501381124704578,
      3.149052262472867,
      3.964421916295009,
      4.990911493497515,
      6.283185307179601,
      7.9100616502201415,
      9.95817762032064,
      12.536602861381624,
      15.782647919764798,
      19.869176531592252,
      25.013811247045776,
      31.490522624728676,
      39.64421916295009,
      49.90911493497515,
      62.83185307179602,
      79.10061650220143,
      99.58177620320646,
      125.36602861381631,
      157.8264791976481,
      198.69176531592274,
      250.1381124704581,
      314.90522624728726,
      396.44219162950156,
      499.09114934975247,
      628.3185307179613,
      791.0061650220156,
      995.8177620320662,
      1253.660286138165,
      1578.2647919764834,
      1986.9176531592304,
      2501.381124704585,
      3149.0522624728774,
      3964.4219162950217,
      4990.911493497532,
      6283.185307179625,
      7910.061650220172,
      9958.177620320681,
      12536.602861381678,
      15782.647919764866,
      19869.176531592344,
      25013.811247045902,
      31490.522624728834,
      39644.2191629503,
      49909.11493497543,
      62831.85307179637,
      79100.6165022018,
      99581.77620320681,
      125366.02861381663,
      157826.47919764832,
      198691.76531592282,
      250138.112470458,
      314905.2262472868,
      396442.19162950054,
      499091.14934975066,
      628318.5307179586,
      791006.1650220115,
      995817.76203206,
      1253660.286138156,
      1578264.7919764705,
      1986917.6531592119,
      2501381.1247045593,
      3149052.262472842,
      3964421.9162949733,
      4990911.493497467,
      6283185.307179535
    ],
    "Mmod": [
      51340304223.19195,
      40781053223.81563,
      32393542017.470432,
      25731105047.20412,
      20438943249.6507,
      16235229711.124804,
      12896101356.780968,
      10243737425.557236,
      8136889866.221932,
      6463361363.581963,
      5134030422.319186,
      4078105322.3815565,
      3239354201.7470384,
      2573110504.720409,
      2043894324.9650679,
      1623522971.1124797,
      1289610135.6780968,
      1024373742.5557239,
      813688986.6221939,
      646336136.358197,
      513403042.2319194,
      407810532.23815656,
      323935420.1747049,
      257311050.47204217,
      204389432.4965083,
      162352297.1112499,
      128961013.5678121,
      102437374.25557542,
      81368898.6622232,
      64633613.635824494,
      51340304.22319797,
      40781053.22382323,
      32393542.01748003,
      25731105.047216225,
      20438943.24966595,
      16235229.711144025,
      12896101.356805183,
      10243737.425587727,
      8136889.866260327,
      6463361.363630303,
      5134030.422380047,
      4078105.3224581787,
      3239354.2018435025,
      2573110.5048418525,
      2043894.325117958,
      1623522.971304958,
      1289610.135920414,
      1024373.7428607848,
      813688.9870062444,
      646336.1368416888,
      513403.0428406004,
      407810.5330044414,
      323935.4211394011,
      257311.05168652325,
      204389.4340254499,
      162352.29903607367,
      128961.01599102194,
      102437.37730621619,
      81368.90250275255,
      64633.61847076463,
      51340.31031002699,
      40781.06088668688,
      32393.551664453396,
      25731.117192035174,
      20438.958539085088,
      16235.248959378125,
      12896.125588887833,
      10243.767931955605,
      8136.9282714686215,
      6463.409712856231,
      5134.091290316045,
      4078.1819503838838,
      3239.4506701563287,
      2573.2319501946554,
      2044.0472136485644,
      1623.715442351254,
      1289.852434212419,
      1024.6787615822032,
      814.0729494033468,
      646.819450204189,
      514.0113654122786
    ],
    "Mphz": [
      -89.99999997210001,
      -89.99999996487598,
      -89.99999995578149,
      -89.99999994433219,
      -89.99999992991837,
      -89.99999991177246,
      -89.99999988892809,
      -89.99999986016877,
      -89.99999982396291,
      -89.99999977838242,
      -89.999999721,
      -89.99999964875981,
      -89.9999995578148,
      -89.99999944332181,
      -89.9999992991837,
      -89.99999911772453,
      -89.999998889281,
      -89.99999860168762,
      -89.99999823962901,
      -89.99999778382423,
      -89.99999721,
      -89.99999648759811,
      -89.999995578148,
      -89.99999443321813,
      -89.99999299183686,
      -89.99999117724533,
      -89.99998889280995,
      -89.99998601687618,
      -89.9999823962901,
      -89.99997783824226,
      -89.99997210000001,
      -89.99996487598101,
      -89.99995578147994,
      -89.99994433218141,
      -89.99992991836857,
      -89.99991177245327,
      -89.99988892809941,
      -89.99986016876183,
      -89.9998239629009,
      -89.9997783824225,
      -89.99972100000001,
      -89.99964875981011,
      -89.99955781479932,
      -89.99944332181416,
      -89.99929918368564,
      -89.9991177245329,
      -89.99888928099429,
      -89.99860168761846,
      -89.99823962900945,
      -89.99778382422623,
      -89.99721000000221,
      -89.99648759810549,
      -89.99557814800181,
      -89.99443321815875,
      -89.99299183689105,
      -89.99117724539786,
      -89.98889281008069,
      -89.98601687645942,
      -89.98239629064292,
      -89.9778382433564,
      -89.9721000022052,
      -89.96487598541087,
      -89.95578148870936,
      -89.9443321989288,
      -89.92991840351075,
      -89.91177252301546,
      -89.88892823855338,
      -89.86016903943403,
      -89.82396345480566,
      -89.77838352771528,
      -89.72100220515902,
      -89.64876420994351,
      -89.55782357801067,
      -89.4433393295815,
      -89.29921863238414,
      -89.11779425713652,
      -88.88942010089781,
      -88.60196513603236,
      -88.24018261415925,
      -87.78492844733562,
      -87.21220205835725
    ],
    "tanDelta": [
      -2053612168.9276774,
      -1631242128.9526246,
      -1295741680.698817,
      -1029244201.8881645,
      -817557729.9860277,
      -649409188.444992,
      -515844054.27123857,
      -409749497.02228934,
      -325475594.6488772,
      -258534454.54327843,
      -205361216.8927674,
      -163124212.8952622,
      -129574168.0698815,
      -102924420.18881632,
      -81755772.99860269,
      -64940918.844499156,
      -51584405.427123845,
      -40974949.70222893,
      -32547559.464887735,
      -25853445.45432785,
      -20536121.689276747,
      -16312421.289526226,
      -12957416.806988155,
      -10292442.018881636,
      -8175577.299860269,
      -6494091.884449916,
      -5158440.542712386,
      -4097494.970222893,
      -3254755.9464887735,
      -2585344.5454327855,
      -2053612.1689276746,
      -1631242.1289526222,
      -1295741.6806988148,
      -1029244.201888163,
      -817557.7299860262,
      -649409.1884449909,
      -515844.05427123787,
      -409749.4970222887,
      -325475.5946488768,
      -258534.45454327806,
      -205361.2168927671,
      -163124.21289526194,
      -129574.16806988127,
      -102924.42018881615,
      -81755.77299860252,
      -64940.91884449899,
      -51584.4054271237,
      -40974.949702228805,
      -32547.55946488763,
      -25853.445454327764,
      -20536.121689276668,
      -16312.42128952616,
      -12957.416806988102,
      -10292.442018881591,
      -8175.577299860234,
      -6494.0918844498865,
      -5158.440542712358,
      -4097.494970222873,
      -3254.7559464887554,
      -2585.3445454327716,
      -2053.612168927663,
      -1631.2421289526146,
      -1295.7416806988101,
      -1029.2442018881604,
      -817.5577299860252,
      -649.4091884449906,
      -515.8440542712381,
      -409.7494970222893,
      -325.4755946488776,
      -258.53445454327897,
      -205.36121689276797,
      -163.1242128952628,
      -129.5741680698821,
      -102.92442018881688,
      -81.75577299860318,
      -64.9409188444996,
      -51.584405427124224,
      -40.974949702229274,
      -32.547559464888025,
      -25.853445454328103,
      -20.536121689276964
    ],
    "nimg": [
      397.8873577297381,
      316.0531624845715,
      251.0499506353961,
      199.41606411583217,
      158.40181018479308,
      125.82303026121735,
      99.94478551505262,
      79.38896504806867,
      63.060896463220054,
      50.09105056776027,
      39.78873577297374,
      31.6053162484571,
      25.10499506353958,
      19.941606411583194,
      15.840181018479294,
      12.58230302612173,
      9.994478551505258,
      7.938896504806869,
      6.3060896463220075,
      5.009105056776029,
      3.978873577297375,
      3.1605316248457105,
      2.5104995063539586,
      1.99416064115832,
      1.5840181018479293,
      1.258230302612173,
      0.999447855150526,
      0.7938896504806867,
      0.6306089646322007,
      0.500910505677603,
      0.3978873577297375,
      0.316053162484571,
      0.25104995063539576,
      0.19941606411583188,
      0.15840181018479282,
      0.12582303026121716,
      0.09994478551505247,
      0.07938896504806855,
      0.06306089646321997,
      0.0500910505677602,
      0.03978873577297368,
      0.03160531624845705,
      0.025104995063539533,
      0.019941606411583157,
      0.015840181018479258,
      0.012582303026121698,
      0.009994478551505231,
      0.007938896504806843,
      0.006306089646321987,
      0.005009105056776012,
      0.00397887357729736,
      0.0031605316248456985,
      0.0025104995063539486,
      0.0019941606411583114,
      0.0015840181018479226,
      0.0012582303026121673,
      0.000999447855150521,
      0.0007938896504806828,
      0.0006306089646321974,
      0.0005009105056776002,
      0.00039788735772973525,
      0.00031605316248456953,
      0.0002510499506353948,
      0.00019941606411583138,
      0.0001584018101847926,
      0.00012582303026121712,
      0.00009994478551505252,
      0.00007938896504806867,
      0.00006306089646322013,
      0.00005009105056776038,
      0.00003978873577297385,
      0.00003160531624845721,
      0.00002510499506353969,
      0.000019941606411583303,
      0.00001584018101847939,
      0.000012582303026121815,
      0.000009994478551505334,
      0.000007938896504806932,
      0.0000063060896463220635,
      0.000005009105056776078,
      0.000003978873577297417
    ],
    "nreal": [
      -817106319696.27,
      -515559233633.54224,
      -325295884975.66315,
      -205247827754.57874,
      -129502624360.35709,
      -81710631969.62683,
      -51555923363.354095,
      -32529588497.566242,
      -20524782775.457825,
      -12950262436.035679,
      -8171063196.962672,
      -5155592336.335406,
      -3252958849.756623,
      -2052478277.5457823,
      -1295026243.603568,
      -817106319.6962675,
      -515559233.63354075,
      -325295884.9756625,
      -205247827.75457838,
      -129502624.36035688,
      -81710631.96962678,
      -51555923.36335409,
      -32529588.49756625,
      -20524782.775457833,
      -12950262.436035683,
      -8171063.196962676,
      -5155592.336335409,
      -3252958.849756625,
      -2052478.2775457841,
      -1295026.2436035692,
      -817106.3196962677,
      -515559.2336335406,
      -325295.88497566216,
      -205247.82775457812,
      -129502.62436035663,
      -81710.63196962657,
      -51555.923363353955,
      -32529.588497566147,
      -20524.78277545777,
      -12950.262436035642,
      -8171.063196962648,
      -5155.5923363353895,
      -3252.958849756611,
      -2052.4782775457747,
      -1295.0262436035628,
      -817.1063196962633,
      -515.5592336335378,
      -325.29588497566044,
      -205.24782775457706,
      -129.502624360356,
      -81.71063196962616,
      -51.555923363353685,
      -32.52958849756599,
      -20.52478277545766,
      -12.950262436035572,
      -8.1710631969626,
      -5.155592336335356,
      -3.252958849756592,
      -2.0524782775457617,
      -1.295026243603555,
      -0.8171063196962584,
      -0.5155592336335358,
      -0.3252958849756598,
      -0.2052478277545771,
      -0.12950262436035628,
      -0.08171063196962651,
      -0.051555923363353996,
      -0.03252958849756624,
      -0.020524782775457873,
      -0.012950262436035734,
      -0.008171063196962718,
      -0.005155592336335443,
      -0.003252958849756652,
      -0.0020524782775458044,
      -0.0012950262436035836,
      -0.0008171063196962785,
      -0.0005155592336335484,
      -0.00032529588497566786,
      -0.00020524782775458206,
      -0.0001295026243603594,
      -0.00008171063196962851
    ],
    "nmod": [
      817106319696.27,
      515559233633.54224,
      325295884975.66315,
      205247827754.57874,
      129502624360.35709,
      81710631969.62683,
      51555923363.354095,
      32529588497.566242,
      20524782775.457825,
      12950262436.035679,
      8171063196.962672,
      5155592336.335406,
      3252958849.756623,
      2052478277.5457823,
      1295026243.6035683,
      817106319.6962676,
      515559233.63354087,
      325295884.9756626,
      205247827.75457847,
      129502624.36035699,
      81710631.96962687,
      51555923.363354184,
      32529588.497566346,
      20524782.77545793,
      12950262.436035778,
      8171063.196962773,
      5155592.336335506,
      3252958.849756722,
      2052478.277545881,
      1295026.243603666,
      817106.3196963646,
      515559.2336336374,
      325295.8849757591,
      205247.82775467497,
      129502.6243604535,
      81710.63196972344,
      51555.923363450835,
      32529.588497663022,
      20524.782775554646,
      12950.262436132516,
      8171.063197059523,
      5155.592336432264,
      3252.958849853486,
      2052.47827764265,
      1295.0262437004378,
      817.1063197931383,
      515.5592337304128,
      325.29588507253544,
      205.2478278514521,
      129.50262445723098,
      81.71063206650116,
      51.55592346022869,
      32.52958859444099,
      20.524782872332658,
      12.950262532910571,
      8.1710632938376,
      5.155592433210355,
      3.252958946631591,
      2.0524783744207595,
      1.2950263404785511,
      0.8171064165712526,
      0.5155593305085268,
      0.3252959818506454,
      0.20524792462955424,
      0.12950272123532003,
      0.08171072884456908,
      0.05155602023826297,
      0.032529685372422,
      0.02052487965022925,
      0.012950359310673398,
      0.008171160071388458,
      0.005155689210425306,
      0.003253055723314198,
      0.0020525751502597092,
      0.0012951231149804664,
      0.000817203188954276,
      0.0005156560995337185,
      0.00032539274555499386,
      0.00020534467990333236,
      0.000129599463153557,
      0.00008180744961077928
    ],
    "cimg": [
      -1.5095352700499003e-19,
      -1.9003923114653904e-19,
      -2.3924521732820357e-19,
      -3.0119188374469387e-19,
      -3.7917811627234956e-19,
      -4.773569661715045e-19,
      -6.009568152102758e-19,
      -7.565598060591081e-19,
      -9.524523653898783e-19,
      -1.199066486312781e-18,
      -1.5095352700499024e-18,
      -1.9003923114653934e-18,
      -2.3924521732820394e-18,
      -3.011918837446942e-18,
      -3.7917811627235006e-18,
      -4.773569661715048e-18,
      -6.009568152102758e-18,
      -7.565598060591077e-18,
      -9.524523653898768e-18,
      -1.199066486312779e-17,
      -1.5095352700498983e-17,
      -1.9003923114653852e-17,
      -2.3924521732820248e-17,
      -3.0119188374469134e-17,
      -3.791781162723444e-17,
      -4.773569661714935e-17,
      -6.009568152102534e-17,
      -7.565598060590628e-17,
      -9.524523653897877e-17,
      -1.1990664863126009e-16,
      -1.509535270049544e-16,
      -1.900392311464679e-16,
      -2.3924521732806147e-16,
      -3.0119188374441e-16,
      -3.7917811627178314e-16,
      -4.773569661703735e-16,
      -6.009568152080183e-16,
      -7.56559806054603e-16,
      -9.524523653808882e-16,
      -1.1990664862948432e-15,
      -1.5095352700141113e-15,
      -1.9003923113939784e-15,
      -2.3924521731395466e-15,
      -3.0119188371626287e-15,
      -3.7917811621562164e-15,
      -4.773569660583165e-15,
      -6.0095681498443505e-15,
      -7.565598056084947e-15,
      -9.524523644907841e-15,
      -1.1990664845188512e-14,
      -1.5095352664705395e-14,
      -1.9003923043236212e-14,
      -2.3924521590323262e-14,
      -3.0119188090150207e-14,
      -3.79178110599435e-14,
      -4.7735695485255043e-14,
      -6.009567926259919e-14,
      -7.565597609975354e-14,
      -9.524522754802209e-14,
      -1.1990663069194363e-13,
      -1.5095349121131502e-13,
      -1.9003915972877712e-13,
      -2.392450748310649e-13,
      -3.011915994256207e-13,
      -3.7917754898152974e-13,
      -4.77355834278499e-13,
      -6.00954556789948e-13,
      -7.565552999280399e-13,
      -9.52443374507692e-13,
      -1.1990485472434218e-12,
      -1.5094994772140508e-12,
      -1.900320896359294e-12,
      -2.3923096845439943e-12,
      -3.0116345449409028e-12,
      -3.7912139559138965e-12,
      -4.772438034352864e-12,
      -6.007310571698108e-12,
      -7.56109458500615e-12,
      -9.515541166136905e-12,
      -1.1972752324487434e-11,
      -1.5059643688167797e-11
    ],
    "creal": [
      3.100000000000003e-10,
      3.1000000000000027e-10,
      3.100000000000002e-10,
      3.1000000000000027e-10,
      3.100000000000002e-10,
      3.1000000000000027e-10,
      3.100000000000002e-10,
      3.1000000000000027e-10,
      3.1000000000000027e-10,
      3.1000000000000027e-10,
      3.1000000000000017e-10,
      3.100000000000003e-10,
      3.100000000000002e-10,
      3.1000000000000027e-10,
      3.100000000000002e-10,
      3.100000000000002e-10,
      3.1000000000000017e-10,
      3.100000000000001e-10,
      3.0999999999999996e-10,
      3.099999999999998e-10,
      3.099999999999995e-10,
      3.0999999999999903e-10,
      3.0999999999999846e-10,
      3.099999999999974e-10,
      3.099999999999956e-10,
      3.099999999999929e-10,
      3.099999999999886e-10,
      3.0999999999998177e-10,
      3.0999999999997096e-10,
      3.099999999999539e-10,
      3.099999999999267e-10,
      3.0999999999988374e-10,
      3.099999999998156e-10,
      3.099999999997076e-10,
      3.0999999999953654e-10,
      3.099999999992652e-10,
      3.0999999999883524e-10,
      3.099999999981539e-10,
      3.0999999999707386e-10,
      3.099999999953623e-10,
      3.0999999999264964e-10,
      3.099999999883503e-10,
      3.099999999815363e-10,
      3.099999999707369e-10,
      3.099999999536209e-10,
      3.099999999264939e-10,
      3.099999998835006e-10,
      3.099999998153607e-10,
      3.099999997073662e-10,
      3.099999995362066e-10,
      3.099999992649368e-10,
      3.099999988350032e-10,
      3.0999999815360436e-10,
      3.0999999707365995e-10,
      3.099999953620634e-10,
      3.0999999264936585e-10,
      3.0999998835003e-10,
      3.09999981536042e-10,
      3.0999997073659956e-10,
      3.0999995362063814e-10,
      3.099999264936715e-10,
      3.0999988350033636e-10,
      3.0999981536051666e-10,
      3.0999970736624154e-10,
      3.099995362070043e-10,
      3.0999926493828147e-10,
      3.099988350073018e-10,
      3.099981536150616e-10,
      3.0999707368727467e-10,
      3.0999536213248917e-10,
      3.099926495396745e-10,
      3.099883504670301e-10,
      3.099815371403101e-10,
      3.099707393586538e-10,
      3.099536275688329e-10,
      3.0992651107931255e-10,
      3.0988354405712457e-10,
      3.098154703144251e-10,
      3.097076419454308e-10,
      3.095368991593159e-10,
      3.0926667537736567e-10
    ]
  },
c: {
    "fHz": [
      0.010000000000000009,
      0.012589254117941687,
      0.01584893192461116,
      0.01995262314968883,
      0.025118864315095846,
      0.03162277660168386,
      0.03981071705534982,
      0.05011872336272736,
      0.0630957344480195,
      0.07943282347242837,
      0.10000000000000028,
      0.12589254117941706,
      0.1584893192461118,
      0.19952623149688853,
      0.2511886431509587,
      0.3162277660168387,
      0.3981071705534983,
      0.5011872336272735,
      0.6309573444801948,
      0.7943282347242835,
      1.0000000000000024,
      1.2589254117941704,
      1.5848931924611174,
      1.9952623149688846,
      2.511886431509587,
      3.1622776601683875,
      3.981071705534982,
      5.011872336272735,
      6.309573444801948,
      7.9432823472428336,
      10.000000000000025,
      12.589254117941707,
      15.848931924611183,
      19.952623149688858,
      25.118864315095887,
      31.622776601683906,
      39.810717055349876,
      50.11872336272743,
      63.09573444801959,
      79.4328234724285,
      100.00000000000043,
      125.89254117941726,
      158.48931924611207,
      199.5262314968889,
      251.18864315095925,
      316.22776601683955,
      398.10717055349943,
      501.1872336272751,
      630.9573444801969,
      794.3282347242862,
      1000.0000000000061,
      1258.9254117941753,
      1584.8931924611238,
      1995.2623149688932,
      2511.8864315095975,
      3162.277660168402,
      3981.0717055350024,
      5011.872336272761,
      6309.573444801982,
      7943.282347242878,
      10000.000000000082,
      12589.254117941764,
      15848.931924611237,
      19952.62314968891,
      25118.86431509592,
      31622.77660168392,
      39810.71705534986,
      50118.72336272735,
      63095.73444801942,
      79432.82347242821,
      100000,
      125892.54117941661,
      158489.3192461111,
      199526.23149688746,
      251188.6431509572,
      316227.7660168366,
      398107.1705534953,
      501187.23362726945,
      630957.3444801891,
      794328.2347242757,
      999999.9999999919
    ],
    "Mreal": [
      1525810060.3093097,
      1217588326.9569924,
      971629021.2413167,
      775354801.9960876,
      618729017.7907243,
      493742474.10566425,
      394003876.26499915,
      314413005.0725913,
      250899912.70602003,
      200216802.42553598,
      159771949.6874926,
      127497172.0751984,
      101742071.0078322,
      81189637.18602411,
      64788904.185794614,
      51701206.3843109,
      41257293.07015811,
      32923105.731176905,
      26272468.51197386,
      20965295.344103172,
      16730199.351731189,
      13350615.249748452,
      10653725.091190912,
      8501621.07251671,
      6784253.4991666265,
      5413803.29954697,
      4320191.321085139,
      3447494.772339982,
      2751087.562844247,
      2195358.309491533,
      1751889.3177566677,
      1398003.4183841585,
      1115604.3507858475,
      890251.3951529638,
      710420.9337977882,
      566917.1810274081,
      452401.94871362695,
      361019.4017159726,
      288096.61470282776,
      229904.61808556426,
      183467.71394347164,
      146411.31114490953,
      116840.49858239475,
      93243.14725931083,
      74412.58626989399,
      59385.898642063636,
      47394.681747819646,
      37825.75437233489,
      30189.80116384497,
      24096.351071243185,
      19233.810270214355,
      15353.528544369585,
      12257.084342042494,
      9786.138319163698,
      7814.336520380487,
      6240.849160606062,
      4985.214607022751,
      3983.2249041131645,
      3183.642444494104,
      2545.579889685427,
      2036.4093608034696,
      1630.0939838422498,
      1305.856471671908,
      1047.1166595469883,
      840.6436640865884,
      675.8793106448842,
      544.3982319472617,
      439.4770296702954,
      355.75046767150747,
      288.9371160132617,
      235.62041635141452,
      193.07397327859817,
      159.1221377443413,
      132.0287533650082,
      110.40837657019114,
      93.1554307511953,
      79.38767164791447,
      68.40107302791094,
      59.63382569800243,
      52.637608906771064,
      47.05466507639345
    ],
    "Mimg": [
      -48552062570.98814,
      -38744287987.550896,
      -30917735976.046097,
      -24672189051.238773,
      -19688275786.15967,
      -15711139478.82216,
      -12537405835.026247,
      -10004783248.473475,
      -7983763879.5494795,
      -6371001160.282455,
      -5084025078.483514,
      -4057025002.5670023,
      -3237484398.1618176,
      -2583495349.8460927,
      -2061615563.7587044,
      -1645158267.068421,
      -1312827556.8355908,
      -1047629415.6537993,
      -836002707.9173867,
      -667125719.4597167,
      -532362779.8686806,
      -424822670.03441834,
      -339006233.7184624,
      -270525173.45805913,
      -215877651.1917789,
      -172269218.75092903,
      -137469921.3430462,
      -109700266.89089751,
      -87540230.17081533,
      -69856638.5985265,
      -55745226.47202319,
      -44484394.565224655,
      -35498310.52938175,
      -28327463.20943548,
      -22605165.144908592,
      -18038801.690452296,
      -14394867.913661508,
      -11487028.119026154,
      -9166587.411480727,
      -7314888.054739137,
      -5837241.805641813,
      -4658087.949200697,
      -3717129.4362891396,
      -2966249.542046141,
      -2367051.3756638905,
      -1888894.4222701383,
      -1507327.713781685,
      -1202839.5075695694,
      -959859.5366765454,
      -765962.8107913852,
      -611234.253656371,
      -487761.68709399033,
      -389231.2349538705,
      -310604.45761194173,
      -247860.70701608062,
      -197791.527381317,
      -157836.58803690958,
      -125952.75870995795,
      -100509.63229729189,
      -80206.15259249667,
      -64004.08365501689,
      -51074.91872015875,
      -40757.51379133043,
      -32524.27947173299,
      -25954.20222566185,
      -20711.315488356868,
      -16527.519726039776,
      -13188.872935096433,
      -10524.650533259366,
      -8398.615210893055,
      -6702.050318700678,
      -5348.200548125795,
      -4267.835623848067,
      -3405.71015396377,
      -2717.7386092376923,
      -2168.740971554739,
      -1730.6437733610762,
      -1381.044536695544,
      -1102.066203163509,
      -879.442975142071,
      -701.7908219185165
    ],
    "fRad": [
      0.06283185307179592,
      0.0791006165022013,
      0.09958177620320631,
      0.12536602861381613,
      0.15782647919764783,
      0.19869176531592245,
      0.25013811247045775,
      0.3149052262472868,
      0.396442191629501,
      0.49909114934975174,
      0.6283185307179604,
      0.7910061650220143,
      0.9958177620320644,
      1.2536602861381627,
      1.5782647919764798,
      1.9869176531592252,
      2.501381124704578,
      3.149052262472867,
      3.964421916295009,
      4.990911493497515,
      6.283185307179601,
      7.9100616502201415,
      9.95817762032064,
      12.536602861381624,
      15.782647919764798,
      19.869176531592252,
      25.013811247045776,
      31.490522624728676,
      39.64421916295009,
      49.90911493497515,
      62.83185307179602,
      79.10061650220143,
      99.58177620320646,
      125.36602861381631,
      157.8264791976481,
      198.69176531592274,
      250.1381124704581,
      314.90522624728726,
      396.44219162950156,
      499.09114934975247,
      628.3185307179613,
      791.0061650220156,
      995.8177620320662,
      1253.660286138165,
      1578.2647919764834,
      1986.9176531592304,
      2501.381124704585,
      3149.0522624728774,
      3964.4219162950217,
      4990.911493497532,
      6283.185307179625,
      7910.061650220172,
      9958.177620320681,
      12536.602861381678,
      15782.647919764866,
      19869.176531592344,
      25013.811247045902,
      31490.522624728834,
      39644.2191629503,
      49909.11493497543,
      62831.85307179637,
      79100.6165022018,
      99581.77620320681,
      125366.02861381663,
      157826.47919764832,
      198691.76531592282,
      250138.112470458,
      314905.2262472868,
      396442.19162950054,
      499091.14934975066,
      628318.5307179586,
      791006.1650220115,
      995817.76203206,
      1253660.286138156,
      1578264.7919764705,
      1986917.6531592119,
      2501381.1247045593,
      3149052.262472842,
      3964421.9162949733,
      4990911.493497467,
      6283185.307179535
    ],
    "Mmod": [
      48576031911.19349,
      38763415393.850716,
      30932999544.813198,
      24684369298.182297,
      19697995558.668842,
      15718895805.804562,
      12543595342.909544,
      10009722443.03978,
      7987705330.731401,
      6374146417.544384,
      5086534977.227252,
      4059027888.5887146,
      3239082690.724976,
      2584770778.9788213,
      2062633349.5892372,
      1645970454.9125915,
      1313475678.578931,
      1048146613.5203162,
      836415429.2257621,
      667455069.0297513,
      532625599.23445094,
      425032398.650643,
      339173596.19860655,
      270658727.98669106,
      215984227.15471682,
      172354265.9617731,
      137537788.72409606,
      109754424.85904364,
      87583448.10029897,
      69891126.43527928,
      55772747.74115782,
      44506356.550412014,
      35515836.23551482,
      28341448.788452797,
      22616325.721296296,
      18047707.92975132,
      14401975.203942046,
      11492699.857549265,
      9171113.587330611,
      7318500.077664985,
      5840124.339394851,
      4660388.3544743275,
      3718965.306135054,
      2967714.7150964094,
      2368220.7346503176,
      1889827.7232173213,
      1508072.641682853,
      1203434.1147998257,
      960334.1888338793,
      766341.7394677051,
      611536.7955408501,
      488003.27277133963,
      389424.17796059226,
      310758.5840996356,
      247983.85822017753,
      197889.9605894137,
      157915.29656056498,
      126015.72722198462,
      100560.04059143574,
      80246.53818493127,
      64036.47154242001,
      51100.924929662295,
      40778.42801745915,
      32541.131026042913,
      25967.81267146926,
      20722.34065207907,
      16536.483233432107,
      13196.192987287168,
      10530.661291793842,
      8403.583885322634,
      6706.190830493677,
      5351.684469597459,
      4270.8009514500845,
      3408.268364511082,
      2719.9803598110943,
      2170.7407343988907,
      1732.4636425285066,
      1382.7374006397524,
      1103.6784447123232,
      881.0168354794132,
      703.3665468548519
    ],
    "Mphz": [
      -88.19999997052686,
      -88.199999963066,
      -88.1999999537165,
      -88.19999994200023,
      -88.19999992731809,
      -88.19999990891932,
      -88.19999988586305,
      -88.1999998569703,
      -88.19999982076358,
      -88.19999977539148,
      -88.19999971853379,
      -88.19999964728311,
      -88.19999955799595,
      -88.1999994461065,
      -88.19999930589326,
      -88.19999913018627,
      -88.19999891000062,
      -88.19999863407688,
      -88.1999982883055,
      -88.19999785500514,
      -88.19999731201864,
      -88.19999663157986,
      -88.19999577889405,
      -88.19999471035834,
      -88.19999337133223,
      -88.19999169334349,
      -88.1999895905867,
      -88.1999869555356,
      -88.19998365344459,
      -88.19997951545838,
      -88.19997432997754,
      -88.199967831838,
      -88.19995968875186,
      -88.19994948431541,
      -88.19993669671611,
      -88.19992067204915,
      -88.19990059087955,
      -88.19987542633973,
      -88.1998438916197,
      -88.19980437416513,
      -88.19975485321811,
      -88.19969279648417,
      -88.19961503064164,
      -88.19951757907177,
      -88.19939545851166,
      -88.19924242423261,
      -88.19905065071264,
      -88.19881033147547,
      -88.19850917763377,
      -88.19813178949529,
      -88.19765886910083,
      -88.19706623342891,
      -88.19632357781147,
      -88.19539292633442,
      -88.1942266899938,
      -88.19276523332888,
      -88.19093382512796,
      -88.18863881732418,
      -88.18576285675773,
      -88.18215888506677,
      -88.17764262006537,
      -88.17198313442194,
      -88.16489105032205,
      -88.15600374716031,
      -88.1448668269958,
      -88.13091089184347,
      -88.11342244827775,
      -88.09150745638864,
      -88.06404566708152,
      -88.02963342582719,
      -87.98651203989965,
      -87.93247808278619,
      -87.8647711114897,
      -87.77993316238702,
      -87.67363302781293,
      -87.54044665736892,
      -87.37358304337165,
      -87.16454263695132,
      -86.90269276975309,
      -86.57474195060337,
      -86.16409280962084
    ],
    "tanDelta": [
      -31.820515432402996,
      -31.82051530042257,
      -31.820515135032466,
      -31.82051492777531,
      -31.820514668052844,
      -31.820514342583923,
      -31.820513934725447,
      -31.820513423621207,
      -31.820512783135452,
      -31.820511980516414,
      -31.820510974721525,
      -31.82050971431861,
      -31.820508134855963,
      -31.82050615556652,
      -31.820503675237756,
      -31.820500567036206,
      -31.82049667202172,
      -31.820491791020032,
      -31.82048567443796,
      -31.820478009500427,
      -31.820468404256843,
      -31.82045636753877,
      -31.820441283844605,
      -31.820422381866564,
      -31.820398695051296,
      -31.820369012177558,
      -31.820331815424485,
      -31.82028520276439,
      -31.82022679071354,
      -31.820153592470287,
      -31.82006186521313,
      -31.819946918756926,
      -31.819802875792156,
      -31.819622371463097,
      -31.819396176947187,
      -31.81911272782575,
      -31.8187575331899,
      -31.81831243536165,
      -31.81775468252579,
      -31.81705576708657,
      -31.816179970718608,
      -31.815082542293393,
      -31.81370741642169,
      -31.811984357380695,
      -31.809825384627942,
      -31.807120300646847,
      -31.803731097973422,
      -31.799484968085807,
      -31.794165568273577,
      -31.7875021212441,
      -31.779155823478913,
      -31.768702919620473,
      -31.755613659178742,
      -31.739226187279694,
      -31.718714233721286,
      -31.693047258669694,
      -31.66094149980277,
      -31.620800166191067,
      -31.570640877437903,
      -31.508008417841577,
      -31.429871069618315,
      -31.3324993690066,
      -31.211327336110653,
      -31.06079840789076,
      -30.874201917482726,
      -30.64351158877071,
      -30.359245780285473,
      -30.010380622147633,
      -29.584361763868735,
      -29.067277083597574,
      -28.444268211057523,
      -27.700266676589035,
      -26.821130512368573,
      -25.79521556602375,
      -24.615329865935617,
      -23.280886085397782,
      -21.799905922880665,
      -20.19039286316475,
      -18.480555125619336,
      -16.70750236204106,
      -14.91437290604781
    ],
    "nimg": [
      24284021331.756935,
      15392905653.562231,
      9757096712.74203,
      6184728116.294805,
      3920311857.2763896,
      2484966970.4258122,
      1575145316.2161863,
      998436922.8146472,
      632878936.711411,
      401162798.99251145,
      254284955.6019398,
      161183537.76882392,
      102169367.61623679,
      64762071.57851725,
      41050718.81167589,
      26020809.821738362,
      16493805.227314467,
      10454925.16066509,
      6627061.666667171,
      4200694.676998004,
      2662693.9257408353,
      1687801.6683190954,
      1069846.8632906224,
      678143.9251541999,
      429855.2140082036,
      272472.45455487055,
      172712.23798793837,
      109477.21679387928,
      69394.41918470939,
      43987.121638037235,
      27882.18446708611,
      17673.73606178216,
      11202.896687736786,
      7101.217171801286,
      4501.278476269619,
      2853.2495049606196,
      1808.608629231008,
      1146.4382665801584,
      726.7052316471677,
      460.64655401142363,
      291.9979357187324,
      185.09503164344434,
      117.33120560530068,
      74.37672572889859,
      47.14835346273299,
      29.88845488771974,
      18.947405207359992,
      12.011789967128463,
      7.615183701753688,
      4.828046160032571,
      3.061155978996259,
      1.941012500698048,
      1.2308561676014553,
      0.7806052746002957,
      0.4951220200885598,
      0.3140970211162501,
      0.19929848185815738,
      0.12648964107649402,
      0.08030533862726177,
      0.05100430839140226,
      0.032410461593047654,
      0.02060785434961655,
      0.01311340811000573,
      0.008352475316679094,
      0.005326379124467691,
      0.0034016473182480723,
      0.0021763905810697147,
      0.0013955850619169644,
      0.0008973577363430026,
      0.0005789265475649254,
      0.00037500153955698066,
      0.00024408655939265082,
      0.0001597904193028628,
      0.0001053146173846799,
      0.00006995554683313062,
      0.00004688439432961782,
      0.00003173753526156117,
      0.00002172116158345303,
      0.000015042250032189906,
      0.000010546692518060335,
      0.000007488982542441656
    ],
    "nreal": [
      -772730075547.9751,
      -489810189867.13806,
      -310475843621.7832,
      -196801233348.7905,
      -124746340957.80486,
      -79072927123.28188,
      -50121933483.87468,
      -31770775505.062534,
      -20138532295.802628,
      -12765205651.47871,
      -8091477220.438101,
      -5128942329.361102,
      -3251081193.3655524,
      -2060761897.3114479,
      -1306254548.8180842,
      -827995193.6873667,
      -524841074.34473443,
      -332680860.2506723,
      -210876320.82779968,
      -133668112.5940405,
      -84728167.93424286,
      -53706619.34380692,
      -34042999.29604457,
      -21578826.13410355,
      -13678164.290887635,
      -8670174.04958976,
      -5495760.72136076,
      -3483596.2615861064,
      -2208146.1564672953,
      -1399676.966613018,
      -887212.8346799646,
      -562377.3433420284,
      -356473.9642416494,
      -225958.0487844661,
      -143227.96313918818,
      -90787.86763895497,
      -57547.679445936395,
      -36477.73095390191,
      -23122.12878705766,
      -14656.417097897722,
      -9290.258873905514,
      -5888.813709904589,
      -3732.740645943052,
      -2366.0712354409166,
      -1499.7808908222546,
      -950.665680214158,
      -602.5981802192185,
      -381.96873449950544,
      -242.11841144637523,
      -153.47152755349978,
      -97.28095285649626,
      -61.66344949794592,
      -39.08659292836917,
      -24.775807373522362,
      -15.70463386601184,
      -9.954691734044687,
      -6.309977575110625,
      -3.9997036635730505,
      -2.5352910063423235,
      -1.6070441781424902,
      -1.018656629176304,
      -0.6456955834059405,
      -0.409286873013397,
      -0.2594345520182529,
      -0.16444770457788035,
      -0.1042384190176456,
      -0.06607357656459378,
      -0.041882038898711574,
      -0.02654775590357773,
      -0.016827818369120215,
      -0.010666644370718256,
      -0.006761262787347516,
      -0.004285759690748182,
      -0.0027166132576911303,
      -0.001721978861249418,
      -0.0010915102435707022,
      -0.0006918752829261412,
      -0.0004385587858141984,
      -0.0002779891309332348,
      -0.0001762088901572138,
      -0.00011169347832485686
    ],
    "nmod": [
      773111559445.6084,
      490052000957.1855,
      310629120349.20325,
      196898390825.00793,
      124807926140.20633,
      79111964105.86678,
      50146677845.388275,
      31786460206.854134,
      20148474353.598545,
      12771507621.100945,
      8095471848.355362,
      5131474403.206136,
      3252686198.4418797,
      2061779261.5422773,
      1306899425.2898316,
      828403961.4301462,
      525100179.8992376,
      332845099.4640637,
      210980427.13058218,
      133734102.4579088,
      84769996.93544549,
      53733133.50077545,
      34059805.832995936,
      21589479.3014814,
      13684917.021068258,
      8674454.408703227,
      5498473.917697759,
      3485316.0796021973,
      2209236.301017904,
      1400367.9794029207,
      887650.8492822585,
      562654.9895369445,
      356649.9573480317,
      226069.60674934668,
      143298.6773592889,
      90832.69203962837,
      57576.092910043655,
      36495.74189195683,
      23133.545775323415,
      14663.654298818943,
      9294.846568859764,
      5891.7219113514975,
      3734.5842260798117,
      2367.239951612649,
      1500.5218051430782,
      951.1354032274389,
      602.8959868564441,
      382.157555509986,
      242.23813940857394,
      153.54745129544824,
      97.32910389290345,
      61.693991064880805,
      39.10596826129435,
      24.788101492543127,
      15.712436815473994,
      9.959645799853112,
      6.313124177716603,
      4.001703265573216,
      2.5365625232294806,
      1.6078533608436305,
      1.019172098413955,
      0.6460243571962537,
      0.4094968936308847,
      0.25956897084364167,
      0.16453394134801297,
      0.10429390779799175,
      0.06610941079754534,
      0.04190528415341238,
      0.02656291765644205,
      0.016837773814004486,
      0.010673234199906116,
      0.0067656672049433856,
      0.004288737472140598,
      0.002718653850805228,
      0.0017233992506446567,
      0.001092516708454122,
      0.0006926028286605583,
      0.00043909636468019,
      0.0002783958085227687,
      0.0001765242354281914,
      0.00011194426273742781
    ],
    "cimg": [
      -1.0291449216192954e-11,
      -1.0244164344601981e-11,
      -1.0197096737751945e-11,
      -1.0150245399997193e-11,
      -1.0103609340908646e-11,
      -1.0057187575408632e-11,
      -1.0010979123944442e-11,
      -9.964983012710042e-12,
      -9.919198273927932e-12,
      -9.87362394620594e-12,
      -9.828259074987512e-12,
      -9.783102713118606e-12,
      -9.738153921559935e-12,
      -9.693411770280613e-12,
      -9.648875339377937e-12,
      -9.604543720479307e-12,
      -9.560416018496036e-12,
      -9.51649135381605e-12,
      -9.47276886504405e-12,
      -9.429247712424578e-12,
      -9.385927082116818e-12,
      -9.342806191531942e-12,
      -9.299884295995661e-12,
      -9.257160697063969e-12,
      -9.214634752900963e-12,
      -9.172305891228724e-12,
      -9.130173625485768e-12,
      -9.088237574987509e-12,
      -9.046497490079023e-12,
      -9.004953283514823e-12,
      -8.963605069606369e-12,
      -8.922453213058582e-12,
      -8.881498389892536e-12,
      -8.840741663444088e-12,
      -8.800184579167753e-12,
      -8.759829282898361e-12,
      -8.719678668373011e-12,
      -8.679736561252027e-12,
      -8.640007948668118e-12,
      -8.600499265566231e-12,
      -8.561218751883291e-12,
      -8.522176898091814e-12,
      -8.483387000966837e-12,
      -8.444865856842667e-12,
      -8.406634626370921e-12,
      -8.368719913204564e-12,
      -8.33115510952647e-12,
      -8.293982074430986e-12,
      -8.257253227493013e-12,
      -8.221034160223895e-12,
      -8.185406893512325e-12,
      -8.150473940827948e-12,
      -8.116363376475582e-12,
      -8.083235157463335e-12,
      -8.05128900899839e-12,
      -8.020774260255482e-12,
      -7.992002112614248e-12,
      -7.9653609416962e-12,
      -7.941335383049779e-12,
      -7.920530136451194e-12,
      -7.90369965448046e-12,
      -7.891785168440867e-12,
      -7.885960862631105e-12,
      -7.887691453556699e-12,
      -7.898803984995916e-12,
      -7.92157733888061e-12,
      -7.95885381748579e-12,
      -8.014178212998615e-12,
      -8.091971092401088e-12,
      -8.197744643176367e-12,
      -8.338371410733102e-12,
      -8.52241867868266e-12,
      -8.76056416288419e-12,
      -9.066112155381263e-12,
      -9.455633257756616e-12,
      -9.949755255699964e-12,
      -1.0574137120696548e-11,
      -1.136066167302664e-11,
      -1.2348883169590936e-11,
      -1.3587760072118363e-11,
      -1.513768279927368e-11
    ],
    "creal": [
      3.274792186056596e-10,
      3.259745882674507e-10,
      3.2447687107702593e-10,
      3.2298603527119334e-10,
      3.215020492326593e-10,
      3.200248814893472e-10,
      3.1855450071371965e-10,
      3.170908757220972e-10,
      3.156339754739789e-10,
      3.141837690713599e-10,
      3.1274022575804653e-10,
      3.1130331491896735e-10,
      3.0987300607947745e-10,
      3.084492689046552e-10,
      3.070320731985866e-10,
      3.056213889036358e-10,
      3.042171860996963e-10,
      3.0281943500341673e-10,
      3.0142810596739615e-10,
      3.000431694793385e-10,
      2.9866459616115683e-10,
      2.9729235676801327e-10,
      2.959264221872785e-10,
      2.945667634373898e-10,
      2.932133516665841e-10,
      2.918661581514682e-10,
      2.905251542953943e-10,
      2.891903116265824e-10,
      2.8786160179593533e-10,
      2.8653899657446126e-10,
      2.852224678502127e-10,
      2.839119876246162e-10,
      2.826075280080459e-10,
      2.813090612144514e-10,
      2.800165595548e-10,
      2.7872999542905197e-10,
      2.77449341316289e-10,
      2.761745697625486e-10,
      2.7490565336579506e-10,
      2.7364256475730784e-10,
      2.7238527657860975e-10,
      2.7113376145281693e-10,
      2.69887991949034e-10,
      2.686479405380573e-10,
      2.67413579537226e-10,
      2.661848810417164e-10,
      2.649618168388872e-10,
      2.6374435830144127e-10,
      2.6253247625407424e-10,
      2.6132614080693727e-10,
      2.601253211475066e-10,
      2.589299852802714e-10,
      2.5774009970086607e-10,
      2.565556289877002e-10,
      2.55376535289521e-10,
      2.542027776813984e-10,
      2.5303431135377984e-10,
      2.5187108658895906e-10,
      2.507130474655553e-10,
      2.4956013021307214e-10,
      2.4841226111330767e-10,
      2.472693538105091e-10,
      2.46131305843337e-10,
      2.4499799414256747e-10,
      2.438692691393811e-10,
      2.4274494698533137e-10,
      2.4162479917441437e-10,
      2.4050853854581135e-10,
      2.3939580018036185e-10,
      2.382861150037852e-10,
      2.3717887285030643e-10,
      2.3607327012905324e-10,
      2.3496823477469576e-10,
      2.338623173938079e-10,
      2.3275353173099057e-10,
      2.3163911868553874e-10,
      2.3051519444682498e-10,
      2.2937622236390637e-10,
      2.2821421615545814e-10,
      2.2701753349976472e-10,
      2.2576904620183333e-10
    ]
  },
d:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    4504609.469475547,
    3686869.6811206243,
    3017578.4584953277,
    2469787.1597688408,
    2021439.270514583,
    1654482.3103254181,
    1354140.9596496066,
    1108322.1397006118,
    907128.0913115143,
    742457.8471227506,
    607681.0506006904,
    497370.98838977655,
    407086.0866931852,
    333191.0737543145,
    272710.60764508427,
    223209.4757646997,
    182694.5423477884,
    149534.49595242096,
    122394.16560489679,
    100180.76087710216,
    81999.87128281544,
    67119.45333292552,
    54940.35520821174,
    44972.19224189509,
    36813.60185157566,
    30136.082896284322,
    24670.768759275783,
    20197.601582038995,
    16536.471756030398,
    13539.965908652317,
    11087.43138513335,
    9080.118235948143,
    7437.203104580094,
    6092.534919661499,
    4991.971358580516,
    4091.198836731497,
    3353.9482454786967,
    2750.5345965067263,
    2256.6617721432463,
    1852.4442555850574,
    1521.6064515348878,
    1250.8273583359871,
    1029.2042051896187,
    847.8134581039129,
    699.3515187207984,
    577.8406489575179,
    478.38828068064083,
    396.9900191494035,
    330.3684082706988,
    275.8409656376616,
    231.2121738554807,
    194.68507924476643,
    164.7889384930289,
    140.31999998661004,
    120.29303541607075,
    103.90167010186151,
    90.48591476272718,
    79.50559141141444,
    70.51858338603083,
    63.16303376656951,
    57.142775405662135,
    52.215405921785475,
    48.18252750110252,
    44.881758518944885,
    42.18019533328135,
    39.96906099289446,
    38.15932539345218,
    36.678120529474654,
    35.46580650429507,
    34.47357016252362,
    33.66145965527601,
    32.99677580101956,
    32.45275547106894,
    32.00749398697489,
    31.64306314073127,
    31.344789325370705,
    31.100662710275458,
    30.90085367197347,
    30.737317009772212,
    30.603468010191392,
    30.49391731710742
  ],
  "Mimg": [
    -21751791.506534774,
    -17803075.426948093,
    -14571190.357464753,
    -11926006.228794081,
    -9761016.161344128,
    -7989048.024474779,
    -6538754.498750411,
    -5351740.315485157,
    -4380211.003465972,
    -3585048.4709374513,
    -2934235.937218774,
    -2401568.7946932507,
    -1965599.4947397332,
    -1608773.974020006,
    -1316724.8498030484,
    -1077692.925225865,
    -882053.7117193496,
    -721929.9042858826,
    -590874.2061595139,
    -483609.73195865867,
    -395817.5368074662,
    -323962.7164031571,
    -265152.07604448096,
    -217017.63774324584,
    -177621.29489703823,
    -145376.7754961314,
    -118985.77175616013,
    -97385.66447146006,
    -79706.7372389993,
    -65237.15780723292,
    -53394.316543212735,
    -43701.36797714251,
    -35768.03088261243,
    -29274.873818335982,
    -23960.453397397076,
    -19610.787413514106,
    -16050.738965558205,
    -13136.964666852411,
    -10752.143002789586,
    -8800.250444773143,
    -7202.695115814335,
    -5895.152331964463,
    -4824.974604403607,
    -3949.0718173489413,
    -3232.176228315568,
    -2645.4224319226046,
    -2165.185110270556,
    -1772.1277725501927,
    -1450.4241819080694,
    -1187.1211207509643,
    -971.6168365857716,
    -795.2341683043678,
    -650.8711650787805,
    -532.7151302292476,
    -436.00857619927785,
    -356.8576669438937,
    -292.0754347695092,
    -239.05345883800288,
    -195.65683854073495,
    -160.1382329033653,
    -131.0675048655322,
    -107.27413760112348,
    -87.80010430404592,
    -71.86129376742349,
    -58.81593857844622,
    -48.13877470755677,
    -39.39989204208888,
    -32.247424292761906,
    -26.39338129166808,
    -21.602053227045865,
    -17.680519917750537,
    -14.470882988594616,
    -11.84390817938344,
    -9.693821798726985,
    -7.934051804711498,
    -6.493742029393651,
    -5.314899194289651,
    -4.35005784301205,
    -3.5603691708549436,
    -2.9140367991054315,
    -2.385036511396811
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    22213327.986059338,
    18180827.888277516,
    14880368.550094748,
    12179058.79712362,
    9968131.882474257,
    8158566.053697433,
    6677500.141035528,
    5465299.842619773,
    4473156.582205774,
    3661122.258778735,
    2996500.7583054765,
    2452531.462741348,
    2007311.7485085034,
    1642915.0894412273,
    1344669.255099934,
    1100565.541507199,
    900775.2473073121,
    737253.9265286882,
    603417.483404943,
    493877.06739131024,
    404222.09407048515,
    330842.6554043828,
    270784.168778573,
    221628.41236335348,
    181396.15674591056,
    148467.47234995384,
    121516.5038650417,
    99458.08541398021,
    81404.04694746366,
    66627.45256704085,
    54533.33085221222,
    44634.718664425505,
    36533.05384495378,
    29902.12732945247,
    24474.948519940244,
    20032.995055650477,
    16397.41413070482,
    13421.82108451417,
    10986.405304115162,
    8993.106115841176,
    7361.664426250919,
    6026.391133790424,
    4933.52219303002,
    4039.053834541177,
    3306.970776651245,
    2707.796088871345,
    2217.404317852272,
    1816.050086739887,
    1487.5730545579963,
    1218.7472230355822,
    998.748389974131,
    818.7183047417963,
    671.4079741711952,
    550.8857525580114,
    452.29845554559824,
    371.67586887850956,
    305.77076440733396,
    251.9279564639294,
    207.9770878499067,
    172.14477184047522,
    142.98247309631432,
    119.30712138695496,
    100.15195590798935,
    84.7255439031277,
    72.37736876411941,
    62.56890175637275,
    54.84966369460822,
    48.83831384366255,
    44.20898106728397,
    40.682622130026985,
    38.02229149703826,
    36.030454725583844,
    34.54648315858882,
    33.443233282559696,
    32.6225784230516,
    32.01038118167584,
    31.551535215640126,
    31.20554054802235,
    30.94283253989535,
    30.741891040034417,
    30.587046155248135
  ],
  "Mphz": [
    -78.29992422747151,
    -78.29990742115608,
    -78.29988688720834,
    -78.29986179884213,
    -78.29983114589098,
    -78.29979369413492,
    -78.29974793560584,
    -78.29969202787112,
    -78.29962371985049,
    -78.29954026117987,
    -78.29943829147284,
    -78.29931370502146,
    -78.2991614854898,
    -78.29897550394512,
    -78.29874827209643,
    -78.2984706408076,
    -78.29813143174961,
    -78.29771698736701,
    -78.29721062104679,
    -78.29659194536521,
    -78.29583605138433,
    -78.29491250598353,
    -78.29378412689915,
    -78.29240548621664,
    -78.29072108216018,
    -78.28866310571678,
    -78.28614871239374,
    -78.28307668959226,
    -78.2793233859102,
    -78.2747377392166,
    -78.26913520442179,
    -78.26229033811734,
    -78.25392774400744,
    -78.24371101829122,
    -78.23122925548465,
    -78.21598057973316,
    -78.1973520510906,
    -78.17459515658719,
    -78.146795927633,
    -78.11283852328113,
    -78.0713608774807,
    -78.02070072183382,
    -77.95882995794062,
    -77.88327496082235,
    -77.79101994573737,
    -77.67839002944568,
    -77.5409100790967,
    -77.373134902382,
    -77.16844586015469,
    -76.91880870359485,
    -76.6144875749243,
    -76.24371104639184,
    -75.79228845113133,
    -75.24317964554277,
    -74.57603045426511,
    -73.76670207803407,
    -72.78684972321695,
    -71.60364923361793,
    -70.17983726888355,
    -68.47432596448468,
    -66.44377418722733,
    -64.045618147311,
    -61.243108085830116,
    -58.01270950936714,
    -54.353579014082605,
    -50.29754798169197,
    -45.91637299532203,
    -41.32195304498605,
    -36.65634023300645,
    -32.07234913129531,
    -27.710484807675723,
    -23.68006770497994,
    -20.049982668413318,
    -16.849514108368,
    -14.075920133898652,
    -11.704466316009155,
    -9.697790378292513,
    -8.013138511415526,
    -6.607247676513186,
    -5.439257395230063,
    -4.472199893173385
  ],
  "tanDelta": [
    -4.8287851930185735,
    -4.828778060182709,
    -4.828769345314875,
    -4.828758697535011,
    -4.828745688144931,
    -4.828729793371694,
    -4.828710373285185,
    -4.828686646042105,
    -4.828657656421067,
    -4.828622237384387,
    -4.828578963122666,
    -4.828526091697179,
    -4.828461494979013,
    -4.828382573076581,
    -4.828286149824737,
    -4.828168345155448,
    -4.828024419252868,
    -4.827848582280219,
    -4.827633762110259,
    -4.827371320846047,
    -4.827050708924917,
    -4.826659043187362,
    -4.8261805923826575,
    -4.825596150082206,
    -4.8248822707750305,
    -4.824010339912354,
    -4.822945442728595,
    -4.8216449896735085,
    -4.820057048138605,
    -4.818118320781371,
    -4.815751700146422,
    -4.81286331758644,
    -4.809338992044631,
    -4.805039971763099,
    -4.7997978506451835,
    -4.793408532835176,
    -4.785625117261564,
    -4.776149583261672,
    -4.764623186122316,
    -4.750615527695736,
    -4.733612366422849,
    -4.713002392118253,
    -4.688063437823462,
    -4.657948962240843,
    -4.621676141102294,
    -4.578117577389563,
    -4.525999481404469,
    -4.463910141487132,
    -4.390323486135557,
    -4.303643289555256,
    -4.202273696855951,
    -4.084720674996183,
    -3.949726061900171,
    -3.796430517959533,
    -3.624553779786228,
    -3.434571038117512,
    -3.227855247254686,
    -3.0067502749710067,
    -2.774542952311958,
    -2.535315727474163,
    -2.293684616035381,
    -2.0544537710156194,
    -1.8222394892430012,
    -1.6011247361684897,
    -1.3943970176932492,
    -1.2044009419214199,
    -1.0325101829197842,
    -0.8792005650029906,
    -0.7441923332117578,
    -0.6266265177991213,
    -0.5252451943206016,
    -0.4385544538005281,
    -0.36495847602038217,
    -0.3028610050718679,
    -0.250735896503607,
    -0.20717134072863486,
    -0.17089343863189263,
    -0.1407746817997289,
    -0.11583213882080233,
    -0.09521916921752187,
    -0.07821351670219095
  ],
  "nimg": [
    71693086.37655929,
    46609872.89546627,
    30302516.92174746,
    19700609.38419688,
    12807985.5851255,
    8326879.111948953,
    5413573.110773096,
    3519541.904427701,
    2288172.4258029526,
    1487619.7425862446,
    967154.4302637578,
    628782.6952346627,
    408795.76787472225,
    265774.610106453,
    172791.41563030466,
    112339.57049492901,
    73037.46739888159,
    47485.55549058925,
    30873.14321965042,
    20072.6382360465,
    13050.684847559203,
    8485.326196042675,
    5517.109385165067,
    3587.2710286157085,
    2332.536469084731,
    1516.725308085494,
    986.2858768549112,
    641.386674420524,
    417.1218933095983,
    271.29244680642137,
    176.46195111361882,
    114.79200336820935,
    74.68437889080975,
    48.59797336668647,
    31.62949198359172,
    20.59068140154894,
    13.408385520918191,
    8.73448379782303,
    5.692284574625268,
    3.711635155218719,
    2.4217118820229486,
    1.581311769297239,
    1.03352665962638,
    0.676270491678059,
    0.44311418608343317,
    0.2908226458397721,
    0.1912496564221651,
    0.126066507018736,
    0.08333331195470912,
    0.055268655033663544,
    0.036798560372122216,
    0.024612333993547002,
    0.016548101949573603,
    0.011192824845625298,
    0.007621853825011571,
    0.00522928919256699,
    0.0036174381372376207,
    0.0025247466470747107,
    0.0017787860342557665,
    0.0012655610873657463,
    0.000909455516780104,
    0.0006601137668798326,
    0.00048384884602561355,
    0.00035800574537780684,
    0.00026725677178959616,
    0.00020116113483285565,
    0.00015255302367390695,
    0.00011647352114973251,
    0.00008946022207807799,
    0.00006907269385048822,
    0.00005357387695825585,
    0.00004171494137482652,
    0.00003258905063597785,
    0.000025531233892374893,
    0.000020049273925134244,
    0.000015775585503270498,
    0.000012433396255817988,
    0.000009812747168479223,
    0.000007753291062041743,
    0.000006131839454589384,
    0.000004853257675253233
  ],
  "nreal": [
    -346190513.9369311,
    -225068731.62553224,
    -146323864.7976194,
    -95129488.91068055,
    -61846505.16799719,
    -40208249.25367235,
    -26140576.6365278,
    -16994764.994095642,
    -11048801.303064993,
    -7183153.769823979,
    -4669981.536062469,
    -3036093.6499482444,
    -1973854.624493475,
    -1283261.4958042207,
    -834286.3988964097,
    -542394.3581719751,
    -352626.6761221856,
    -229253.07175402995,
    -149044.2285496498,
    -96898.07815440865,
    -62996.31754536632,
    -40955.776418524,
    -26626.566240735865,
    -17310.7212649894,
    -11254.21385562311,
    -7316.6985690111715,
    -4756.80297500497,
    -3092.5388451630733,
    -2010.5513217798484,
    -1307.1191082476246,
    -849.7969410865647,
    -552.4782221631137,
    -359.18249549620634,
    -233.51520457360698,
    -151.8151676398426,
    -98.69954792707526,
    -64.16750653083238,
    -41.71720115097829,
    -27.121591066265957,
    -17.63255160152342,
    -11.463445312656981,
    -7.452726151382634,
    -4.845238545010246,
    -3.150033434905919,
    -2.047930261605765,
    -1.3314202668219999,
    -0.8655958457855021,
    -0.5627495591827942,
    -0.36586019665222047,
    -0.23785657635837043,
    -0.1546376223339349,
    -0.10053450952335283,
    -0.0653604695452119,
    -0.04249278182610758,
    -0.02762581909042381,
    -0.01796036521073149,
    -0.011676566672901671,
    -0.007591282675324013,
    -0.004935318255015274,
    -0.00320859692887768,
    -0.002086004127807032,
    -0.0013561732176655978,
    -0.0008816884740525296,
    -0.0005732118546148445,
    -0.00037266204554173824,
    -0.00024227866027067308,
    -0.00015751255037851184,
    -0.0001024035856027326,
    -0.00006657561139792687,
    -0.000043282781622536216,
    -0.00002813942141344709,
    -0.000018294273329958095,
    -0.000011893650255057542,
    -0.000007732415157369597,
    -0.000005027072671864926,
    -0.0000032682491994917655,
    -0.000002124785840029635,
    -0.0000013813863602238535,
    -8.98080286616505e-7,
    -5.838686586412235e-7,
    -3.795903502434552e-7
  ],
  "nmod": [
    353536095.1502877,
    229844326.02711204,
    149428631.59751144,
    97147998.79830772,
    63158805.37378684,
    41061420.138500504,
    26695252.77490117,
    17355379.92731825,
    11283250.563769981,
    7335578.407969531,
    4769079.07662928,
    3100521.2995692547,
    2015742.0614916384,
    1310494.6432515178,
    851992.1764306665,
    553905.965734053,
    360111.15555759094,
    234119.30481894963,
    152208.18978038366,
    98955.28462782108,
    64333.944378274675,
    41825.54701013933,
    27192.140881882973,
    17678.506275896216,
    11493.391835646666,
    7472.250906517872,
    4857.97636613226,
    3158.3497866713205,
    2053.3648704964444,
    1334.975638294677,
    867.9249168395314,
    564.2777596200314,
    366.8648545734359,
    238.51858162919441,
    155.075045989526,
    100.82448572439694,
    65.55344153179134,
    42.62177939839698,
    27.712502695431066,
    18.018965328393346,
    11.716452828215905,
    7.618639904813757,
    4.954242012075254,
    3.2218088737446338,
    2.095320628999066,
    1.3628124369250565,
    0.8864719957915845,
    0.576697347446938,
    0.37523076149983003,
    0.2441933151135702,
    0.15895574316945393,
    0.10350340375906017,
    0.06742277550875558,
    0.0439421874210425,
    0.028657957640883414,
    0.018706153638905886,
    0.012224077386185805,
    0.00800011989213846,
    0.005246088641449967,
    0.003449164988494701,
    0.002275636736878218,
    0.001508295720851101,
    0.0010057257434695583,
    0.0006758253798093917,
    0.00045858824914595106,
    0.0003149043527641283,
    0.0002192775149412152,
    0.00015508892762964533,
    0.00011151431911313811,
    0.00008151341129377075,
    0.00006051435639434581,
    0.00004555015664711187,
    0.000034691571566361166,
    0.000026676471810062732,
    0.00002066990190042708,
    0.000016110572640380504,
    0.000012613645679191214,
    0.000009909502271492223,
    0.000007805131036308458,
    0.000006159574474539822,
    0.000004868079590187733
  ],
  "cimg": [
    -1.4529478238709012e-7,
    -1.4101022565719e-7,
    -1.3685205615737844e-7,
    -1.32816553321038e-7,
    -1.2890010752666816e-7,
    -1.2509921705375914e-7,
    -1.2141048517072226e-7,
    -1.1783061735883789e-7,
    -1.1435641867752053e-7,
    -1.1098479127777522e-7,
    -1.0771273207256423e-7,
    -1.0453733057497712e-7,
    -1.0145576691765377e-7,
    -9.846531006992805e-8,
    -9.556331627271818e-8,
    -9.27472277153919e-8,
    -9.001457148380016e-8,
    -8.736295881453806e-8,
    -8.479008469742546e-8,
    -8.229372787639321e-8,
    -7.98717513086476e-8,
    -7.752210315342627e-8,
    -7.52428183751775e-8,
    -7.30320210619516e-8,
    -7.088792757861749e-8,
    -6.88088506967063e-8,
    -6.679320486880379e-8,
    -6.483951284611545e-8,
    -6.294641387384709e-8,
    -6.111267374119663e-8,
    -5.933719701193051e-8,
    -5.7619041818665474e-8,
    -5.595743767004615e-8,
    -5.435180679588827e-8,
    -5.2801789641745004e-8,
    -5.130727522154887e-8,
    -4.986843714456941e-8,
    -4.848577624926981e-8,
    -4.7160170898097746e-8,
    -4.589293610693513e-8,
    -4.4685892788913854e-8,
    -4.354144846463609e-8,
    -4.246269079717894e-8,
    -4.1453495198960095e-8,
    -4.051864744702043e-8,
    -3.966398160554058e-8,
    -3.889653238950332e-8,
    -3.8224699100022726e-8,
    -3.7658414936854576e-8,
    -3.720931010071486e-8,
    -3.689084849326173e-8,
    -3.671840427465387e-8,
    -3.670922348390776e-8,
    -3.688218370911093e-8,
    -3.725721653617648e-8,
    -3.785418753208947e-8,
    -3.869093232749001e-8,
    -3.9780027259625916e-8,
    -4.1123751429138994e-8,
    -4.270664334852977e-8,
    -4.448523125759674e-8,
    -4.637522776830871e-8,
    -4.8238171714087146e-8,
    -4.9872514510432976e-8,
    -5.1017965661120603e-8,
    -5.1383893970509805e-8,
    -5.0707579686630875e-8,
    -4.883215407018735e-8,
    -4.577295613836294e-8,
    -4.173385666909404e-8,
    -3.705753281499916e-8,
    -3.2133064897545805e-8,
    -2.7306373340258935e-8,
    -2.282733896184845e-8,
    -1.883918373899936e-8,
    -1.5395854400131265e-8,
    -1.2489607301375934e-8,
    -1.0076905580820755e-8,
    -8.09777963180926e-9,
    -6.488280510464027e-9,
    -5.187501952443495e-9
  ],
  "creal": [
    7.015972938136367e-7,
    6.80907083914852e-7,
    6.608270136160587e-7,
    6.413390870255847e-7,
    6.224258384208169e-7,
    6.04070316514959e-7,
    5.862560691694537e-7,
    5.689671285355176e-7,
    5.521879966081026e-7,
    5.359036311753303e-7,
    5.200994321460517e-7,
    5.047612282376503e-7,
    4.898752640054568e-7,
    4.7542818719422254e-7,
    4.614070363908861e-7,
    4.477992289563792e-7,
    4.3459254921237e-7,
    4.217751368565727e-7,
    4.093354755774795e-7,
    3.9726238183600946e-7,
    3.85544993777482e-7,
    3.7417276023238844e-7,
    3.631354297584549e-7,
    3.5242303966927624e-7,
    3.4202590498605585e-7,
    3.319346072383965e-7,
    3.2213998302723464e-7,
    3.1263311224734365e-7,
    3.034053058476864e-7,
    2.9444809298439417e-7,
    2.8575320739212756e-7,
    2.7731257276353413e-7,
    2.6911828688146e-7,
    2.611626041917884e-7,
    2.534379164326668e-7,
    2.459367308434952e-7,
    2.3865164535763094e-7,
    2.3157532002706866e-7,
    2.247004437225674e-7,
    2.1801969488115434e-7,
    2.1152569471024823e-7,
    2.0521095077012352e-7,
    1.9906778819785736e-7,
    1.9308826494325194e-7,
    1.8726406617562972e-7,
    1.815863713775816e-7,
    1.7604568542332415e-7,
    1.7063162196788545e-7,
    1.6533262354791072e-7,
    1.6013559772392213e-7,
    1.5502544227793172e-7,
    1.499844250935469e-7,
    1.4499137670650827e-7,
    1.4002064780225865e-7,
    1.3504078502051242e-7,
    1.300128961691835e-7,
    1.2488872893446458e-7,
    1.1960860790123437e-7,
    1.140996147003464e-7,
    1.0827482454915735e-7,
    1.0203509057632591e-7,
    9.527576157031011e-8,
    8.790150138629436e-8,
    7.985211663757617e-8,
    7.113929916664317e-8,
    6.188681029767237e-8,
    5.235609237766278e-8,
    4.293325744882181e-8,
    3.4063883026607765e-8,
    2.6151541278882035e-8,
    1.9464291024456298e-8,
    1.4092098725080121e-8,
    9.965692399904493e-9,
    6.913510821101632e-9,
    4.72365962419418e-9,
    3.189579797738047e-9,
    2.134391938894126e-9,
    1.4185731766659543e-9,
    9.379831344519956e-10,
    6.178086798566233e-10,
    4.0573277060008747e-10
  ]
},
e:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    16947411620.498482,
    15650586999.099512,
    13989712918.915289,
    12012031102.93434,
    9852490696.07203,
    7706828769.599716,
    5765880407.935912,
    4153229828.4889174,
    2904842026.413301,
    1989757332.1698046,
    1344932553.360917,
    902597359.3276665,
    604325437.3680692,
    405184328.9942885,
    272842022.14109254,
    184953216.69567192,
    126451118.83968279,
    87326322.59792015,
    60985122.67681687,
    43101740.7044214,
    30841229.52954808,
    22343321.65846573,
    16383495.190764574,
    12151871.899948306,
    9109470.374764698,
    6894858.181287187,
    5263500.649391204,
    4048278.915720965,
    3133708.384001078,
    2439031.553528155,
    1907070.3642993593,
    1496827.5415688832,
    1178537.8903422845,
    930328.5926801836,
    735942.6211385279,
    583169.634818351,
    462751.61944251775,
    367610.1173187254,
    292293.5993191946,
    232577.26263864196,
    185169.6539691918,
    147495.10499183479,
    117530.656726586,
    93682.63843640765,
    74692.45307620981,
    59564.116973651544,
    47508.17058775075,
    37898.023661224295,
    30235.82197611599,
    24125.656945194147,
    19252.472133113133,
    15365.41234200078,
    12264.651894664243,
    9790.957304565422,
    7817.4052296081245,
    6242.8033013588265,
    4986.458995335907,
    3984.017325136449,
    3184.147054712973,
    2545.9012232433856,
    2036.6139845895343,
    1630.2242873606347,
    1305.9394483728124,
    1047.1694987405158,
    840.677311849398,
    675.9007373900599,
    544.4118764028866,
    439.48571839865934,
    355.75600061488166,
    288.94063936685507,
    235.62266000721934,
    193.07540202857425,
    159.12304756593122,
    132.02933273532383,
    110.4087455106143,
    93.15566569080485,
    79.38782125640583,
    68.4011682979283,
    59.633886365522834,
    52.63764753957636,
    47.05468967759084
  ],
  "Mimg": [
    -6885197954.256734,
    -7942227384.85085,
    -8860755162.103342,
    -9486272629.088646,
    -9689586699.568966,
    -9424326899.31017,
    -8750559019.45274,
    -7804483504.395913,
    -6739636544.604362,
    -5680299293.679833,
    -4704558723.229967,
    -3849392675.7297335,
    -3123768762.2079816,
    -2520922127.0936046,
    -2026924689.429941,
    -1625741764.957773,
    -1301841611.3890557,
    -1041340928.1752636,
    -832360115.8710732,
    -664990920.5834335,
    -531097576.6470339,
    -424064997.1863925,
    -338548189.24849844,
    -270245928.34738433,
    -215706154.91603932,
    -172163227.69734672,
    -137404062.44372308,
    -109659159.83220859,
    -87514476.11263241,
    -69840453.40487354,
    -55735029.02780678,
    -44477956.38632628,
    -35494238.93655307,
    -28324884.77439243,
    -22603530.495132986,
    -18037764.455549426,
    -14394209.288809296,
    -11486609.66590801,
    -9166321.428346824,
    -7314718.924811091,
    -5837134.2299488615,
    -4658019.50925009,
    -3717085.886440307,
    -2966221.826164635,
    -2367033.7346827076,
    -1888883.1928246524,
    -1507320.5650819638,
    -1202834.956407676,
    -959856.6390750329,
    -765960.9658952726,
    -611233.0789788798,
    -487760.9391378461,
    -389230.7586959559,
    -310604.15435194253,
    -247860.51391107953,
    -197791.40441779967,
    -157836.50973677356,
    -125952.7088500428,
    -100509.60054736193,
    -80206.13237460905,
    -64004.07078052205,
    -51074.91052182217,
    -40757.508570709135,
    -32524.27614728644,
    -25954.200108680216,
    -20711.31414027805,
    -16527.518867592073,
    -13188.872388442227,
    -10524.650185153154,
    -8398.61498922095,
    -6702.050177541065,
    -5348.2004582360805,
    -4267.83556660675,
    -3405.7101175127805,
    -2717.7385860258782,
    -2168.7409567735663,
    -1730.643763948495,
    -1381.044530701656,
    -1102.0661993466288,
    -879.4429727114992,
    -701.7908203707395
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    18292640911.13987,
    17550494273.63963,
    16559741833.627913,
    15306151038.43087,
    13818815554.33206,
    12174282204.261206,
    10479392159.45013,
    8840773765.83283,
    7338992298.114472,
    6018715336.905635,
    4893027319.912396,
    3953796399.035615,
    3181688311.8867087,
    2553276857.556324,
    2045205726.9787564,
    1636228583.8763762,
    1307968450.1546133,
    1044996083.8737127,
    834591245.8687863,
    666386287.7566986,
    531992309.49257743,
    424653206.583241,
    338944383.87160647,
    270519000.7726118,
    215898419.9089263,
    172301236.32901916,
    137504839.24256882,
    109733859.39301206,
    87570563.87567952,
    69883029.46150386,
    55767646.33822063,
    44503135.81078464,
    35513799.42000141,
    28340158.940502897,
    22615508.00636623,
    18047189.071344502,
    14401645.743155207,
    11492490.54014344,
    9170980.540595053,
    7318415.479602535,
    5840070.532039125,
    4660354.123299109,
    3718943.52504099,
    2967700.8539877916,
    2368211.9127460932,
    1889822.108074277,
    1508069.0674473783,
    1203431.8395961123,
    960332.7405160096,
    766340.8175206748,
    611536.2086755271,
    488002.8992178974,
    389423.9402002999,
    310758.43278280506,
    247983.76192966415,
    197889.89932440978,
    157915.2575883622,
    126015.7024370798,
    100560.02483420684,
    80246.5281712039,
    64036.46518195943,
    51100.920892281785,
    40778.425456781,
    32541.129403633768,
    25967.8116448821,
    20722.340003582747,
    16536.48282464504,
    13196.19273030256,
    10530.661130803603,
    8403.583784924414,
    6706.190768251907,
    5351.684431311801,
    4270.800928146877,
    3408.268350531022,
    2719.9803515943213,
    2170.740729713574,
    1732.4636399814149,
    1382.7373993660108,
    1103.6784441790007,
    881.0168353613564,
    703.3665469563433
  ],
  "Mphz": [
    -22.110353674992606,
    -26.906496544273818,
    -32.34920797512621,
    -38.29924234088211,
    -44.5223898111871,
    -50.725105286797316,
    -56.618486980617014,
    -61.97990149125456,
    -66.68353711953328,
    -70.6951050959829,
    -74.04590170780908,
    -76.80380281657571,
    -79.05079875952984,
    -80.8690230291297,
    -82.33356290680481,
    -83.50962553070153,
    -84.45212208030524,
    -85.20642077828754,
    -85.8095568459028,
    -86.29153088777126,
    -86.67652412573153,
    -86.98396380926071,
    -87.22942436271775,
    -87.42537367065451,
    -87.58178315997952,
    -87.70662248195644,
    -87.80625852380795,
    -87.8857761524138,
    -87.94923545939226,
    -87.99987776547377,
    -88.04029042044408,
    -88.07253854599095,
    -88.09827029900099,
    -88.11880094629531,
    -88.13517999653526,
    -88.14824479057738,
    -88.15866327183582,
    -88.16696811240769,
    -88.17358393313529,
    -88.17884900530302,
    -88.18303254109952,
    -88.18634845539279,
    -88.18896630153141,
    -88.19101993975428,
    -88.19261438107968,
    -88.19383115639343,
    -88.19473248513236,
    -88.19536445663319,
    -88.19575938676624,
    -88.1959374703236,
    -88.19590781363466,
    -88.19566890019986,
    -88.19520851314732,
    -88.19450311054857,
    -88.19351662165666,
    -88.19219860252694,
    -88.19048165673406,
    -88.18827798933518,
    -88.18547491793446,
    -88.1819291114163,
    -88.1774592619185,
    -88.1718368156046,
    -88.16477428869723,
    -88.1559105720228,
    -88.14479247375534,
    -88.13085155838345,
    -88.11337510053089,
    -88.09146967318723,
    -88.06401551634912,
    -88.02960936578016,
    -87.98649284020684,
    -87.93246276165469,
    -87.86475888546029,
    -87.77992340627111,
    -87.67362524272309,
    -87.54044044520779,
    -87.3735780864749,
    -87.16453868184347,
    -86.9026896141859,
    -86.57473943321351,
    -86.16409080167813
  ],
  "tanDelta": [
    -0.4062684089131846,
    -0.5074715335155047,
    -0.6333764826669775,
    -0.7897309412370159,
    -0.9834657041018054,
    -1.2228540663165217,
    -1.517644904221191,
    -1.879135956036279,
    -2.320138748793166,
    -2.8547698766288954,
    -3.497988587958197,
    -4.264794967489267,
    -5.169017501253096,
    -6.2216673911126,
    -7.42893148762024,
    -8.790016167346913,
    -10.2952162332352,
    -11.924708349050132,
    -13.648576559926966,
    -15.428400563766983,
    -17.22037625439689,
    -18.9794965882218,
    -20.663978309056976,
    -22.2390369625715,
    -23.679329976591653,
    -24.96979969284965,
    -26.105071813682734,
    -27.08784698760787,
    -27.926809194956125,
    -28.634501797997064,
    -29.225470685914274,
    -29.71481693856809,
    -30.11718098112605,
    -30.446107963629572,
    -30.7137130611685,
    -30.930561844441595,
    -31.105691874509628,
    -31.246717989398775,
    -31.359979998525,
    -31.45070520576234,
    -31.52316864468534,
    -31.580841340517402,
    -31.626521879201615,
    -31.662449688349934,
    -31.690400263967607,
    -31.711763538108162,
    -31.72760698705168,
    -31.738725141975344,
    -31.745677026185927,
    -31.748812794416057,
    -31.74829054433973,
    -31.74408393874142,
    -31.735980934386845,
    -31.72357356794019,
    -31.706238404057306,
    -31.68310691044003,
    -31.653024698369364,
    -31.614498274233544,
    -31.565627723943837,
    -31.504023660599586,
    -31.42670690902755,
    -31.329989939306728,
    -31.209340235102466,
    -31.059227934355466,
    -30.87296367209413,
    -30.64253816359668,
    -30.358483317437855,
    -30.00978606653731,
    -29.58390067057915,
    -29.06692186888118,
    -28.44399675878253,
    -27.7000612301953,
    -26.820976796830205,
    -25.795102095533036,
    -24.615247401435287,
    -23.280827212076236,
    -21.799864721805157,
    -20.19036465410028,
    -18.480536260735562,
    -16.70749005358337,
    -14.914365075601763
  ],
  "nimg": [
    269726433201.53592,
    197856700632.21277,
    140484669507.8818,
    95815678583.36494,
    62426094443.465645,
    38787861979.81461,
    23050787227.063946,
    13188824707.620111,
    7327277690.786378,
    3986761405.731577,
    2140526639.9259365,
    1141074999.4629266,
    606863484.8758707,
    323201056.51782143,
    172874680.8065136,
    93085496.724836,
    50552519.80228207,
    27730985.490010604,
    15383106.02767809,
    8636045.892734654,
    4908534.130659295,
    2824670.988227241,
    1645230.2635506764,
    969311.3863709872,
    577182.6388749888,
    347012.77983635967,
    210423.77738469755,
    128555.46933800197,
    79045.78397976665,
    48869.461153656695,
    30351.967530230388,
    18923.083128274044,
    11834.875167694954,
    7420.898651468123,
    4662.9857352193585,
    2935.046824366893,
    1849.9844540770243,
    1167.3674702053065,
    737.2918561411851,
    466.0015769497382,
    294.7066573981255,
    186.46517753465253,
    118.02426227742001,
    74.72729213189972,
    47.32567909765724,
    29.978150769833697,
    18.992775678421054,
    12.03473950332722,
    7.626792156464791,
    4.833918008088611,
    3.064126106723903,
    1.9425148654275144,
    1.2316161010863036,
    0.7809896678410331,
    0.49531645572719524,
    0.31419537148067805,
    0.19934822990737971,
    0.12651480487046238,
    0.08031806709636832,
    0.05101074676560259,
    0.03241371828175029,
    0.020609501663179285,
    0.01311424136187237,
    0.008352896796039266,
    0.0053265923191292,
    0.003401755157368338,
    0.0021764451287573506,
    0.0013956126534830603,
    0.0008973716928377729,
    0.0005789336071042459,
    0.0003750051104461,
    0.0002440883656364442,
    0.00015979133294552375,
    0.00010531507952767193,
    0.00006995578059645414,
    0.00004688451257287223,
    0.00003173759507191548,
    0.00002172119183700534,
    0.000015042265335182797,
    0.00001054670025869155,
    0.000007488986457843826
  ],
  "nreal": [
    -109581328858.61636,
    -100406643286.14714,
    -88979685841.53499,
    -75668606032.90419,
    -61393922926.16875,
    -47431894745.74031,
    -34982909773.44051,
    -24783594725.94862,
    -17000300893.561188,
    -11381286366.389175,
    -7487537758.681431,
    -4866450915.237307,
    -3136887974.19482,
    -2010849474.1100702,
    -1284274159.6558073,
    -818223021.1568266,
    -520449122.49939835,
    -330683914.2001175,
    -209957500.34823838,
    -133240375.3201849,
    -84526804.587502,
    -53610833.38390802,
    -33997002.47941527,
    -21556551.749745805,
    -13667298.162680795,
    -8664839.603372838,
    -5493127.819933918,
    -3482290.8828479126,
    -2207496.526868663,
    -1399352.6732715303,
    -887050.5373145702,
    -562295.9510699696,
    -356433.07731450343,
    -225937.48152975156,
    -143217.60587984923,
    -90782.64731741209,
    -57545.046401152824,
    -36476.402131503084,
    -23121.457861662937,
    -14656.07822206659,
    -9290.087661872614,
    -5888.727187253271,
    -3732.696913193454,
    -2366.049127472903,
    -1499.7697133688432,
    -950.6600285227213,
    -602.5953223181771,
    -381.9672892513755,
    -242.11768054497935,
    -153.47115790236188,
    -97.280765900767,
    -61.66335494038401,
    -39.086545102558794,
    -24.775783183556186,
    -15.704621630739148,
    -9.954685545387743,
    -6.309974444834505,
    -3.9997020802422263,
    -2.535290205470705,
    -1.6070437730483977,
    -1.0186564242723546,
    -0.6456954797615323,
    -0.4092868205879283,
    -0.259434525500331,
    -0.16444769116453142,
    -0.10423841223287113,
    -0.06607357313269892,
    -0.04188203716277911,
    -0.026547755025502138,
    -0.016827817924968673,
    -0.010666644146055754,
    -0.006761262673707802,
    -0.004285759633266462,
    -0.0027166132286154786,
    -0.0017219788465422446,
    -0.0010915102361314542,
    -0.0006918752791631876,
    -0.00043855878391080413,
    -0.00027798912997045126,
    -0.00017620888967021423,
    -0.00011169347807852051
  ],
  "nmod": [
    291136422321.29395,
    221875568734.04663,
    166292894794.6876,
    122091695873.85362,
    87557015936.6389,
    61272203127.814285,
    41894424068.17468,
    28074395179.743393,
    18512137338.23064,
    12059350971.755774,
    7787494846.477445,
    4998439422.941258,
    3195050774.545495,
    2036657686.1276867,
    1295857157.4149613,
    823500954.4933839,
    522898504.8446342,
    331844630.3120752,
    210520288.57936546,
    133519956.95073135,
    84669205.74261694,
    53685195.559938855,
    34036788.33564458,
    21578333.761048775,
    13679480.211844182,
    8671785.469068531,
    5497156.66615213,
    3484663.0111766057,
    2208911.30471601,
    1400205.7450337885,
    887569.6579328428,
    562614.2725391537,
    356629.5036506679,
    226059.3180932876,
    143293.4962582834,
    90830.08066614746,
    57574.77579453661,
    36495.077192268225,
    23133.21017346678,
    14663.484794585978,
    9294.76093179338,
    5891.678635866763,
    3734.5623535094537,
    2367.2288951017495,
    1500.5162155206845,
    951.1325771701862,
    602.8945579516526,
    382.1568330057137,
    242.23777407968103,
    153.54726657026697,
    97.32901049038635,
    61.693943839782094,
    39.10594438541049,
    24.788089422540413,
    15.712430714437344,
    9.959642716433734,
    6.313122619689224,
    4.001702478514039,
    2.5365621257634885,
    1.6078531602043808,
    1.0191719971840807,
    0.6460243061551786,
    0.4094968679165598,
    0.2595689579022638,
    0.1645339348434824,
    0.10429390453416085,
    0.06610940916329992,
    0.041905283337342704,
    0.026562917250354496,
    0.016837773612842395,
    0.010673234100845261,
    0.006765667156542172,
    0.004288737448739521,
    0.002718653839653834,
    0.0017233992454384498,
    0.001092516706096039,
    0.0006926028276422842,
    0.0004390963642757059,
    0.0002783958083882415,
    0.00017652423540453704,
    0.00011194426275358066
  ],
  "cimg": [
    -8.060661636552216e-10,
    -6.423504884980012e-10,
    -5.122969973554263e-10,
    -4.089823731077455e-10,
    -3.269072292892312e-10,
    -2.6170324775814273e-10,
    -2.099005498791602e-10,
    -1.6874291288546244e-10,
    -1.3604099101791775e-10,
    -1.1005572493181474e-10,
    -8.940573049689247e-11,
    -7.299373510656173e-11,
    -5.99481439615312e-11,
    -4.957662451935943e-11,
    -4.132923731261386e-11,
    -3.476914971725564e-11,
    -2.9549373072619295e-11,
    -2.53942843197913e-11,
    -2.2084948118240896e-11,
    -1.9447457789880735e-11,
    -1.7343674203299542e-11,
    -1.5663869409964995e-11,
    -1.4320883279177587e-11,
    -1.324548195125227e-11,
    -1.2382670929600147e-11,
    -1.1688766470376674e-11,
    -1.1129069310462701e-11,
    -1.0676016851243482e-11,
    -1.0307715395150556e-11,
    -1.0006774271105244e-11,
    -9.759379761661957e-12,
    -9.554559514931258e-12,
    -9.383598268331709e-12,
    -9.239573769075048e-12,
    -9.116988177140489e-12,
    -9.011475321206182e-12,
    -8.919568217353455e-12,
    -8.8385144694496e-12,
    -8.766129719835952e-12,
    -8.700681345074109e-12,
    -8.640796201925264e-12,
    -8.585387509202458e-12,
    -8.533596969820423e-12,
    -8.484749048500993e-12,
    -8.438314967348167e-12,
    -8.393884498299155e-12,
    -8.351144045771125e-12,
    -8.309859846678955e-12,
    -8.269865386118228e-12,
    -8.2310523497617e-12,
    -8.193364620180181e-12,
    -8.156794983676997e-12,
    -8.121384355170452e-12,
    -8.087223458506817e-12,
    -8.054457025037768e-12,
    -8.023290700765543e-12,
    -7.994000988292046e-12,
    -7.96694870101941e-12,
    -7.94259658104633e-12,
    -7.921531937562935e-12,
    -7.90449540931463e-12,
    -7.892417254884601e-12,
    -7.886462942628517e-12,
    -7.88809026573029e-12,
    -7.899120768575864e-12,
    -7.921828964771388e-12,
    -7.959053686703565e-12,
    -8.014336970324465e-12,
    -8.092097193254733e-12,
    -8.197844803898486e-12,
    -8.338450966255444e-12,
    -8.522481866633229e-12,
    -8.760614349257132e-12,
    -9.06615201378933e-12,
    -9.455664911812028e-12,
    -9.949780392106137e-12,
    -1.0574157079073378e-11,
    -1.1360677517253098e-11,
    -1.2348895744464587e-11,
    -1.3587770048349848e-11,
    -1.5137690709213345e-11
  ],
  "creal": [
    3.274792177869616e-10,
    3.259745874525142e-10,
    3.2447687026583384e-10,
    3.2298603446372827e-10,
    3.2150204842890413e-10,
    3.20024880689285e-10,
    3.185544999173334e-10,
    3.1709087492937005e-10,
    3.156339746848941e-10,
    3.1418376828590045e-10,
    3.1274022497619603e-10,
    3.113033141407091e-10,
    3.098730053047949e-10,
    3.08449268133532e-10,
    3.070320724310064e-10,
    3.0562138813958236e-10,
    3.042171853391533e-10,
    3.028194342463682e-10,
    3.014281052138258e-10,
    3.0004316872923054e-10,
    2.9866459541449527e-10,
    2.972923560247824e-10,
    2.9592642144746237e-10,
    2.945667627009729e-10,
    2.932133509335508e-10,
    2.918661574218028e-10,
    2.9052515356908136e-10,
    2.891903109036066e-10,
    2.8786160107628137e-10,
    2.8653899585811385e-10,
    2.8522246713715656e-10,
    2.8391198691483627e-10,
    2.826075273015271e-10,
    2.813090605111787e-10,
    2.8001655885475863e-10,
    2.7872999473222696e-10,
    2.774493406226657e-10,
    2.761745690721122e-10,
    2.74905652678531e-10,
    2.736425640732015e-10,
    2.7238527589764665e-10,
    2.711337607749827e-10,
    2.698879912743142e-10,
    2.6864793986643765e-10,
    2.6741357886869215e-10,
    2.6618488037625445e-10,
    2.64961816176483e-10,
    2.637443576420807e-10,
    2.6253247559774365e-10,
    2.6132614015362257e-10,
    2.601253204971941e-10,
    2.589299846329475e-10,
    2.577400990565171e-10,
    2.565556283463127e-10,
    2.553765346510816e-10,
    2.54202777045894e-10,
    2.5303431072119724e-10,
    2.518710859592853e-10,
    2.5071304683877755e-10,
    2.49560129589178e-10,
    2.484122604922847e-10,
    2.472693531923454e-10,
    2.461313052280208e-10,
    2.4499799353008764e-10,
    2.438692685297269e-10,
    2.4274494637849286e-10,
    2.416247985703824e-10,
    2.40508537944578e-10,
    2.3939579958192035e-10,
    2.3828611440813084e-10,
    2.3717887225743693e-10,
    2.3607326953896957e-10,
    2.3496823418740327e-10,
    2.338623168093182e-10,
    2.3275353114932383e-10,
    2.3163911810672712e-10,
    2.30515193870918e-10,
    2.2937622179097866e-10,
    2.2821421558562088e-10,
    2.2701753293318312e-10,
    2.2576904563875282e-10
  ]
},
f:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    5001963.502469339,
    5001942.155559078,
    5001908.323359698,
    5001854.703875064,
    5001769.725074576,
    5001635.048669086,
    5001421.61581215,
    5001083.384854942,
    5000547.4186486425,
    4999698.20482805,
    4998352.882527545,
    4996222.173367919,
    4992848.946938321,
    4987512.067307966,
    4979077.023474561,
    4965766.690123021,
    4944816.447652213,
    4911972.394690978,
    4860802.851685622,
    4781853.767213035,
    4661851.379873318,
    4483530.72354204,
    4227268.88378241,
    3876164.969307427,
    3425318.542029455,
    2892247.017303462,
    2320139.4104166874,
    1766560.71605417,
    1282079.649214734,
    893947.5754319233,
    604441.6176762127,
    399803.42417302297,
    260589.51289100642,
    168333.61437498912,
    108253.87745013148,
    69571.5906792145,
    44848.4922673293,
    29121.34443337626,
    19146.735567809552,
    12832.56817179238,
    8840.357559319042,
    6318.153976441287,
    4725.439608883304,
    3719.9821309615254,
    3085.3731453251794,
    2684.879023064378,
    2432.1512948939726,
    2272.677730892212,
    2172.0514796808707,
    2108.558523303578,
    2068.496346414385,
    2043.2184913362564,
    2027.2691115115224,
    2017.205680815399,
    2010.8560644636568,
    2006.8497190939704,
    2004.3218827549713,
    2002.726924536098,
    2001.7205734101947,
    2001.0856085677444,
    2000.684972753934,
    2000.4321886117136,
    2000.2726925874601,
    2000.1720573943064,
    2000.1085608779886,
    2000.068497283839,
    2000.0432188645336,
    2000.0272692600847,
    2000.0172057399636,
    2000.0108560880112,
    2000.0068497284685,
    2000.004321886487,
    2000.002726926022,
    2000.0017205740019,
    2000.0010856088034,
    2000.000684972848,
    2000.0004321886493,
    2000.0002726926025,
    2000.0001720574005,
    2000.0001085608806,
    2000.000068497285
  ],
  "Mimg": [
    -13508.74980251433,
    -17006.435800142965,
    -21409.689322814247,
    -26952.91289844978,
    -33931.13025782306,
    -42715.611491483054,
    -53773.47311620012,
    -67692.21182648238,
    -85210.30902536138,
    -107255.19846948549,
    -134989.94739022074,
    -169869.8027308179,
    -213708.96924614906,
    -268755.95484840276,
    -337771.25362351525,
    -424091.61349907477,
    -531646.3138965736,
    -664855.6661617815,
    -828280.8140237053,
    -1025800.5654729016,
    -1258984.518117408,
    -1524314.9253546502,
    -1809267.0556477264,
    -2088461.308506412,
    -2323248.344440301,
    -2469353.6128885527,
    -2493376.571229546,
    -2389378.3835192877,
    -2182153.600858554,
    -1914201.504502786,
    -1627658.4978651789,
    -1353059.3322472346,
    -1107284.5290519614,
    -896661.1403444993,
    -721096.041299264,
    -577314.5014020981,
    -460875.7620520557,
    -367248.0835116876,
    -292300.64539513993,
    -232476.87266858472,
    -184810.70668404433,
    -146874.51592581396,
    -116703.77039048415,
    -92719.75148951165,
    -73659.26752121082,
    -58514.32351181763,
    -46481.928958770455,
    -36923.08629185993,
    -29329.640241446727,
    -23297.657211936727,
    -18506.13520761291,
    -14700.0200286696,
    -11676.678207143006,
    -9275.133855720978,
    -7367.510058639045,
    -5852.22594840623,
    -4648.590656982461,
    -3692.5079883965022,
    -2933.063942466944,
    -2329.8157996234977,
    -1850.638619634115,
    -1470.01458216583,
    -1167.6741253186153,
    -927.5165453657179,
    -736.7525895141599,
    -585.2233885465571,
    -464.8594634937317,
    -369.25099820973423,
    -293.3064941684565,
    -232.98163004186847,
    -185.06388706262965,
    -147.00147079599185,
    -116.76741883650121,
    -92.75165769637704,
    -73.67526053507028,
    -58.52233964836319,
    -46.48594674716938,
    -36.92510002034392,
    -29.33064951676768,
    -23.29816305426656,
    -18.50638873136234
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    5001981.743904767,
    5001971.066131593,
    5001954.143141651,
    5001927.322361932,
    5001884.815174493,
    5001817.448042271,
    5001710.683908456,
    5001541.488162836,
    5001273.366145646,
    5000848.509699085,
    5000175.379340992,
    4999109.096182345,
    4997420.547690514,
    4994747.880004461,
    4990520.756941774,
    4983843.1473488,
    4973314.539022408,
    4956763.648090723,
    4930867.4155603545,
    4890643.3371422645,
    4828861.180950026,
    4735565.83109182,
    4598178.932408696,
    4402990.518546952,
    4138875.4492392484,
    3802998.8265297324,
    3405873.398957322,
    2971509.0144798597,
    2530913.385454059,
    2112654.649359744,
    1736266.6427861152,
    1410890.6175043501,
    1137535.0203429325,
    912325.2744128299,
    729176.5237310221,
    581491.3926781628,
    463052.75650374417,
    368400.87885972095,
    292927.0639277275,
    232830.7778873941,
    185022.02362646582,
    147010.34826879433,
    116799.39983943432,
    92794.34564310193,
    73723.85786980491,
    58575.887798770455,
    46545.51621423312,
    36992.96372804737,
    29409.957570915925,
    23392.880340204014,
    18621.377968817986,
    14841.33857327653,
    11851.35578757372,
    9491.955899616023,
    7636.998531893127,
    6186.75959984116,
    5062.282223056619,
    4200.6583268142385,
    3551.0208876622187,
    3071.2188578791024,
    2725.363032824191,
    2482.4729229165887,
    2316.150622832997,
    2204.761937513465,
    2131.487422776226,
    2083.9290794864273,
    2053.3551076532485,
    2033.8277649454087,
    2021.409785960601,
    2013.5351659225785,
    2008.5507315613222,
    2005.3993916376887,
    2003.4084800193052,
    2002.1512810735342,
    2001.3576358190714,
    2000.8567175412086,
    2000.540594939219,
    2000.341109356601,
    2000.215232226449,
    2000.1358050505564,
    2000.0856882676353
  ],
  "Mphz": [
    -0.15473772810248093,
    -0.19480298072800498,
    -0.2452418693623557,
    -0.3087401172706934,
    -0.3886785763289685,
    -0.4893129414263948,
    -0.6159997268032703,
    -0.7754802134800319,
    -0.9762368411463126,
    -1.228939732307387,
    -1.5470045551951428,
    -1.9472863137176362,
    -2.4509358363726905,
    -3.0844444020017154,
    -3.88089228494512,
    -4.881389466306643,
    -6.136633054251843,
    -7.708372857519877,
    -9.670318512232635,
    -12.107559733988328,
    -15.112835761128558,
    -18.777053580228273,
    -23.170878633880005,
    -28.315599600939937,
    -34.14738095782734,
    -40.490162505019214,
    -47.061170265616234,
    -53.52303696794363,
    -59.56450704883259,
    -64.96704516332555,
    -69.62719194589674,
    -73.53858727515998,
    -76.75693169952633,
    -79.36740377850053,
    -81.46228187676297,
    -83.12847901407149,
    -84.44196406353747,
    -85.46615443112393,
    -86.25227596139315,
    -86.84051756334429,
    -87.26136332814822,
    -87.53680543266104,
    -87.68130947556715,
    -87.70248494543128,
    -87.60144804302547,
    -87.37287545377501,
    -87.00474915627764,
    -86.47779252265067,
    -85.76460472927837,
    -84.82852495796381,
    -83.62231915025858,
    -82.0869139919729,
    -80.15066142431155,
    -77.73008142057756,
    -74.73376989777228,
    -71.07209450235179,
    -66.67585774323996,
    -61.52566777067657,
    -55.68773711376516,
    -49.340621973373096,
    -42.76890286360984,
    -36.31025007022719,
    -30.274565290613868,
    -24.878021552823526,
    -20.221592717437247,
    -16.309564172390385,
    -13.084640106966171,
    -10.460330034299673,
    -8.343068124397588,
    -6.6444488418883605,
    -5.286617786312282,
    -4.203713747495291,
    -3.3413425386607267,
    -2.6552345191828963,
    -2.1096856531648838,
    -1.6760627125773822,
    -1.3314842526742667,
    -1.0577058827854644,
    -0.8402009105286358,
    -0.6674129820164081,
    -0.5301538354824239
  ],
  "tanDelta": [
    -0.002700689398442314,
    -0.003399966507258043,
    -0.004280304223655526,
    -0.005388583734263347,
    -0.0067838249505413105,
    -0.008540329527411142,
    -0.010751637683612519,
    -0.01353550953209028,
    -0.01704019618083909,
    -0.02145233453609511,
    -0.027006886180865167,
    -0.033999649502437924,
    -0.04280301117004514,
    -0.053885775356823366,
    -0.06783812582754696,
    -0.08540304850459425,
    -0.107515884467056,
    -0.13535411291813843,
    -0.17040000166566627,
    -0.21451943438888546,
    -0.2700610584783636,
    -0.3399809256019604,
    -0.4279990474674652,
    -0.538795774958868,
    -0.6782576031786544,
    -0.8537837875240729,
    -1.074666703231314,
    -1.3525594460496424,
    -1.7020421486255628,
    -2.141290560106853,
    -2.692829961184246,
    -3.38431151520521,
    -4.249152303819271,
    -5.326690950430424,
    -6.6611567020446545,
    -8.298135715539692,
    -10.276282183688682,
    -12.610959097437169,
    -15.266343673047345,
    -18.11616112662456,
    -20.90534296197404,
    -23.24642869950145,
    -24.69691289062164,
    -24.924784105225214,
    -23.873698269791475,
    -21.794026102908912,
    -19.11144633820849,
    -16.24651211650875,
    -13.503197560380103,
    -11.04909204769673,
    -8.946660814601966,
    -7.1945414017156075,
    -5.759806698005145,
    -4.598010973264643,
    -3.6638674387687393,
    -2.916125653418795,
    -2.3192834928254644,
    -1.8437401241069433,
    -1.465271417713454,
    -1.1642759258515873,
    -0.9250025090590445,
    -0.7348484945075845,
    -0.5837574694919052,
    -0.4637183795948164,
    -0.36835630021539795,
    -0.2926016730633528,
    -0.23242470918085567,
    -0.18462298183881243,
    -0.1466519854562648,
    -0.11649018270709628,
    -0.09253162662305627,
    -0.0735005765674216,
    -0.05838362981433091,
    -0.04637578895170012,
    -0.036837610271918134,
    -0.029261159802631616,
    -0.02324296835091114,
    -0.0184625474928719,
    -0.01466532349674512,
    -0.01164908089481604,
    -0.009253194048771835
  ],
  "nimg": [
    79608721.65832445,
    63235185.473173626,
    50229153.50648915,
    39898007.13304105,
    31691575.1431723,
    25172835.123370223,
    19994640.426507723,
    15881233.361709032,
    12613560.12107297,
    10017605.42406328,
    7955125.685718803,
    6316287.273473868,
    5013817.926635412,
    3978360.1047710823,
    3154779.254271524,
    2499231.250085974,
    1976834.4770876183,
    1559825.619036801,
    1226106.3414330871,
    958112.3154444125,
    741956.6910666098,
    566813.6257088794,
    424502.2578384475,
    309187.82481718063,
    217030.6630068005,
    145564.51359243557,
    92754.33429564655,
    56098.17077684625,
    32339.636806692735,
    17911.509282354866,
    9619.987126363056,
    5054.36546328177,
    2616.8393738955588,
    1342.7370734821016,
    685.9044059049418,
    350.1483343740727,
    179.29491761326858,
    92.47653581496292,
    48.29641237001156,
    25.711872848299276,
    14.06986604265423,
    7.987490181274931,
    4.74528552216278,
    2.9672967805502846,
    1.9549147652602215,
    1.3512784582669433,
    0.972323358033339,
    0.7217021317733009,
    0.5478860538917555,
    0.4224796464635244,
    0.32921141829937284,
    0.2583062663335101,
    0.20357832414785132,
    0.16090528695212092,
    0.1274092962528442,
    0.10100316517410994,
    0.08012860827002838,
    0.0635977671251286,
    0.05049211753124684,
    0.040094592163673476,
    0.031841890298346,
    0.025289716781866433,
    0.020086734429256403,
    0.01595465756960149,
    0.012672832664366949,
    0.010066187162330058,
    0.00799575562121004,
    0.006351203798978922,
    0.0050449151174330655,
    0.004007305797134971,
    0.003183109763519353,
    0.002528430763660145,
    0.0020084023434617476,
    0.0015953298853670455,
    0.0012672151693279492,
    0.0010065845868311824,
    0.0007995584569004338,
    0.0006351118069796883,
    0.0005044872151061407,
    0.0004007284262938003,
    0.00031830989708547477
  ],
  "nreal": [
    -214998.43060618185,
    -214997.51268904065,
    -214996.0579044673,
    -214993.752266628,
    -214990.09817820706,
    -214984.307092771,
    -214975.12947992267,
    -214960.58554876276,
    -214937.5390018919,
    -214901.0228076064,
    -214843.17394868468,
    -214751.5534548208,
    -214606.5047183481,
    -214377.01889424282,
    -214014.3120094064,
    -213441.9676752902,
    -212541.10724904542,
    -211128.81297171232,
    -208928.52262248204,
    -205533.71199016078,
    -200373.60933455286,
    -192705.82111230798,
    -181686.56200264386,
    -166589.09368021955,
    -147202.69730726676,
    -124280.62174404903,
    -99679.99464791767,
    -75876.1107903294,
    -55043.424916233635,
    -38353.74574357275,
    -25904.989560077174,
    -17105.54723944001,
    -11119.34905431329,
    -7152.3454181245415,
    -4568.91673035566,
    -2905.578399206227,
    -1842.485167495162,
    -1166.2178106351807,
    -737.3096294158115,
    -465.8004313870729,
    -294.1353750507191,
    -185.6806209869756,
    -117.1939031819822,
    -73.95923163134567,
    -46.67104524898276,
    -29.44979799176827,
    -18.582505680440843,
    -11.725142428365126,
    -7.3982136262774345,
    -4.668016502053854,
    -2.9453428958185355,
    -1.8583951274590171,
    -1.1725717949954566,
    -0.739844275062148,
    -0.46681077193723575,
    -0.29453792104071785,
    -0.18584095846375484,
    -0.11725775505220909,
    -0.0739846566383644,
    -0.04668116841300273,
    -0.029453828419152894,
    -0.01858411030367774,
    -0.011725781260778646,
    -0.007398467955165774,
    -0.004668117753495054,
    -0.0029453832050666184,
    -0.0018584111749409354,
    -0.001172578183633482,
    -0.0007398468184298843,
    -0.00046681178447145885,
    -0.0002945383241381777,
    -0.00018584111893982672,
    -0.00011725781893890534,
    -0.00007398468207212206,
    -0.000046681178538365735,
    -0.00002945383245013315,
    -0.000018584111908440136,
    -0.000011725781899646185,
    -0.000007398468209503594,
    -0.000004668117854748808,
    -0.0000029453832453764904
  ],
  "nmod": [
    79609011.97978617,
    63235550.964288525,
    50229613.62864904,
    39898586.38475438,
    31692304.362378746,
    25173753.125044297,
    19995796.060463104,
    15882688.095608985,
    12615391.277070822,
    10019910.223241815,
    7958026.279485096,
    6319936.957815262,
    5018408.727208062,
    3984131.8539255396,
    3162030.0866574394,
    2508328.9885841133,
    1988227.4196059485,
    1574049.344039247,
    1243779.6782660678,
    979909.8508386922,
    768537.126452762,
    598676.2228281782,
    461749.03759756807,
    351210.8158191836,
    262242.14531555795,
    191401.93457352972,
    136159.71454008506,
    94362.010116225,
    63840.66678299847,
    42330.03634931712,
    27633.541872497965,
    17836.65766328236,
    11423.12442812508,
    7277.29261667211,
    4620.115252129936,
    2926.600363903271,
    1851.1883372368206,
    1169.8785798188846,
    738.8897299848575,
    466.5095307555359,
    294.4716964101736,
    185.85234195324216,
    117.28993425573512,
    74.01873272140578,
    46.71197016153384,
    29.480782812329373,
    18.60792653887567,
    11.74733241772166,
    7.418473157468872,
    4.687095808186882,
    2.963684350919882,
    1.8762607966353118,
    1.190112914173153,
    0.7571393945049879,
    0.48388575673218875,
    0.3113747361398749,
    0.20237948440002193,
    0.13339436683453298,
    0.0895722242142391,
    0.061536231645872096,
    0.04337549983938859,
    0.03138373672280403,
    0.023258779981054512,
    0.017586597915653184,
    0.013505258646154898,
    0.01048825086521804,
    0.008208885432825656,
    0.0064585392538017055,
    0.005098876528888055,
    0.004034403672647666,
    0.00319670777378827,
    0.0025352512790869134,
    0.002011822400045528,
    0.001597044512944628,
    0.0012680746893636013,
    0.0010070154212781961,
    0.0007997744027014296,
    0.0006352200416597095,
    0.0005045414626543555,
    0.00040075561501270524,
    0.00031832352389515243
  ],
  "cimg": [
    -0.0000031818261406682943,
    -0.000002527414345829448,
    -0.0000020075965811690604,
    -0.0000015946906551938965,
    -0.0000012667078216767014,
    -0.0000010061817987372317,
    -7.992386256410113e-7,
    -6.348578237985687e-7,
    -5.042855160941664e-7,
    -4.0056825100821664e-7,
    -3.181827059679716e-7,
    -2.527415502796285e-7,
    -2.0075980377040135e-7,
    -1.594692488862763e-7,
    -1.266710130129035e-7,
    -1.0061847049065361e-7,
    -7.992422842913977e-8,
    -6.348624297665081e-8,
    -5.042913146642411e-8,
    -4.0057555097541575e-8,
    -3.1819189608214376e-8,
    -2.5275311994781718e-8,
    -2.00774369119531e-8,
    -1.5948758557410974e-8,
    -1.2669409753455152e-8,
    -1.006475321803113e-8,
    -7.99608149262373e-9,
    -6.353230264254282e-9,
    -5.048711714023311e-9,
    -4.013055471578478e-9,
    -3.1911090642697145e-9,
    -2.5391008462701937e-9,
    -2.0223089976330242e-9,
    -1.613212458393101e-9,
    -1.2900253270348026e-9,
    -1.0355366723500108e-9,
    -8.361939697495384e-10,
    -6.813813423171014e-10,
    -5.628541516531116e-10,
    -4.742997911933935e-10,
    -4.11001218422115e-10,
    -3.695851598465362e-10,
    -3.478412847537385e-10,
    -3.446021305284436e-10,
    -3.596762159043081e-10,
    -3.938284571800707e-10,
    -4.488022406277878e-10,
    -5.27375238552068e-10,
    -6.33434115571398e-10,
    -7.720374941246571e-10,
    -9.49404441585442e-10,
    -1.1727050533790715e-9,
    -1.4494240477857827e-9,
    -1.7859072100947815e-9,
    -2.184518377716353e-9,
    -2.638812313025301e-9,
    -3.1267624854884645e-9,
    -3.604185635708426e-9,
    -4.004212625591645e-9,
    -4.250737327118779e-9,
    -4.28696680019284e-9,
    -4.103693584406172e-9,
    -3.7443439890653654e-9,
    -3.282192751356641e-9,
    -2.7893830121651665e-9,
    -2.3179236743117875e-9,
    -1.8964062867658155e-9,
    -1.5354218091129361e-9,
    -1.234653624714293e-9,
    -9.884029818918343e-10,
    -7.890163516249436e-10,
    -6.287084654321694e-10,
    -5.00393551084727e-10,
    -3.979758529624772e-10,
    -3.163741257034837e-10,
    -2.51430695485246e-10,
    -1.9978159870771015e-10,
    -1.5872380495283676e-10,
    -1.2609466268121194e-10,
    -1.0016850272302141e-10,
    -7.957065585365251e-11
  ],
  "creal": [
    8.593124125789486e-9,
    8.593124125783621e-9,
    8.593124125774324e-9,
    8.59312412575959e-9,
    8.593124125736239e-9,
    8.593124125699234e-9,
    8.593124125640575e-9,
    8.593124125547618e-9,
    8.593124125400286e-9,
    8.593124125166781e-9,
    8.593124124796697e-9,
    8.59312412421016e-9,
    8.59312412328056e-9,
    8.59312412180724e-9,
    8.593124119472185e-9,
    8.593124115771374e-9,
    8.593124109905984e-9,
    8.593124100609967e-9,
    8.593124085876772e-9,
    8.593124062526234e-9,
    8.593124025518121e-9,
    8.593123966864223e-9,
    8.593123873904054e-9,
    8.593123726572123e-9,
    8.593123493066757e-9,
    8.593123122985719e-9,
    8.593122536446867e-9,
    8.593121606845595e-9,
    8.593120133527284e-9,
    8.59311779847615e-9,
    8.593114097672111e-9,
    8.593108232299511e-9,
    8.593098936326804e-9,
    8.593084203244148e-9,
    8.593060852985222e-9,
    8.593023845578747e-9,
    8.592965193445095e-9,
    8.5928722377178e-9,
    8.592724916937912e-9,
    8.592491439583902e-9,
    8.592121428903516e-9,
    8.59153506676635e-9,
    8.590605909324998e-9,
    8.589133705622095e-9,
    8.586801453319825e-9,
    8.583107675850806e-9,
    8.577259938225702e-9,
    8.568008203082864e-9,
    8.55338600404523e-9,
    8.53031333685646e-9,
    8.493999514741536e-9,
    8.437075058536841e-9,
    8.34840233868628e-9,
    8.211620949248248e-9,
    8.003785753506856e-9,
    7.695108280570467e-9,
    7.251848618579317e-9,
    6.645181671285515e-9,
    5.867258310726782e-9,
    4.9490311370831175e-9,
    3.9654550464312e-9,
    3.0155930524213087e-9,
    2.1857887719640236e-9,
    1.5220131041769537e-9,
    1.0274868062448432e-9,
    6.78228345136783e-10,
    4.407716796902911e-10,
    2.8347415277877413e-10,
    1.810644054151251e-10,
    1.1513924394881855e-10,
    7.300896644804535e-11,
    4.6210434702083296e-11,
    2.9214791848009183e-11,
    1.8456444164860682e-11,
    1.165446674278377e-11,
    7.35715375988059e-12,
    4.643517375857737e-12,
    2.9304457871910843e-12,
    1.8492190194329274e-12,
    1.1668709913330772e-12,
    7.362827192018892e-13
  ]
},
g:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    9430964975.182674,
    8403532527.649293,
    7368863836.350237,
    6353192593.064633,
    5383135591.3121395,
    4482641242.940715,
    3670280577.856381,
    2957535513.931402,
    2348408958.8111567,
    1840255527.3622663,
    1425413153.027126,
    1093114078.6715019,
    831245070.6314139,
    627710287.7818521,
    471327571.5769441,
    352307688.20568347,
    262419655.07891563,
    194951622.6037229,
    144558433.64603764,
    107061036.19238468,
    79238894.83372784,
    58638119.475242265,
    43405553.3443286,
    32151458.23663389,
    23839299.699825358,
    17699235.597253166,
    13161331.891386699,
    9804655.37428909,
    7318844.2533338955,
    5475315.554206796,
    4105818.280416572,
    3086530.263434824,
    2326306.62452501,
    1758018.834988402,
    1332183.8953378827,
    1012284.4264782338,
    771333.845034222,
    589356.4750347915,
    451539.00594319915,
    346874.077032208,
    267164.40904349816,
    206291.03218283303,
    159674.98632088507,
    123880.81551068294,
    96324.05460698629,
    75055.05582151092,
    58598.92195484087,
    45836.73332951165,
    35917.215035895526,
    28190.884086654223,
    22160.830649375675,
    17445.834151469222,
    13752.64727043934,
    10855.110583405542,
    8578.369542946179,
    6786.912933177801,
    5375.481381229233,
    4262.137458066902,
    3382.968457295793,
    2688.02592860754,
    2138.204771390199,
    1702.838170602783,
    1357.8394820641208,
    1084.2631927024938,
    867.187855384513,
    694.8470519794174,
    557.9519095803439,
    449.1619165734512,
    362.6708204303967,
    293.88202791742884,
    239.15375988510982,
    195.59867716684877,
    160.92612133457035,
    133.31775215332547,
    111.32939951032009,
    93.81352272662659,
    79.85789168398942,
    68.73705363745913,
    59.87388911823705,
    52.80913752436936,
    47.17722433513728
  ],
  "Mimg": [
    -5759059610.170283,
    -5795649409.133844,
    -5737553885.447837,
    -5582102533.472028,
    -5334142109.887962,
    -5005873648.189358,
    -4615339899.823252,
    -4183922548.0624986,
    -3733505894.131761,
    -3283987114.1687193,
    -2851589100.650659,
    -2448108326.0756426,
    -2080962246.4147484,
    -1753766108.456167,
    -1467159736.1881633,
    -1219669006.252421,
    -1008471544.196089,
    -830009056.095626,
    -680437882.131789,
    -555936024.0241511,
    -452895350.16116047,
    -368028514.6178993,
    -298416313.3027858,
    -241515844.45083368,
    -195144583.02701697,
    -157451021.6562862,
    -126879054.7058738,
    -102130718.42586628,
    -82130087.34823154,
    -65989890.64653286,
    -52981597.47526873,
    -42509203.84140177,
    -34086641.96123981,
    -27318558.50871839,
    -21884122.789403923,
    -17523496.64752492,
    -14026602.508519972,
    -11223849.600319063,
    -8978511.526374841,
    -7180484.979173988,
    -5741195.862373691,
    -4589453.37901265,
    -3668083.7009547562,
    -2931202.255701452,
    -2342007.4311704026,
    -1870998.807440724,
    -1494540.1908842889,
    -1193702.1110436614,
    -953330.4101357308,
    -761297.4571953587,
    -607900.6712906086,
    -485379.72295314114,
    -387529.24539398745,
    -309388.33761943533,
    -246991.75853140937,
    -197170.64421877146,
    -157392.95472056625,
    -125635.77496133972,
    -100283.14254753977,
    -80044.32262573372,
    -63888.45425006303,
    -50992.30033672357,
    -40698.482233299255,
    -32482.10098115679,
    -25924.06542583032,
    -20689.782583862245,
    -16512.134370459502,
    -13177.880043103007,
    -10516.79608046571,
    -8393.00318711947,
    -6698.040518745174,
    -5345.335541804847,
    -4265.788575177154,
    -3404.2475373945867,
    -2716.6935700592894,
    -2167.9942916851437,
    -1730.110271274496,
    -1380.6633500914174,
    -1101.7938458865028,
    -879.2483763238394,
    -701.6517814286409
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    11050333386.672867,
    10208276593.865059,
    9339147649.924372,
    8457122726.954253,
    7578338923.730681,
    6719586556.81655,
    5896806077.114141,
    5123692438.500897,
    4410679187.986937,
    3764453714.9516883,
    3188002988.670552,
    2681069332.410248,
    2240841859.3137136,
    1862717307.7406735,
    1541008556.5045836,
    1269532666.7642708,
    1042055147.6889222,
    852596720.8220874,
    695624073.8920629,
    566150976.5763621,
    459774945.6550136,
    372670654.36850554,
    301556525.5575707,
    243646504.97706982,
    196595321.6507616,
    158442693.6192874,
    127559849.40494148,
    102600267.60779992,
    82455543.9557141,
    66216650.08107481,
    53140449.89255553,
    42621110.73513859,
    34165931.31622587,
    27375066.56098655,
    21924633.27384761,
    17552710.7455204,
    14047794.625225484,
    11239312.296811039,
    8989858.547448566,
    7188858.47415709,
    5747408.6988482345,
    4594087.320468526,
    3671557.454060404,
    2933818.8628954026,
    2343987.442618522,
    1872503.6178467004,
    1495688.5423853176,
    1194581.8247539238,
    954006.7700103052,
    761819.2333340674,
    608304.470286585,
    485693.14652701106,
    387773.19575052947,
    309578.70870054304,
    247140.68302578875,
    197287.4175633304,
    157484.72305512734,
    125708.04972573496,
    100340.18713752352,
    80089.44417340786,
    63924.22472042029,
    51020.72472501621,
    40721.12700003805,
    32500.19247360108,
    25938.565553616438,
    20701.447190791612,
    16521.55839506414,
    13185.532558744544,
    10523.047558672848,
    8398.146768503777,
    6702.308655352967,
    5348.913057527813,
    4268.822950725378,
    3406.857044094502,
    2718.973738158722,
    2170.0230933853554,
    1731.9523185219973,
    1382.3733463968463,
    1103.419485704106,
    880.8328514957736,
    703.2360292803214
  ],
  "Mphz": [
    -31.41050649598454,
    -34.592795709803056,
    -37.90508261588291,
    -41.30349389184706,
    -44.738077128050726,
    -48.15630813909852,
    -51.50704558708067,
    -54.74423398720136,
    -57.82973602812955,
    -60.73493596605329,
    -63.441079755201656,
    -65.93858035516577,
    -68.22565013664969,
    -70.30662839975628,
    -72.19029615192342,
    -73.88836534916518,
    -75.41423346594534,
    -76.78202382067136,
    -78.00588962552857,
    -79.09953967704548,
    -80.07593842078248,
    -80.94713626644885,
    -81.72419288505998,
    -82.41716399053273,
    -83.03512933744449,
    -83.58624574920641,
    -84.07781379919325,
    -84.51635040675872,
    -84.90766227499084,
    -85.25691699550848,
    -85.56870996456551,
    -85.84712614824379,
    -86.095796322918,
    -86.3179477911657,
    -86.51644979948973,
    -86.693854010543,
    -86.85243044289506,
    -86.99419930934752,
    -87.12095917656953,
    -87.23431184526976,
    -87.33568431845633,
    -87.4263481901787,
    -87.50743675137898,
    -87.57996007478476,
    -87.64481830804533,
    -87.70281337391297,
    -87.75465924823199,
    -87.80099096062132,
    -87.84237243868886,
    -87.87930329395712,
    -87.91222462590213,
    -87.94152389903184,
    -87.9675389261067,
    -87.9905609676991,
    -88.01083693345161,
    -88.0285706426397,
    -88.04392306979568,
    -88.0570114637946,
    -88.06790718422063,
    -88.07663204491232,
    -88.08315288872667,
    -88.08737403654257,
    -88.08912715333592,
    -88.08815794982479,
    -88.08410898349462,
    -88.07649763005074,
    -88.06468805589303,
    -88.04785572212904,
    -88.02494257614075,
    -87.99460061955241,
    -87.95512095924587,
    -87.90434472372377,
    -87.8395513282902,
    -87.75731846171851,
    -87.65334680295807,
    -87.52224081776411,
    -87.35723500019418,
    -87.1498526108194,
    -86.88948139196485,
    -86.56284813500723,
    -86.15337187856748
  ],
  "tanDelta": [
    -0.610654331272048,
    -0.6896682306000489,
    -0.7786212383440674,
    -0.8786295160586892,
    -0.9908987093872857,
    -1.116724131352834,
    -1.2574896665035975,
    -1.4146651928114569,
    -1.5898022702237462,
    -1.7845277817889933,
    -2.0005351392996387,
    -2.239572587932369,
    -2.503428074267014,
    -2.793910092908422,
    -3.1128239141186116,
    -3.4619426344745463,
    -3.8429726001004707,
    -4.2575129409555155,
    -4.707009234742354,
    -5.192701694248082,
    -5.715568738199851,
    -6.276267348124721,
    -6.875072204137149,
    -7.511816188033631,
    -8.185835384604296,
    -8.895922131276778,
    -9.640289885016008,
    -10.416553619384253,
    -11.221729074343871,
    -12.052253425984096,
    -12.904028833417652,
    -13.772488915788466,
    -14.65268662431793,
    -15.539400355115431,
    -16.42725367420347,
    -17.310842870999863,
    -18.184865864271323,
    -19.044245844005506,
    -19.88424346113807,
    -20.70055231745457,
    -21.489373838859436,
    -22.247469172315146,
    -22.97218735051791,
    -23.66147045140075,
    -24.313837708826462,
    -24.928351420991046,
    -25.504568019801678,
    -26.04247781931495,
    -26.542436800374862,
    -27.005093378953752,
    -27.43131252202113,
    -27.822098888419404,
    -28.1785199440811,
    -28.501629277955438,
    -28.79238966039948,
    -29.051594761869335,
    -29.27978790330673,
    -29.477175759207448,
    -29.64353460974981,
    -29.778106592594714,
    -29.87948352978588,
    -29.94547645045621,
    -29.972970127095895,
    -29.957764129386444,
    -29.894405537235677,
    -29.776024126349913,
    -29.594189188955163,
    -29.338818712935193,
    -28.9981864766098,
    -28.559089668040627,
    -28.007255758650555,
    -27.328076136451532,
    -26.507744919225686,
    -25.534840502556968,
    -24.402301476596534,
    -23.109613930634474,
    -21.664862855643896,
    -20.086158440445686,
    -18.40190878048218,
    -16.649550012402692,
    -14.87268043673471
  ],
  "nimg": [
    150098469392.68552,
    106238521256.22092,
    73998116094.18729,
    50677146459.15227,
    34107936885.361168,
    22560780190.428417,
    14673016205.35677,
    9391827341.756874,
    5923710968.195549,
    3687213307.148144,
    2268615492.5247073,
    1381928646.082145,
    834736135.7917299,
    500702059.9778924,
    298636562.11116195,
    177313683.65745285,
    104909904.56718518,
    61908030.21180492,
    36463937.66815218,
    21451199.110998213,
    12611261.797926603,
    7413105.240919372,
    4358784.809758293,
    2564606.9028536305,
    1510475.2903960506,
    890788.5824614397,
    526162.597190826,
    311352.577127118,
    184613.15187596876,
    109705.7233200868,
    65346.127476536145,
    39020.30603451648,
    23360.766529991906,
    14023.087868595485,
    8440.813620821966,
    5094.747761029185,
    3083.6318280978408,
    1871.5360238955973,
    1138.9781801155737,
    695.0114773306188,
    425.2053631749762,
    260.7957324543627,
    160.34558973426243,
    98.81529859439938,
    61.03161845634173,
    37.774618239549184,
    23.426626744759435,
    14.55572328085693,
    9.059887114503244,
    5.648444001337841,
    3.527005740870494,
    2.2055244223012633,
    1.3810405673399173,
    0.8658733712339344,
    0.5435317056147053,
    0.34157998054858935,
    0.21490053347484464,
    0.13534667267541428,
    0.08533320944954725,
    0.053858417086932134,
    0.03403058587094234,
    0.021527495560737936,
    0.013635421397719501,
    0.008648779934175859,
    0.005494565042526998,
    0.0034971104659249487,
    0.002230575357228857,
    0.00142633998783093,
    0.0009148138822956935,
    0.0005888343808547156,
    0.0003806250304472746,
    0.0002472783219855256,
    0.00016160197926795908,
    0.00010634280564474512,
    0.00007053911363688321,
    0.00004721560683577524,
    0.00003192551942416273,
    0.00002182785419492603,
    0.000015102804490141995,
    0.000010581060712692073,
    0.000007508488454292415
  ],
  "nreal": [
    -91658280451.94835,
    -73269332976.34357,
    -57616504788.38417,
    -44526436668.640274,
    -33797510639.56738,
    -25194167660.798397,
    -18451166254.67597,
    -13286291237.2784,
    -9417529145.38659,
    -6579934583.987935,
    -4538445010.355234,
    -3094929514.244064,
    -2089701876.946179,
    -1398916538.912272,
    -929603032.169793,
    -613849801.1294687,
    -403165888.7308479,
    -263574239.77582446,
    -171636091.3390619,
    -111389677.96733338,
    -72080533.68148333,
    -46526630.37179449,
    -29966960.289384473,
    -19264855.648798697,
    -12364502.079694439,
    -7924385.86500739,
    -5072359.963572472,
    -3243220.8141780957,
    -2071678.7739128196,
    -1322201.1797345795,
    -843228.3131094079,
    -537406.732351052,
    -342297.9912678264,
    -217910.37660506758,
    -138659.38656591432,
    -88194.3779585546,
    -56075.431168757,
    -35641.99214498032,
    -22647.71943034203,
    -14387.121447713866,
    -9137.397007555059,
    -5802.045018049783,
    -3683.488928204758,
    -2338.115267837723,
    -1483.9128662545106,
    -941.6589582692601,
    -597.4859952862222,
    -379.0671006858028,
    -240.47148115523294,
    -152.53675770191958,
    -96.75039674478118,
    -61.36231857808983,
    -38.91567917037293,
    -24.678801828563085,
    -15.649576660840136,
    -9.923443173664829,
    -6.292242040450919,
    -3.989637658877107,
    -2.5295779476786837,
    -1.6038016849230896,
    -1.0168163300397857,
    -0.6446511113513784,
    -0.4086940782242108,
    -0.2590981092750108,
    -0.1642567556320207,
    -0.10413004560589205,
    -0.06601206912205204,
    -0.04184713032598184,
    -0.02652794355020189,
    -0.01681657388245501,
    -0.010660262575880975,
    -0.0067576408101144205,
    -0.004283704044877057,
    -0.0027154465807329815,
    -0.0017213167168591257,
    -0.001091134445475392,
    -0.0006916619999196808,
    -0.0004384377377742312,
    -0.00027792043055704967,
    -0.00017616989952023594,
    -0.00011167134934360325
  ],
  "nmod": [
    175871518130.23895,
    129054324040.33122,
    93783702259.60754,
    67459445118.15081,
    48016904148.51265,
    33819149707.247917,
    23574200744.04126,
    16270585596.688055,
    11125655344.244946,
    7542617655.825519,
    5073864342.386525,
    3389441765.3946247,
    2250252952.6497445,
    1485823016.2803357,
    976394179.442323,
    638945788.5915388,
    416591912.922503,
    270747085.0777706,
    175466710.80412287,
    113436388.78669368,
    73175455.31080279,
    47113495.55134427,
    30282300.342003837,
    19434810.823242288,
    12456421.929336898,
    7974295.93357135,
    5099576.715643713,
    3258131.623615247,
    2079888.208083911,
    1326744.6270555225,
    845756.527852737,
    538821.4734578258,
    343094.21481403295,
    218361.1211400343,
    138916.06392860803,
    88341.41021200019,
    56160.1528311067,
    35691.09484383433,
    22676.341563185877,
    14403.898934138964,
    9147.285043910511,
    5807.903305457376,
    3686.977270387528,
    2340.202441869542,
    1485.1674158447856,
    942.4163174902543,
    597.945082264087,
    379.3464589297881,
    240.6420885953226,
    152.641302961716,
    96.81466335100639,
    61.40194198277735,
    38.94017666035988,
    24.693987049249472,
    15.659012624636356,
    9.929320284091286,
    6.295910747056832,
    3.9919327863748797,
    2.5310168608717847,
    1.6047057592135858,
    1.0173856347571937,
    0.6450104560638416,
    0.4089214769270877,
    0.2592424186436997,
    0.16434862949159,
    0.10418875265351842,
    0.06604974440676402,
    0.04187143133785367,
    0.026543712503000385,
    0.016826879778263838,
    0.010667055526270192,
    0.0067621635507467475,
    0.004286751164203421,
    0.002717528090954505,
    0.0017227614478770288,
    0.001092155525386271,
    0.0006923984119879213,
    0.00043898075712192733,
    0.0002783304877739471,
    0.0001764873716240788,
    0.00011192349022028092
  ],
  "cimg": [
    -1.229208350679834e-9,
    -1.0194763597427814e-9,
    -8.484107840030891e-10,
    -7.085441008958239e-10,
    -5.938919003418519e-10,
    -4.996535864491386e-10,
    -4.2197401263206877e-10,
    -3.5775358570189984e-10,
    -3.044969306890707e-10,
    -2.601922394282428e-10,
    -2.23215040147191e-10,
    -1.9225140815070581e-10,
    -1.6623665726263195e-10,
    -1.443063620701632e-10,
    -1.2575720517416652e-10,
    -1.1001565572559825e-10,
    -9.661289264308808e-11,
    -8.516470960911293e-11,
    -7.535539635275412e-11,
    -6.692479544922496e-11,
    -5.965789670909364e-11,
    -5.337646078224564e-11,
    -4.793226668815843e-11,
    -4.320166002869877e-11,
    -3.908114395271678e-11,
    -3.5483806948910195e-11,
    -3.233642296758695e-11,
    -2.957709237738495e-11,
    -2.715331857024143e-11,
    -2.502043600479626e-11,
    -2.3140322212565477e-11,
    -2.148033964749331e-11,
    -2.001246392572491e-11,
    -1.8712563525766488e-11,
    -1.7559802834900212e-11,
    -1.6536145882169117e-11,
    -1.5625942467121156e-11,
    -1.481558189628377e-11,
    -1.4093202350813024e-11,
    -1.3448446168175824e-11,
    -1.2872253139062772e-11,
    -1.2356685386501717e-11,
    -1.189477857798137e-11,
    -1.1480415179723174e-11,
    -1.1108216240182707e-11,
    -1.0773448823575876e-11,
    -1.0471946732744702e-11,
    -1.0200042587407338e-11,
    -9.954509677730137e-12,
    -9.7325123096869e-12,
    -9.531563610583111e-12,
    -9.349489981101757e-12,
    -9.184401573400316e-12,
    -9.034668351184605e-12,
    -8.898901454622694e-12,
    -8.77593975740604e-12,
    -8.66484167215098e-12,
    -8.564882440914143e-12,
    -8.475557347766593e-12,
    -8.396591519055795e-12,
    -8.32795724453984e-12,
    -8.269900071280098e-12,
    -8.222975306765766e-12,
    -8.188097036079109e-12,
    -8.16660233172887e-12,
    -8.160334040557938e-12,
    -8.17174640206125e-12,
    -8.204038825431206e-12,
    -8.261324475205056e-12,
    -8.348841942175123e-12,
    -8.473220269514291e-12,
    -8.642810031047577e-12,
    -8.868096084039169e-12,
    -9.162211088691128e-12,
    -9.541572893511599e-12,
    -1.0026673300381029e-11,
    -1.0643050158303785e-11,
    -1.1422478282459122e-11,
    -1.240441542159173e-11,
    -1.3637733482886731e-11,
    -1.5182744761393323e-11
  ],
  "creal": [
    7.50621403378411e-10,
    7.031004571623829e-10,
    6.605906552649464e-10,
    6.225477604763367e-10,
    5.884867175643036e-10,
    5.579752173047425e-10,
    5.30627960417885e-10,
    5.061015452959682e-10,
    4.840899116856473e-10,
    4.643202798655928e-10,
    4.465495314346352e-10,
    4.305609836857183e-10,
    4.161615147635763e-10,
    4.0317900145872607e-10,
    3.9146003563886634e-10,
    3.8086788901612233e-10,
    3.712806992438358e-10,
    3.6258985327351686e-10,
    3.5469854652008397e-10,
    3.4752049871639675e-10,
    3.4097880941725143e-10,
    3.35004937966068e-10,
    3.29537794389047e-10,
    3.2452292915350493e-10,
    3.1991181103896323e-10,
    3.1566118353876294e-10,
    3.117324912520278e-10,
    3.080913686545116e-10,
    3.0470718446459964e-10,
    3.015526355584215e-10,
    2.9860338504551987e-10,
    2.9583773970247316e-10,
    2.932363624841145e-10,
    2.907820162974158e-10,
    2.88459335637903e-10,
    2.8625462305816104e-10,
    2.841556677674192e-10,
    2.8215158395482543e-10,
    2.802326666906495e-10,
    2.783902634927951e-10,
    2.766166598537518e-10,
    2.7490497720819404e-10,
    2.73249081986315e-10,
    2.716435045448325e-10,
    2.7008336689835284e-10,
    2.68564318290162e-10,
    2.670824777450272e-10,
    2.6563438283862347e-10,
    2.642169439998721e-10,
    2.628274037349116e-10,
    2.614633002254294e-10,
    2.6012243481049956e-10,
    2.5880284291051063e-10,
    2.575027679947405e-10,
    2.562206382309924e-10,
    2.5495504548673817e-10,
    2.537047263763143e-10,
    2.5246854506777586e-10,
    2.5124547757543836e-10,
    2.5003459726892024e-10,
    2.4883506132498913e-10,
    2.476460978321443e-10,
    2.464669932255375e-10,
    2.452970796753862e-10,
    2.4413572196603736e-10,
    2.429823032707276e-10,
    2.4183620902676425e-10,
    2.406968078132079e-10,
    2.395634276757768e-10,
    2.384353256508778e-10,
    2.3731164718766873e-10,
    2.361913705613552e-10,
    2.350732289148945e-10,
    2.339555988004868e-10,
    2.3283633830839153e-10,
    2.3171254898040614e-10,
    2.3058022204539056e-10,
    2.2943370856402383e-10,
    2.2826492106333744e-10,
    2.2706212567914136e-10,
    2.258081109887109e-10
  ]
},
h:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    470862120.4466575,
    372907016.3748353,
    293056427.20016795,
    228555557.36782348,
    176929067.46725896,
    135981053.56871703,
    103790636.9986974,
    78702109.70302255,
    59309601.843985625,
    44437066.11834702,
    33114905.576203894,
    24554788.870456114,
    18124145.42004765,
    13321596.432320638,
    9754247.137417277,
    7117418.287876519,
    5177084.3186635375,
    3755039.870611634,
    2716643.62237268,
    1960882.987674193,
    1412452.6084747927,
    1015528.9870476723,
    728939.3012391936,
    522453.1842487641,
    373963.69407215656,
    267362.2286605151,
    190948.34444286604,
    136247.52067792552,
    97137.20114828406,
    69203.95066495042,
    49272.685537338766,
    35063.25179535886,
    24940.757076638136,
    17734.608068505702,
    12607.695392025418,
    8962.051587313897,
    6370.954433057463,
    4530.152530620849,
    3222.8846999660286,
    2294.8284107712793,
    1636.1819217931713,
    1168.863282007202,
    837.3741723702279,
    602.2852036619228,
    435.59418284036377,
    317.4212072079795,
    233.65705425621277,
    174.29082387333347,
    132.22122385598894,
    102.41200266535809,
    81.29213018888461,
    66.3299495325219,
    55.730935425883274,
    48.22324056368534,
    42.90556881530232,
    39.13928485119051,
    36.47191177440153,
    34.58289463478786,
    33.24515533349982,
    32.2978448857813,
    31.62703411863564,
    31.152031494156883,
    30.815689144132744,
    30.57753519058142,
    30.40890872200636,
    30.28951367676505,
    30.204977952534108,
    30.145124613822123,
    30.102747511900724,
    30.07274418165265,
    30.05150178488822,
    30.036462268654276,
    30.02581444336352,
    30.018275941878574,
    30.01293882748989,
    30.00916027325119,
    30.006485156581153,
    30.004591252679727,
    30.003250429542636,
    30.002301173046273,
    30.001629134248105
  ],
  "Mimg": [
    -1127909128.5499017,
    -956759683.3701996,
    -807874733.0605631,
    -679055276.7209638,
    -568221931.0945578,
    -473409063.53088945,
    -392766044.56177545,
    -324562771.6284568,
    -267196743.38838154,
    -219199434.08807972,
    -179240387.67839336,
    -146128169.06180534,
    -118807950.68885604,
    -96356000.7071881,
    -77971648.05194943,
    -62967443.10745699,
    -50758245.38750036,
    -40849895.25540099,
    -32828005.243595228,
    -26347269.63598469,
    -21121560.042921465,
    -16914961.88106698,
    -13533817.006716143,
    -10819771.509052705,
    -8643782.371568054,
    -6901008.639784741,
    -5506497.926676939,
    -4391573.896051561,
    -3500831.7216008487,
    -2789654.0093258596,
    -2222167.48985142,
    -1769569.6535707559,
    -1408763.5517156844,
    -1121247.6734090322,
    -892215.8159650897,
    -709829.0372608398,
    -564628.0661679956,
    -449059.9688087657,
    -357097.4834598056,
    -283933.32439282205,
    -225735.00162898525,
    -179448.39601194332,
    -142640.5486507394,
    -113373.94459950257,
    -90106.05801297243,
    -71609.13665612593,
    -56906.18601478096,
    -45219.90828693718,
    -35931.99348732348,
    -28550.67721909407,
    -22684.895835757132,
    -18023.704009271787,
    -14319.887892876117,
    -11376.921950580516,
    -9038.589535140807,
    -7180.724864911335,
    -5704.643987657902,
    -4531.920115560664,
    -3600.2287870859113,
    -2860.0442065609395,
    -2272.0126780779037,
    -1804.864568241156,
    -1433.7545286948064,
    -1138.942245175122,
    -904.7439225455354,
    -718.6989973293831,
    -570.9079348906764,
    -453.5060113210981,
    -360.24517365085137,
    -286.1617934977835,
    -227.31267928950612,
    -180.56533013647672,
    -143.43129091171232,
    -113.93375483156828,
    -90.50237757793509,
    -71.8897118270076,
    -57.104819378869294,
    -45.36053070241262,
    -36.031546962941015,
    -28.621155924182126,
    -22.734791073953073
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    1222247985.7777314,
    1026863542.3386235,
    859385625.8054374,
    716487063.1395247,
    595130286.4849529,
    492551508.33476496,
    406248276.41394114,
    333968583.55068725,
    273700070.42061335,
    223658321.44085485,
    182273732.46417502,
    148176851.93655667,
    120182418.82277408,
    97272523.37525357,
    78579407.2079631,
    63368419.06324815,
    51021580.50139425,
    41022119.23837084,
    32940219.80564935,
    26420137.76199191,
    21168734.516213384,
    16945419.27960129,
    13553433.339053622,
    10832377.986289082,
    8651868.152689034,
    6906185.836458353,
    5509807.681466105,
    4393686.910940785,
    3502179.090054317,
    2790512.2609541565,
    2222713.690625183,
    1769917.001010214,
    1408984.310063865,
    1121387.9174703602,
    892304.8897329594,
    709885.6108608657,
    564664.0082075344,
    449082.8184961882,
    357112.02679147536,
    283942.5979633813,
    225740.9312723751,
    179452.20275224058,
    142643.00654041368,
    113375.54437142795,
    90107.1108899252,
    71609.84016780448,
    56906.665711213966,
    45220.244169733276,
    35932.236757890474,
    28550.860895727594,
    22685.041491907876,
    18023.826060968433,
    14319.996340841133,
    11377.024151795198,
    9038.691369473365,
    7180.831530482705,
    5704.760575718312,
    4532.052063957861,
    3600.382279663542,
    2860.2265669465874,
    2272.232795871468,
    1805.1333908491492,
    1434.0856512950313,
    1139.3526335173317,
    905.2548078374094,
    719.3369887618984,
    571.7064026353473,
    454.5067995556885,
    361.50070614937573,
    287.7376270154517,
    229.2905295150696,
    183.04651734712442,
    146.54038605649825,
    117.82188837623795,
    95.3491313244215,
    77.90173532647314,
    64.50852306281249,
    54.38614935665976,
    46.887817318366125,
    41.46454680946748,
    37.642641722988316
  ],
  "Mphz": [
    -67.34119147837826,
    -68.7061341073014,
    -70.0617683406239,
    -71.39786205177188,
    -72.70481249438099,
    -73.97392521492685,
    -75.19763123448196,
    -76.36963289400343,
    -77.48497635118832,
    -78.5400554767777,
    -79.53255699823845,
    -80.46135980444613,
    -81.32640240297803,
    -82.1285319604434,
    -82.86934665549697,
    -83.55104074981658,
    -84.17625927480354,
    -84.74796686150725,
    -85.26933320291347,
    -85.74363601848331,
    -86.17418119929086,
    -86.56423900765562,
    -86.91699472243755,
    -87.23551188770458,
    -87.52270626979937,
    -87.78132869716327,
    -88.01395510167083,
    -88.2229822643251,
    -88.41062796677832,
    -88.57893444637456,
    -88.72977423571903,
    -88.86485763227896,
    -88.98574118654734,
    -89.09383671831499,
    -89.19042047034083,
    -89.27664208861985,
    -89.35353318022166,
    -89.42201524499158,
    -89.48290680774923,
    -89.53692959411931,
    -89.58471359650723,
    -89.62680086720727,
    -89.6636478528728,
    -89.69562604762682,
    -89.72302068927564,
    -89.74602715191087,
    -89.76474459516993,
    -89.779166310932,
    -89.78916605619017,
    -89.79447946845633,
    -89.7946794173662,
    -89.7891438405404,
    -89.77701422730809,
    -89.75714243062775,
    -89.72802288047613,
    -89.68770651013334,
    -89.63369175209851,
    -89.56278676682112,
    -89.47093558096417,
    -89.35299897218101,
    -89.2024786837883,
    -89.01117083959184,
    -88.76873125984793,
    -88.46213187890835,
    -88.074984029469,
    -87.58670195497484,
    -86.97148067190828,
    -86.19707066297389,
    -85.22335670436769,
    -84.0008064521701,
    -82.46897740032475,
    -80.55551120165738,
    -78.1764788896726,
    -75.23964398718607,
    -71.65313842530976,
    -67.34271771932617,
    -62.27972296830717,
    -56.51659401331054,
    -50.21605644388215,
    -43.65038397172324,
    -37.154310961473406
  ],
  "tanDelta": [
    -2.3954127536952274,
    -2.565678953083823,
    -2.7567207475328837,
    -2.9710731366209284,
    -3.2115804329308877,
    -3.481434002065998,
    -3.784214606628773,
    -4.123939915374237,
    -4.505117806915053,
    -4.932806173663599,
    -5.412680017037224,
    -5.951106720270935,
    -6.555230491443701,
    -7.233067087470895,
    -7.993610060672986,
    -8.846949913666423,
    -9.804407705803715,
    -10.878684824389685,
    -12.084030814068903,
    -13.436431343226266,
    -14.953818567922884,
    -16.656306316023393,
    -18.566452630155506,
    -20.709552234063736,
    -23.11396135128623,
    -25.811456892616427,
    -28.837631154872607,
    -32.23232154391102,
    -36.04007198289233,
    -40.31061785521343,
    -45.09937840038097,
    -50.4679276154582,
    -56.4843940938451,
    -63.2237075145866,
    -70.76755808436103,
    -79.20385531652462,
    -88.62534995357467,
    -99.12689821665296,
    -110.80057672046712,
    -123.72747481254758,
    -137.96448831410586,
    -153.52385413612268,
    -170.34266562937862,
    -188.23963117503732,
    -206.85780839730458,
    -225.5965733543647,
    -243.54576494994848,
    -259.45088376999655,
    -271.75662453752085,
    -278.78253013356635,
    -279.05402138002927,
    -271.72799220108976,
    -256.9468426009137,
    -235.92197076751293,
    -210.6623868349039,
    -183.46591901749878,
    -156.4119814432598,
    -131.0451355625357,
    -108.29333630630097,
    -88.5521686253449,
    -71.83767752472126,
    -57.93729916393062,
    -46.52677154123587,
    -37.247679974085756,
    -29.752594241923227,
    -23.72765059878442,
    -18.901120728769772,
    -15.044091445326346,
    -11.967185836058094,
    -9.515652837308094,
    -7.5641038147322535,
    -6.011537860932202,
    -4.776932568549006,
    -3.795479628882317,
    -3.0154453750140866,
    -2.39559225157949,
    -1.9030825863436662,
    -1.5117863236467666,
    -1.2009214484128903,
    -0.9539653561605816,
    -0.7577852180033905
  ],
  "nimg": [
    7494003398.381687,
    4714337673.518103,
    2942872063.279508,
    1823105987.2836652,
    1121035382.4448478,
    684381928.7251558,
    414933318.13221973,
    249923161.45690086,
    149604666.44633526,
    89035973.04068106,
    52704009.12474204,
    31042474.7065944,
    18200263.252046783,
    10626161.313091557,
    6180361.614226924,
    3582140.546468914,
    2069690.3272886772,
    1192434.9161683652,
    685255.9287916425,
    392890.7555721954,
    224798.81452180422,
    128384.4591804679,
    73200.0702369198,
    41674.22307506884,
    23694.61043376868,
    13456.130314983393,
    7633.7165319186515,
    4326.619862794336,
    2450.2235937355686,
    1386.5994368987276,
    784.1991462679987,
    443.27406467663906,
    250.45503331597595,
    141.46262958633125,
    79.88327089421188,
    45.105299522927425,
    25.469746973524025,
    14.38576483663511,
    8.129519935098136,
    4.598014638731075,
    2.6040644065097904,
    1.477691747161882,
    0.8408909785476028,
    0.48042137915785055,
    0.27599562827151647,
    0.15975559264032646,
    0.09341121668686447,
    0.05534707249871647,
    0.03335195563129093,
    0.020519699217024137,
    0.012938044354030799,
    0.008385516126878182,
    0.005596499435012948,
    0.0038465955328484086,
    0.0027185279069408233,
    0.0019698493688934896,
    0.0014580709598465856,
    0.0010982000853688803,
    0.0008385877193557955,
    0.000647133192561336,
    0.0005033598815316847,
    0.0003938279228618876,
    0.0003094510895372078,
    0.0002439060687227629,
    0.00019267304749239736,
    0.0001524447358379663,
    0.00012075320171811643,
    0.00009572760977345594,
    0.00007593224976425713,
    0.00006025501398057936,
    0.000047828450563998766,
    0.00003797247555942683,
    0.000030151916935175987,
    0.000023944505759489695,
    0.00001901641535695953,
    0.000015103373924700117,
    0.000011995966892140537,
    0.000009528133784962388,
    0.0000075681274755898715,
    0.000006011387140832996,
    0.0000047749075775254455
  ],
  "nreal": [
    -17951231316.718872,
    -12095476946.67555,
    -8112676474.177525,
    -5416581224.031274,
    -3600295298.8830676,
    -2382630517.0632663,
    -1570196723.2528894,
    -1030668101.3086337,
    -673986646.804972,
    -439197197.4932173,
    -285269937.00723875,
    -184737079.84025446,
    -119306920.62211934,
    -76859737.65987906,
    -49403400.77808148,
    -31691017.998324152,
    -20292087.79349652,
    -12972123.626593182,
    -8280653.759041615,
    -5279049.662634095,
    -3361600.6866432084,
    -2138410.8783268747,
    -1359065.6365778272,
    -863054.4995871623,
    -547676.3097999127,
    -347322.32756662293,
    -220138.30168832338,
    -139457.00261585924,
    -88306.23469241102,
    -55894.68001907873,
    -35366.89403879618,
    -22371.123409910557,
    -14146.800804606688,
    -8943.791917210512,
    -5653.144012974886,
    -3572.5136174224513,
    -2257.2652387575663,
    -1426.0162467298337,
    -900.755497269408,
    -568.9007404013241,
    -359.268413381099,
    -226.86093224943303,
    -143.2396107894953,
    -90.43434322127655,
    -57.091850791483054,
    -36.04031427385341,
    -22.749906222907804,
    -14.359846873874059,
    -9.063614884084785,
    -5.720533665301749,
    -3.6104133057854777,
    -2.278579460726467,
    -1.4380028594443746,
    -0.907496398855108,
    -0.5726915775534493,
    -0.36140022479008405,
    -0.22805976791448015,
    -0.1439137790619532,
    -0.0908134619145311,
    -0.05730504759074922,
    -0.036160204848355054,
    -0.022817326185958574,
    -0.014397760146084194,
    -0.00908493519152284,
    -0.005732523003396102,
    -0.003617155427587254,
    -0.002282370844059608,
    -0.0014401349152743871,
    -0.0009086953438788435,
    -0.0005733657947463389,
    -0.00036177936536387604,
    -0.00022827297449881708,
    -0.00014403367401182648,
    -0.00009088088383379845,
    -0.00005734296173749046,
    -0.00003618152554671931,
    -0.000022829315698787805,
    -0.00001440450234598284,
    -0.000009088726609758779,
    -0.00000573465507482389,
    -0.000003618354379581161
  ],
  "nmod": [
    19452680862.063835,
    12981738799.849262,
    8629948757.40896,
    5715161204.847828,
    3770788587.0004406,
    2478972933.536535,
    1624095874.0820458,
    1060536808.2663401,
    690390872.1108133,
    448131211.5677695,
    290097655.1748305,
    187327050.646227,
    120687161.25079954,
    77590815.03243329,
    49788481.379957266,
    31892826.03760228,
    20397363.679403346,
    13026814.361650912,
    8308959.162559058,
    5293649.826572518,
    3369108.7372553735,
    2142261.3411780032,
    1361035.5082838158,
    864060.0732163,
    548188.6307464412,
    347582.8917961158,
    220270.61878132762,
    139524.1026419339,
    88340.2211974278,
    55911.876309363885,
    35375.58709410267,
    22375.514620179423,
    14149.017659503214,
    8944.91059395954,
    5653.708390817707,
    3572.7983478939727,
    2257.408927534874,
    1426.0888072512796,
    900.7921818907142,
    568.9193213169973,
    359.2778507016617,
    226.86574477867185,
    143.24207900182088,
    90.4356193021599,
    57.092517901943935,
    36.04066834573828,
    22.75009799553624,
    14.359953535424296,
    9.063676247524935,
    5.720570467523902,
    3.61043648768186,
    2.278594890656352,
    1.4380137497868801,
    0.9075045510806999,
    0.5726980298504958,
    0.36140559318424975,
    0.2280644288619567,
    0.1439179691606305,
    0.09081733366634943,
    0.05730870144006884,
    0.036163708131845884,
    0.02282072467537472,
    0.014401085278581824,
    0.009088208712641339,
    0.005735760009597288,
    0.0036203663882000445,
    0.002285562951558881,
    0.0014433129769614438,
    0.0009118623440746696,
    0.0005765232010031345,
    0.00036492721176481453,
    0.000231409722757383,
    0.00014715582674230352,
    0.00009398230898673752,
    0.00006041390000534398,
    0.00003920732960553694,
    0.000025789162005622655,
    0.000017270640441499752,
    0.000011827151173199556,
    0.000008308010844009275,
    0.000005991012501250859
  ],
  "cimg": [
    -5.016435415199827e-9,
    -4.4709026801354995e-9,
    -3.984696162473642e-9,
    -3.5513641879339885e-9,
    -3.1651566977487875e-9,
    -2.8209489485173804e-9,
    -2.514173509727187e-9,
    -2.2407596565762276e-9,
    -1.9970793538688504e-9,
    -1.7798991142206012e-9,
    -1.5863370917613303e-9,
    -1.413824842002229e-9,
    -1.2600732404547083e-9,
    -1.1230421077787493e-9,
    -1.0009131384283127e-9,
    -8.92065773604569e-10,
    -7.950556984063372e-10,
    -7.085956779002292e-10,
    -6.315384778834256e-10,
    -5.628616437925802e-10,
    -5.016539358912316e-10,
    -4.47103240874077e-10,
    -3.984857996529632e-10,
    -3.5515660859878617e-10,
    -3.1654086714043003e-10,
    -2.821263585766718e-10,
    -2.514566634286132e-10,
    -2.24125115818839e-10,
    -1.9976942336314308e-10,
    -1.7806688004147855e-10,
    -1.5873010960532559e-10,
    -1.4150328439810733e-10,
    -1.2615877112565285e-10,
    -1.1249416122210047e-10,
    -1.0032964911772179e-10,
    -8.950572703373456e-11,
    -7.988117001322464e-11,
    -7.133128986135619e-11,
    -6.37464416372228e-11,
    -5.7030771454523974e-11,
    -5.110119977050709e-11,
    -4.5886640262445514e-11,
    -4.132746103566189e-11,
    -3.7375202552184606e-11,
    -3.399257565163451e-11,
    -3.11537737715645e-11,
    -2.8845146478558166e-11,
    -2.7066297335239938e-11,
    -2.5831688751819216e-11,
    -2.5172860802626207e-11,
    -2.5141401275122538e-11,
    -2.5812842015120944e-11,
    -2.729170386739365e-11,
    -2.9717971566666263e-11,
    -3.3275353714974e-11,
    -3.820177496247238e-11,
    -4.480266170505196e-11,
    -5.3467723363721656e-11,
    -6.469210266050293e-11,
    -7.910297251459609e-11,
    -9.749289147057484e-11,
    -1.2086147925751957e-10,
    -1.5046719852004922e-10,
    -1.878911330121985e-10,
    -2.351144311072261e-10,
    -2.9461011046373917e-10,
    -3.6944733892620665e-10,
    -4.634004440056804e-10,
    -5.810425908262034e-10,
    -7.277795972056132e-10,
    -9.09733269318757e-10,
    -1.1333028227471795e-9,
    -1.4041071967629047e-9,
    -1.7248602706877659e-9,
    -2.0916796595516537e-9,
    -2.4887410460991632e-9,
    -2.88271070586701e-9,
    -3.2213003844029133e-9,
    -3.4424560633691475e-9,
    -3.496397908777911e-9,
    -3.369805086554441e-9
  ],
  "creal": [
    1.2016433371658078e-8,
    1.1470900907709708e-8,
    1.098469458370575e-8,
    1.0551362737128271e-8,
    1.0165155317650149e-8,
    9.820947587460735e-9,
    9.51417211910875e-9,
    9.240758188514973e-9,
    8.997077758936967e-9,
    8.779897339125752e-9,
    8.5863350768615e-9,
    8.413822518525457e-9,
    8.260070527280974e-9,
    8.123038907618413e-9,
    8.000909333200334e-9,
    7.892061218775711e-9,
    7.795050215998246e-9,
    7.708589047801346e-9,
    7.631530427013486e-9,
    7.562851832554483e-9,
    7.501641941201894e-9,
    7.447088534885417e-9,
    7.398467723046378e-9,
    7.355134337049492e-9,
    7.316513369186529e-9,
    7.282092342672608e-9,
    7.251414511389292e-9,
    7.2240727991391175e-9,
    7.199704397988569e-9,
    7.1779859540221735e-9,
    7.158629276624525e-9,
    7.141377514353277e-9,
    7.126001746656581e-9,
    7.11229794620483e-9,
    7.100084271521939e-9,
    7.089198653980259e-9,
    7.079496647123029e-9,
    7.070849509749224e-9,
    7.063142497281885e-9,
    7.056273338679772e-9,
    7.050150878574915e-9,
    7.044693866448419e-9,
    7.039829876508927e-9,
    7.035494343515544e-9,
    7.031629701076693e-9,
    7.028184609922034e-9,
    7.025113264213761e-9,
    7.022374764009504e-9,
    7.0199325412982355e-9,
    7.017753825256212e-9,
    7.015809128951941e-9,
    7.014071733772746e-9,
    7.012517137925945e-9,
    7.011122419220819e-9,
    7.009865436372111e-9,
    7.00872375158967e-9,
    7.0076730912192336e-9,
    7.006685056419061e-9,
    7.0057236297755924e-9,
    7.004739760878536e-9,
    7.003662898415803e-9,
    7.0023876811381065e-9,
    7.000752969992113e-9,
    6.998508792406749e-9,
    6.99526426915391e-9,
    6.9904057639528846e-9,
    6.982968755966956e-9,
    6.971438655426287e-9,
    6.953444663081839e-9,
    6.925297989084535e-9,
    6.881316892842855e-9,
    6.8128928268460054e-9,
    6.707325397950767e-9,
    6.546672020063854e-9,
    6.3073457554060745e-9,
    5.96200876622299e-9,
    5.486036545801966e-9,
    4.869917865498397e-9,
    4.134119321719013e-9,
    3.335442476326433e-9,
    2.5535884821435913e-9
  ]
},
i:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    302149940.62086046,
    296908000.96503687,
    290065245.2277905,
    281205797.89565015,
    269893597.68468386,
    255746033.6342041,
    238550002.08536455,
    218403121.3050712,
    195829179.61836573,
    171793497.30629295,
    147566036.97007605,
    124457845.14111896,
    103539174.2480112,
    85460574.88568246,
    70428613.96782032,
    58301121.56261683,
    48727870.77216009,
    41276708.88356087,
    35519133.93146336,
    31075472.296852082,
    27630998.59213769,
    24935232.701104585,
    22793442.10429393,
    21055766.676124103,
    19606710.749763805,
    18356140.299180575,
    17232113.736946106,
    16175570.781348804,
    15136888.98434011,
    14074433.908048663,
    12955315.666552965,
    11758400.04248346,
    10478950.32955257,
    9133040.453047538,
    7758666.024238679,
    6410653.722999073,
    5149130.25580378,
    4025385.1452148384,
    3071126.776040537,
    2295159.7418362643,
    1687122.371334025,
    1224809.0483097096,
    881428.3977469758,
    630790.3841041966,
    450096.77662024135,
    320901.0375588813,
    228986.79566851535,
    163754.76870363776,
    117479.29665374676,
    84614.76942395045,
    61220.30622150674,
    44512.832985932764,
    32533.63611540524,
    23905.9658910676,
    17661.808258173343,
    13119.459265876701,
    9797.611298743761,
    7355.310245591023,
    5550.075841276851,
    4208.700804776937,
    3206.87175513617,
    2454.9197265089647,
    1887.8298122315118,
    1458.2136071660602,
    1131.3468264523337,
    881.6503452807286,
    690.1834214208156,
    542.8493730649114,
    429.10481904819477,
    341.02641992145675,
    272.6326139324511,
    219.3881181919087,
    177.84007864744967,
    145.34952932045204,
    119.89220409119471,
    99.91007047103125,
    84.20014704556957,
    71.83086307617198,
    62.07886325268435,
    54.38106144248492,
    48.298120253364814
  ],
  "Mimg": [
    -44319781.391907975,
    -52559830.11403062,
    -61850318.46590807,
    -72051220.60956171,
    -82849270.96375631,
    -93709777.04251407,
    -103862065.25295219,
    -112355462.8728156,
    -118211818.10573031,
    -120656228.15294637,
    -119344466.48141055,
    -114475598.55517897,
    -106726584.59958324,
    -97046118.88119641,
    -86414165.42951421,
    -75663126.44309942,
    -65396375.993779406,
    -55987023.438764215,
    -47620183.890699096,
    -40347569.120821446,
    -34136751.87870415,
    -28908649.626578085,
    -24563050.883780614,
    -20994478.197701257,
    -18101078.700496208,
    -15788789.80723269,
    -13972394.587342635,
    -12574536.870920504,
    -11523413.734646343,
    -10749749.456020314,
    -10183808.453084439,
    -9753587.314437265,
    -9385700.314629076,
    -9010281.448297182,
    -8569797.922225175,
    -8029053.324612189,
    -7381506.28438468,
    -6647803.494024507,
    -5866527.188129862,
    -5081504.600033638,
    -4331082.3073095875,
    -3642374.1270601824,
    -3030334.383763122,
    -2499793.374954381,
    -2048525.8955133657,
    -1670132.5874079086,
    -1356235.7579411988,
    -1097938.5979527137,
    -886687.2925244549,
    -714714.382225444,
    -575215.9543327701,
    -462373.31827081984,
    -371292.0631476386,
    -297903.34057361603,
    -238853.3484452495,
    -191395.08101232184,
    -153289.23989009202,
    -122717.06468530267,
    -98205.56918065493,
    -78564.5036137051,
    -62833.83652121473,
    -50240.37791443106,
    -40162.18220235485,
    -32099.477810855064,
    -25651.016492721992,
    -20494.88930624534,
    -16373.002948904388,
    -13078.542551382865,
    -10445.862704156472,
    -8342.347379348941,
    -6661.862714406273,
    -5319.496054465084,
    -4247.332061643229,
    -3391.0638869197496,
    -2707.2760024216527,
    -2161.2667528526354,
    -1725.3042480969727,
    -1377.2299387486999,
    -1099.3409755639589,
    -877.4959861587441,
    -700.3998129603146
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    305383086.695737,
    301524288.8701839,
    296586089.32887006,
    290289647.0104591,
    282323494.8962057,
    272373926.85972196,
    260179615.06147236,
    245608781.26232028,
    228742434.91286093,
    209930776.94521862,
    189786292.83166468,
    169098840.56887332,
    148697425.88307312,
    129311480.73428997,
    111479135.50215441,
    95519262.34327543,
    81554223.57614657,
    69557986.52772352,
    59407834.40780541,
    50927510.36946422,
    43917990.755804464,
    38176902.08870396,
    33509468.388516624,
    29734044.88314921,
    26684680.184451547,
    24212264.872584224,
    22184984.884994816,
    20488242.179859266,
    19024049.83761536,
    17710076.31827822,
    16478779.039393263,
    15277186.817586102,
    14067685.325072432,
    12829559.606414054,
    11560204.795047991,
    10274345.645619866,
    9000009.856530404,
    7771551.766698895,
    6621779.286836734,
    5575791.176210685,
    4648080.878012546,
    3842791.4965944695,
    3155921.8142678724,
    2578151.1643315516,
    2097390.1527643274,
    1700682.3146690866,
    1375430.9810778846,
    1110083.2352339858,
    894435.98980956,
    719705.7088592297,
    578464.6229570261,
    464511.00928740366,
    372714.6812715051,
    298860.997003799,
    239505.4519931775,
    191844.20045219568,
    153602.03206091854,
    122937.29521095564,
    98362.27508521917,
    78677.1529132315,
    62915.61839820561,
    50300.32011675925,
    40206.526592769726,
    32132.58257055758,
    25675.953589917932,
    20513.843984163064,
    16387.54340101151,
    13089.803692575728,
    10454.672571621628,
    8349.314871102573,
    6667.439041174367,
    5324.018164870719,
    4251.05360298313,
    3394.177480752142,
    2709.9294259980293,
    2163.574819405999,
    1727.3576390730311,
    1379.1018733494693,
    1101.09235117526,
    879.1794501512863,
    702.0631071455416
  ],
  "Mphz": [
    -8.344718908912087,
    -10.038725198798858,
    -12.036856677095594,
    -14.37129402091597,
    -17.064921831180534,
    -20.12370917189702,
    -23.52778293767066,
    -27.22313544625067,
    -31.11718074042232,
    -35.08163085213685,
    -38.96430198493453,
    -42.60766773508478,
    -45.868477943928305,
    -48.63226829132945,
    -50.81953996335878,
    -52.38447380026786,
    -53.30962409229373,
    -53.60032137770799,
    -53.28133108483232,
    -52.396736592226716,
    -51.01256999929483,
    -49.220500036699356,
    -47.140022517226036,
    -44.91649112459685,
    -42.71345492315005,
    -40.70003286990687,
    -39.03633634652855,
    -37.86071779715144,
    -37.281299693511535,
    -37.37181170139835,
    -38.16989001406464,
    -39.67569059112972,
    -41.84990757118377,
    -44.61233860155512,
    -47.84388431896233,
    -51.395011319380295,
    -55.101461180878246,
    -58.804210998401096,
    -62.36794413880999,
    -65.69276579904134,
    -68.71718183003235,
    -71.41392307072512,
    -73.78195785722555,
    -75.83780174195428,
    -77.60802685819208,
    -79.123680337009,
    -80.41656535298509,
    -81.51701163117252,
    -82.45270411634223,
    -83.24820160228671,
    -83.92487252305227,
    -84.50106249417288,
    -84.99237517016942,
    -85.41199468919798,
    -85.77100864697238,
    -86.0787098182968,
    -86.34286650667065,
    -86.56995817734666,
    -86.765376757709,
    -86.93359586401313,
    -87.07831098757983,
    -87.20255381047023,
    -87.30878360077229,
    -87.39895822580898,
    -87.47458681089563,
    -87.53676551011694,
    -87.5861972644368,
    -87.62319580392666,
    -87.64767349577214,
    -87.65911193022191,
    -87.65651334820376,
    -87.63833011634878,
    -87.6023684113816,
    -87.5456610448705,
    -87.46430289624897,
    -87.35324068240067,
    -87.20600674152851,
    -87.01438414343532,
    -86.76798782578541,
    -86.45374381998718,
    -86.05524651337333
  ],
  "tanDelta": [
    -0.1466814168516442,
    -0.17702396009267507,
    -0.21322898721401987,
    -0.2562223864114583,
    -0.3069701233170745,
    -0.36641732311887204,
    -0.43538907711174696,
    -0.5144407378495042,
    -0.6036476195023792,
    -0.7023329173969067,
    -0.8087529416108913,
    -0.91979415540562,
    -1.0307845834653568,
    -1.1355659496909716,
    -1.2269752386295418,
    -1.2977988144162094,
    -1.342073334161414,
    -1.356382932483795,
    -1.3406910197356037,
    -1.2983734803898257,
    -1.2354512546795053,
    -1.1593495024932126,
    -1.0776367505789446,
    -0.9970892307382783,
    -0.9232083306331361,
    -0.8601366926759384,
    -0.8108346312376904,
    -0.7773782477845874,
    -0.7612801908349798,
    -0.7637784600255161,
    -0.7860718113859788,
    -0.8294995304801039,
    -0.8956718010352308,
    -0.9865588020351543,
    -1.104545278202781,
    -1.2524546905110305,
    -1.4335442914975214,
    -1.6514701709790574,
    -1.9102198039812954,
    -2.214009119891643,
    -2.567141768077532,
    -2.9738301918056687,
    -3.437981339731029,
    -3.962954157115773,
    -4.551300968861995,
    -5.204509776947855,
    -5.922768402351486,
    -6.704773281685344,
    -7.547604708069009,
    -8.446685928368694,
    -9.395835954356862,
    -10.387416105754088,
    -11.412559660732953,
    -12.461464302721472,
    -13.523719935908344,
    -14.588640974718706,
    -15.645572702985941,
    -16.684145275702367,
    -17.694455353255453,
    -18.66716292223321,
    -19.593498374414004,
    -20.465181558451906,
    -21.274259968848085,
    -22.012877710857623,
    -22.672991069553973,
    -23.246051471481596,
    -23.722683624012337,
    -24.09239689739679,
    -24.343382410215366,
    -24.46246651878263,
    -24.435311015493003,
    -24.246965142441674,
    -23.882873275506945,
    -23.33040844902547,
    -22.580917774790365,
    -21.632121193221366,
    -20.49051348049581,
    -19.173233896524906,
    -17.708780701882816,
    -16.136058452754014,
    -14.501595699503843
  ],
  "nimg": [
    4808865660.473255,
    3753548506.8788834,
    2912834619.819234,
    2243078136.9161077,
    1710065377.2216077,
    1287149637.1657104,
    953673151.7214843,
    693551910.5470958,
    493966544.81564325,
    344212670.4312762,
    234858642.16268912,
    157341182.21146256,
    103974018.33517139,
    68168845.92311643,
    44624079.76523491,
    29342495.13054418,
    19480386.371714957,
    13107660.795425277,
    8959473.70926103,
    6226412.216954605,
    4397610.008503904,
    3152343.660989118,
    2288917.0060374974,
    1679543.2469975846,
    1242295.3898128897,
    923850.0785371789,
    688903.9645640278,
    513664.7293572243,
    381818.3156066912,
    282001.2722402662,
    206190.25276477722,
    148651.1807674244,
    105229.59851779744,
    72850.99922229654,
    49159.46971434624,
    32264.315095323866,
    20585.148760215034,
    12782.846423939003,
    7746.720305972591,
    4598.678507576308,
    2685.1386499872915,
    1548.4190926320027,
    885.1302229720552,
    503.1589427206898,
    285.18457669994575,
    161.50696383850814,
    91.54414471547548,
    52.0012864362705,
    29.63339905141526,
    16.953770775977855,
    9.743514352752252,
    5.62736865454061,
    3.267027096304953,
    1.906893450753603,
    1.1190649596925477,
    0.6602920480884815,
    0.3916880639251184,
    0.23357218720196962,
    0.13999710319591063,
    0.0843272979346615,
    0.051038949169169956,
    0.031035405728356506,
    0.018957583246749905,
    0.011631648727248188,
    0.0071682954102748,
    0.004437276722962785,
    0.00275920936079794,
    0.0017238499961846493,
    0.0010823893826346805,
    0.0006832948658091188,
    0.00043390828155413926,
    0.0002773532342643672,
    0.0001785869718617493,
    0.00011594012423269362,
    0.00007596456862035995,
    0.000050283951281107996,
    0.0000336614625472216,
    0.000022810311512507477,
    0.000015658995072527836,
    0.00001089601799457607,
    0.000007686884580369857
  ],
  "nreal": [
    -705371228.527435,
    -664468021.0876476,
    -621100775.9059899,
    -574726833.1480129,
    -524938979.7259765,
    -471633924.503687,
    -415218873.3942681,
    -356791356.5987813,
    -298181728.89177835,
    -241751889.0289782,
    -189942617.71181452,
    -144721499.80271396,
    -107174815.18083902,
    -77410220.26002122,
    -54752640.91857281,
    -38080655.39243363,
    -26144107.08864006,
    -17779007.387701813,
    -12011885.9435635,
    -8084208.50046908,
    -5433032.802597297,
    -3654668.0550553664,
    -2466621.0847311355,
    -1674654.4841404918,
    -1146897.4529823991,
    -794637.351081375,
    -558587.1920654565,
    -399311.7872564634,
    -290670.7201693524,
    -215386.49743690685,
    -162080.34548094127,
    -123306.08465189161,
    -94251.18402664988,
    -71871.79451981283,
    -54298.86015193375,
    -40409.592777264224,
    -29509.722494833542,
    -21110.489569341575,
    -14797.938544372882,
    -10181.516155223637,
    -6893.131581461693,
    -4604.735447237388,
    -3043.0611898098905,
    -1993.9958237449348,
    -1297.9608402389613,
    -840.5645723426793,
    -542.1947677411099,
    -348.6568359111729,
    -223.66118219654953,
    -143.20317704624054,
    -91.54846247738173,
    -58.453819795190846,
    -37.285141649811415,
    -23.76268466565939,
    -15.133921104970577,
    -9.632763628024556,
    -6.128184081032245,
    -3.89695230364121,
    -2.477172492085137,
    -1.5741514093380262,
    -1.0000315675779305,
    -0.6351452129710342,
    -0.4033085543724366,
    -0.25604606100856714,
    -0.16252669782108528,
    -0.10314916309520007,
    -0.06545585071862284,
    -0.04153167829965652,
    -0.02634901865823295,
    -0.016715077777311637,
    -0.010602683811973499,
    -0.0067249742033515724,
    -0.004265170017630684,
    -0.002704930453979498,
    -0.0017153496778137683,
    -0.001087748528186967,
    -0.0006897406520970491,
    -0.00043734743788190065,
    -0.00027730170975125905,
    -0.000175818783262739,
    -0.00011147209237327393
  ],
  "nmod": [
    4860322778.428732,
    3811908202.533323,
    2978316923.4063196,
    2315536754.4159994,
    1788822105.9702466,
    1370836513.6654956,
    1040143832.908312,
    779945078.0453233,
    576988120.1913909,
    420626126.5476858,
    302054266.35245293,
    213776893.33706668,
    149321925.71022367,
    103147146.13208932,
    70633987.44550827,
    48074092.14538838,
    32603677.53265845,
    22088546.245053895,
    14985245.178778956,
    10204049.988827871,
    6989765.319450429,
    4826372.255599484,
    3365020.153902182,
    2371778.480336443,
    1690760.6581677592,
    1218584.214302309,
    886909.4223941962,
    650616.1369252852,
    479869.4548484002,
    354846.53136710724,
    262267.9140238546,
    193136.1283026274,
    141267.66825654855,
    102336.81124202204,
    73246.29462570093,
    51709.97212332131,
    35980.16218977157,
    24679.01806302855,
    16703.013520380227,
    11171.889510513618,
    7397.650476266106,
    4858.105621069989,
    3169.1760627244657,
    2056.4990315465857,
    1328.921587446512,
    855.9400093732999,
    549.8686175783484,
    352.5134366497504,
    225.61574138543293,
    144.20326022549324,
    92.06550414739962,
    58.72406939767331,
    37.4280009337193,
    23.839073496092315,
    15.175238857938467,
    9.655367455574215,
    6.1406888596018625,
    3.9039458530426425,
    2.4811252980142973,
    1.5764084980416262,
    1.0013331665757736,
    0.6359030098755187,
    0.40375386065342106,
    0.25631012584390217,
    0.16268470107454894,
    0.10324456049572941,
    0.06551398041330836,
    0.04156743871343897,
    0.026371240983835944,
    0.016729038136582105,
    0.010611558811667877,
    0.006730691112530794,
    0.004268907188709363,
    0.002707414056488742,
    0.0017170309062045086,
    0.001088910159898122,
    0.0006905615549797719,
    0.00043794188168427167,
    0.00027774348301562896,
    0.00017615608918265674,
    0.00011173681386466882
  ],
  "cimg": [
    -5.156472238656696e-8,
    -4.1285488615131644e-8,
    -3.31142003791342e-8,
    -2.661836082223244e-8,
    -2.145451350510483e-8,
    -1.734993988201229e-8,
    -1.4088119367381742e-8,
    -1.1497176333525855e-8,
    -9.44069694595039e-9,
    -7.810424281286941e-9,
    -6.520439360928431e-9,
    -5.502513912407835e-9,
    -4.702382232062348e-9,
    -4.0767283357585515e-9,
    -3.5907245321020333e-9,
    -3.2159931967202565e-9,
    -2.928900724652446e-9,
    -2.709138385324341e-9,
    -2.538604565291597e-9,
    -2.4006728329375013e-9,
    -2.279983932455632e-9,
    -2.162878825268196e-9,
    -2.0384260925889493e-9,
    -1.8996920522756106e-9,
    -1.744620089961112e-9,
    -1.575907300814768e-9,
    -1.3997175558882186e-9,
    -1.2236870047521925e-9,
    -1.0549959863729767e-9,
    -8.991045639994282e-10,
    -7.593081654073682e-10,
    -6.369151573218062e-10,
    -5.317317867641218e-10,
    -4.4260061724774394e-10,
    -3.6785455123982496e-10,
    -3.056427554868061e-10,
    -2.5413708236805585e-10,
    -2.1164698337741652e-10,
    -1.7667230454510505e-10,
    -1.479176671097175e-10,
    -1.2428518504579727e-10,
    -1.0485638371008482e-10,
    -8.886990162445574e-11,
    -7.569870335256366e-11,
    -6.482874063306498e-11,
    -5.583992957495054e-11,
    -4.8389704118234215e-11,
    -4.2199081652418183e-11,
    -3.704102003837607e-11,
    -3.273078427512962e-11,
    -2.911803214564786e-11,
    -2.6080345713844588e-11,
    -2.3517964825345707e-11,
    -2.134951164529936e-11,
    -1.9508527464592352e-11,
    -1.7940672463376487e-11,
    -1.6601465030531442e-11,
    -1.5454459469723957e-11,
    -1.4469779692174834e-11,
    -1.3622942174136044e-11,
    -1.2893914465828656e-11,
    -1.2266366296912436e-11,
    -1.1727079219842653e-11,
    -1.1265488114340869e-11,
    -1.087333404892262e-11,
    -1.0544413224952543e-11,
    -1.0274411246989563e-11,
    -1.006081597422951e-11,
    -9.90290589364641e-12,
    -9.801814481181074e-12,
    -9.76067453509732e-12,
    -9.784850115233716e-12,
    -9.88226762658058e-12,
    -1.006386183817775e-11,
    -1.0344157258712998e-11,
    -1.0742010198136608e-11,
    -1.1281541665147466e-11,
    -1.199329509172384e-11,
    -1.2915653814872325e-11,
    -1.4096547368554387e-11,
    -1.5595455237768466e-11
  ],
  "creal": [
    7.563586539223338e-9,
    7.3085206890116555e-9,
    7.060907409244899e-9,
    6.8202199322336624e-9,
    6.585894656369871e-9,
    6.357318527840301e-9,
    6.133813289604465e-9,
    5.914615876204898e-9,
    5.698854237866334e-9,
    5.4855180715838955e-9,
    5.273424513746308e-9,
    5.061180136670839e-9,
    4.847143110371282e-9,
    4.629393884227754e-9,
    4.4057300896288424e-9,
    4.173712157874145e-9,
    3.930799560962089e-9,
    3.6746290675906433e-9,
    3.40348434334625e-9,
    3.1169699413783658e-9,
    2.8168090100014226e-9,
    2.507532490027787e-9,
    2.1966828707128904e-9,
    1.89416248704311e-9,
    1.61064780084203e-9,
    1.3554956936866798e-9,
    1.134939468265545e-9,
    9.512676595910296e-10,
    8.031475458361575e-10,
    6.867166992933963e-10,
    5.968707449819343e-10,
    5.283208239540997e-10,
    4.762571671187022e-10,
    4.366515347319541e-10,
    4.063120076373516e-10,
    3.828037027301663e-10,
    3.6431676368656184e-10,
    3.4952867982550376e-10,
    3.374829349570743e-10,
    3.274910639740107e-10,
    3.1905768968431126e-10,
    3.118250796806103e-10,
    3.055330634486111e-10,
    2.9999049113931585e-10,
    2.950551100533717e-10,
    2.906194594169098e-10,
    2.866010105506152e-10,
    2.829352751747916e-10,
    2.7957097723332577e-10,
    2.764666549612087e-10,
    2.7358825335419706e-10,
    2.7090740311162387e-10,
    2.6840017666827696e-10,
    2.660461772484343e-10,
    2.638278617931231e-10,
    2.617300294132218e-10,
    2.5973942811125836e-10,
    2.5784444695032863e-10,
    2.5603487073463004e-10,
    2.5430168104475944e-10,
    2.52636892126047e-10,
    2.5103341332878835e-10,
    2.494849319982088e-10,
    2.479858122141056e-10,
    2.4653100578749973e-10,
    2.4511597256381804e-10,
    2.4373660743532746e-10,
    2.423891715628071e-10,
    2.410702251414101e-10,
    2.3977655856921076e-10,
    2.385051179861056e-10,
    2.3725291966808826e-10,
    2.360169454002688e-10,
    2.347940072592472e-10,
    2.3358056450849907e-10,
    2.3237246646491098e-10,
    2.311645815704793e-10,
    2.2995025198366528e-10,
    2.287204810290102e-10,
    2.2746271232100935e-10,
    2.2615898660782787e-10
  ]
},
j:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    1978320.0558854733,
    1960234.8535061725,
    1932242.6715400794,
    1889487.3048212973,
    1825487.6061013904,
    1732525.1434384738,
    1603225.5475006192,
    1433831.191803159,
    1228497.4583844428,
    1001799.3853781492,
    775933.9820031793,
    572781.737284877,
    406198.4504601691,
    279654.93893412064,
    188998.514885781,
    126743.44225801721,
    85224.70901583435,
    58072.95138925009,
    40543.6695705463,
    29320.522193169567,
    22173.142502510815,
    17636.84800838995,
    14763.969507039696,
    12947.035620660328,
    11798.918926160899,
    11073.818369914003,
    10616.026675782497,
    10327.050583446577,
    10144.642475289018,
    10029.480459159744,
    9956.727062636588,
    9910.686518651606,
    9881.42418618664,
    9862.625095656134,
    9850.232060801143,
    9841.570485569924,
    9834.771457751773,
    9828.368973175822,
    9820.98492178128,
    9811.03512532956,
    9796.396123370258,
    9773.968572141956,
    9739.062190642559,
    9684.51616950486,
    9599.476711263906,
    9467.826459572703,
    9266.497938885399,
    8964.469314252787,
    8524.273875340015,
    7909.0383090999285,
    7097.720289508163,
    6106.107729184395,
    5000.789155577022,
    3888.375564649493,
    2878.1751345718562,
    2042.90589460479,
    1404.1911606244375,
    944.3583947768587,
    627.4878647363632,
    415.66412380475805,
    276.92261522809605,
    187.25946867304447,
    129.8149410902317,
    93.21651502496894,
    69.98207589828277,
    55.265012514162805,
    45.95632171124055,
    40.073809637898385,
    36.358555591712005,
    34.01293794614572,
    32.532375239287475,
    31.597973097294823,
    31.008313519530255,
    30.636226971245605,
    30.40144169536991,
    30.253296414066448,
    30.159820756774437,
    30.100840687007487,
    30.063626413631862,
    30.04014564914418,
    30.025330230458668
  ],
  "Mimg": [
    -249841.2430203929,
    -311641.7760074991,
    -386702.97580855637,
    -476002.85528922593,
    -578848.3694294215,
    -691415.7344164733,
    -805108.437268648,
    -905818.218942059,
    -975923.5240060114,
    -1000065.9486591116,
    -972339.1505926299,
    -899499.0569067494,
    -797311.1327703224,
    -683297.0879778183,
    -571198.6451428857,
    -469231.0479759992,
    -380941.3537696225,
    -306862.0145970195,
    -245939.51632674967,
    -196471.71512906734,
    -156628.41984227084,
    -124701.11226694858,
    -99200.00004008546,
    -78873.59880048857,
    -62693.19197297474,
    -49824.15522409326,
    -39594.75256794219,
    -31467.05159347232,
    -25011.644378139346,
    -19886.472901268295,
    -15819.46325659784,
    -12594.463172074664,
    -10039.944810939522,
    -8019.977056022462,
    -6427.039437490588,
    -5176.321184029067,
    -4201.214162330925,
    -3449.764375356465,
    -2881.8924054781173,
    -2467.2285456915047,
    -2183.4325956556227,
    -2014.8788775384655,
    -1951.578179890617,
    -1988.169295454099,
    -2122.727042402226,
    -2354.984396613509,
    -2683.364109132973,
    -3100.080316571689,
    -3583.903808893024,
    -4091.812256946768,
    -4554.396232192884,
    -4883.8255233195405,
    -5000.418424158198,
    -4868.17839489903,
    -4513.4021412331385,
    -4009.7108661152324,
    -3442.9247720912344,
    -2882.295151466893,
    -2370.2162570519263,
    -1925.596006921281,
    -1551.8574870991933,
    -1244.1328997272237,
    -994.0761540351415,
    -792.5712754797154,
    -631.0486145326239,
    -502.0078371911106,
    -399.134862595705,
    -317.23276146665495,
    -252.08159146173884,
    -200.28299331091318,
    -159.1142360481538,
    -126.40086119801146,
    -100.40975351696342,
    -79.76129994164233,
    -63.358155074631874,
    -50.327924535383424,
    -39.977268886670565,
    -31.75526259146156,
    -25.224196486805482,
    -20.036338985219555,
    -15.915453591732652
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    1994033.823743388,
    1984852.9611670165,
    1970558.5333147391,
    1948522.7720825707,
    1915064.0811265,
    1865395.1566478282,
    1794026.6865127243,
    1695988.9540781307,
    1568959.1231021117,
    1415533.0834040886,
    1243992.35054019,
    1066385.2361802526,
    894819.6598174054,
    738310.0942755783,
    601654.6607831598,
    486046.99005349865,
    390358.2534527307,
    312308.7633826492,
    249258.97141984923,
    198647.49650432932,
    158190.1076259932,
    125942.15262685152,
    100292.64580993839,
    79929.15812832686,
    63793.814806659604,
    51039.94413284936,
    40993.22448036371,
    33118.32287026795,
    26990.66737689062,
    22272.45569607324,
    18692.025880751637,
    16026.297447125075,
    14086.981073106675,
    12711.860829815314,
    11761.5435884366,
    11119.838605963178,
    10694.528043068514,
    10416.22344798554,
    10235.089089529592,
    10116.502702373258,
    10036.770143116522,
    9979.488891740364,
    9932.672839947392,
    9886.489295234558,
    9831.374432226849,
    9756.315358621721,
    9647.19788298915,
    9485.368103314444,
    9247.032584197063,
    8904.819735452822,
    8433.27684520724,
    7818.97073422281,
    7071.921704543787,
    6230.459486773424,
    5353.0076586677515,
    4500.138411655086,
    3718.2635465839808,
    3033.0575526941598,
    2451.86992427915,
    1969.9483865547238,
    1576.371718564689,
    1258.1465657034482,
    1002.5164931070005,
    798.0341755770507,
    634.9171952708299,
    505.0406817371123,
    401.7718532258318,
    319.7538665390211,
    254.69015159326167,
    203.1505780383301,
    162.40595910217522,
    130.29048167635395,
    105.08869638863558,
    85.44263204872793,
    70.2744866336146,
    58.72105186350645,
    50.077907461453705,
    43.754511908113926,
    39.24387495576085,
    36.10907407498045,
    33.982673798256236
  ],
  "Mphz": [
    -7.197756496412266,
    -9.03338868095074,
    -11.317186925367217,
    -14.139825074198479,
    -17.593429180359895,
    -21.755942189114712,
    -26.664905890342744,
    -32.28251731993999,
    -38.463776049821796,
    -44.950386980811295,
    -51.40986861189718,
    -57.51188879395131,
    -63.00295885982444,
    -67.7420474942144,
    -71.69162545931349,
    -74.88461402789407,
    -77.3893673894163,
    -79.28363666552293,
    -80.63885914886293,
    -81.51208719557985,
    -81.94244994639448,
    -81.94988095150181,
    -81.53478762678874,
    -80.67807844315986,
    -79.34155158967764,
    -77.46923745600569,
    -74.99102922932649,
    -71.83088033380565,
    -67.9226837553578,
    -63.23650877452786,
    -57.81380060139935,
    -51.80050210223965,
    -45.45591099052936,
    -39.116926299422296,
    -33.123481291196164,
    -27.74279739612989,
    -23.131187131331103,
    -19.34110894063403,
    -16.353920075115177,
    -14.115747537456103,
    -12.564782947536397,
    -11.6482055489268,
    -11.331233002390054,
    -11.601269503910254,
    -12.469129305557658,
    -13.968033622994822,
    -16.149797289489918,
    -19.076337840870586,
    -22.80359294994323,
    -27.35519286946231,
    -32.68708357028483,
    -38.65373911173908,
    -44.99787612191304,
    -51.384477625643086,
    -57.47453552147195,
    -63.00163172753853,
    -67.81199017628722,
    -71.85907681897655,
    -75.17176524769914,
    -77.81888957374557,
    -79.88229816396145,
    -81.44043235332923,
    -82.55993063261826,
    -83.2921001362284,
    -83.67186581279934,
    -83.71772277061925,
    -83.43189641711385,
    -82.80035696007529,
    -81.79264247897912,
    -80.36173384671186,
    -78.44461082810408,
    -75.96471310976708,
    -72.83838383536032,
    -68.98827274603795,
    -64.36669189607517,
    -58.98893616290555,
    -52.968212746822886,
    -46.5320848528309,
    -39.99756033713258,
    -33.70270919989156,
    -27.926611735483043
  ],
  "tanDelta": [
    -0.12628959721513153,
    -0.15898185640872636,
    -0.2001316819591494,
    -0.25192170070401454,
    -0.3170924675109908,
    -0.39907976922300126,
    -0.5021803940960072,
    -0.6317467663699791,
    -0.7944041864680915,
    -0.9982696767992294,
    -1.2531209782595214,
    -1.5704045683624464,
    -1.9628610888768145,
    -2.443357841567694,
    -3.0222388016545145,
    -3.7022116459545487,
    -4.469846341145564,
    -5.284078168168026,
    -6.066039875813733,
    -6.700825920993893,
    -7.063880089370947,
    -7.07048743673629,
    -6.7190602088947236,
    -6.092019911849585,
    -5.313469171651787,
    -4.499275097328527,
    -3.7297148714091466,
    -3.047051172956531,
    -2.4655027951023745,
    -1.9828018990860425,
    -1.588821623519403,
    -1.2707962408428792,
    -1.016042285177321,
    -0.8131686014867134,
    -0.6524759414620188,
    -0.5259649556560898,
    -0.42717964320559026,
    -0.3510006985667479,
    -0.2934423001797476,
    -0.25147484584187835,
    -0.22288120735,
    -0.20614746841741755,
    -0.2003866636939359,
    -0.2052936110236004,
    -0.22112945384944208,
    -0.24873548397504033,
    -0.28957693907993637,
    -0.34581860988054364,
    -0.4204350847127222,
    -0.5173590134515896,
    -0.6416702893921001,
    -0.7998262952317553,
    -0.9999258654169791,
    -1.251982560315739,
    -1.5681471523464228,
    -1.9627486888674968,
    -2.451891785560152,
    -3.0521200080483712,
    -3.777310112678855,
    -4.632576873114395,
    -5.603939157590856,
    -6.643898482375185,
    -7.657640528020418,
    -8.502477004932201,
    -9.017289162011107,
    -9.083646494469908,
    -8.685091576815205,
    -7.916211718654353,
    -6.933212482159255,
    -5.888435560257413,
    -4.890950472500412,
    -4.000283841270595,
    -3.2381559046647737,
    -2.603496181710114,
    -2.084051003550967,
    -1.6635517613208972,
    -1.325514140454265,
    -1.0549626477764182,
    -0.8390270734394165,
    -0.6669854140933692,
    -0.5300675619410007
  ],
  "nimg": [
    31485941.59120074,
    24781536.986524254,
    19403577.09222971,
    15071764.860971786,
    11566421.651054587,
    8719662.542047158,
    6409361.339088085,
    4553214.974835664,
    3098806.0411404124,
    2007247.3468691206,
    1234937.287487834,
    724117.9179291682,
    407904.40374479874,
    223070.7489311826,
    119750.82752058095,
    63788.97588256538,
    34071.06105268132,
    18441.406032317464,
    10226.880596108902,
    5874.783039404777,
    3528.9652331555862,
    2229.67263572454,
    1482.5975263699218,
    1032.738754175824,
    747.5880464509996,
    557.3365535459653,
    424.40660365326335,
    327.9415431275509,
    255.89209951623408,
    200.95488513925372,
    158.46623290354566,
    125.2921526645217,
    99.22924216598243,
    78.67063513703103,
    62.41178356684731,
    49.53184884095064,
    39.31736495738242,
    31.21056163563906,
    24.772804532771744,
    19.65780226339817,
    15.59144867520651,
    12.356374709001065,
    9.779964328783436,
    7.724992389555152,
    6.082297951563847,
    4.765082460523066,
    3.704552595910301,
    2.846719764254785,
    2.150193409107786,
    1.5846881515339055,
    1.1296372687588556,
    0.7719418633120811,
    0.5021791482582516,
    0.3101618203626296,
    0.182363260538016,
    0.10281784407907059,
    0.05613663374829658,
    0.029988654238315954,
    0.01582797890802665,
    0.008328421057883116,
    0.00440736030674861,
    0.0023673578911718347,
    0.001303601382097574,
    0.0007435548214749424,
    0.00044341150011110135,
    0.0002781444536782419,
    0.00018372378865962746,
    0.00012725673090744285,
    0.00009171212438884733,
    0.00006814975178473923,
    0.00005177688329852983,
    0.000039946557302009825,
    0.00003113854231345991,
    0.0000244374232078605,
    0.000019262573587096246,
    0.000015226245720834736,
    0.000012057267266833095,
    0.00000955869835687336,
    0.000007583356930315934,
    0.000006018969819096719,
    0.0000047786797241440534
  ],
  "nreal": [
    -3976346.8814918986,
    -3939814.7547791405,
    -3883270.519491953,
    -3796904.6363870176,
    -3667625.1816054475,
    -3479840.9149826285,
    -3218655.6031669676,
    -2876478.8369397963,
    -2461704.4921345566,
    -2003774.1602151478,
    -1547525.8217859142,
    -1137158.0863490687,
    -800659.6820921635,
    -545041.6636253834,
    -361915.59746293706,
    -236160.28939594736,
    -152292.40758527472,
    -97445.83100569087,
    -62036.66550118232,
    -39365.898470658816,
    -24928.187246570047,
    -15764.87235892505,
    -9961.662045237887,
    -6291.465054177854,
    -3972.2860379127706,
    -2507.610476200269,
    -1582.9156211698237,
    -999.2546636479788,
    -630.9026866018902,
    -398.45372788472986,
    -251.77457743481526,
    -159.2207966131863,
    -100.82110596673854,
    -63.97249035245102,
    -40.72218724110246,
    -26.052016679194743,
    -16.7955779342786,
    -10.954928936769853,
    -7.2693887439998175,
    -4.943442793778183,
    -3.475040905065585,
    -2.547235365077574,
    -1.9597744228906158,
    -1.5858915827816087,
    -1.3449752241788937,
    -1.185245091999181,
    -1.0727530013843374,
    -0.9844486715940586,
    -0.904016748106969,
    -0.8198526987060046,
    -0.7248546731525966,
    -0.6174194006671999,
    -0.5021419194164937,
    -0.3883171899697954,
    -0.2859724277052985,
    -0.2018055886583785,
    -0.1376409511564472,
    -0.0915289716152087,
    -0.05978718479255668,
    -0.03858205078230825,
    -0.024698579004600183,
    -0.015728485500395472,
    -0.009982510775933814,
    -0.0063220577714971655,
    -0.00399836971426292,
    -0.0025265658916106097,
    -0.0015956579293483072,
    -0.0010073912244871427,
    -0.0006358596455780986,
    -0.00040129542183197454,
    -0.0002532381718335432,
    -0.0001597975676896198,
    -0.00010083145465498412,
    -0.00006362273801249893,
    -0.00004014418581516228,
    -0.000025329647887199403,
    -0.000015982078257423613,
    -0.000010084069727863217,
    -0.000006362641772089495,
    -0.000004014565077205716,
    -0.0000025330231106739324
  ],
  "nmod": [
    31736033.97411931,
    25092762.217748072,
    19788344.90051295,
    15542669.681951074,
    12133984.682812598,
    9388386.849761115,
    7172144.495671788,
    5385712.311888768,
    3957598.752678671,
    2836221.570445272,
    1979875.317569765,
    1348137.7052864998,
    898577.7256989648,
    588923.5723896984,
    381212.7494966801,
    244623.62055149974,
    156057.0876614548,
    99175.47800156275,
    62873.97675694335,
    39801.84717022937,
    25176.737576915686,
    15921.766251132376,
    10071.385511871307,
    6375.663248817159,
    4042.02229758733,
    2568.8001740633376,
    1638.8236113041414,
    1051.6917507193418,
    680.8222723709242,
    446.26028181608206,
    297.4928315323254,
    202.6064796432914,
    141.46143612020737,
    101.39797017079927,
    74.52199179902803,
    55.965271576718216,
    42.75449245796785,
    33.07732796979983,
    25.81735573466631,
    20.269849937338456,
    15.974015809541376,
    12.616196096856731,
    9.974388104585296,
    7.8860991327159065,
    6.229230026676879,
    4.910276650423346,
    3.856748492946468,
    3.012134227288373,
    2.332504657536285,
    1.784207102661422,
    1.3421976963771487,
    0.9884841711701671,
    0.7101622379292378,
    0.49698148339419884,
    0.33917044122641127,
    0.22648842061973662,
    0.14864842106070913,
    0.09631652001584644,
    0.06184684617449994,
    0.03947071369871596,
    0.02508873511598343,
    0.015905648038387,
    0.010067268644227263,
    0.00636563337293991,
    0.004022881321934035,
    0.0025418299592541754,
    0.0016062000678656364,
    0.001015397141386046,
    0.0006424395711929804,
    0.00040704103509550964,
    0.00025847711178691383,
    0.0001647148751017994,
    0.00010553004816282067,
    0.00006815453356341861,
    0.000044526423570286606,
    0.000029553842742370124,
    0.000020020102881110713,
    0.000013894501666274354,
    0.000009899015741603247,
    0.00000723496582177946,
    0.000005408510514471958
  ],
  "cimg": [
    -0.000007918659046708692,
    -0.0000062903029086299635,
    -0.000004996928337925514,
    -0.0000039696584221762434,
    -0.000003153787291981122,
    -0.000002505866833712799,
    -0.0000019913929186079834,
    -0.000001582967931243641,
    -0.000001258841744919452,
    -0.000001001753444726233,
    -7.98012114156173e-7,
    -6.367677304508966e-7,
    -5.094333345460319e-7,
    -4.092276907740581e-7,
    -3.3081405481221663e-7,
    -2.700157602028657e-7,
    -2.2359337238193593e-7,
    -1.8907133408446446e-7,
    -1.646044561789086e-7,
    -1.488763251277999e-7,
    -1.4102260627451653e-7,
    -1.4057201809435556e-7,
    -1.4739579507960916e-7,
    -1.6165159612130564e-7,
    -1.836983273988505e-7,
    -2.1394253970478922e-7,
    -2.525561632343526e-7,
    -2.9899213502810606e-7,
    -3.512607556535529e-7,
    -4.051001096714997e-7,
    -4.535487627490252e-7,
    -4.878176088385072e-7,
    -5.000389244647474e-7,
    -4.868491289415079e-7,
    -4.511676468731169e-7,
    -4.0057844013737404e-7,
    -3.437646035245294e-7,
    -2.876610148865042e-7,
    -2.3647864126705194e-7,
    -1.9207646683652966e-7,
    -1.5477418038523437e-7,
    -1.2407219676334265e-7,
    -9.912997775465618e-8,
    -7.903398120122017e-8,
    -6.292731731817161e-8,
    -5.006091243039914e-8,
    -3.980461167745993e-8,
    -3.163999001227934e-8,
    -2.5146222289944073e-8,
    -1.9984511933048223e-8,
    -1.588350328514243e-8,
    -1.262657081154881e-8,
    -1.0041157705226626e-8,
    -7.990025845484801e-9,
    -6.364167758003616e-9,
    -5.077112069058939e-9,
    -4.06037391188896e-9,
    -3.2598354218814004e-9,
    -2.6328772385853936e-9,
    -2.1461149423090873e-9,
    -1.7736228968093617e-9,
    -1.4955517504592226e-9,
    -1.2970650575715492e-9,
    -1.167535282141559e-9,
    -1.099949570270992e-9,
    -1.090479935807227e-9,
    -1.1381680130619059e-9,
    -1.244655472402126e-9,
    -1.4138471137490296e-9,
    -1.6513082611901413e-9,
    -1.963052811125018e-9,
    -2.353173653665929e-9,
    -2.8195919936386035e-9,
    -3.347388913877193e-9,
    -3.900488011762996e-9,
    -4.415757154477603e-9,
    -4.807912344119232e-9,
    -4.992902320346537e-9,
    -4.923996450471647e-9,
    -4.616251494448231e-9,
    -4.1380223392666426e-9
  ],
  "creal": [
    0.000001000044261492798,
    0.0000010000440337872027,
    0.00000100004367289837,
    0.0000010000431009286541,
    0.0000010000421944190995,
    0.0000010000407577016767,
    0.0000010000384806665552,
    0.0000010000348718305456,
    0.00000100002915226481,
    0.0000010000200874993711,
    0.0000010000057211543324,
    9.999829528858749e-7,
    9.999468697571708e-7,
    9.998896872394343e-7,
    9.997990725861444e-7,
    9.996554920143202e-7,
    9.994280174457939e-7,
    9.99067708662122e-7,
    9.984971949178936e-7,
    9.97594338438676e-7,
    9.961667806137557e-7,
    9.939126878928073e-7,
    9.903612216778027e-7,
    9.847847423532612e-7,
    9.76075399517789e-7,
    9.625863411529778e-7,
    9.419624778812009e-7,
    9.110443357421681e-7,
    8.660343748736069e-7,
    8.032332667766137e-7,
    7.206080815761228e-7,
    6.199167835289371e-7,
    5.080606914907717e-7,
    3.9589042531639067e-7,
    2.943760351507406e-7,
    2.1069022150363954e-7,
    1.4684924068031968e-7,
    1.0096921717558265e-7,
    6.939283643678509e-8,
    4.830239988756896e-8,
    3.4496256190867726e-8,
    2.5577169263750796e-8,
    1.9864325514309637e-8,
    1.6225171394369838e-8,
    1.3915083310777825e-8,
    1.2451925281607443e-8,
    1.152649761082434e-8,
    1.0941697362680725e-8,
    1.0572354098677579e-8,
    1.0339167377993349e-8,
    1.0191972149537717e-8,
    1.0099063353682502e-8,
    1.0040413308187101e-8,
    1.000337301501899e-8,
    9.979951546768287e-9,
    9.965095056778776e-9,
    9.95559744086328e-9,
    9.949408914069025e-9,
    9.945193818750584e-9,
    9.942042448786313e-9,
    9.939274802229707e-9,
    9.936294005189582e-9,
    9.932457952339032e-9,
    9.926941888855634e-9,
    9.918563338763392e-9,
    9.905534246185087e-9,
    9.885093423244456e-9,
    9.85295623631698e-9,
    9.802502456909608e-9,
    9.723622286138864e-9,
    9.601194074115168e-9,
    9.413362542463501e-9,
    9.130278462946366e-9,
    8.714914255978037e-9,
    8.128815955253187e-9,
    7.34584059189657e-9,
    6.3729557981946545e-9,
    5.267325451961805e-9,
    4.131366331465301e-9,
    3.0789724145836877e-9,
    2.1934314126324657e-9
  ]
},
k:{
  "fHz": [
    0.010000000000000009,
    0.012589254117941687,
    0.01584893192461116,
    0.01995262314968883,
    0.025118864315095846,
    0.03162277660168386,
    0.03981071705534982,
    0.05011872336272736,
    0.0630957344480195,
    0.07943282347242837,
    0.10000000000000028,
    0.12589254117941706,
    0.1584893192461118,
    0.19952623149688853,
    0.2511886431509587,
    0.3162277660168387,
    0.3981071705534983,
    0.5011872336272735,
    0.6309573444801948,
    0.7943282347242835,
    1.0000000000000024,
    1.2589254117941704,
    1.5848931924611174,
    1.9952623149688846,
    2.511886431509587,
    3.1622776601683875,
    3.981071705534982,
    5.011872336272735,
    6.309573444801948,
    7.9432823472428336,
    10.000000000000025,
    12.589254117941707,
    15.848931924611183,
    19.952623149688858,
    25.118864315095887,
    31.622776601683906,
    39.810717055349876,
    50.11872336272743,
    63.09573444801959,
    79.4328234724285,
    100.00000000000043,
    125.89254117941726,
    158.48931924611207,
    199.5262314968889,
    251.18864315095925,
    316.22776601683955,
    398.10717055349943,
    501.1872336272751,
    630.9573444801969,
    794.3282347242862,
    1000.0000000000061,
    1258.9254117941753,
    1584.8931924611238,
    1995.2623149688932,
    2511.8864315095975,
    3162.277660168402,
    3981.0717055350024,
    5011.872336272761,
    6309.573444801982,
    7943.282347242878,
    10000.000000000082,
    12589.254117941764,
    15848.931924611237,
    19952.62314968891,
    25118.86431509592,
    31622.77660168392,
    39810.71705534986,
    50118.72336272735,
    63095.73444801942,
    79432.82347242821,
    100000,
    125892.54117941661,
    158489.3192461111,
    199526.23149688746,
    251188.6431509572,
    316227.7660168366,
    398107.1705534953,
    501187.23362726945,
    630957.3444801891,
    794328.2347242757,
    999999.9999999919
  ],
  "Mreal": [
    302237726.40149474,
    297000558.1982901,
    290160951.9757633,
    281302414.2385646,
    269988470.6378662,
    255836659.29209915,
    238635036.2199907,
    218483730.15398318,
    195910292.06560594,
    171884273.7283374,
    147679050.48397458,
    124607152.51947284,
    103738151.63335092,
    85720481.8563812,
    70758295.52123207,
    58707614.856116824,
    49217409.65355251,
    41855712.305184655,
    36194984.04815301,
    31857055.363081932,
    28529052.099883776,
    25962540.85872066,
    23964880.900777478,
    22388135.1126495,
    21118196.302931953,
    20065146.824307185,
    19155026.593537148,
    18322873.972050074,
    17506986.537358403,
    16644820.35770933,
    15671857.890098613,
    14525950.37198346,
    13160052.724302674,
    11563487.727424407,
    9783700.196051365,
    7930971.519822532,
    6151643.553824041,
    4578673.836212695,
    3290447.167007456,
    2301172.1502684713,
    1578632.2392974868,
    1069862.1428653204,
    720490.345727202,
    484394.27333028463,
    326307.17762195755,
    220884.89829160168,
    150597.98914105067,
    103605.98745810954,
    72025.03943451725,
    50648.349247922946,
    36050.45707036508,
    25979.601161833933,
    18953.240357108054,
    13991.861203548056,
    10444.942297856864,
    7877.552414054152,
    5996.536578756409,
    4602.437376400486,
    3558.112153532504,
    2768.1780727747155,
    2165.4863977576374,
    1702.1698669664395,
    1343.6748803462854,
    1064.750251604049,
    846.7254470560973,
    675.6442373956346,
    540.9701907413155,
    434.6778390787462,
    350.60656330619685,
    283.9954134837617,
    231.14401661976638,
    189.16244289258063,
    155.78463257401202,
    129.2278124299483,
    108.08559752723048,
    91.2460540998313,
    77.8284585147504,
    67.13419690509551,
    58.608453035238504,
    51.81018927187999,
    46.388544264749186
  ],
  "Mimg": [
    -44274889.05426173,
    -52514389.57847963,
    -61803937.78519891,
    -72002564.46770366,
    -82795495.5508778,
    -93645939.89192706,
    -103780760.17291938,
    -112247047.9498034,
    -118065532.89058976,
    -120462097.07579269,
    -119095303.8718452,
    -114168159.37156321,
    -106361320.71608722,
    -96625819.44220372,
    -85942285.05440278,
    -75142515.13961744,
    -64828695.797879405,
    -55372777.210581146,
    -46959149.94255663,
    -39639468.80864603,
    -33382066.795423605,
    -28109586.20752989,
    -23724704.36307514,
    -20126275.45508136,
    -17218579.515066452,
    -14915883.295191696,
    -13143803.584593812,
    -11838267.97956178,
    -10942270.009085469,
    -10400196.92481356,
    -10149526.702833455,
    -10110726.6678731,
    -10178954.94827526,
    -10225219.077351741,
    -10115855.458261961,
    -9750247.366755858,
    -9097998.545387786,
    -8207828.454220723,
    -7179720.338606661,
    -6121474.074304252,
    -5116935.800402238,
    -4215703.67319296,
    -3437864.6929877806,
    -2783876.819691855,
    -2243567.445057453,
    -1802338.6016065856,
    -1444782.3531614663,
    -1156499.9828521488,
    -924849.7180122273,
    -739120.5229552977,
    -590429.9955950597,
    -471510.02975822677,
    -376463.79937108624,
    -300533.2307405357,
    -239892.8359021423,
    -191474.19433673532,
    -152820.00737794623,
    -121964.47289140226,
    -97336.11465236213,
    -77679.30791346544,
    -61991.14359651404,
    -49470.76041778868,
    -39478.75130589171,
    -31504.67830256741,
    -25141.096804766694,
    -20062.79623957267,
    -16010.216059831213,
    -12776.200962554683,
    -10195.425101609548,
    -8135.948705145587,
    -6492.477877557338,
    -5180.984464182275,
    -4134.411806802976,
    -3299.247383359707,
    -2632.787432559336,
    -2100.953912963713,
    -1676.55230584799,
    -1337.8812615471002,
    -1067.6230480326687,
    -851.9580989665045,
    -679.8584050698676
  ],
  "fRad": [
    0.06283185307179592,
    0.0791006165022013,
    0.09958177620320631,
    0.12536602861381613,
    0.15782647919764783,
    0.19869176531592245,
    0.25013811247045775,
    0.3149052262472868,
    0.396442191629501,
    0.49909114934975174,
    0.6283185307179604,
    0.7910061650220143,
    0.9958177620320644,
    1.2536602861381627,
    1.5782647919764798,
    1.9869176531592252,
    2.501381124704578,
    3.149052262472867,
    3.964421916295009,
    4.990911493497515,
    6.283185307179601,
    7.9100616502201415,
    9.95817762032064,
    12.536602861381624,
    15.782647919764798,
    19.869176531592252,
    25.013811247045776,
    31.490522624728676,
    39.64421916295009,
    49.90911493497515,
    62.83185307179602,
    79.10061650220143,
    99.58177620320646,
    125.36602861381631,
    157.8264791976481,
    198.69176531592274,
    250.1381124704581,
    314.90522624728726,
    396.44219162950156,
    499.09114934975247,
    628.3185307179613,
    791.0061650220156,
    995.8177620320662,
    1253.660286138165,
    1578.2647919764834,
    1986.9176531592304,
    2501.381124704585,
    3149.0522624728774,
    3964.4219162950217,
    4990.911493497532,
    6283.185307179625,
    7910.061650220172,
    9958.177620320681,
    12536.602861381678,
    15782.647919764866,
    19869.176531592344,
    25013.811247045902,
    31490.522624728834,
    39644.2191629503,
    49909.11493497543,
    62831.85307179637,
    79100.6165022018,
    99581.77620320681,
    125366.02861381663,
    157826.47919764832,
    198691.76531592282,
    250138.112470458,
    314905.2262472868,
    396442.19162950054,
    499091.14934975066,
    628318.5307179586,
    791006.1650220115,
    995817.76203206,
    1253660.286138156,
    1578264.7919764705,
    1986917.6531592119,
    2501381.1247045593,
    3149052.262472842,
    3964421.9162949733,
    4990911.493497467,
    6283185.307179535
  ],
  "Mmod": [
    305463433.2634791,
    301607514.30111325,
    296670026.759088,
    290371172.0305079,
    282398421.31444246,
    272437072.1762808,
    260225146.1596002,
    245630902.19971102,
    228736338.5952699,
    209893593.0108771,
    189717667.48559675,
    169000920.33270654,
    148574340.47901565,
    129168688.12745422,
    111323010.8532923,
    95357126.75831726,
    81394798.43245262,
    69412139.49005635,
    59289447.91085145,
    50854296.414738536,
    43912062.09291481,
    38264897.289804585,
    33722056.78341352,
    30104743.106627896,
    27248076.915735446,
    25001873.76091607,
    23230897.88341158,
    21814497.45814805,
    20645286.400798116,
    19626872.925031036,
    18671369.045117594,
    17698305.793543253,
    16637250.720760435,
    15435975.95236961,
    14073070.779991971,
    12568517.532357017,
    10982545.057735167,
    9398547.868279122,
    7897811.513290852,
    6539712.387219358,
    5354914.726901586,
    4349340.46320922,
    3512551.7769248714,
    2825704.8606060776,
    2267172.5242445124,
    1815823.3870986663,
    1452609.9966405684,
    1161131.5218243755,
    927650.0456599138,
    740853.8335834218,
    591529.5556042525,
    472225.20881368866,
    376940.60216027114,
    300858.7624771521,
    240120.11481085408,
    191636.17333097124,
    152937.6118093123,
    122051.2805237261,
    97401.12616245718,
    77728.61563000827,
    62028.95465621274,
    49500.03554110094,
    39501.61094001763,
    31522.6656240641,
    25155.35117086109,
    20074.16967364123,
    16019.352890480564,
    12783.593229580227,
    10201.451757704068,
    8140.903802873485,
    6496.591148209232,
    5184.436560311948,
    4137.3457481793885,
    3301.7772674896323,
    2635.0051539674832,
    2102.9344228449745,
    1678.3577995168357,
    1339.5645823897228,
    1069.2305286783342,
    853.532013522052,
    681.4391740890354
  ],
  "Mphz": [
    -8.333997918955403,
    -10.027160165272832,
    -12.024241504663587,
    -14.35726183148565,
    -17.048843054628225,
    -20.104545958842245,
    -23.503895634854256,
    -27.192046347416685,
    -31.075310448391907,
    -35.02402289417618,
    -38.88433079681146,
    -42.49668582977764,
    -45.7153216845927,
    -48.42253427666598,
    -50.534573879439584,
    -52.00007204567465,
    -52.794532493222945,
    -52.9147408087569,
    -52.37582831470595,
    -51.21220126947213,
    -49.48206798030912,
    -47.27385259733034,
    -44.711446733505895,
    -41.9546103070691,
    -39.19185063119285,
    -36.62610683893211,
    -34.45714998613507,
    -32.86619159969257,
    -32.00633262641593,
    -31.998412640872623,
    -32.9282052747554,
    -34.83968435402583,
    -37.721009416006304,
    -41.485291964789596,
    -45.956268968048605,
    -50.87466825761659,
    -55.935270671771285,
    -60.84537378617364,
    -65.37813662994911,
    -69.39785695891887,
    -72.85441729028263,
    -75.7600893223964,
    -78.16354742063066,
    -80.12936301288688,
    -81.72485506666115,
    -83.01298008848804,
    -84.04922061264331,
    -84.8807769841775,
    -85.54693246874123,
    -86.07992592459745,
    -86.50597369248821,
    -86.84626450119214,
    -87.11785149935753,
    -87.33441747660795,
    -87.50691441000339,
    -87.64408927488257,
    -87.7529116134773,
    -87.83891843030085,
    -87.90649054186169,
    -87.95907253545373,
    -87.99934645832211,
    -88.02936746906067,
    -88.05066800835824,
    -88.06433559180388,
    -88.07106806924244,
    -88.07120909675436,
    -88.06476558836997,
    -88.05140801424591,
    -88.03045354983455,
    -88.0008312163667,
    -87.96102724591472,
    -87.90900791190313,
    -87.84211594314016,
    -87.75693533840956,
    -87.64911786989406,
    -87.51316276024895,
    -87.34213890582367,
    -87.12733659823688,
    -86.85783305010038,
    -86.51995341256386,
    -86.09660698840703
  ],
  "tanDelta": [
    -0.1464902796265965,
    -0.1768157942094466,
    -0.21299881105422241,
    -0.2559614166931405,
    -0.3066630784465269,
    -0.3660380031190435,
    -0.434893223630611,
    -0.513754721556126,
    -0.6026509972791643,
    -0.7008325686978361,
    -0.8064468418610862,
    -0.9162247677052223,
    -1.0252864451644323,
    -1.127219741999277,
    -1.2145895321717373,
    -1.2799449496250186,
    -1.3171903246070178,
    -1.3229443285265063,
    -1.297393856565407,
    -1.2442916759526643,
    -1.1701078142571586,
    -1.0826978129949885,
    -0.9899779790812754,
    -0.8989706089324889,
    -0.8153432834922509,
    -0.7433727460754186,
    -0.6861803882344123,
    -0.646092310497797,
    -0.6250230435566742,
    -0.624830830330742,
    -0.6476275355486644,
    -0.6960457945232895,
    -0.7734737209280158,
    -0.8842677329177444,
    -1.033949861049979,
    -1.2293887756860884,
    -1.478954114584907,
    -1.7926213457934204,
    -2.1819892477217193,
    -2.660154770945788,
    -3.2413729258939656,
    -3.94041765222424,
    -4.77156246905417,
    -5.747129916611682,
    -6.875630077793548,
    -8.15962800330163,
    -9.593636418400497,
    -11.162482123147086,
    -12.840669373781733,
    -14.593180901855524,
    -16.377878217816463,
    -18.149240506852426,
    -19.862767119391307,
    -21.47914608131808,
    -22.96736823058989,
    -24.30630534366624,
    -25.484711945113958,
    -26.499974451969468,
    -27.356112020169615,
    -28.061528511279146,
    -28.626891242866225,
    -29.063351066103714,
    -29.381178351505266,
    -29.588796297634612,
    -29.692147427690387,
    -29.694320071325603,
    -29.595375741298614,
    -29.39234489071836,
    -29.079390315649995,
    -28.648169367745034,
    -28.0884531319602,
    -27.389075679913862,
    -26.53927886525489,
    -25.53047460389504,
    -24.35835571798589,
    -23.025148141366007,
    -21.541635769777482,
    -19.928461547523995,
    -18.216195663631613,
    -16.443832978408075,
    -14.655739166760064
  ],
  "nimg": [
    4810262814.565369,
    3754718627.1301036,
    2913795706.793396,
    2243848810.9494386,
    1710666499.122474,
    1287605748.9615414,
    954013100.455351,
    693807888.6706493,
    494171145.761135,
    344394553.8851558,
    235038508.75642046,
    157529938.48790663,
    104173831.38623978,
    68376164.42364846,
    44832968.384614736,
    29547080.002420302,
    19676093.80572313,
    13291526.725033289,
    9129952.566193914,
    6383013.484528303,
    4540539.663422708,
    3282217.2578134215,
    2406552.8668493303,
    1785821.5148232083,
    1338064.2088888888,
    1009863.0304282282,
    765778.0097704794,
    581853.6005389001,
    441602.5061661383,
    333502.6152917215,
    249425.36506429096,
    183638.90212637206,
    132153.2234718156,
    92237.808402188,
    61990.23285439393,
    39915.95478157927,
    24592.98782207995,
    14539.847085983818,
    8299.941924654104,
    4610.725221768777,
    2512.471242084218,
    1352.5332547004152,
    723.5162629123718,
    386.3839978710946,
    206.7505904464348,
    111.1696289679601,
    60.20593489480193,
    32.90068846832989,
    18.16785421815768,
    10.14811609340513,
    5.7376084434707355,
    3.284374042914167,
    1.9032840224130996,
    1.1160807563466195,
    0.6617991069024921,
    0.39647100631114246,
    0.2397290248787894,
    0.14615309600439244,
    0.0897510968473748,
    0.05546437912956908,
    0.03446478644013874,
    0.021519046781627284,
    0.013493180495238196,
    0.008493132177648823,
    0.005364913741728542,
    0.003400464213105915,
    0.002162685987347112,
    0.0013803449509517036,
    0.0008843825675191003,
    0.0005690251447130849,
    0.0003678771281115103,
    0.00023914155319802946,
    0.00015643889726983662,
    0.00010308040691631763,
    0.00006848381721287353,
    0.00004592342010487928,
    0.00003111419437289581,
    0.000021318857646515318,
    0.000014783606354898814,
    0.000010380907242971987,
    0.000007382966122572085
  ],
  "nreal": [
    -704656744.7830999,
    -663893556.0890123,
    -620635021.201891,
    -574338720.4958371,
    -524598254.81624085,
    -471312637.1544831,
    -414893832.64286155,
    -356447078.6574331,
    -297812733.8195352,
    -241362919.84487903,
    -189546063.10235453,
    -144332831.29770023,
    -106808017.26115675,
    -77074962.42052516,
    -54453654.09613949,
    -37818635.82526425,
    -25917160.386958584,
    -17583949.89834128,
    -11845144.370113557,
    -7942330.546292177,
    -5312920.94111548,
    -3553649.446809,
    -2382434.3436757494,
    -1605401.0546253594,
    -1090981.6655989278,
    -750704.654089476,
    -525461.8520456831,
    -375931.1371436402,
    -276011.7424462147,
    -208382.7160302004,
    -161534.73447991267,
    -127821.08553593523,
    -102217.04549137682,
    -81562.91772510407,
    -64094.79264625644,
    -49072.226779267,
    -36371.90052940165,
    -26064.440250906853,
    -18110.384036309966,
    -12265.24269620829,
    -8143.8562611789675,
    -5329.54591204182,
    -3452.3030458630024,
    -2220.5990334649923,
    -1421.5405782750827,
    -907.1028176436193,
    -577.5938496106211,
    -367.2533468669638,
    -233.28740924642858,
    -148.09329396409245,
    -93.96985234867917,
    -59.60889441931246,
    -37.80448729924974,
    -23.972461604117008,
    -15.199783782905062,
    -9.636745339309254,
    -6.109425143919005,
    -3.873053310192641,
    -2.455241059289877,
    -1.5564152563047988,
    -0.9866196931304624,
    -0.6254156112223429,
    -0.3964455426596456,
    -0.25130155793333686,
    -0.15929580975624594,
    -0.10097447273505539,
    -0.06400550440597917,
    -0.04057157486653419,
    -0.025717305869244603,
    -0.01630152872024608,
    -0.010333099471280275,
    -0.006549866098752975,
    -0.004151775520017155,
    -0.002631691710936214,
    -0.0016681531805966985,
    -0.0010573935510730314,
    -0.0006702506424509817,
    -0.0004248520348457184,
    -0.00026930106597494453,
    -0.00017070190486777803,
    -0.00010820282576944239
  ],
  "nmod": [
    4861601533.770394,
    3812960348.9590974,
    2979159822.9148264,
    2316187050.3610034,
    1789296845.181421,
    1371154319.068545,
    1040325856.741778,
    780015324.378337,
    576972742.6212943,
    420551623.2542694,
    301945045.72197187,
    213653101.33581972,
    149198323.37177342,
    103033245.57352921,
    70535065.73753138,
    47992490.58293794,
    32539942.685489655,
    22042231.663551003,
    14955382.944270726,
    10189380.533194153,
    6988821.743445614,
    4837496.720236011,
    3386368.276319992,
    2401347.752616783,
    1726457.8830027853,
    1258324.6075227507,
    928722.8425118637,
    692732.1504984392,
    520764.107269702,
    393252.2736698939,
    297164.067782346,
    223744.22066673392,
    167071.2388862243,
    123127.26280832705,
    89167.99545637767,
    63256.3584724958,
    43905.92440818962,
    29845.639528696407,
    19921.722965026434,
    13103.242555472501,
    8522.611486219706,
    5498.491232477521,
    3527.3038008050353,
    2253.9637666201534,
    1436.4969273662248,
    913.8896039357637,
    580.7231782050496,
    368.7241192093033,
    233.99377393384398,
    148.44058736538443,
    94.14485275936836,
    59.69930826020105,
    37.85236782592482,
    23.998428107181343,
    15.214184339127769,
    9.644897614466625,
    6.114126723786406,
    3.875809937428026,
    2.4568809329326853,
    1.5574032064338899,
    0.9872214748359216,
    0.6257857110345415,
    0.3966750990603998,
    0.2514450363676111,
    0.1593861264519415,
    0.10103171433261467,
    0.06404203154916065,
    0.04059504944367487,
    0.025732507722684442,
    0.016311456962280334,
    0.010339645944845514,
    0.006554230282348911,
    0.004154721783368018,
    0.002633709709079649,
    0.001669558344938843,
    0.001058390326091922,
    0.000670972441160908,
    0.000425386583243877,
    0.0002697065426571963,
    0.00017101726100214308,
    0.00010845441297272949
  ],
  "cimg": [
    -5.155257322736585e-8,
    -4.12755702792299e-8,
    -3.310638471586978e-8,
    -2.661255647243154e-8,
    -2.1450668006737804e-8,
    -1.7348043335461418e-8,
    -1.4088209985183929e-8,
    -1.1499348256290983e-8,
    -9.445110727761389e-9,
    -7.81732037946223e-9,
    -6.530154701540935e-9,
    -5.515500954546883e-9,
    -4.719228599355466e-9,
    -4.0981725281238034e-9,
    -3.617658824152182e-9,
    -3.249437997953033e-9,
    -2.969925671685282e-9,
    -2.7586970286650585e-9,
    -2.597249722677424e-9,
    -2.468143814711003e-9,
    -2.3547229141519626e-9,
    -2.241641822994638e-9,
    -2.116251582843793e-9,
    -1.9704626156589796e-9,
    -1.8022093606249817e-9,
    -1.6155386689524645e-9,
    -1.4189624384784506e-9,
    -1.2227095406304775e-9,
    -1.0360714476413555e-9,
    -8.657590154552841e-10,
    -7.154648307913728e-10,
    -5.862748961883925e-10,
    -4.774354230018626e-10,
    -3.871157033321566e-10,
    -3.130008549575491e-10,
    -2.5268438877850437e-10,
    -2.0389440439504793e-10,
    -1.6460321886974013e-10,
    -1.3306429092090986e-10,
    -1.078082368852493e-10,
    -8.761853364450792e-11,
    -7.14991932266102e-11,
    -5.864119719031829e-11,
    -4.839110446535335e-11,
    -4.022329267475005e-11,
    -3.371622516586098e-11,
    -2.853259339215798e-11,
    -2.4402940189071637e-11,
    -2.1112287717284253e-11,
    -1.8489295430405475e-11,
    -1.6397513955109327e-11,
    -1.472835611987984e-11,
    -1.3395464008170067e-11,
    -1.2330204809091067e-11,
    -1.1478075895529466e-11,
    -1.079584033432781e-11,
    -1.0249248203404006e-11,
    -9.811227340368264e-12,
    -9.460450294453245e-12,
    -9.180203113080716e-12,
    -8.95749691368599e-12,
    -8.782375627067287e-12,
    -8.64738341287237e-12,
    -8.547163515869e-12,
    -8.478167152107124e-12,
    -8.438456721815693e-12,
    -8.427592523905849e-12,
    -8.446596437467052e-12,
    -8.497989949559922e-12,
    -8.58590763331983e-12,
    -8.716290885092418e-12,
    -8.897170566777198e-12,
    -9.139051301627929e-12,
    -9.455414633874615e-12,
    -9.863363107169313e-12,
    -1.0384432417280495e-11,
    -1.1045603722594765e-11,
    -1.188055192592747e-11,
    -1.293116607917345e-11,
    -1.4249370563601102e-11,
    -1.589925163483527e-11
  ],
  "creal": [
    7.551950867547415e-9,
    7.298172740369864e-9,
    7.051620582783943e-9,
    6.8117876565097826e-9,
    6.5781278856806405e-9,
    6.350043140534929e-9,
    6.1268670556416016e-9,
    5.907844461487697e-9,
    5.692105399497534e-9,
    5.478632721872456e-9,
    5.26622263592201e-9,
    5.053438580857649e-9,
    4.838561114551489e-9,
    4.6195409798202385e-9,
    4.393970538783956e-9,
    4.159101754599616e-9,
    3.911957359545852e-9,
    3.649602588195364e-9,
    3.369655834167897e-9,
    3.071090803698956e-9,
    2.7552796822595996e-9,
    2.4270206992743934e-9,
    2.0950424652112482e-9,
    1.771387977477658e-9,
    1.4694192976324427e-9,
    1.2009474167302201e-9,
    9.736641969251916e-10,
    7.899832321736452e-10,
    6.475685295469693e-10,
    5.409529244932509e-10,
    4.633547251371589e-10,
    4.080741759265087e-10,
    3.6928375313209183e-10,
    3.4231392536238425e-10,
    3.2362719049188253e-10,
    3.10647351355393e-10,
    3.015504683208951e-10,
    2.9507124373220246e-10,
    2.903448520451401e-10,
    2.867865956975496e-10,
    2.840043427618375e-10,
    2.8173668310992665e-10,
    2.7981013565372763e-10,
    2.7810996417071337e-10,
    2.7656048094240433e-10,
    2.7511185502898243e-10,
    2.737313270784202e-10,
    2.723973836127397e-10,
    2.710959063018002e-10,
    2.6981763296375783e-10,
    2.685564866317265e-10,
    2.6730847749027103e-10,
    2.660709820504701e-10,
    2.648422703070377e-10,
    2.636211956712831e-10,
    2.6240699160763954e-10,
    2.611991381177278e-10,
    2.5999727386222335e-10,
    2.5880113801630957e-10,
    2.576105313970481e-10,
    2.564252899563987e-10,
    2.552452660438493e-10,
    2.5407031432745143e-10,
    2.529002801936223e-10,
    2.5173498899696665e-10,
    2.5057423480562413e-10,
    2.494177673395527e-10,
    2.482652756427446e-10,
    2.471163666417238e-10,
    2.4597053605516145e-10,
    2.448271280104503e-10,
    2.436852779905628e-10,
    2.4254383105777424e-10,
    2.4140122317943337e-10,
    2.4025530714008874e-10,
    2.3910309477188687e-10,
    2.379403722494347e-10,
    2.367611222192077e-10,
    2.3555665145713963e-10,
    2.343142695953011e-10,
    2.3301528490682924e-10
  ]
}
}