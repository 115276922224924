import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Button, Dropdown } from 'antd';
import 'antd/dist/antd.css';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class NavBarTop extends Component {

  handleClick = (e) => {
    this.props.pathChange(e)
  }

  render(){
    console.log(this.props)

    const menuEis = (
    <Menu>
      <Menu.Item >
        <Link to="/eis" onClick={() => this.handleClick("eis")}>MODELLING TOOL</Link>
      </Menu.Item>
      <Menu.Item >
        <Link to="/eisTTS" onClick={() => this.handleClick("eisTTS")}>TTS</Link>
      </Menu.Item>
    <Menu.Divider />
      <Menu.Item>
        <Link to="/coatingsfit" onClick={() => this.handleClick("coating")}>Protective Coatings</Link>
      </Menu.Item>
      <Menu.Item disabled>
        Batteries
      </Menu.Item>
      <Menu.Item disabled>
        Reinforced Concrete
      </Menu.Item>

    </Menu>
  );

  const menuRheo = (
  <Menu>
    <Menu.Item >
      <Link to="/rheo" onClick={() => this.handleClick("rheo")}>MODELLING TOOL</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item disabled>
      3D Printing
    </Menu.Item>
    <Menu.Item disabled>
      PSA
    </Menu.Item>
    <Menu.Item disabled>
      Bitumen
    </Menu.Item>
    <Menu.Item disabled>
      Dispersions
    </Menu.Item>

  </Menu>
);
    const appNavbar = (
      <div className='rowDiv rightDiv' >
        <div style={{marginRight: '10px', marginBottom: '1px', marginTop: '1px'}}>
          <Dropdown overlay={menuEis} placement="bottomLeft">
            <Link to ='/eis'><Button key="3" type="primary">EIS</Button></Link>
          </Dropdown>
        </div>
        <div style={{marginRight: '10px', marginBottom: '1px', marginTop: '1px'}}>
          <Dropdown overlay={menuRheo} placement="bottomLeft">
            <Link to ='/rheo'><Button key="2" type="primary">RHEO</Button></Link>
          </Dropdown>
        </div>
        <div style={{ marginBottom: '1px',marginTop: '1px'}} >
          <Link to="/about"><Button key="1" type="primary"><Icon type="team" />About</Button></Link>
        </div>
      </div>

          );

      const styleBox={
          justifyContent: 'space-between',
          margin: '10px',
          marginTop: '0px',
          padding:'10px',
          backgroundColor: '#fafafa',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
        }

    return(
      <div className='rowDivColMenu' style ={styleBox}>

          <div  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
            <div style={{paddingRight: '5px',  fontSize: '1.2em'}}>SINMODE</div>
            <div style={{fontSize: '1.1em', borderStyle: 'none none none solid', borderWidth: '2px', borderColor: 'grey', paddingLeft: '5px', textAlign:'justify' }}>
            {this.props.lang === 'en' ? "A practical approach to sinusoidal data":(
              this.props.lang === 'es' ? "Una perspectiva práctica a datos sinusoidales" : ""
            )}
            </div>
          </div>
        <div className='centerDiv'>{appNavbar}</div>
      </div>
    );
  }
}

export default NavBarTop
