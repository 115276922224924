import React from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import Circuit from '../../Common/componentsHTM/circuitDraw/circuit'
import iviumSoft from './pic/iviumSoft.gif';
import relaxis from './pic/relaxis.jpeg';



import {
  RightOutlined
} from '@ant-design/icons';





function Software(props) {

  const explanation = "ddddd"
  const description = ()=>{
    return (
      <div>
        <div>
        Many potentiostat controllers also contain fittin modelling tools. They are also some specialised software like Relaxis3 or Zwiew. But all the actual software is too generalist.
        The specific constrains of coatings would need to be included manualy to obtain good results. The selection of the proper circuit is also important. Some times, with complicated circuits (like a
        damaged coatings with corrosion reactions) we might get apparently good fits but doubtful physical meaning.
        </div>
        <div>

        </div>
    </div>
  )
  }
  return (
    <div className ="centerDiv columnDiv"  >
      <div className="preH1">Fitting software solutions</div>




      <div className="centerDiv rowDiv" style={{marginTop: "15"}}>
        <div className="centerDiv columnDiv" style={{marginRight:"40px"}} >
          <div style={{width:'250px', marginTop:'10px'}}><Circuit smiles={"R-Q(R-QR)"}/></div>

        </div>
        <div> <RightOutlined /><RightOutlined /> Generic Fitting algorithm<RightOutlined /><RightOutlined /></div>
        <div className="columnDiv" style={{marginLeft:"40px"}}>
          <div>Re : Electtrolite Resistance</div>
          <div>Rc : Coating Resistance</div>
          <div>Cc : Coating Capacitance</div>
          <div>&alpha;1 : non-ideality of Cc</div>
          <div>Rp : Polarization Resistance</div>
          <div>Cdl : Double Layer Capacitance</div>
          <div>&alpha;2 : non-ideality of Cdl</div>
        </div>
      </div>
      <div className="rowDiv">
        <img src={relaxis} alt="Relaxis 3" style={{height: 0.9*301+"px", width: 0.9*600+"px", margin:"25px"}}/>

        <img src={iviumSoft} alt="Ivium soft circuit analyzer" style={{height: 0.8*301+"px", width: 0.8*234+"px", margin:"30px"}}/>
      </div>
        <div className="columnDiv centerDiv">
        <ul className="ulPresentation">
          <li>Current software solutions are generalists</li>
          <li>Manual adjustments (to define constrains) is often required</li>
          <li>Requires wise circuit selection from the user</li>
          <li>Long learning curve</li>
        </ul>

        </div>









      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"

        />**/}
    </div>
    )
}

export default Software
