import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

import eisRules from '../../../../Common/sinTools/rules/eisRules'
import PanelModule from '../../../../Common/componentsHTM/panelModule'
import PlotModule from '../../../../Common/componentsHTM/plot'
import DataFromUser from '../../../../Common/componentsHTM/dataFromUser'
import ParamPanel from '../../../../Common/componentsHTM/param/paramPanel'
import UpdateSinData from '../../../../Common/componentsHTM/stateManagement'


import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex'; //InlineMath import if needed

import { InputNumber } from 'antd';
import { Divider } from 'antd';

class CoatingThickness extends Component {
  screen = window.innerWidth
  state = {

      thickness: 250,
      dielectric: 4,
      area:3,

    sinData:{
      rules: eisRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
        },
        C1: {
          type:"Capacitance",
          valueSci:0.00000000006,
          valueSciTex:"6.00e-11",
          valueStep: -2,
          subType:"Generic Capacitance",
          subTypeAbr:"C",
          units:"(F)",
          maxRange: -4,
          minRange: -15,
          stepRange:0.05
        } }
      },
    }
  };

  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData,
      })//updates the state
    }


    componentDidMount() {
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }
      UpdateSinData('R-C', mainState);//execute the function to make the magic
      window.scrollTo(0, 0);
    }

    changeCapacitance = (value) => {
      let capacitance = value
      let dielectric = ((this.state.thickness*0.000001)*capacitance)/((8.854187817e-12)*(this.state.area*0.0001))
      this.setState({
        dielectric: dielectric
      })

    }

    updateCapacitance = (capacitance) => {
      let sinData = this.state.sinData;
      sinData.model.param.C1.valueSci = capacitance
      sinData.model.param.C1.valueSciTex = Number.parseFloat(capacitance).toExponential(2)
      this.setState({
        sinData: sinData
      })//updates the state
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }

      UpdateSinData('R-C', mainState);//execute the function to make the magic
    }


// derived changes from the thicknes value variation
    changeThickness = (value) =>{
      //get old value of dielectric
      let capacitanceOld = this.state.sinData.model.param.C1.valueSci;
      let dielectric = ((this.state.thickness*0.000001)*capacitanceOld)/((8.854187817e-12)*(this.state.area*0.0001))
      //Update dielectric
      this.setState({
        dielectric: dielectric,
      })
      let thickness = value ? value : 1
      let capacitance = (dielectric*8.854187817e-12)*(this.state.area*0.0001)/(thickness*0.000001)
      this.setState({
        thickness: thickness,
      })//updates the state
      this.updateCapacitance(capacitance)
    }

    OnCahngeThicknessRange = (e) =>{
      let thickness = e.target.value;
      this.changeThickness(thickness)
    }
    OnCahngeThickness = (e) =>{
      let thickness = e;
      this.changeThickness(thickness)
    }

    OnCahngeArea = (e) =>{
      let area = e;
      this.setState({
        area: area
      })//updates the state
      let capacitance = (this.state.dielectric*8.854187817e-12)*(area*0.0001)/(this.state.thickness*0.000001)
      this.updateCapacitance(capacitance)
    }
    OnCahngeDielectric= (e) =>{
      let dielectric = e;
      this.setState({
        dielectric: dielectric
      })//updates the state
      let capacitance = (dielectric*8.854187817e-12)*(this.state.area*0.0001)/(this.state.thickness*0.000001)
      this.updateCapacitance(capacitance)
    }


  render () {
    const { expData, modelData, rules } =  this.state.sinData
    const plotWidth = this.screen> 700 ? 450 : this.screen-25
    const capacitance = this.state.sinData.model.param.C1.valueSci;
    const capacitanceCal = (this.state.dielectric*8.854187817e-12)*(this.state.area*0.0001)/(this.state.thickness*0.000001);
    const dielectricNew = ((this.state.thickness*0.000001)*capacitance)/((8.854187817e-12)*(this.state.area*0.0001))
    const changeDielectric = (capacitance == capacitanceCal) ? false : true

    //console.log(capacitance, capacitanceCal,changeDielectric)
    return (
      <div style= {{ maxWidth:850}}>
      <Helmet>
        <title>Thinkness and Coating Capacitance</title>
        <meta name="description" content="Thickness and Capacitance calculator" />
        <meta name="keywords" content="EIS, circuit, Impedance, modelling, coating, corrosion, thickness, capacitance, dielectric constant, epoxy" />
      </Helmet>
        <h1> Thickness and Coating Capacitance </h1>
        The capacitance is related to the dielectric constant of your coating (4-8 for epoxy resins, 1 for vacuum and 80 for water), the thickness and the tested area as:

        <BlockMath>C = \epsilon_r \epsilon_0 \frac A d</BlockMath>

        where<br></br>
        <ul>
          <li><InlineMath>C </InlineMath> is the coating capacitance, in farads (<InlineMath>F</InlineMath>);</li>
          <li><InlineMath>A </InlineMath> is the tested area, in square meters (<InlineMath>m^2</InlineMath>);</li>
          <li><InlineMath>\epsilon_0 </InlineMath> is the reference electrical permittivity (<InlineMath math='8.854 \cdot 10^{-12} F\cdot m^{-1}'/>);</li>
          <li><InlineMath>\epsilon_r </InlineMath> is the dilectric constant of the coating; and</li>
          <li><InlineMath>d </InlineMath> is the coating thickness, in meters (<InlineMath>m</InlineMath>);</li>
        </ul>

        Here you can play with the different variables and check the result on the final impedance plot:

        <div className="centerDiv" style= {{ marginTop:'20px', marginBottom:'20px'}}>

            <PlotModule size={plotWidth}  expData={expData} modelData={modelData} rules={rules}/>
            <div className="columnDiv">
              <div className="centerDiv">
                <table style={{borderTop:"1px"}}>
                  <tbody>
                    <tr key="e_tr">
                      <td key="e_td1">
                        <InlineMath>\epsilon_r</InlineMath>
                      </td>
                      <td key="e_td2" className="centerContent">
                        <div>
                          <InputNumber
                            step={0.1}
                            precision={1}
                            min={1}
                            max={80}
                            value={changeDielectric ? dielectricNew: this.state.dielectric}
                            style={{ width: 55 }}
                            onChange={this.OnCahngeDielectric}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr key="area_tr">
                      <td key="area_td1">
                        <InlineMath>A</InlineMath> (<InlineMath math='cm^{2}'/>)
                      </td>
                      <td key="area_td2" className="centerContent">
                        <div>
                          <InputNumber
                            step={0.1}
                            precision={1}
                            min={0.1}
                            max={20}
                            value={this.state.area}
                            style={{ width: 55 }}
                            onChange={this.OnCahngeArea}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr key="thickness_tr">
                      <td key="thickness_td1">
                        <InlineMath>d</InlineMath> (<InlineMath math='\mu m'/>)
                      </td>
                      <td key="thickness_td2" className="centerContent">
                        <div className="centerContent">
                          <input
                            className="inputRange"
                            type="range"
                            min={5}
                            max={2000}
                            step={5}
                            value={this.state.thickness}
                            onChange={this.OnCahngeThicknessRange}
                             />
                        </div>
                      </td>
                      <td key="thickness_td3" className="centerContent">
                        <div>
                          <InputNumber
                            step={5}
                            precision={0}
                            min={5}
                            max={2000}
                            value={this.state.thickness}
                            style={{ width: 60 }}
                            onChange={this.OnCahngeThickness}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Divider />
              <div>
                <ParamPanel sinData={this.state.sinData} updateData = {this.updateData} />
              </div>
            </div>
            <PanelModule title="Insert Your Data" content={<DataFromUser updateData = {this.updateData} type='eis'/>} />

        </div>

        As you see, higher thickness gives lower capacitance. Changing the capacitance will change the dielectric constant in the app.
        Increasing the dielectric constant (that is an effect of water uptake,<InlineMath>\epsilon_r = 80</InlineMath> for water) increases the capacitance value affecting coating barrier performance.
      </div>

    )
  }
}

export default CoatingThickness
