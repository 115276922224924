/**
 * This presents the specific rules for EIS models
 */

import { facSum, simpleSum } from '../../math/operations';
import { replaceAll } from '../../utils/string';
// general plots

import {dataHz_MmodMphz, layoutHz_MmodMphz} from "../plots/plotHz_MmodMphz";
import {dataHz_MrealMimg, layoutHz_MrealMimg} from "../plots/plotHz_MrealMimg";
import {dataMreal_Mimg, layoutMreal_Mimg} from "../plots/plotMreal_Mimg";
import {dataHz_mImg, layoutHz_mImg} from "../plots/plotHz_mImg";

//TTS plots
import {dataHz_MmodMphzTTS, layoutHz_MmodMphzTTS} from "../plots/tts/plotHz_MmodMphzTTS";
import {dataHz_MrealMimgTTS, layoutHz_MrealMimgTTS} from "../plots/tts/plotHz_MrealMimgTTS";
import {dataHz_CrealCimgTTS, layoutHz_CrealCimgTTS} from "../plots/tts/plotHz_CrealCimgTTS";
import {dataHz_mImgTTS, layoutHz_mImgTTS} from "../plots/tts/plotHz_mImgTTS";
import {dataMreal_MimgTTS, layoutMreal_MimgTTS} from "../plots/tts/plotMreal_MimgTTS";

//Protective coatings
import {dataHz_MmodMphzPC, layoutHz_MmodMphzPC} from "../plots/plotHz_MmodMphzPC";
import {dataHz_MrealMimgPC, layoutHz_MrealMimgPC} from "../plots/plotHz_MrealMimgPC";
import {dataMreal_MimgPC, layoutMreal_MimgPC} from "../plots/plotMreal_MimgPC";

//Coating plots inmersion
import {dataHz_MmodMphzCoating, layoutHz_MmodMphzCoating} from "../plots/plotHz_MmodMphzCoating";
import {dataHz_MrealMimgCoating, layoutHz_MrealMimgCoating} from "../plots/plotHz_MrealMimgCoating";
import {dataMreal_MimgCoating, layoutMreal_MimgCoating} from "../plots/plotMreal_MimgCoating";

import { varNameEIS } from './variables';

export default {
  type: 'eis',
  R: {
    name: 'Resistance',
    parameters: ['R'],
    extraVar: [],
    fun: R => R,
  },
  C: {
    name: 'Capacitor',
    parameters: ['C'],
    extraVar: ['w'],
    fun: (C, w) => `1/(${C}*${w}*i)`,
  },
  L: {
    name: 'Inductor',
    parameters: ['L'],
    extraVar: ['w'],
    fun: (L, w) => `${L}*${w}*i`,
  },
  Q: {
    name: 'CPE',
    parameters: ['Q', 'a'],
    extraVar: ['w'],
    fun: (Q, a, w) => `1/(${Q}*(${w}*i)^${a})`,
  },
  W: {
    name: 'Wb',
    parameters: ['W'],
    extraVar: ['w'],
    fun: (W, w) => `1/(${W}*(${w}*i)^${1/2})`,
  },
  defVals: (str) =>{
    let val ={}
    switch(str[0]) {
      case 'R':
        val = {
          type:"Resistance",
          valueSci:1000000000000000000,
          valueSciTex:"1.00e+18",
          valueStep: 1,
          subType:"Generic Resistance",
          subTypeAbr:"R",
          units:"(Ohm)",
          maxRange: 18,
          minRange: -2,
          stepRange:0.05
        };
        break;
      case 'C':
        val = {
          type:"Capacitance",
          valueSci:0.00000001,
          valueSciTex:"1.00e-8",
          valueStep: -2,
          subType:"Generic Capacitance",
          subTypeAbr:"C",
          units:"(F)",
          maxRange: 3,
          minRange: -15,
          stepRange:0.05
        };
        break;
      case 'L':
        val = {
            type:"Inductance",
            valueSci:0.00000001,
            valueSciTex:"1.00e-8",
            valueStep: -2,
            subType:"Generic Inductance",
            subTypeAbr:"L",
            units:"(H)",
            maxRange: 0,
            minRange: -15,
            stepRange:0.05
          };
          break;
      case 'Q':
        val = {
          type:"Capacitance",
          valueSci:0.00000001,
          valueSciTex:"1.00e-8",
          valueStep: -2,
          subType:"Generic Capacitance",
          subTypeAbr:"Q",
          units:"(F.s^{\\alpha})",
          maxRange: 3,
          minRange: -15,
          stepRange:0.05
        };
        break;
      case 'a':
        val = {
          type:"Constant",
          valueSci:1.00,
          valueSciTex:"1.000",
          valueStep:1,
          subType:"Surface Roughness",
          subTypeAbr:"\\alpha",
          units:"",
          maxRange: 1,
          minRange: 0.5,
          stepRange: 0.01
        };
        break;
      case 'W':
        val = {
          type:"Capacitance",
          valueSci:0.000000000000000001,
          valueSciTex:"1.00e-18",
          valueStep: -2,
          subType:"Generic W",
          subTypeAbr:"Wb",
          units:"(F.s^{1/2})",
          maxRange: 0,
          minRange: -18,
          stepRange:0.05
        };
        break;
      default:
        console.log("Add smile string");
      }
    return val;
  },
  validCharacters: ['R','C','Q','L','W','(',')','-'],
  serial: simpleSum,
  parallel: facSum,
  parseLaTeX : str => {
      let texStr = replaceAll(str,"1/(","\\frac{1}{",0); // add fractions
      texStr = replaceAll(texStr,")","}",0);// close fractions
      texStr = replaceAll(texStr,"(w*i}^a","(\\omega i)^{a}",1);//
      texStr = replaceAll(texStr,"(w*i}^0.5","(\\omega i)^{0.5}",0);//
      texStr = replaceAll(texStr,"a}_{","\\alpha",0);
      texStr = replaceAll(texStr,"R","R",1);// add E sing
      texStr = replaceAll(texStr,"Q","Q",1);// add p sing
      texStr = replaceAll(texStr,"C","C",1);
      texStr = replaceAll(texStr,"L","L",1);
      texStr = replaceAll(texStr,"W","W",1);// add eta sign
      texStr = replaceAll(texStr,"w","\\omega ",0); // add omega sign
      texStr = replaceAll(texStr,"*","",0);// remove *
      return texStr
  },
  modulusTeX: '|Z|',
  predefinedMod : {
      default: "R-CR",
      Randless: "R-C(R-W)",
      n1: "R-QR",
      n2: "R-Q(R-QR)",
      n3: "R-Q(R-Q(R-QR)",
  },
  varNames: varNameEIS,
  plots: [
    {
      name: 'Bode',
      layout: layoutHz_MmodMphz,
      plotData: dataHz_MmodMphz
    },
    {
      name: 'Zreal/Zimg',
      layout: layoutHz_MrealMimgPC,
      plotData: dataHz_MrealMimgPC
    },
    /**
    {
      name: 'Zreal/Zimg',
      layout: layoutHz_MrealMimg,
      plotData: dataHz_MrealMimg
    },
    {
      name: 'Mimg',
      layout: layoutHz_mImg,
      plotData: dataHz_mImg
    },**/
    {
      name: 'Nyquist',
      layout: layoutMreal_Mimg,
      plotData: dataMreal_Mimg
    }
  ],
  plotsTTS: [
    {
      name: 'Bode',
      layout: layoutHz_MmodMphzTTS,
      plotData: dataHz_MmodMphzTTS
    },
    {
      name: 'Zreal/Zimg',
      layout: layoutHz_MrealMimgTTS,
      plotData: dataHz_MrealMimgTTS
    },
    {
      name: 'Creal/Cimg',
      layout: layoutHz_CrealCimgTTS,
      plotData: dataHz_CrealCimgTTS
    },
    {
      name: 'Mimg',
      layout: layoutHz_mImgTTS,
      plotData: dataHz_mImgTTS
    },
    {
      name: 'Nyquist',
      layout: layoutMreal_MimgTTS,
      plotData: dataMreal_MimgTTS
    }
  ],
  plotsPC: [
    {
      name: 'Bode',
      layout: layoutHz_MmodMphzPC,
      plotData: dataHz_MmodMphzPC
    },
    {
      name: 'Zreal/Zimg',
      layout: layoutHz_MrealMimgPC,
      plotData: dataHz_MrealMimgPC
    },
    {
      name: 'Nyquist',
      layout: layoutMreal_MimgPC,
      plotData: dataMreal_MimgPC
    }
  ],
  plotsCoating: [
    {
      name: 'Bode',
      layout: layoutHz_MmodMphzCoating,
      plotData: dataHz_MmodMphzCoating
    },
    {
      name: 'Zreal/Zimg',
      layout: layoutHz_MrealMimgCoating,
      plotData: dataHz_MrealMimgCoating
    },
    {
      name: 'Nyquist',
      layout: layoutMreal_MimgCoating,
      plotData: dataMreal_MimgCoating
    }
  ]
};
