import React, { Component } from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import eisRules from '../../Common/sinTools/rules/eisRules'

import PlotSin from '../../Common/componentsHTM/plotSin'
import PlotModule from '../../Common/componentsHTM/plot'
//import ParamPanel from '../../Common/componentsHTM/param/paramPanel'
import Circuit from '../../Common/componentsHTM/circuitDraw/circuit'
import UpdateSinData from '../../Common/componentsHTM/stateManagement'

class EisForCoatings extends Component {
  state = {
    sinData:{
      rules: eisRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
        },
          R2: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 150000000,
          valueSciTex: "1.50e+8",
          valueStep: 1
          }
        }
      }
    }
  }
  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }

    componentDidMount() {
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }
      UpdateSinData('R-QR', mainState);//execute the function to make the magic
    }
  render() {
    const {expData, modelData, rules} = this.state.sinData
  return (
    <div className ="columnDiv"  >


      <PlotModule size={500} expData={expData} modelData={modelData} rules={rules}/>

      <DownOutlined />
      <DownOutlined />
      <DownOutlined />

      <div className="centerDiv rowDiv" >
        <div style={{width:'90px', margin:'5px'}}><Circuit smiles={"R"}/></div>
        <div style={{width:'90px', margin:'5px'}}><Circuit smiles={"C"}/></div>
      </div>

      <div className="centerDiv columnDiv" >
        <div style={{width:'250px', marginTop:'10px'}}><Circuit smiles={"R-Q(R-QR)"}/></div>
        Representation for protective coatings
      </div>


      </div>




  )
}
}

export default EisForCoatings
