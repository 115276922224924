import React from 'react'
import { Select } from 'antd';
import TableExpTTS from './tableExpTTS'



const { Option } = Select;

const TTSrefSelector = (props) =>{
  let panes = props.ttsData.data

  const refTemSelection =  (panes.length === 0) ? '' : panes.map(pane => { return (<Option value={pane.key} key={pane.key}>{pane.temp+'ºC'}</Option>) })
  const changeRefTemp = (key) =>{
    panes.map((pane) => { pane.aE = 0})
    panes[key-1].aE = 1
    panes[key-1].logcT = 0
    panes[key-1].aH = 0
    props.ttsData.refTemp = key
    props.updateData("refTemp",key)
    console.log('Reference changed to ', panes[key-1].temp,' ºC')
  }

  return(
    <div className="leftDiv">
      Reference temperature: <Select style={{marginLeft:'5px'}} defaultValue={'select temperature'} onChange={changeRefTemp}>
      {refTemSelection}
      </Select><span>&nbsp;&nbsp;</span>
      <TableExpTTS />
    </div>
  )
}

export default TTSrefSelector
