import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Radio } from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';


const RadioGroup = Radio.Group;
const Plot = lazy(() => import('react-plotly.js'));

class PlotModule extends Component {
  state = {
    name: 'Bode',
    dataFunc: this.props.rules.plotsCoating[0].plotData,
    layout: this.props.rules.plotsCoating[0].layout
  }
  onChange = (e) => {
   const plotObj = e.target.value;
   this.setState({
     name: plotObj.name,
     dataFunc: plotObj.plotData,
     layout: plotObj.layout,
   });
 }
    render() {
      // make a selector to select which plot to makes
      const { coatingData, size } = this.props;

      const { plotsCoating } = this.props.rules;
      const { varNames } = this.props.rules;
      const plotList = plotsCoating.map(item => {
        return (<Radio key={item.name} value={item} checked={item.name === this.state.name}>{item.name}</Radio>)
      });

        return (
          <div className = "columnDiv">
            <div className = "centerDiv">
              <RadioGroup onChange={this.onChange}>
                {plotList}
              </RadioGroup>
            </div>
            <div className = "centerDiv">
            <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
              <Plot
                  data={ this.state.dataFunc(coatingData, varNames) }
                  layout={ this.state.layout(coatingData, varNames, size) }
                  config={{displayModeBar: false}}
                  useResizeHandler= {true}
                  //style= {{width: "100vh", height: "100hv"}}
              />
              </Suspense>
            </div>
          </div>
        );
    }
}

export default PlotModule;
