/**
 * Info: Functions to obtain coatting seed parametres
 * getResistances() --> returns values of Re and Rc *not needed
 * getCapacitance() --> returns values of Cc and n
 * extractCoating() --> returns a new expData till the Zimg maximum
 * @returns {
 *            explanation: 'string',
 *            seed:{...parameter:value}
 *          }
 */

import {linearReg, toLog, unLog, avgSmooth, average, avg2} from '../../../math/operations'
const smooth = 1
const getCleanData = (expData) => {
  // remove the points where Zimg >-1 or Zreal < 1 (the machine might give even positive values of Zimg)
  // also remove the induction effects that puts Zreal < 1
  let cleanDataZimg = []
  let cleanDataZreal = []
  let cleanDataZmod = []
  let cleanDataZphz = []
  let cleanDatafHz = []
  let cleanDatafRad = []
  for (let i=0; i< expData.Mimg.length; i++){
    if ((expData.Mimg[i] < -1) && (expData.Mreal[i] > 1)){
      cleanDataZimg.push(expData.Mimg[i])
      cleanDataZreal.push(expData.Mreal[i])
      cleanDataZmod.push(expData.Mmod[i])
      cleanDataZphz.push(expData.Mphz[i])
      cleanDatafHz.push(expData.fHz[i])
      cleanDatafRad.push(expData.fRad[i])
    }
  }

 const cleanData =  {
   Mimg: cleanDataZimg,
   Mreal: cleanDataZreal,
   Mmod: cleanDataZmod,
   Mphz: cleanDataZphz,
   fHz: cleanDatafHz,
   fRad: cleanDatafRad
 }

 return cleanData

}

const getCloser = (Zphz,fHz, Zmod,angleDegree) => {
  let colserToup = -200
  let colserTodown = -200
  let freqUp = 100000
  let freqDown = 100000
  let zUp = 100000
  let zDown = 100000
  for (let i= 0; i<Zphz.length ; i++){
    if (Zphz[i] > colserToup && Zphz[i] <= -angleDegree){
      colserToup = Zphz[i]
      colserTodown = Zphz[i+1] ? Zphz[i+1] : Zphz[i]
      freqUp = fHz[i]
      freqDown = fHz[i+1] ? fHz[i+1] : fHz[i]
      zUp = Zmod[i]
      zDown = Zmod[i+1] ? Zmod[i+1] : Zmod[i]
    }
  }
  const regresion = (freqUp == freqDown) ? false : linearReg([colserTodown,colserToup],[freqDown,freqUp])
  const regresionZ = (zUp == zDown) ? false : linearReg([colserTodown,colserToup],[zDown,zUp])
  const angle = regresion ? -angleDegree : colserToup
  const freq = regresion ? ((-angleDegree-regresion.intercept)/regresion.slope) : freqUp
  const z = regresionZ ? ((-angleDegree-regresionZ.intercept)/regresionZ.slope) : zUp

  const a = Math.tan(Math.PI*angle/180)
  const zImg = a*z/Math.sqrt(1+a*a)


  return {
    angle,
    freq,
    z,
    zImg
  }
}

const getNfromZphz = (Zphz,logw) => { //obtener el coeficiente n por: Average inicial de Zphz, maximo Zphz, calcular la pendiente inicial desde el maximo
  //const phzSmooth = Zphz
  const phzSmooth = avg2(Zphz)



  const nMax = Math.min(...phzSmooth.slice(0,phzSmooth.length/2))/(-90)
  //console.log("n max = ", nMax)

  const nAvg = average(phzSmooth.slice(0,10))/(-90)
  //console.log("n Avg = ", nAvg)

  const nReg = linearReg(phzSmooth.slice(0,5),logw.slice(0,5))
  console.log("nSlope = ", nReg.slope, "nError = ", nReg.r2, )

  const result =  {nMax,nAvg, nReg}

  return result
}

export const getResistances = (expData) => {
  const cleanData = getCleanData(expData)

  //get averages to smoth the data
  const avgZphz = avgSmooth(cleanData.Mphz,smooth)
  const avgfHz = avgSmooth(cleanData.fHz,smooth)
  const avgMreal = avgSmooth(cleanData.Mreal,smooth)
  const avgMmod = avgSmooth(cleanData.Mmod,smooth)

  //find regions with phz< -10
  let regions = 0
  let activeRegion = false
  let resistances = {}
  let freq ={}

  for (let i=0; i<avgZphz.length; i++){
    if( avgZphz[i] > -10 ){
      if (!activeRegion){
        regions = regions +1
        activeRegion = true
      }
      //lo registramos en la primera region
      resistances.hasOwnProperty('R'+regions) ? resistances['R'+regions].push(avgMreal[i]) : resistances['R'+regions]=[avgMreal[i]]
      freq.hasOwnProperty('Hz'+regions) ? freq['Hz'+regions].push(avgfHz[i]) : freq['Hz'+regions]=[avgfHz[i]]
    }

    if( avgZphz[i] <= -10 ){ // si es menor que -10 pero ya tenemos datos de mayores de -10
      activeRegion = false
    }
  }

  /*for (const prop in resistances) {
    console.log(`${prop} = Avg ${average(resistances[prop])} between ${resistances[prop][0]} - ${resistances[prop].slice(-1)[0]}`);
  }*/

  let vervose = [`We have found ${regions} regions with a pure resistive behaviour`]
  let seed = {}
  if(Object.keys(resistances).length){ //if the resistance object is not empty
    for (const prop in resistances) {
      seed[prop] = average(resistances[prop])
      vervose.push("A resistance behavior "+prop+" of "+seed[prop].toExponential(2)+
      " appears between "+freq["Hz"+prop.slice(-1)][0].toExponential(2)+"Hz - "+freq["Hz"+prop.slice(-1)].slice(-1)[0].toExponential(2)+"Hz")
  }

  if('R1'in seed){ // if there is a resistance R1
    vervose.push(`This is a bad sign, since may represents that the electrolyte (salt water) is already reaching the metal surface`)
      //console.log(freq.Hz1[0])
      if(freq.Hz1[0] > 10000) { //and it starts at Hz higher o equal than 10⁴ check this!!! better relate to Re
      seed.Re = seed.R1 //tomamos R1 como Re
      vervose.push(`We can consider the first one (R1) as the electrolite resistance Re = ${seed.Re} Ohms`)
      if('R2'in seed) {// si existe un R2 lo tomamos como Rc
        seed.Rc = seed.R2 - seed.R1// lo tomamos como Rc
        vervose.push(`And the second one (R2) as the coating resistance Rc = ${seed.Rc} Ohms`)
      }
    }
    if(freq.Hz1[0] < 10000){//and it starts at Hz lower than 10⁵ check this!!! related to Re
      seed.Re = 5 //Fijamos Re a 15 algorithms
      seed.Rc = seed.R1 // lo tomamos R1 como Rc
    }
  }

}
if (regions == 0) {
    vervose.push(`This is a good sign, the electrolyte (salt water) is strugeling to reach the the metal surface`)
    seed.Re = 30 //Fijamos Re a 30 algorithms
    seed.Rc = 10000000000000000 // lo tomamos R1 como 10¹6
}
//console.log('semilla',seed)
// frequency and Zphz 45
const below45 = getCloser(avgZphz,avgfHz, avgMmod,45)
vervose.push(below45.angle.toFixed(2)+"º at "+Number.parseFloat(below45.freq).toExponential(2)+" Hz")


  return {
            explanation:vervose,
            seed: {
                  Re:seed.Re,
                  Rc:seed.Rc
                }
        }
 }

export const getCapacitance = (expData) => {

  const cleanData = getCleanData(expData)

  //get general averages to smoth the data
  //const avgZphz = avgSmooth(cleanData.Mphz,smooth)
  const avgfHz = avgSmooth(cleanData.fHz,smooth)
  const avgMreal = avgSmooth(cleanData.Mreal,smooth)
  const avgMmod = avgSmooth(cleanData.Mmod,smooth)

  const cleanDataMmod = cleanData.Mmod
  const cleanDataZimg = cleanData.Mimg
  const cleanDatafRad = cleanData.fRad


  // get the log version of the data
  const logMmod = toLog(cleanDataMmod)
  const logZimg = toLog(cleanDataZimg.map(zimg => -zimg))
  const logw = toLog(cleanDatafRad)




  //get averages to smoth the data
  const avgLogMmod = avgSmooth(logMmod,smooth)
  const avgLogZimg = avgSmooth(logZimg,smooth)
  const avgLogw = avgSmooth(logw,smooth)
  const avgZphz = avgSmooth(cleanData.Mphz,smooth)


  // alternative aproach to start on 10⁵
    //const startingValue = expData.fHz.find((hz)=> hz <= 100000) // find the first value at 10⁵
    //const ini = expData.fHz.indexOf(startingValue)// the the position of the value in the array

    //get the linear regresion of the first 10 points *** check this
/**  const regresion = linearReg(avgLogZimg.slice(0,10),avgLogw.slice(0,10))
//console.log(regresion)
    const n = (-regresion.slope > 1) ? 1 : -regresion.slope
    const cpe = 1/(Math.pow(10,regresion.intercept)*Math.pow(1,n))
**/

// getting CPE and n from nMax value --> seems better unles the nSlope is < -1
  const phz10first = getNfromZphz(avgZphz,avgLogw)
  const nFromZphzMax = phz10first.nMax
  phz10first.w0 = avgLogw[0]
  phz10first.Zimg0 = avgLogZimg[0]
  const newIntercept = avgLogw[0]*(nFromZphzMax) + avgLogMmod[0]
  const cpe2 = 1/(Math.pow(10,newIntercept)*Math.pow(1,nFromZphzMax))
//  console.log("CPE = ",cpe2, "n = ",nFromZphzMax,)

const below45 = getCloser(avgZphz,avgfHz, avgMmod,45)

const belowForWc = getCloser(avgZphz,avgfHz, avgMmod,(nFromZphzMax*(90)-below45.angle)/2)

const belowIni = getCloser(avgZphz,avgfHz, avgMmod,nFromZphzMax*(90))

  return {
    explanation:'',
    phz10first: phz10first,
    angleIni: belowIni,
    angle45: below45,
    angleForWc: belowForWc,
    seed: {
            Cc: cpe2,
            n: nFromZphzMax
          }
  }
}

export const getZat01 = (expData) => {
  const cleanData = getCleanData(expData)

  //get averages to smoth the data
  const avgfHz = avgSmooth(cleanData.fHz,smooth)
  const avgMmod = avgSmooth(cleanData.Mmod,smooth)

 let max01avg = []
 for (let i=0; i< avgfHz.length; i++){
   max01avg.push((avgfHz[i] <= 0.1) ? avgfHz[i] : 0.1 - (avgfHz[i] - 0.1) )
 }
 let max01 = []
 for (let i=0; i< cleanData.fHz.length; i++){
   max01.push((cleanData.fHz[i] <= 0.1) ? cleanData.fHz[i] : 0.1 - (cleanData.fHz[i] - 0.1) )
 }

 const closedIndex = max01.indexOf(Math.max(...max01))
 const closedIndexAvg = max01avg.indexOf(Math.max(...max01avg))
 //console.log("Z_01avg = ", avgMmod[closedIndexAvg], " at ", avgfHz[closedIndexAvg])
 //console.log("Z_01 = ", cleanData.Mmod[closedIndex], " at ", cleanData.fHz[closedIndex])

 return cleanData.Mmod[closedIndex]


}

export const extractCoating = (expData) => {
  let result = {
    fHz:[],
    fRad:[],
    Mreal:[],
    Mimg:[]
  }

  const cleanData = getCleanData(expData)
  const cleanDataZimg = cleanData.Mimg
  const cleanDataZreal = cleanData.Mreal
  const cleanDatafHz = cleanData.fHz
  const cleanDatafRad = cleanData.fRad


  // get the log version of the data
  const logZimg = toLog(cleanDataZimg.map(zimg => -zimg))
  const logw = toLog(cleanDatafRad)
  //console.log('logZimg',logZimg)
  //console.log('logw',logw)


  //get averages to smoth the data
  const avgLogZimg = avgSmooth(logZimg,smooth)
  const avgLogw = avgSmooth(logw,smooth)

  for (let i=0; i< avgLogZimg.length-2; i++){

    const slope = linearReg(avgLogZimg.slice(i,i+2),avgLogw.slice(i,i+2)).slope
    //console.log(slope)
    if (slope > 0){

      const cut = Math.pow(10,avgLogw[i+2])
      //console.log("fRad corte =", cut, ' => ', cut/(2*Math.PI) ,'Hz')
      for (let u=0; u< cleanDatafRad.length; u++){
        if (cleanDatafRad[u] >= cut){
          result.fHz.push(cleanDatafHz[u])
          result.fRad.push(cleanDatafRad[u])
          result.Mimg.push(cleanDataZimg[u])
          result.Mreal.push(cleanDataZreal[u])
        }
      }

      return result
    }
  }

  return {
          fHz:cleanDatafHz,
          fRad:cleanDatafRad,
          Mreal:cleanDataZreal,
          Mimg:cleanDataZimg
        }

}
