
import React, { Component } from 'react';
import { InputNumber, Button, Divider, Slider } from 'antd';
import { InlineMath } from 'react-katex'

import NumImputTTS from './numberInputTTS'
import ParamRangeTTS from './paramRangeTTS'
import NumImputEaTTS from './numberInputEaTTS'
import RangeEaTTS from './RangeEaTTS'
import { ttsFit } from '../../sinTools/algorithms/ttsFit'

class ParamPanelTTS extends Component {
  state = {
    range: [-3,7],
  }

  activationEnergy = (logShift,temp,refTemp) =>{
    const aH = -(logShift*2.303*8.314/((1/(temp+273.15))-(1/(refTemp+273.15))))/1000
    return aH
  }
  logcTfromEa = (aH,temp,refTemp) => {
    const logcT = -1000*(aH/(2.303*8.314))*((1/(temp+273.15))-(1/(refTemp+273.15)))
    return logcT
  }

    updateParamData = (key,value) => {
      //console.log(key,value)
        let data = this.props.ttsData.data
        console.log('data',data)
        let refTempKey = this.props.ttsData.refTemp-1
        data[key].logcT = value
        data[key].aH = this.activationEnergy(value,data[key].temp,data[refTempKey].temp)
        this.props.updateData ('data',data)
        let avgEa = data.reduce((prev, curr) => {return prev + curr.aH},0)/(data.length-1)
        this.props.updateData ('avgEa',avgEa)
    }

    updateToPreadjustedData = () => {
      let data = this.props.ttsData.data
      const predefined25 = this.props.ttsData.predefined25
      let refTempKey = this.props.ttsData.refTemp-1
      for (let index = 0; index < data.length; index++) {
        data[index].logcT = predefined25[index]
        data[index].aH = this.activationEnergy(predefined25[index],data[index].temp,data[refTempKey].temp)
      }
      this.props.updateData ('data',data)
      let avgEa = data.reduce((prev, curr) => {return prev + curr.aH},0)/(data.length-1)
      this.props.updateData ('avgEa',avgEa)
    }

    updateEa = (value)=>{
      let data = this.props.ttsData.data
      let refTempKey = this.props.ttsData.refTemp-1
      let refTemp = data[refTempKey].temp
      data = data.map(item => ({...item, logcT: (item.temp == refTemp) ? 0 : this.logcTfromEa(value,item.temp,refTemp) }))
      data = data.map(item => ({...item, aH: (item.temp == refTemp) ? 0 : value}))
      this.props.updateData ('data',data)
      this.props.updateData ('avgEa',value)
    }

    autottsFit = () => {

      let data = this.props.ttsData.data
      let refTempKey = this.props.ttsData.refTemp-1
     //console.log('Factor par 1 :',ttsFit(data[refTempKey].expData,data[1].expData))


     for(let i=0; i< data.length; i++){
      //  console.log(i !== refTempKey)
        if (i !== refTempKey){
          data[i].logcT = ttsFit( data[refTempKey].expData,data[i].expData,this.state.range)
        //  console.log(data[i].logcT)
          data[i].aH = this.activationEnergy(data[i].logcT,data[i].temp,data[refTempKey].temp)
        }
      }
      this.props.updateData ('data',data)
      let avgEa = data.reduce((prev, curr) => {return prev + curr.aH},0)/(data.length-1)
      this.props.updateData ('avgEa',avgEa)
    }

    resetcT = () =>{
      let data = this.props.ttsData.data
      for(let i=0; i< data.length; i++){
          data[i].logcT = 0
          data[i].aH = 0
        }
      this.props.updateData ('data',data)
    }
    onChange = (value) => {
  //console.log('onChange: ', value);

}

    onAfterChange =(value) =>{
      //console.log('onAfterChange: ', value);
      this.setState({
        range: value
      })//u
}
    render() {
      const maxRange = 7 //Math.log(this.props.ttsData.data.map(temp =>{return temp.expData.fHz ? Math.max(...temp.expData.fHz) : 100000}))/Math.log(10)
      const minRange = -3 //Math.log(this.props.ttsData.data.map(temp =>{return temp.expData.fHz ? Math.min(...temp.expData.fHz) : 0.01}))/Math.log(10)
      //console.log([minRange,maxRange])
    const tempList = this.props.ttsData.data.map(item => { //it draws the parametres list including slicers names and values
      const temperature = item.temp
      const key = item.key
      const colorST = (item.temp<360) ? 360-item.temp : 360-(item.temp-360)
      return (
        <tr key={key+"_tr"} >
          <td key={key+"_td1"} style={{textAlign:"center", color: item.aE ? 'rgb(0,0,0)' :'hsl('+colorST+', 100%, 50%)', fontWeight: 'bold'}}>
            {temperature.toString()}
          </td>
          <td key={key+"_td2"} style={{textAlign:"center"}}>
            <ParamRangeTTS id={key} logcT= {item.logcT} updateParamData = {this.updateParamData} notActive={this.props.ttsData.refTemp == key ? true : false} />
          </td>
          <td key={key+"_td3"} style={{textAlign:"center"}}>
            <NumImputTTS id={key} logcT= {item.logcT} updateParamData = {this.updateParamData} notActive={this.props.ttsData.refTemp == key ? true : false} />
          </td>
          <td key={key+"_td4"} style={{textAlign:"center", color: 'rgb(150,150,150)', fontWeight: 'bold'}}>
            {item.aH ? (item.aH.toFixed(0)):0}
          </td>
        </tr>
      )
    });
    return(
      <div>
        <table>
          <thead>
            <tr>
              <th style={{textAlign:"center"}}><InlineMath>T (\degree C)</InlineMath></th>
              <th style={{textAlign:"center"}}><InlineMath>log(Shift  Factor)</InlineMath></th>
              <th style={{textAlign:"center"}}></th>
              <th style={{textAlign:"center"}}><InlineMath math="E_{a} (\frac{KJ}{mol})"/></th>
            </tr>
          </thead>
          <tbody>
            {tempList}
          </tbody>
        </table>
        {this.props.ttsData.predefined25 ? (
        <div className="centerDiv" style={{marginTop: "10px"}}>
          <Button onClick={this.updateToPreadjustedData}> Preadjusted 25ºC </Button>
        </div>
        ):''}
        <div className="centerDiv" style={{marginTop: "10px"}}>
         <Button onClick={this.resetcT}> RESET </Button><span>&nbsp;&nbsp;</span>
          <Button onClick={this.autottsFit}> AUTOMATIC FIT </Button><p style={{color:'red'}}> --> <i> beta version </i> </p>
        </div>
        <div className="centerDiv" style={{marginTop: "10px"}}>
        <table>

          <tbody>
          <tr key={'ae_tr'} >
            <td key={"ae_td1"} style={{textAlign:"right"}}>
              <InlineMath math="\overline{E_{a}} = {\frac {1}{n}}\sum _{i=1}^{n}{E_{a}}_{i} = "/>
            </td>
            <td key={"ae_td2"} style={{textAlign:"center"}}>
              <NumImputEaTTS id={'ae_imput'} aH= {this.props.ttsData.avgEa} updateEa = {this.updateEa} />
            </td>
            <td key={"ae_td3"} style={{textAlign:"left"}}>
            <InlineMath math="\frac{KJ}{mol}"/>
            </td>
          </tr>
          </tbody>
        </table>
        </div>

        <div>
          <Slider
             range
             step={0.1}
             max={maxRange}
             min={minRange}
             defaultValue={[minRange, maxRange]}
             onChange={this.onChange}
             onAfterChange={this.onAfterChange}
           />
        </div>
      </div>
    )
  }
}

export default ParamPanelTTS
