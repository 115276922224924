import React from "react";

function D() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="30"
      version="1.1"
      viewBox="0 0 14.552 4.763"
    >
      <g fill="none" stroke="#000" strokeWidth="0.5">
        <path
          d="M5.681 292.812v3.639M5.681 294.608H0M9.963 294.608h4.59M3.498 292.444c1.877.006 3.754-.017 5.63.012.37-.021.855.09.811.468.062.48-.002.965.022 1.447-.002.684.015 1.368-.019 2.05-.09.367-.626.379-.99.368-1.253.018-2.507 0-3.76.006H3.498"
          transform="translate(0 -292.238)"
        ></path>
      </g>
    </svg>
  );
}

export default D;
