import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log('@@@@@@@@@@@    @@@@   @@@@     @@@@   @@@@         @@@@   @@@@@@@@@@@@@@   @@@@@@@@@        @@@@@@@@@@@@')
console.log('@@@@@@@@@@@    @@@@   @@@@@    @@@@   @@@@@       @@@@@   @@@@@@@@@@@@@@   @@@@@@@@@@@      @@@@@@@@@@@@')
console.log('@@@@           @@@@   @@@@@@   @@@@   @@@@@@     @@@@@@   @@@@      @@@@   @@@@    @@@@     @@@@        ')
console.log('@@@@           @@@@   @@@@@@@  @@@@   @@@@@@@   @@@@@@@   @@@@      @@@@   @@@@     @@@@    @@@@        ')
console.log('@@@@@@@@@@@    @@@@   @@@@@@@@ @@@@   @@@@@@@@ @@@@@@@@   @@@@      @@@@   @@@@      @@@@   @@@@@@@@@@@@')
console.log('@@@@@@@@@@@    @@@@   @@@@ @@@@@@@@   @@@@ @@@@@@@ @@@@   @@@@      @@@@   @@@@      @@@@   @@@@@@@@@@@@')
console.log('       @@@@    @@@@   @@@@  @@@@@@@   @@@@  @@@@@  @@@@   @@@@      @@@@   @@@@      @@@@   @@@@        ')
console.log('       @@@@    @@@@   @@@@   @@@@@@   @@@@         @@@@   @@@@      @@@@   @@@@     @@@@    @@@@        ')
console.log('@@@@@@@@@@@    @@@@   @@@@    @@@@@   @@@@         @@@@   @@@@@@@@@@@@@@   @@@@@@@@@@@@     @@@@@@@@@@@@')
console.log('@@@@@@@@@@@    @@@@   @@@@     @@@@   @@@@         @@@@   @@@@@@@@@@@@@@   @@@@@@@@@@       @@@@@@@@@@@@')

console.log('I am looking for collaborators with JS and Reactjs skills!!! contact me at info@sinmode.com')
