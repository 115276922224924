import React, { useState } from 'react'
import { Tabs, Button, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import DataFromUser from './dataFromUser' /// Needed props: type ('eis' or 'rheo')
import DataFitter from './dataFitterCoating'

import {getModel} from '../sinTools/algorithms/getModelFromStr';

const { TabPane } = Tabs;

const PanelTab = (props) => {
  const [activeKey, setActiveKey] = useState('1');
  const [time, setTime] = useState(0);



let panes = props.timeData.data
//console.log("panes",props)
  const onChange = activeKey => { // when clicking in the tab
    setActiveKey(activeKey)//updates the hook
    console.log('Selected the tab number ',activeKey)
  }
  const onEdit = activeKey => { // when clicking in the x on the tab

    panes.splice(activeKey-1, 1);
    panes.map((item,i)=>{ return item.key=i+1})
    setActiveKey('1')
    console.log('Removed the tab number ',activeKey)
    props.updateData('data', panes) //update the TTS data on the parent component
  }
  const onChangeTime = (value) => {
    setTime(value)
    console.log('The inmersion time input has changed to ',value,'h')
  }
  const addTime = (e) => {
    e.preventDefault();//to prevent the reload of the page when pressed enter
    console.log("inmersion time is now ",time,"h")
    const keynum = panes.length+1
    panes.push({
      time:time,
      expData:{},
      modelData:{},
      explanation:[],
      z01: 0,
      key: keynum,
      model: getModel('R-QWR', props.rules),
    })
    setActiveKey(keynum.toString())
    props.updateData('data', panes)//update the TTS data on the parent component
  }
  const addData = (item,data) => { //item to change with the new data
    panes[activeKey-1][item] = data; //changes the selected item from the object with the new data,p.ej item= expData
    props.updateData('data', panes) //updates the parent state
    console.log('New data added for inmersion time ', panes[activeKey-1].time,'h:')
  }

  const analysis = () => {
    panes[activeKey-1].analysis = true
    props.updateData('data', panes)
    console.log('Analysing data for ', panes[activeKey-1].time,'h:')
  }
  const reset = () => {

  panes[activeKey-1].analysis = false
    props.updateData('data', panes)
    console.log('Reset ', panes[activeKey-1].time,'h:')
  }
  const panesTable = panes.map(pane => (
    <TabPane tab={pane.time+' h'} key={pane.key}>
    {!pane.analysis ? (
      <div>
        <DataFromUser updateData = {addData} type={props.type}/>
        {(Object.keys(props.timeData.data[0].expData).length === 0)? '':
        <Button onClick={analysis}>Automatic Analysis</Button>} 
      </div>
    ):(
      <div>
        <DataFitter sinData={pane} updateData={addData} type='eis'/>
        <Button onClick={reset}>Reset</Button>
      </div>
    )}
    </TabPane>
  ))

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <form onSubmit={addTime}>
          <InputNumber defaultValue={0} onChange={onChangeTime} /> <ArrowLeftOutlined /> <Button onClick={addTime}>ADD NEW INMERSION TIME (h)</Button>
        </form>
        </div>
          <Tabs
            hideAdd
            onChange={onChange}
            tabPosition={panes.length > 4 ? "left" : "top"}
            activeKey={activeKey}
            type="editable-card"
            onEdit={onEdit}
          >
            {panesTable}
          </Tabs>
    </div>
  )
}

export default PanelTab
