import React, {useState} from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import PlotSin from '../../Common/componentsHTM/plotSin'


function SinBase(props) {
  const [angle, setAngle] = useState(0);
  const [angleRheo, setAngleRheo] = useState(-90);

  const updateAngle = (angle,type) => {
    setAngle(angle)
  }

  const explanation = "ddddd"
  const description = () => {
    return (
      <div>
        This is a sinusoidal signal, it is like a wave that goes up an down (at a certain amplitud). To make the measurement, we apply an input and we hear/measure the response.
        The measurement can be made at different speeds (different frequencies) and the response can have a delay that we can also quantify (phase angle).
      </div>
    )
  }

  return (
    <div className ="centerDiv columnDiv"  >
    <div className="preH1">Why sinusoidal data is so difficult to interpret.</div>
      <div className="rowDiv">

          <PlotSin  size={800} type="eis" base updateAngle= {updateAngle} />

      </div>
      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"

        />**/}
    </div>



  )
}

export default SinBase
