import { deburr } from 'lodash';

/**
 * Function that returns the sort compare function using the specified property for strings
 */
export const clean = string => deburr(string.trim().toLowerCase());

/**
 * Function that take out white spaces of a string
 */
export const spacesOut = str => str.replace(/\s/g, '');

export const strToArray = str => Array.from(str);

/**
 * A parser function that takes a string (str) and a array (arr) of items to localice in the
 * string to include a new string (includeStr) before the item. It returns a new string with
 * encapsulated values to be evaluated on future functions.
 */

export const parseStr = (str, arr, includeFirst, includeEnd) => { // a string, a list of things to localise, the addition before the item
  arr.map(a => str = str.replace(new RegExp(a, 'g'), `'+${includeFirst}${a}${includeEnd}+'`));
  return str;
};
/**
 * Other parser function that takes a string (str) and the items to replace in the
 * string. If the replacement contains a subscript (sub) different than 0 it numerates interval
 * in LaTeX syntax.
 **/
export const replaceAll = (str,a,b,sub) => {
    let count = sub || 0;
    let strTemp = "";
    if (count === 0){
        strTemp = str.replace(a,b);
    } else {
        strTemp = str.replace(a+sub,b+'_{'+sub+'}');
        count++;
    }
    if(strTemp === str){
        return strTemp;
    } else {
        return replaceAll(strTemp,a,b,count)
    }
};

export const checkParent = (str,elementArray) => {
  let parentSum = 0;
  for ( let i = 0; i < str.length; i++){
    if(str[i] === ')' && parentSum === 0){
      return 'unexpected parenthesis ")"'};
    if(str[i] === '(' && str[i+1] === ')'){
      return 'empty parenthesis "(?)"'};
    if(str[i] === '('){
      parentSum ++};
    if(str[i] === ')'){
      parentSum--};
    if(str[i] === '-' && str[i-1] === '-'){
      return 'duplicated connectors "--"';
    }
    if(!(elementArray.indexOf(str[i]) > -1)){
      return 'character "'+str[i]+'" is not allowed'
    }
  }
  console.log(parentSum)
  return parentSum === 0 ? parentSum : 'unclosed parenthesis';
}
