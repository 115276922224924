import React, { Component } from 'react';

import {extractData} from "../sinTools/algorithms/extractRawData";
import {extendData} from "../sinTools/algorithms/extendData";

import { Button } from 'antd';
import { Input } from 'antd';


class DataFromUser extends Component {
  state = {
    placeHolder: this.props.type === 'eis' ? "Frequency(Hz) Zreal Zimg" : "Frequency(Hz)  G'  G''",
    expData: {},
    texAreaCSS: {
       maxWidth: 310,
       marginBottom: '2px'
    }
  }
  handleColect = (e) => {
    const userData = e.target.value
    let expData = extractData(userData);
    expData = extendData(expData);
    this.setState({
      expData: expData
    });
  }
  handleClick = (e) => {
      this.props.updateData("expData",this.state.expData);
  };

  render() {
    const { TextArea } = Input;
    return (
      <div>
          <div><TextArea style={this.state.texAreaCSS} rows="5" placeholder={this.state.placeHolder} onChange={this.handleColect} /></div>
          <div><Button onClick={this.handleClick}>Add data</Button></div>
      </div>
    );
  }
}

export default DataFromUser;
