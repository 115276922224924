/*
General layout for bode plot Modulus and Angle vs Frequency
 */
 export const dataHz_MmodMphzPC = (expData,modelData, varNames) => {// todo finalize this func and test to plot it

     var traceHzVsMmod = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.Mmod ? modelData.Mmod : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#1f77b4'},
         type: 'scatter',
         name: varNames.Mmod + ' Model'
     };
     var traceHzVsMmodR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.Mmod ? expData.Mmod : [],
         mode: 'markers',
         marker: {
             color: '#1f77b4',
         },
         type: 'scatter',
         name: varNames.Mmod + ' Real'
     };

     var traceHzVsMphz = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.Mphz ? modelData.Mphz : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#ff7f0e'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mphz + ' Model'
     };
     var traceHzVsMphzR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.Mphz ? expData.Mphz : [],
         mode: 'markers',
         marker: {color: '#ff7f0e'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mphz + ' Real'
     };

   return [traceHzVsMmod, traceHzVsMmodR, traceHzVsMphz, traceHzVsMphzR];
 }

 export const layoutHz_MmodMphzPC = (expData ,modelData, varNames,size, rangeX, rangeY, rangeY2) =>{

   const xMax = expData.fHz ? Math.max(...expData.fHz) : (
                   modelData.fHz ? Math.max(...modelData.fHz) : 100000
                 );
   const xMin = expData.fHz ? Math.min(...expData.fHz) : (
                   modelData.fHz ? Math.min(...modelData.fHz) : 0.01
                 );
   const xMaxLog = rangeX ? rangeX[1] : Math.log(xMax)/Math.log(10);
   const xMinLog = rangeX ? rangeX[0] : Math.log(xMin)/Math.log(10);

   const xMaxLogCeil = Math.ceil(xMaxLog);
   const xMinLogFloor = Math.floor(xMinLog);

   const yMax = expData.Mmod ? Math.max(...expData.Mmod) : (
                   modelData.Mmod ? Math.max(...modelData.Mmod) : 1000
                 );
   const yMin = expData.Mmod ? Math.min(...expData.Mmod) : (
                   modelData.Mmod ? Math.min(...modelData.Mmod) : 0
                 );
   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

   const graphWidth = size
   const graphHeigth = graphWidth/1.4

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: false,
       width: graphWidth,
       height: graphHeigth,

       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         ////fixedrange: true,
         title: {
           "text":varNames.Mmod,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:rangeY ? rangeY : [yMinLogFloor, yMaxLogCeil]
       },
       yaxis2: {
         ////fixedrange: true,
        title: {
          "text":varNames.Mphz,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2f',
 				dtick:rangeY2 ? Math.abs((rangeY2[1] - rangeY2[0])/10) :'10',
 				ticks: 'inside',
 				linewidth: 2,
 				overlaying: 'y',
 				side: 'right',
 				range:varNames.Mmod === 'Zmod' ? (rangeY2 ? [rangeY2[1],rangeY2[0]] : [0,-100]) : [0,100]
       }
       }
   };
