import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Slider, Switch } from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';



const Plot = lazy(() => import('react-plotly.js'));

const genAxis= (start,end,interval) => {
  let axis = []
  for (let i = 0; i<1; i+= 0.005){
    console.log(axis)
    axis.push(i)
  }
  return axis
}
const genHz= (start,end,interval) => {
  let axis = []
  for (let i = 0; i<1; i+= 0.005){
    console.log(axis)
    axis.push(i)
  }
  return axis
}


class PlotSinEIS extends Component {
  state = {
    realTime: false,
    xAxes : genAxis(0,1,0.01),
    phase: this.props.type === "eis" ? 0 : -90,
    frequency: 3,
    text: this.props.type === "eis" ? ["Input Voltage", "Measured Current", "Capacitive","Resistive"] : ["Input Stress", "Measured Strain", "Viscous", "Elastic"],
    base: this.props.base
  }

  onChangeFreq= value => {
      this.setState({
        frequency: value,
        //phase: -(Math.PI/180)*(value*10-10),
      })
    }
  onChangePhase= value => {
    const phase =  value*Math.PI/180
    //onsole.log(value," = ",phase)
    this.props.updateAngle(value,this.props.type)
      this.setState({
        phase: phase,
      })
    }

  sin = function(A, freq, t,pi) {
    var _i, _len, _results, _t;
    _results = [];
    for (_i = 0, _len = t.length; _i < _len; _i++) {
      _t = t[_i];
      _results.push(A * Math.sin(2 * Math.PI * freq * _t+pi));
    }
    return _results
  }

    render() {
      // make a selector to select which plot to makes
      const {xAxes,phase, frequency, text, base} = this.state
      const graphWidth = this.props.size
      const graphHeigth = base ? graphWidth/2 : graphWidth/1.4

      const trace1 = {
        x: xAxes,
        y: this.sin(0.95, frequency, xAxes,0),
        name: base ? "Input" : text[0]
      }
      const trace2 = {
        x: xAxes,
        y: this.sin(0.9*frequency/10, frequency, xAxes,phase),
        name: base ? "Measured response" : text[1],
        yaxis: base ? 'y' : 'y2',
      }

        return (
          <div className = "columnDiv">
            <div className = "centerDiv" >
              <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
                <Plot
                  config={{displayModeBar: false}}
                  data={[trace1, trace2]}
                    layout = {
                      {
                        margin: {
                          t: 10
                        },
                        xaxis: {
                          title: "time",
                          showgrid: false
                        },
                        yaxis: {
                          title: {
                            "text": base ? "Amplitud" : text[0],
                            "font": {"color": '#1f77b4'}
                          },
                          range:[-1,1],
                          showgrid: false,
                        },
                        yaxis2: {
                          title: {
                           "text":text[1],
                           "font": {"color": '#ff7f0e'}
                            },
                          showgrid: false,
                          overlaying: 'y',
                  				side: 'right',
                          range:[-1,1]
                        },
                        showlegend: base? true : false,
                        width: graphWidth,
                        height: graphHeigth,
                        hovermode: false,
                      }
                    }

                    />
              </Suspense>
            </div>
<div className = "centerDiv">

            <div className = "rowDiv">
              <div className = "centerDiv" style={{ width: '140px' }}>Slow Measurement</div>
              <div style={{  width: graphWidth/2.5 }}>
                 <Slider
                 min={1}
                 max={10}
                 onChange={this.onChangeFreq}
                 tooltipVisible={false}
                 defaultValue={4}  />
              </div>
              <div className = "centerDiv" style={{ width: '140px'}}>Fast Measurement</div>
            </div>
            </div>
            <div className = "centerDiv">

            <div className = "rowDiv">
              <div className = "centerDiv" style={{ width: '140px'}}>{ base ? "out of phase" :text[2] + " Behaviour" }</div>
              <div style={{ width:graphWidth/2.5 }}>
                 <Slider

                 min={-90}
                 max={0}
                 onChange={this.onChangePhase}
                 tooltipVisible={false}
                 defaultValue={this.state.phase}  />
              </div>
              <div className = "centerDiv" style={{ width: '140px' }}>{ base ? "in phase" :text[3] + " Behaviour" }</div>

            </div>

            </div>
          </div>
        );
    }
}

export default PlotSinEIS;
