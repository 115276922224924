import React from 'react';

const AMmanufact = (props) => {
  return (
    <div>
      <h1>Interactive monograph for 3D printing </h1>
      <ul>
        <li>Introduction. The story of a filament that went through a nozzel</li>
        <li>We need a certain flow to get the polymer out of the nozzel</li>
        <li>Stop the flow!!! give me some structure</li>
        <li>Why some filaments work better that others?... wow!! loock at this structured flow...</li>
      </ul>
    </div>
  )
}

export default AMmanufact
