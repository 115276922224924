import React from 'react';
import { Modal, Icon } from 'antd';
import { InlineMath, BlockMath } from 'react-katex'



function TableExpTTS (props) {

  function infoTTS() {
    return (
      <div>
      We use Shift Factors using Arrhenius law:
      <br />
      <br />
     <InlineMath math="\log(a_{T})=-\frac{E_{a}}{2.303R}(\frac{1}{T}-\frac{1}{T_{0}})"/>
     <br />
     <br />
     where <InlineMath math="E_{a}"/> is the activation energy in <InlineMath math="\frac{KJ}{mol}"/>, <InlineMath math="R"/> is the universal gas constant (8.314472 <InlineMath math="\frac{J}{K\cdot mol}"/>), and <InlineMath math="T_{0}"/> is the selected reference temperature in kelvins.

      </div>
    )
  }


  function info() {
  Modal.info({
    title: 'Shift Factors using Arrhenius',
    content: (
      <div>{infoTTS()}</div>
    ),
    onOk() {},
  });
}

return (
    <Icon onClick={info} type="question-circle" />
)
}

export default TableExpTTS
