import React from "react";

function C() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="32" version="1">
      <g
        fill="none"
        fillOpacity="0.75"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"


      >
        <path
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M 27 5 V27 M32 27 V5"
        ></path>
        <path
          strokeWidth="2"
          d="M 0 15 H27 M 32 15 H55"></path>
      </g>
    </svg>



  );
}

export default C;
