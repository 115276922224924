import React from 'react';
import { Modal, Icon } from 'antd';
import { InlineMath, BlockMath } from 'react-katex'



function TableExpTTS (props) {

  function infoTTS() {
    return (
      <div>
      The geometric factor (k) is used to plot the imaginary part of the modulus (Mimg):
      <br />
      <br />
     <InlineMath math="M_{img} = \omega Z_{real} C_{0}"/>
     <br />
     <br />
     where <InlineMath math="\omega"/> is the angular frequency, <InlineMath math="C_{0} = \frac{\epsilon_{0}}{k}"/> is the vacuum capacitance, where <InlineMath math="\epsilon_{0}"/> is the vacuum permitivity and  <InlineMath math="k"/> is the geometric factor <InlineMath math="k= \frac{l}{wt}"/>.

      </div>
    )
  }


  function info() {
  Modal.info({
    title: 'Geometric Factor',
    content: (
      <div>{infoTTS()}</div>
    ),
    onOk() {},
  });
}

return (
    <Icon onClick={info} type="question-circle" />
)
}

export default TableExpTTS
