import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import 'antd/dist/antd.css';

import {
  ReadOutlined,
  LineChartOutlined,
  LineHeightOutlined,
  ToolOutlined,
} from '@ant-design/icons';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class NavBarCoating extends Component {
  state = {
    current: this.props.current,
  }

  handleClick = (e) => {
    //console.log('click ', e);
    this.setState({
      current: e.key,
    });
  }
  render(){
    const appNavbar = (

      <Menu
        onClick={this.handleClick}
        selectedKeys={[this.state.current]}
        mode="horizontal"
      >
        <Menu.Item key="/coatingsmonograph">
          <Link to="/coatingsmonograph"><ReadOutlined />Interactive Monograph</Link>
        </Menu.Item>
        <Menu.Item key="/coatingsfit">
          <Link to="/coatingsfit"><LineChartOutlined />Coating Modelling</Link>
        </Menu.Item>
      {/*
       <Menu.Item key="/coatingtts">
           <Link to="/coatingTTS"><LineHeightOutlined />Coating TTS</Link>
       </Menu.Item>*/}
       <Menu.Item key="/coatingToolBox">
          <Link to="/coatingToolBox"><ToolOutlined />Coating Tool Box</Link>
        </Menu.Item>
      </Menu>
    );
    return(
      <div>
        {appNavbar}
      </div>
    );
  }
}

export default NavBarCoating
