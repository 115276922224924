import React from "react";

function Pores(prop) {
  const colorA = '#ff7f0e'
  const colorB= '#b4d4f3'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 100">
      <circle cx="30" cy="47" r="43"  fill={colorA}></circle>
      <circle cx="75" cy="34" r="18" fill={colorA}></circle>
      <circle cx="105" cy="27" r="24" fill={colorA}></circle>
      <circle cx="125" cy="67" r="14"  fill={colorA}></circle>
      <circle cx="150" cy="37" r="38"  fill={colorA}></circle>
      <circle cx="190" cy="18" r="13"  fill={colorA}></circle>
      <circle cx="198" cy="27" r="11" fill={colorA}></circle>
      <circle cx="220" cy="42" r="23"  fill={colorA}></circle>
      <circle cx="260" cy="32" r="28"  fill={colorA}></circle>
      <circle cx="295" cy="32" r="15"  fill={colorA}></circle>
      <circle cx="308" cy="32" r="14"  fill={colorA}></circle>

      <circle cx="30" cy="47" r="39"  fill={colorB}></circle>
      <circle cx="75" cy="34" r="14"  fill={colorB}></circle>
      <circle cx="105" cy="27" r="20"  fill={colorB}></circle>
      <circle cx="125" cy="67" r="9"  fill={colorB}></circle>
      <circle cx="150" cy="37" r="34"  fill={colorB}></circle>
      <circle cx="190" cy="18" r="9"  fill={colorB}></circle>
      <circle cx="198" cy="27" r="7"  fill={colorB}></circle>
      <circle cx="220" cy="42" r="19"  fill={colorB}></circle>
      <circle cx="260" cy="32" r="24"  fill={colorB}></circle>
      <circle cx="295" cy="32" r="11"  fill={colorB}></circle>
      <circle cx="308" cy="32" r="10"  fill={colorB}></circle>
      <path
         fill="none"
         stroke="#000"
         strokeLinejoin="bevel"
         strokeWidth="5"
         d="M0 32 h100l10-20 20 40 20-40 20 40 20-40 20 40 10-20h100"
       ></path>
    </svg>
  );
}

export default Pores;
