import React, { Component } from 'react';

class ParamRange extends Component {

  handleOnInput = (e) => {
    const val = e.target.value;
    this.props.updateParamData(this.props.id,Number(val));
    //console.log(this.props.id)
  }

  render() {
    const { id, minRange, maxRange, stepRange } = this.props
    const { type, valueSciTex } = this.props.paramObj
    const expVal = Number(valueSciTex.slice(5));
    const baseVal = Number(valueSciTex.slice(0, 4));

    return(
      <div className="centerContent">
        <input
          className="inputRange"
          type="range"
          min={minRange}
          max={maxRange}
          step={stepRange}
          value={type === "Constant" ? baseVal : expVal}
          onChange={this.handleOnInput}
           />
      </div>
    )
  }
}

export default ParamRange
