import React, { useState } from 'react'
import { Tabs, Button, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import DataFromUser from './dataFromUser' /// Needed props: type ('eis' or 'rheo')
import TableExpGeometry from './tableExpGeometry'

const { TabPane } = Tabs;

const PanelTab = (props) => {
  const [activeKey, setActiveKey] = useState('1');
  const [temp, setTemp] = useState(25);

  let panes = props.ttsData.data

  const onChange = activeKey => { // when clicking in the tab
    setActiveKey(activeKey)//updates the hook
    console.log('Selected the tab number ',activeKey)
  }
  const onEdit = activeKey => { // when clicking in the x on the tab
    panes.splice(activeKey-1, 1);
    panes.map((item,i)=>{ return item.key=i+1})
    setActiveKey('1')
    console.log('Removed the tab number ',activeKey)
    props.updateData('data', panes) //update the TTS data on the parent component
  }
  const onChangeTemp = (value) => {
    setTemp(value)
    console.log('The temperature input has changed to ',value,'ºC')
  }
  const addTemp = (e) => {
    e.preventDefault();//to prevent the reload of the page when pressed enter
    console.log("temperature is now ",temp,"ºC")
    const keynum = panes.length+1
    panes.push({
      temp:temp,
      expData:{},
      key: keynum,
      logcT:0
    })
    setActiveKey(keynum.toString())
    props.updateData('data', panes)//update the TTS data on the parent component
  }
  const addData = (item,data) => { //item to change with the new data
    panes[activeKey-1][item] = data; //changes the selected item from the object with the new data
    props.updateData('data', panes) //updates the parent state
    console.log('New data added for temperature ', panes[activeKey-1].temp,'ºC:')
  }
  const onChangeK = (value) => {
    //setTemp(value)
    props.updateData('geometryK', value)
    console.log('k input has changed to ',value)
  }
  let panesWithKey = panes // to include key values on the panes

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <form onSubmit={addTemp}>
          <InputNumber defaultValue={25} onChange={onChangeTemp} /> <ArrowLeftOutlined /> <Button onClick={addTemp}>ADD NEW TEMPERATURE</Button>
        </form>
          Geometric Factor (k): <InputNumber value={props.ttsData.geometryK} onChange={onChangeK} /> <TableExpGeometry />
        </div>
          <Tabs
            hideAdd
            onChange={onChange}
            tabPosition={panes.length > 4 ? "left" : "top"}
            activeKey={activeKey}
            type="editable-card"
            onEdit={onEdit}
          >
            {panesWithKey.map(pane => (
              <TabPane tab={pane.temp+' ºC'} key={pane.key}>
                <DataFromUser updateData = {addData} type={props.type}/>
              </TabPane>
            ))}
          </Tabs>
    </div>
  )
}

export default PanelTab
