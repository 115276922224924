import React from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import pinholeDetector from './pic/pinholeDetector.png';
import pocketStat2 from './pic/pocketstat2.gif';



import {
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
  SoundOutlined
} from '@ant-design/icons';

const traficsize = 1.5
const traficStyle = {
  margin:'40px',
  borderRadius: '15px',
  border: '1px solid #d9d9d9',
  backgroundColor: '#f7f7f7',
  padding: traficsize*10+'px',
  width: traficsize*60+'px',
  height: traficsize*140+'px'
}
const traficCircleGreen = {
  height: traficsize*30+'px',
  width: traficsize*30+'px',
  backgroundColor:  "green" ,
  borderRadius: '50%'
}
const traficCircleOrange = {
  height: traficsize*30+'px',
  width: traficsize*30+'px',
  backgroundColor:  "orange",
  borderRadius: '50%'
}
const traficCircleRed = {
  height: traficsize*30+'px',
  width: traficsize*30+'px',
  backgroundColor: "red",
  borderRadius: '50%'
}




function Automation(props) {

  const explanation = "ddddd"
  const description = ()=>{
    return (
      <div>
        <div>

        </div>
        <div>

        </div>
    </div>
  )
  }
  return (
    <div className ="centerDiv columnDiv"  >
      <div className="preH1">Automated solutions for the coating industry</div>
      <div className="preH2">Can we make EIS as simple as a Pinhole Detector?</div>
      <div className="rowDiv">
        <div className="centerDiv">
          <img src={pinholeDetector} alt="PosiTest® LPD, Low voltage Pinhole Detector" style={{height: 0.75*400+"px", width: 0.75*295+"px"}} />
        </div>

        <div className ="rowDiv">
          <div style={{height: '150px', margin:'50px',borderRadius: '15px',border: '1px solid green', padding: '15px', backgroundColor: '#f7f7f7',}}>
            <div style= {{margin: '5px'}} className="columnDiv centerDiv">
              <h1 style= {{color:'green'}}>no Beep</h1>
              <h1 style= {{color:'green'}}><CheckOutlined /></h1>
            </div>
          </div>

          <div style={{height: '150px', margin:'50px',borderRadius: '15px',border: '1px solid red',padding: '15px', backgroundColor: '#f7f7f7',}}>
            <div style= {{margin: '5px'}} className="columnDiv centerDiv">
              <h1 style= {{color:'red'}}><SoundOutlined /> Beep</h1>
              <h1 style= {{color:'red'}}> <CloseOutlined /></h1>
            </div>
          </div>
        </div>

      </div>
      <Divider />
      <div className="rowDiv">
        <div className="centerDiv">
          <img src={pocketStat2} alt="PocketStat2" style={{height: "300px", width: "295px"}}/>

        </div>

        <div>
        <div className="preIniUl">Requirements:</div>
          <ul className="ulPresentation">
            <li>Fast visual information</li>
            <li>No need of circuit modelling</li>
            <li>Relevant parameters to quantify barrier performance</li>
            <Link to="/coatingTool">http://sinmode.com/coatingTool</Link>
          </ul>

        </div>

          <div style={traficStyle} className='centerDiv'>
           <div style={traficCircleGreen}></div>
           <div style={traficCircleOrange}></div>
           <div style={traficCircleRed}></div>
          </div>
        </div>




      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"
        />**/}
    </div>
    )
}

export default Automation
