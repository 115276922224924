import React, {useState} from 'react'
import { InlineMath } from 'react-katex'
import { LoadingOutlined } from '@ant-design/icons'


import { Button } from 'antd';
import PlotModuleError from './plotError'



function DataFitter (props){
  const {expData, expDataClean, model, modelData } = props.sinData

  /** => Para tener la lista de parámetros
  const param = model.param
  const paramList = Object.keys(param).map(key => {
    return (

      <tr key={key+"_trFit"}>

        <td key={key+"_td1Fit"}>
          <InlineMath>{param[key].subTypeAbr + "_{" + key[1] + "} ="}</InlineMath>
        </td>
        <td key={key+"_td2Fit"} className="centerContent">
        {param[key].valueSciTex+ " "} <InlineMath>{param[key].units}</InlineMath>
        </td>
      </tr>

    )
  })
  **/

  return (
    <div>
      {/**
      <table>
        <tbody>
          {paramList}
        </tbody>
      </table>
      **/}
      <div className = "centerDiv">
        <PlotModuleError size={340} expData={expData} modelData={modelData}/>
      </div>
    </div>
  )
}

export default DataFitter
