import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Link} from 'react-router-dom'

import "./App.css"
import { Layout, Button, Icon } from 'antd'

import SFTec from './powered/sftec'

// panels for modules
//import NavBar from './Common/componentsHTM/navBar'
import NavBarCoating from './Common/componentsHTM/navBars/navBarCoatings'
import NavBarTop from './Common/componentsHTM/navBars/navBarTop'
//import pages/sections
import CircuitPic from './Common/images/circuit'
import FitToolEisExpert from './FitToolEisExpert'
import FitToolEis from './FitToolEis'
import FitToolRheo from './FitToolRheo'
import AMmanufact from './Monographs/rheo/AMmonograph'

import Coatings from './Monographs/eis/Coatings'
import CoatingToolBox from './Monographs/eis/coatings/coatingToolBox/toolBox'
import CoatingFit from './CoatingFit'
import CoatingTool from './coatingTool'
//import CoatingTTS from './Monographs/eis/coatings/coatingTTS/coatingTTS'
import FitToolTTS from './ttsTool'

import About from './About'
import PresentationNvvt from './presentations/nvvt/Presentation'
import PresentationFitPro from './presentations/fitPro/Presentation'

import { createBrowserHistory } from "history";

const history = createBrowserHistory()

const { Footer, Content } = Layout

class App extends Component {
  state = {
    current: "home",
    language: "en"
  }

  // Function that update the sinData object in the state of the App
    updateData = (data) => { //item to change with the new data
      let current = this.state.current; //gets a copy of the initial value
      current = data; //changes the selected item from the object with the new data
      this.setState({
        current: current
      })//updates the state
    }
    changeLang = (lang) => {
      let language = this.state.language; //gets a copy of the initial value
      language = lang; //changes the selected item from the object with the new data
      this.setState({
        language: lang
      })//updates the state
    }


  render() {
console.log(history.location.pathname)
const langMenu = (

  <div className='rowDiv' style={{margin:"0px",marginRight: '20px'}}>
    <div className='rightDiv' style ={{margin: "5px", marginBottom: '0px'}}>
      <a onClick={()=>{this.changeLang("en")}}><span style={this.state.language === "en" ? {textDecoration: "underline"}:{textDecoration: "none"}}>en</span></a>
    </div>
    <div className='rightDiv' style ={{margin: "5px", marginBottom: '0px'}}>
      <a onClick={()=>{this.changeLang("es")}}><span style={this.state.language === "es" ? {textDecoration: "underline"}:{textDecoration: "none"}}>es</span></a>
    </div>
  </div>

)


    return (
      <BrowserRouter>
        <Layout>
        <div style={{margin:"5px"}}></div>
        {/** Language menu
          <div className='rightDiv'>{langMenu}</div>
        **/}
          {(history.location.pathname =="/nvvt" || history.location.pathname =="/fitpro") ? "" : <NavBarTop pathChange={this.updateData} changeLang={this.changeLang} lang={this.state.language}/>}
        <Content> 
          <Switch>
            <Route path='/3Dprinting' component={AMmanufact} />

            {/* ----------------Protective Coatings--------------------------------*/}
            {/* Monograph */}
            <Route path='/coatingsmonograph' component={Coatings} />
            <Route path='/ideal_intact_coating' render={(props) => <Coatings {...props} current={1} lang={this.state.language}/>} />
            <Route path='/intact_coating' render={(props) => <Coatings {...props} current={2} lang={this.state.language}/>} />
            <Route path='/coating_diffusion' render={(props) => <Coatings {...props} current={3} lang={this.state.language}/>} />
            <Route path='/standard_coating' render={(props) => <Coatings {...props} current={4} lang={this.state.language}/>} />
            <Route path='/damaged_coating' render={(props) => <Coatings {...props} current={5} lang={this.state.language}/>} />

            {/* Coating ToolBox */}
            <Route path='/coatingToolBox' component={CoatingToolBox} />
            <Route path='/coating_thickness' render={(props) => <CoatingToolBox {...props} tool={2} />} />
            {/**/}<Route path='/epoxy_selector' render={(props) => <CoatingToolBox {...props} tool={3} />} />

            {/*<Route path='/coatingTTS' component={CoatingTTS} />*/}
            <Route path='/coatingsfit' component={CoatingFit} />
            <Route path='/eisTTS' component={FitToolTTS} />
            <Route path='/coatingTool' component={CoatingTool} />
            {/*<Route path='/rheoTTS' component={FitToolTTS} />*/}
            <Route path='/nvvt' component={PresentationNvvt}  />
            <Route path='/fitpro' component={PresentationFitPro}  />

            <Route path='/eisexpert' component={FitToolEisExpert} />
            <Route path='/eis' component={FitToolEis} />
            <Route path='/rheo' component={FitToolRheo} />
            <Route path='/about' component={About} />
            <Route path='/home' component={CircuitPic} />
            <Route path='/' component={CoatingFit} />
          </Switch>
          </Content>
          <SFTec />
          <Footer>
            <div className="centerDiv"><a href="mailto:info@sinmode.com">info@sinmode.com</a></div>
          </Footer>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
