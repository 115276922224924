import React, { Component, lazy, Suspense } from 'react'
import {Helmet} from 'react-helmet'
import {
  LoadingOutlined
} from '@ant-design/icons';

import { Divider, Radio } from 'antd';


// panels for modules
import PanelModule from './Common/componentsHTM/panelModule'
import SFTec from './powered/sftec'

//components
//import CoatingInput from './Common/componentsHTM/coatingInput'
import DataFromUser from './Common/componentsHTM/dataFromUser'
import PlotModule from './Common/componentsHTM/plotPC'
import ParamPanel from './Common/componentsHTM/param/paramPanel'

import DataFitter from './Common/componentsHTM/dataFitter'

import NavBarCoating from './Common/componentsHTM/navBars/navBarCoatings'
//rules
import eisRules from './Common/sinTools/rules/eisRules'

import { coatingData } from './Monographs/eis/coatings/inmersionData/data'

const CoatingInput = lazy(()=> import('./Common/componentsHTM/coatingInput'))

class FitTool extends Component {
  screen = window.innerWidth
  state = {
    sinData:{
      rules: eisRules, //this.props.location.pathname == "/eis" ? eisRules : rheoRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
          }
        }
      },
    }
  };
// Function that update the sinData object in the state of the App
  updateData = (item,data) => { //item to change with the new data
    let sinData = this.state.sinData; //gets a copy of the initial sinData object
    sinData[item] = data; //changes the selected item from the object with the new data
    this.setState({
      sinData: sinData
    })//updates the state
  }

  handleExampleChange = (e)=>{
    const example = e.target.value
    console.log(e.target.value)
    this.updateData('expData',coatingData[example]["expData"])
     
  }


  render() {
    const { expData, modelData, rules } =  this.state.sinData

    // to adjust the plot size to screen width
  //  const screen = window.innerWidth
    const plotWidth = this.screen> 700 ? 650 : this.screen-25
    console.log("model data:", modelData)
    return (
      <div>
      <Helmet>
        <title>Protective Coatings Fitting Tool</title>
        <meta name="description" content="EIS modelling tool for protective coatings" />
        <meta name="keywords" content="EIS, coatings, protective, corrosion, Impedance, modelling, randles" />
      </Helmet>
      <div style ={{textAlign:'center'}} ><NavBarCoating current='/coatingsfit'/></div>
      <div className = "columnDiv">
        <div className = "centerDiv"> <h1>Protective Coatings Fitting Tool</h1></div>
        <div className = "centerDiv">
          <div className= "rowDivCol">
            <div className = "columnDiv">
              <div className = "centerDiv">

                <PlotModule size={plotWidth} expData={expData} modelData={modelData} rules={rules}/>


              </div>
              <div className = "centerDiv">

                <ParamPanel sinData={this.state.sinData} updateData = {this.updateData} />

              </div>
            </div>
            <div className = "columnDiv">
              <div className = "centerDiv">
                <PanelModule id="panel-1" title="Select Model" content={
                  <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
                    <CoatingInput type = {1} sinData ={this.state.sinData} updateData = {this.updateData}/>
                  </Suspense>
                } />
              </div>
              <div className = "centerDiv">
                <PanelModule id="panel-2" title="Insert Data" content={
                  <div>
                    <DataFromUser updateData = {this.updateData} type='eis'/>
                    <Divider />
                      <h2>Sample Data</h2>
                      <Radio.Group value={this.state.sinData.example} onChange={this.handleExampleChange}>
                        <Radio.Button value={0}>Example 1</Radio.Button>
                        <Radio.Button value={1}>Example 2</Radio.Button>
                        <Radio.Button value={3}>Example 3</Radio.Button>
                      </Radio.Group>
                    
                  </div>
                  

                } />
              </div>
              <div className = "centerDiv">
                 <PanelModule title="Fit Quality" content={<DataFitter sinData={this.state.sinData} updateData={this.updateData} type='eis'/>} />
               </div>

            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default FitTool;
