import React from 'react';
import { InputNumber } from 'antd';

const NumImput = (props) => {

    const { valueSciTex, type, maxRange, minRange } = props.paramObj;
    const {id, updateParamData } = props;
    let baseVal = Number(valueSciTex.slice(0, 4));
    let expVal = Number(valueSciTex.slice(5));

    const OnCahngeNormal = val => {
      updateParamData(id,Number(val));
    }

    const OnCahngeBase = val => {
      baseVal = val;
      const result = Number(val)*Math.pow(10,Number(expVal));
      val = Math.log10(result);
      updateParamData(id,val);
    }

    const OnCahngeExp = val => {
      expVal = val;
      const result = Number(baseVal)*Math.pow(10,Number(val));
      val = Math.log10(result);
      updateParamData(id,val);
    }


    return( type === "Constant" ? (
      <div>
        <InputNumber
          step={0.001}
          precision={4}
          min={minRange}
          max={maxRange}
          value={valueSciTex}
          style={{ width: 72 }}
          onChange={OnCahngeNormal}
        />
      </div>) : (
      <div>
        <InputNumber
          step={0.1}
          precision={3}
          min={0.01}
          max={9.99}
          value={baseVal}
          style={{ width: 65 }}
          onChange={OnCahngeBase} />·10<sup>
          <InputNumber
            size="small"
            step={1}
            min={minRange}
            max={maxRange}
            value={expVal}
            style={{ width: 52 }}
            onChange={OnCahngeExp}
             />
            </sup>
      </div>)
    )
}



export default NumImput
