
export const varNameEIS = {
  fRad: '\u03C9 (rad/s)',
  fHz:  'Frequency (Hz)',
  Mmod: 'Zmod',
  Mreal: 'Zreal',
  Mimg:  'Zimg',
  Mphz:  'Zphz',
  tanDelta: 'tan  \u03B4',
  nimg: 'Cimg',
  nreal: 'Creal',
  nmod: 'C'
}

export const varNameRheo = {
  fRad: '\u03C9 (rad/s)',
  fHz: 'Frequency (Hz)',
  Mmod: 'G*',
  Mreal: "G'",
  Mimg: "G''",
  Mphz: '\u03B4º',
  tanDelta: 'tan  \u03B4',
  nimg: "\u03B7'",
  nreal: "\u03B7''",
  nmod: "\u03B7*"
}
