import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Radio, Checkbox, Slider } from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';

import {linearReg} from '../math/operations/index';

// descomentar para incluir los distintos tipos de visualizado frente a T
//const RadioGroup = Radio.Group;
const RadioGroup = Radio.Group;
const Plot = lazy(() => import('react-plotly.js'));

const dataForGraph = (ttsData, tempRef) => {
  // get the Ea, logcT and Temperature for each analized sample
  let aH = []
  let logcT = []
  let temp = []
  for (let i=0; i< ttsData.length; i++){
      aH.push(ttsData[i].aH)
      logcT.push(ttsData[i].logcT)
      temp.push(ttsData[i].temp)
  }

  const tempText = temp.map(temp => temp+' ºC')
  const tempK = temp.map(temp => temp+273,15)
  const tempInv = temp.map(temp => 1/(temp+273.15))
  const tempInvR = tempInv.map(tempInv => -tempInv/(2.303*8.314472))
  const tempArrh = tempRef ? temp.map(temp => (-1/(2.303*8.314472))*((1/(temp+273.15)) - (1/(tempRef+275.15)))) : 0
  //const regresion = linearReg(logcT,tempInvR)

  const result = {
    aH:aH,
    logcT:logcT,
    temp: temp,
    tempK: tempK,
    tempInv: tempInv,
    tempInvR: tempInvR,
    tempArrh: tempArrh,
    tempText: tempText,
    //regresion: regresion
  }
//console.log("properties=",result)

  return result
}

class PlotModule extends Component {
  state = {
    activeAxis: "T",
    slope1: [],
    line2:false,
    slope2: []
  }

  onChangeTime = (e) => {
    this.setState({
      realTime: e.target.checked
    })//updates the state
  }

  onChangeAxis = (e) => {
   this.setState({
     activeAxis: e.target.value,
   });
 }

 onChangeSlope1 = (e) => {
   this.setState({
     slope1: e
   })//updates the state
 }
 onChangeSlope2 = (e) => {
   this.setState({
     slope2: e
   })//updates the state
 }

 regresion = (slope,dataY,dataX) =>{
   return linearReg(dataY.slice(slope[0]-1,slope[1]),dataX.slice(slope[0]-1,slope[1]))
 }

 onChangeAddSlope = (e) => {
   this.setState({
     line2: e.target.checked
   })//updates the state
 }

    render() {
      // make a selector to select which plot to makes
      //console.log("propertiesGraph props:",this.props)
      const { ttsData, size, refTemp } = this.props;
      const { slope1, slope2 } = this.state
    //  console.log('Temperatura de referencia', refTemp)
      const referenceTemp = typeof refTemp === 'undefined' ?  1 : refTemp
      const graphWidth = size
      const graphHeigth = graphWidth/1.4

      const data = dataForGraph(ttsData,ttsData[referenceTemp-1].temp)

      const regresion1 = this.regresion(slope1,data.logcT,data.tempInv)
      const eaSlope1 = -regresion1.slope*2.303*8.314472/1000
      const eaError1 = regresion1.r2

      const regresion2 = this.regresion(slope2,data.logcT,data.tempInv)
      const eaSlope2 = -regresion2.slope*2.303*8.314472/1000
      const eaError2 = regresion2.r2


      //const eaSlope = data.regresion.slope/1000
      //const eaIntercept = data.regresion.intercept*2.303*8.314472*(273.15+ttsData[referenceTemp-1].temp)/1000
      //const eaError = data.regresion.r2

      const xAxis = this.state.activeAxis === "T" ? data.tempK :  data.tempInv
      const xAxisText = this.state.activeAxis === "T" ? "T (ºK)" : "1/T (ºK<sup>-1</sup>)"

      const line1 = data.tempInv.map(point => regresion1.slope*point + regresion1.intercept )
      const line2 = data.tempInv.map(point => regresion2.slope*point + regresion2.intercept )

      //for slope analysis
      const maxPoints = data.temp.length




        return (
          <div className = "columnDiv">
            <div className = "centerDiv">
              <RadioGroup onChange={this.onChangeAxis}>
                <Radio key="T" value="T" checked={"T" === this.state.activeAxis}>T</Radio>
                <Radio key="1/T" value="1/T" checked={"1/T" === this.state.activeAxis}>1/T</Radio>
              </RadioGroup>
            </div>
            <div className = "centerDiv">
              <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
              <Plot
                config={{displayModeBar: false}}
                data={[
                  {
                    x: xAxis,
                    y: data.logcT,
                    name: "",
                    hovertemplate: data.tempText,
                    mode: 'markers',
                    marker: {
                      size: 10
                    }
                  },
                  {
                    x:  xAxis,
                    y: "1/T" === this.state.activeAxis ? line1: [],
                    name: "",
                    hovertemplate: "Regresion 1",
                    mode: 'lines',

                    line: {
                      dash: 'dashdot',
                      color:'rgb(128,128,128)'
                    }
                  },
                  {
                    x:  xAxis,
                    y: "1/T" === this.state.activeAxis && this.state.line2 ? line2: [],
                    name: "",
                    hovertemplate: "Regresion 2",
                    mode: 'lines',

                    line: {
                      dash: 'dashdot',
                      color:'rgb(128,128,128)'
                    }
                  },

                  ]}
                  layout = {
                    {
                        margin: {b: 75,t: 10,l:50,r:50},
                        showlegend: false,
                        autosize: false,
                        width: graphWidth,
                        height: graphHeigth,


                        xaxis: {

                          title: xAxisText,
                          mirror: 'ticks',
                          showgrid: false,
                          linewidth: 2,
                          ticks: 'inside',
                        },
                        yaxis: {

                          title: {
                            "text":"log(a<sub>T</sub>)",
                            "font": {"color": '#1f77b4'}
                          },
                          mirror: 'ticks',
                          showgrid: false,
                          linewidth: 2,
                          ticks: 'inside',
                          //range: [4, data.maxZ01Range]

                        }
                      }
                  }

      />
              </Suspense>
            </div>
            {(maxPoints > 1 && "1/T" === this.state.activeAxis)? (
              <div className="columnDiv">
                <div style={{marginLeft:"70px", marginRight:"70px"}}>
                  <Slider
                     range
                     step={1}
                     max={maxPoints}
                     min={1}
                     defaultValue={[1,maxPoints]}
                     reverse
                     onChange={this.onChangeSlope1}
                     onAfterChange={this.onChangeSlope1}
                   />
                </div>
                <div className="centerDiv">E<sub>a</sub> = {eaSlope1.toFixed(3)} KJ/mol ({(eaSlope1/96.485).toFixed(3)} eV)</div>
                {/**<div className="centerDiv">E<sub>a</sub> = {eaIntercept.toFixed(3)} KJ/mol ({(eaIntercept/96.485).toFixed(3)} eV)</div>**/}
                <div className="centerDiv"><i>r<sup> 2</sup>={eaError1 .toFixed(4)}</i></div>
                <div className="centerDiv"><Checkbox checked={this.state.line2} onChange={this.onChangeAddSlope}>Add second slope</Checkbox></div>
                {this.state.line2 ? (
                  <div className="columnDiv">
                    <div style={{marginLeft:"70px", marginRight:"70px"}}>
                      <Slider
                         range
                         step={1}
                         max={maxPoints}
                         min={1}
                         defaultValue={[1,maxPoints]}
                         reverse
                         onChange={this.onChangeSlope2}
                         onAfterChange={this.onChangeSlope2}
                       />
                    </div>
                    <div className="centerDiv">E<sub>a</sub> = {eaSlope2.toFixed(3)} KJ/mol ({(eaSlope2/96.485).toFixed(3)} eV)</div>
                    {/**<div className="centerDiv">E<sub>a</sub> = {eaIntercept.toFixed(3)} KJ/mol ({(eaIntercept/96.485).toFixed(3)} eV)</div>**/}
                    <div className="centerDiv"><i>r<sup> 2</sup>={eaError2 .toFixed(4)}</i></div>
                    <div className="centerDiv"><b>Crossover = {((1/((regresion2.intercept - regresion1.intercept)/(regresion1.slope-regresion2.slope)))-273.15).toFixed(2)} ºC</b></div>
                  </div>
                ):""}
              </div>
            ): ""}


          </div>
        );
    }
}

export default PlotModule;
