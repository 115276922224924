/**
 * Get extra data variables
 *
 */

export const extendData = data => {
  console.log("executed extend data ")
    // extra extended data
    data.fRad = [];
    data.Mmod = [];
    data.Mphz = [];
    data.tanDelta = [];
    data.nimg = [];
    data.nreal = [];
    data.nmod = [];
    data.cimg = [];
    data.creal = [];
  //  data.cmod = [];

    for (let i = 0; i < data.fHz.length; i++) {
        data.fRad.push(data.fHz[i]*2*Math.PI);
        data.Mmod.push(Math.sqrt(data.Mreal[i]**2+data.Mimg[i]**2));
        data.tanDelta.push(data.Mimg[i]/data.Mreal[i]);
        data.Mphz.push(Math.atan(data.Mimg[i]/data.Mreal[i])*180/Math.PI);
        data.nimg.push(data.Mreal[i]/(data.fRad[i]));
        data.nreal.push(data.Mimg[i]/(data.fRad[i]));
        data.nmod.push(data.Mmod[i]/data.fRad[i]);
        data.creal.push(-data.Mimg[i]/(data.fRad[i]*(data.Mreal[i]**2+data.Mimg[i]**2)));
        data.cimg.push(-data.Mreal[i]/(data.fRad[i]*(data.Mreal[i]**2+data.Mimg[i]**2)));
        //data.nmod.push(Math.sqrt(data.nreal[i]**2+data.nimg[i]**2));
    }

    return data;
}
