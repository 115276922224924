import React from "react";

function W() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="30" >
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="2"
        d="M 0 15 h22"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="2"
        d="M 19 8 l 5 14  5-10 5 10 5-14"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="2"
        d="M 36 15 h22"
      ></path>
    </svg>
  );
}

export default W;
