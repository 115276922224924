import { spacesOut, strToArray, parseStr } from '../../utils/string';

import { create, all } from 'mathjs'
const config = { }
const math = create(all, config)


/* Takes the array of elements (smiles) and an object with specific element rules (modelRules) to return an object
*  with parameters object (to be used to hold parameter data numbers) and an array with equation
*  strings for each element.
* */
const smilesToEqs = (smilesArray, modelRules) => {
  const elementCounter = {}; // first we define counters for model possible elements
  const param = {}; // create the parameters object
  const eqsArray = smilesArray.map((smile) => {
    if (modelRules[smile]) {
      elementCounter[smile] = elementCounter[smile] ? elementCounter[smile] + 1 : 1;
      const smileParams = modelRules[smile].parameters.map(a => a + elementCounter[smile]);
      smileParams.forEach(smileParam => param[smileParam] = modelRules.defVals(smileParam));
      const funLocalArg = [...smileParams, ...modelRules[smile].extraVar];
      return modelRules[smile].fun(...funLocalArg);
    }
    return smile;
  });

  return {
    eq: eqsArray,
    param,
  };
};


/* 4 - Takes a array of equations and evaluates the parallel positions to return the result
* of the operation and replace the two equations for the resulting one.
*/

const parallelCalc = (eqArr, modelRules) => { // recursion???
  for (let i = 0; i < eqArr.length - 1; i++) {
    if (eqArr[i] !== '-' && eqArr[i] !== '(' && eqArr[i] !== ')' && eqArr[i + 1] !== '-' && eqArr[i + 1] !== '(' && eqArr[i + 1] !== ')') { // revise!!!
      eqArr[i] = modelRules.parallel(eqArr[i], eqArr[i + 1]);
      eqArr.splice(i + 1, 1);
      i--;
    }
  }
  return eqArr;
};

/* 5 - Takes a array of ecuations and evaluates the serial positions to return the result
** of the operation and replace the two equations for the resulting one.
*/
const serialCalc = (eqArr, modelRules) => {
  for (let i = 0; i < eqArr.length - 1; i++) {
    if (eqArr[i] === '-') {
      eqArr[i - 1] = modelRules.serial(eqArr[i - 1], eqArr[i + 1]);
      eqArr.splice(i, 2);
      i--;
    }
  }
  return eqArr;
};

/* 6 - Finds and resolve equations inside parenthesis. Once done, replace the parenthesis for the
*  result of the operation. That use parallelCalc and serialCalc to solve the parenthesis.
*/
const solveParenthesis = (eqArr, modelRules) => {
  let parentStart;
  let parentEnd;
  for (let i = 0; i < eqArr.length; i++) {
    if (eqArr[i] === '(') {
      parentStart = i;
    }
    if (eqArr[i] === ')') {
      parentEnd = i;
      break;
    }
  }
  if (typeof parentStart !== 'undefined') {
    const newSection = eqArr.slice(parentStart + 1, parentEnd);
    const newSecResult = parallelCalc(serialCalc(newSection, modelRules), modelRules);
    eqArr[parentStart] = newSecResult[0];
    eqArr.splice(parentStart + 1, parentEnd - parentStart);
  }
  return eqArr;
};


export const getModel = (str, modelRules) => {
  const model = smilesToEqs(strToArray(str), modelRules); // returns model = {eq: , param: }
  model.smiles = str;// model = {eq: , param: , smiles: }
  // equation on a string to show to user
  model.eq = parallelCalc(model.eq, modelRules);
  while (model.eq.find(a => a === '(')) {
    model.eq = solveParenthesis(model.eq, modelRules);
    model.eq = parallelCalc(model.eq, modelRules);
  }
  model.eq = serialCalc(model.eq, modelRules)[0]; // model = {eq: , param: , smiles: , eq }
  model.paramList = Object.keys(model.param);// model = {eq: , param: , smiles: , eq: , elementList: }
  // equation on a string in LaTeX mode for KaTeX library
  model.eqTeX =  modelRules.parseLaTeX(model.eq);
  // transform the model equation into a function with param and w arguments that returns a complex object
  let eqT = spacesOut(parseStr(model.eq, model.paramList, 'model.param.', '.valueSci'));
  eqT = spacesOut(parseStr(eqT, ['w'], '',''));
  model.eqT = `'${eqT}'`;
  return model;
};

/**
 * Convert model into data
 */

//frequency generator

export const fHzGen = (min,max,ptDec) => {
    const fHz = [];
    const minLog = Math.log(min)/Math.log(10);
    const maxLog = Math.log(max)/Math.log(10);
    for (let f = minLog; f < (maxLog); f = (f + 1/ptDec)) {
        fHz.push(Math.pow(10,f));
    }
    return fHz;
}
export const getModelData = (model,fRad) => {
    const data = {
        fHz: fRad.map(f => f/(2*Math.PI)),
        Mreal: [],
        Mimg: [],
    }
    for (let freq in fRad) {
        let w = fRad[freq];
        let M = math.eval(eval(model.eqT));
        typeof M.re !== 'undefined'? data.Mreal.push(M.re) : data.Mreal.push(M);
        typeof M.im !== 'undefined' ? data.Mimg.push(M.im) : data.Mimg.push(0);
    }
    return data
}




//export const complexEval = (obj, w, eqT) => math.eval(eval(eqT));
