/*
General layout
 */

 export const dataHz_CrealCimgTTS = (ttsData, varNames) => {// todo finalize this func and test to plot it
   console.log(ttsData)
   const buildHzVsMrealObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
      const colorST = (data.temp<360) ? 360-data.temp : 360-(data.temp-360)
     const colorTemp = data.aE ? 'rgb(0,0,0)' : 'hsl('+colorST+', 100%, 50%)'
     const trace = {
       x: data.expData.fHz ? data.expData.fHz.map(x => x * Math.pow(10,-data.logcT)) : [],
       y: data.expData.creal ? data.expData.creal : [],
       mode: 'markers',
       marker: {color: colorTemp}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       name: varNames.Mreal+ ' '+data.temp+'ºC'
     }
     return trace
   }

   const buildHzVsMimgObj = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorST = (data.temp<360) ? 360-data.temp : 360-(data.temp-360)
     const colorTemp = data.aE ? 'rgb(150,150,150)' : 'hsl('+colorST+', 100%, 80%)'
     const trace = {
       x: data.expData.fHz ? data.expData.fHz.map(x => x * Math.pow(10,-data.logcT)) : [],
       y: data.expData.cimg ? data.expData.cimg.map(item => -item) : [],
       mode: 'markers',
       marker: {color: colorTemp}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       yaxis: 'y2',
       name: varNames.Mimg + ' '+data.temp+'ºC'
     }
     return trace
    }

    let dataArray1 = ttsData.map(temp =>{return buildHzVsMrealObj(temp)}) //gives a array of Hz Vs Mreal data objects
    let dataArray2 = ttsData.map(temp =>{return buildHzVsMimgObj(temp)}) //gives a array of Hz Vs Mimg data objects

    const data = dataArray1.concat(dataArray2)

    return data
 }

 export const layoutHz_CrealCimgTTS = (ttsData, varNames) =>{


   const maxHzArray = ttsData.map(temp =>{return temp.expData.fHz ? Math.max(...temp.expData.fHz.map(x => x * Math.pow(10,-ttsData.logcT))) : 100000}) // gives  a array with the maximum Hz value of each temperature
   const minHzArray = ttsData.map(temp =>{return temp.expData.fHz ? Math.min(...temp.expData.fHz.map(x => x * Math.pow(10,-ttsData.logcT))) : 0.01})// gives  a array with the minimum Hz value of each temperature

   const xMax = Math.max(...maxHzArray) // gives the maximum Hz of all temperatures
   const xMin = Math.min(...minHzArray) // gives the minimum Hz of all temperatures

   const xMaxLog = Math.log(xMax)/Math.log(10);
   const xMinLog = Math.log(xMin)/Math.log(10);

   const xMaxLogCeil = Math.ceil(xMaxLog);
   const xMinLogFloor = Math.floor(xMinLog);


//-------------------------------------------------------------------

   const maxMrealArray = ttsData.map(temp =>{return temp.expData.Mreal ? Math.max(...temp.expData.Mreal.map(x => x * Math.pow(10,ttsData.logcT))) : 1000}) // gives  a array with the maximum Mreal value of each temperature
   const minMrealArray = ttsData.map(temp =>{return temp.expData.Mreal ? Math.min(...temp.expData.Mreal.map(x => x * Math.pow(10,ttsData.logcT))) : 0})// gives  a array with the minimum Mreal value of each temperature

   const yMax = Math.max(...maxMrealArray) // gives the maximum Mreal of all temperatures
   const yMin = Math.min(...minMrealArray) // gives the minimum Mreal of all temperatures

   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

//---------------------------------------------------------------------

  const maxMimgArray = ttsData.map(temp =>{return temp.expData.Mimg ? Math.max(...temp.expData.Mimg) : 1000}) // gives  a array with the maximum Mimg value of each temperature
  const minMimgArray = ttsData.map(temp =>{return temp.expData.Mimg ? Math.min(...temp.expData.Mimg) : 0})// gives  a array with the minimum Mimg value of each temperature

  const y2Max = Math.max(...maxMimgArray) // gives the maximum Mimg of all temperatures
  const y2Min = Math.min(...minMimgArray) // gives the minimum Mimg of all temperatures

  const y2MaxLog = Math.log(y2Max)/Math.log(10);
  const y2MinLog = Math.log(y2Min)/Math.log(10);

  const y2MaxLogCeil = Math.ceil(y2MaxLog);
  const y2MinLogFloor = Math.floor(y2MinLog);

  const yMaxTotal = (y2MaxLogCeil > yMaxLogCeil) ? y2MaxLogCeil : yMaxLogCeil
  const yMinTotal = (y2MinLogFloor < yMinLogFloor) ? y2MinLogFloor : yMinLogFloor

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: true,
       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         ////fixedrange: true,
         title: {
           "text":varNames.Mreal,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[yMinTotal, yMaxTotal]
       },
       yaxis2: {
         //fixedrange: true,
 				title: {
          "text": varNames.Mmod === 'Zmod' ? ("-"+varNames.Mimg) : varNames.Mimg,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2e',
        linewidth: 2,
        type: 'log',
        exponentformat: 'power',
 				dtick:'1',
 				ticks: 'inside',
 				overlaying: 'y',
 				side: 'right',
        range:[yMinTotal, yMaxTotal]
       }
       }
   };
