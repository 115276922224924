import React, { Component } from 'react'
import { InlineMath } from 'react-katex'
import {Helmet} from 'react-helmet'

import { Radio, Button, Divider } from 'antd';
import { ReadOutlined} from '@ant-design/icons';

// panels for modules
import PanelTab from './Common/componentsHTM/panelTabCoating' /// Needed props: type ('eis' or 'rheo' for DataFromUser)
import PanelModule from './Common/componentsHTM/panelModule' /// Needed props: title and content
import PlotModuleSpectrum from './Common/componentsHTM/plotCoating'
import PlotModuleProperties from './Common/componentsHTM/plotCoatingProperties'

import {getModel} from './Common/sinTools/algorithms/getModelFromStr';


//rules
import eisRules from './Common/sinTools/rules/eisRules'
import {coatingData} from './Monographs/eis/coatings/inmersionData/data'


import InfoHowTo from './Common/componentsHTM/alerts/modalHowTo.js'

class CoatingTool extends Component {
  screen = window.innerWidth
  state = {
    showGraph: "spectrum",
    sinData:{
      rules: eisRules,//this.props.location.pathname == "/eistts" ? eisRules : rheoRules,
      modelData: {},
      expData: {},
      coatingData: {
        avgEa:0,
        refTemp:1,
        data: [],
        dataSource: ""
      },
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
          }
        }
      },
    }
  };


  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData.coatingData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }


    useGlassData = () => {
      for (var i = 0; i < coatingData.length; i++) { //
        coatingData[i].key= i+1
        coatingData[i].logcT= 0
        coatingData[i].aH= 0
      }

      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData.coatingData.data = coatingData //changes the selected item from the object with the new data
    //  sinData.coatingData.dataSource = this.glassDataSource()
      this.setState({
        sinData: sinData
      })//updates the state
    }


    componentDidMount(){
      let sinData = this.state.sinData
      sinData.coatingData.data[0] = {
                                      time:0,
                                      expData:{},
                                      modelData:{},
                                      explanation:[],
                                      z01: 0,
                                      key: 1,
                                      model: getModel('R-QWR', sinData.rules),
                                      analysis: false
                                    }
      this.setState({
        sinData: sinData
      })
    }
    // to change the graph between spectrum and properties (Z01 and Water uptake)
    handleTypeChange = (e) => {
          this.setState({ showGraph: e.target.value });
        }

    render() {
      const { modelData, rules, coatingData} =  this.state.sinData
      const showGraph = this.state.showGraph
      console.log(this.state.sinData)

      // to adjust the plot size to screen width
      const plotWidth = this.screen> 700 ? 650 : this.screen-25

      return (
        <div>
          <Helmet>
            <title>Automated coating characterization using EIS Data</title>
            <meta name="description" content="Automated coating characterization to obtain coating resistance and water uptake" />
            <meta name="keywords" content="EIS, circuit, Impedance, coating, corrosion, resistasnce, water uptake, permeability" />
            <meta name="og:title" property="og:title" content="SINMODE - Automated coating characterization using EIS Data" />
            <meta property="og:url" content="http://sinmode.com/coatingtool" />
            <meta property="og:image" content="http://sinmode.com/sinmode.png" />
            <meta property="og:description" content="Automated coating characterization to obtain coating resistance and water uptake" />
          </Helmet>
          <div className = "columnDiv">
            <div className = "centerDiv">
              <span style={{color: 'red', marginRight:5, marginLeft:5}}>This section is <u>UNDER CONSTRUCTION</u>!!</span>
            </div>
            <div className = "centerDiv">
              <div className="rowDiv">
                <h1>Automated coating characterization using EIS Data</h1>
                {/** **/}<h1 style= {{color:'#1890ff', marginLeft: '5px'}}><InfoHowTo title='Automated coating characterization using EIS Data' content={
                  <div>
                  <p>Electrochemical Impedance Spectroscopy (EIS) is a potent technique for the characterization of protective coatings. Fitting EIS data to an equivalent circuit can provide information like barrier performance (based on coating resistance) and water uptake (based on coating capacitance).</p>

                   <p>But the complexity of EIS data interpretation restricts its use to experienced users with advanced knowledge on both electrochemistry and coating technology, not always easy to find or train. It is also a waste of skilled personnel time to interpret high number of data produced by actual multichanel devices and online monitoring systems. Even though the prices of potentiostat had become really affordable since the past decade, this knowledge related restriction presents a real roadblock to upgrade formulation labs and inspection procedures with this technology. </p>
                   <p>Here we propose a tailor-made algorithm that provides all relevant data to characterize protective coatings, without the need of circuit selection and with a comprehensive outcome explaining coating state and giving useful data to quantify the results.</p>
                   {/**
                     <p>You can check this video for visual guidance:</p>
                     <a target="_blank" href="https://youtu.be/OEBUHFZROu0">https://youtu.be/OEBUHFZROu0</a>
                   **/}
                  </div>
                }/></h1>
              </div>
            </div>
            <div className = "centerDiv">
              <div className= "rowDivCol">
                <div >
                  <div className="centerDiv" style={{marginTop:"15px", marginBottom:"15px"}}>
                    <Radio.Group value={showGraph} onChange={this.handleTypeChange}>
                      <Radio.Button value='spectrum'>Impedance Spectrum</Radio.Button>
                      <Radio.Button value='properties'>Barrier Performance / Water Uptake</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div style={showGraph === 'spectrum' ? {display: 'block'} : {display: 'none'}}>
                    <PlotModuleSpectrum size={plotWidth} coatingData={coatingData.data} refTemp={coatingData.tempRef} modelData={modelData} rules={rules}/>
                  </div>
                  <div style={showGraph === 'properties' ? {display: 'block'} : {display: 'none'}}>
                    <PlotModuleProperties size={plotWidth} coatingData={coatingData.data} refTemp={coatingData.tempRef} modelData={modelData} rules={rules}/>
                  </div>
                    <div style={{maxWidth:"650px"}}>
                    {coatingData.dataSource}
                      {coatingData.data.map(data => {
                        return (
                          <div key={"info_"+data.key}>
                            <b>{data.analysis ? ("Information for inmersion time: "+data.time+" h") : ""}</b>
                            {data.explanation.map((point,index)=>{
                              return (
                                <div className="columnDiv"  key={"textInfo_"+data.key+"_"+data.time+"_"+index}>
                                  <div> {point} </div>
                                </div>)
                            })}
                          </div>
                          )
                      })}


                   </div>
                </div>
                <div >
                  <div className= "columnDiv">
                    <div className = "centerDiv">
                      <PanelModule  title="Experimental Data " id="panel-1" content={
                        <div>
                        <PanelTab  type={rules.type} rules={rules} timeData={coatingData} updateData = {this.updateData}/>
                        <Divider />
                        <h2>Sample Data</h2>
                         <Button onClick={this.useGlassData}><ReadOutlined />Coating Example</Button>

                        </div>
                      } />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}
export default CoatingTool
