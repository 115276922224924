import React, { Component, lazy, Suspense } from 'react'
import {Helmet} from 'react-helmet'

//import IntroCoating from './coatings/intro.js'
//import IdealIntactCoating from './coatings/idealIntactCoating.js'
//import IntactCoating from './coatings/intactCoating.js'
//import DiffusionCoating from './coatings/diffusion.js'
//import StandardCoating from './coatings/standardCoating.js'
//import DamagedCoating from './coatings/damagedCoating.js'

import NavBarCoating from '../../Common/componentsHTM/navBars/navBarCoatings'


import { Steps, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'

const IntroCoating = lazy(()=>import('./coatings/intro.js'))
const IdealIntactCoating = lazy(()=>import('./coatings/idealIntactCoating.js'))
const IntactCoating = lazy(()=>import('./coatings/intactCoating.js'))
const DiffusionCoating = lazy(()=>import('./coatings/diffusion.js'))
const StandardCoating = lazy(()=>import('./coatings/standardCoating.js'))
const DamagedCoating = lazy(()=>import('./coatings/damagedCoating.js'))

const { Step } = Steps;

class Coatings extends Component {
  screen = window.innerWidth
  state = {
    current:this.props.current ? this.props.current : 0
  }

  updateCurrent = (current,loc) => {
   this.setState({ current });
   this.props.history.push(loc)
   window.scrollTo(0, 0);
  }

  render() {
    const fallback= <div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>
    const content = [
      (<Suspense fallback={fallback}><IntroCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>),
      (<Suspense fallback={fallback}><IdealIntactCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>),
      (<Suspense fallback={fallback}><IntactCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>),
      (<Suspense fallback={fallback}><DiffusionCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>),
      (<Suspense fallback={fallback}><StandardCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>),
      (<Suspense fallback={fallback}><DamagedCoating updateCurrent={this.updateCurrent} lang={this.props.lang}/></Suspense>)
    ];

    return (
      <div>
      <Helmet>
        <title>Protective Coatings Monograph</title>
        <meta name="description" content="Interactive monograph for protective coating modellig" />
        <meta name="keywords" content="EIS, circuit, Impedance, modelling, coating, corrosion" />
      </Helmet>
      <div style ={{textAlign:'center'}} ><NavBarCoating current='/coatingsmonograph'/></div>
      <div className = "centerDiv columnDiv">
        <div className = "columnDiv">
          <div className= "centerDiv" style={{margin: '10px'}}><h1>Interactive monograph for Protective Coatings </h1></div>
          <div className = "rowDivCol">
            <div style={{margin: '10px'}}>
              <Steps direction="vertical" current={this.state.current} onChange={this.updateCurrent}>
                <Step title="Introduction" />
                <Step title="R-C" description="Ideal Intact Coating"/>
                <Step title="R-Q"  description="Intact Coating (CPE)"/>
                <Step title="R-QW" description="Coating Difusion (Wc)" />
                <Step title="R-QR" description="Standard Coating" />
                <Step title="R-Q(R-QR)" description="Damaged Coating" />
              </Steps>
            </div>
            <div> {content[this.state.current]}</div>
          </div>
          <Divider />
          <div style={{margin: '10px'}}>
            <Steps current={this.state.current} size='small' onChange={this.updateCurrent}>
              <Step title="Introduction" />
              <Step title="R-C" description="Ideal Intact Coating" />
              <Step title="R-Q" description="Intact Coating (CPE)" />
              <Step title="R-QW" description="Coating Difusion (Wc)" />
              <Step title="R-QR" description="Standard Coating" />
              <Step title="R-Q(R-QR)" description="Damaged Coating" />
            </Steps>
          </div>
        </div>
      </div>

      </div>
    )
  }
}


export default Coatings
