import React from "react";

function Relect(prop) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 50">
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="5"
        d="M0 20 h100l10-20 20 40 20-40 20 40 20-40 20 40 10-20h100"
      ></path>
    </svg>
  );
}

export default Relect;
