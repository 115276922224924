import React from 'react'
import logoSFtec from './logoSFtec2.png'

function SFTec(){
  return (
    <div className="centerDiv" style={{marginTop:'25px'}}><p style={{marginTop: '19px', fontSize:"15px"}}>Powered by</p><a style={{marginLeft: '5px'}} target="_blank" href="https://www.sftec.es/"><img  width={114/1.2+'px'} height={30/1.2+'px'} src={logoSFtec} alt="SFtec" /></a></div>
  )
}

export default SFTec
