/**
 * This presents the specific rules for rheological models
 */

import { facSum, simpleSum } from '../../math/operations';
import { replaceAll } from '../../utils/string';
// general plots

import {dataHz_MmodMphz, layoutHz_MmodMphz} from "../plots/plotHz_MmodMphz"
import {dataHz_nmodMphz, layoutHz_nmodMphz} from "../plots/plotHz_nmodMphz"
import {dataHz_MrealMimg, layoutHz_MrealMimg} from "../plots/plotHz_MrealMimg"
import {dataMreal_Mimg, layoutMreal_Mimg} from "../plots/plotMreal_Mimg"

import {dataHz_MmodMphzTTS, layoutHz_MmodMphzTTS} from "../plots/tts/plotHz_MmodMphzTTS"
import {dataHz_nmodMphzTTS, layoutHz_nmodMphzTTS} from "../plots/tts/plotHz_nmodMphzTTS"
import {dataHz_MrealMimgTTS, layoutHz_MrealMimgTTS} from "../plots/tts/plotHz_MrealMimgTTS"
import {dataMreal_MimgTTS, layoutMreal_MimgTTS} from "../plots/tts/plotMreal_MimgTTS"

import { varNameRheo } from './variables';

export default {
  type: 'rheo',
  S: {
    name: 'Spring',
    parameters: ['E'],
    extraVar: [],
    fun: E => E,
  },
  D: {
    name: 'Dashpot',
    parameters: ['n'],
    extraVar: ['w'],
    fun: (n, w) => `${n}*${w}*i`,
  },
  P: {
    name: 'Parabolic Dashpot',
    parameters: ['p', 'a'],
    extraVar: ['w'],
    fun: (p, a, w) => `${p}*(${w}*i)^${a}`,
  },
  L: {
    name: 'Sliper',
    parameters: ['L'],
    extraVar: ['w'],
    fun: (L, w) => `${L}*(${w}*i)^${1/2}`,
  },
  defVals: (str) =>{
    let val ={}
    switch(str[0]) {
      case 'E':
        val = {
          type:"Spring",
          valueSci:200,
          valueSciTex:"2.00e+2",
          valueStep: 1,
          subType:"Generic Spring (modulus)",
          subTypeAbr:"E",
          units:"(Pa)",
          maxRange: 15,
          minRange: -3,
          stepRange:0.05
        };
        break;
      case 'n':
        val = {
          type:"Dashpot",
          valueSci:10000,
          valueSciTex:"1.00e+4",
          alueStep: 1,
          subType:"Generic Dashpot (viscosity)",
          subTypeAbr:"\\eta",
          units:"(Pa.s)",
          maxRange: 15,
          minRange: -3,
          stepRange:0.05
        };
        break;
      case 'p':
        val = {
          type:"Parabolic Dashpot",
          valueSci:10000,
          valueSciTex:"1.00e+4",
          valueStep: 1,
          subType:"Parabolic Dashpot",
          subTypeAbr:"p",
          units:"(Pa.s^{\\alpha})",
          maxRange: 15,
          minRange: -3,
          stepRange:0.05
        };
        break;
      case 'a':
        val = {
          type:"Constant",
          valueSci:1,
          valueStep:1,
          valueSciTex:"1.000",
          subType:"Surface Roughness",
          subTypeAbr:"\\alpha",
          units:"",
          maxRange: 1,
          minRange: 0.5,
          stepRange: 0.01
        };
        break;
        case 'L':
          val = {
            type:"Slider",
            valueSci:10000,
            valueSciTex:"1.00e+4",
            valueStep: 2,
            subType:"Sliding element",
            subTypeAbr:"L",
            units:"(Pa.s^{1/2})",
            maxRange: 15,
            minRange: -3,
            stepRange:0.05
          };
        break;
      default:
        console.log("Add smile string");
      }
    return val;
  },
  validCharacters: ['S','D','P','L','(',')','-'],
  serial: facSum,
  parallel: simpleSum,
  parseLaTeX : str => {
    let texStr = replaceAll(str,"1/(","\\frac{1}{",0); // add fractions
    texStr = replaceAll(texStr,")","}",0);// close fractions
    texStr = replaceAll(texStr,"(w*i}^a","(\\omega i)^{a}",1);//
    texStr = replaceAll(texStr,"(w*i}^0.5","(\\omega i)^{0.5}",0);//
    texStr = replaceAll(texStr,"a}_{","\\alpha",0);
    texStr = replaceAll(texStr,"E","E",1);// add E sing
    texStr = replaceAll(texStr,"p","p",1);// add p sing
    texStr = replaceAll(texStr,"L","L",1);// add L sing
    texStr = replaceAll(texStr,"n","\\eta",1);// add eta sign
    texStr = replaceAll(texStr,"w","\\omega ",0); // add omega sign
    texStr = replaceAll(texStr,"*","",0);// remove *
    return texStr
  },
  modulusTeX: 'G^{*}',
  predefinedMod : {
      default: "S-D"
  },
  varNames: varNameRheo,
  plots: [
    {
      name: 'Master Curve',
      layout: layoutHz_MmodMphz,
      plotData: dataHz_MmodMphz
    },
    {
      name: 'Hz Vs \u03B7*/\u03B4º',
      layout: layoutHz_nmodMphz,
      plotData: dataHz_nmodMphz
    },
    {
      name: "G'/G''",
      layout: layoutHz_MrealMimg,
      plotData: dataHz_MrealMimg
    },
    {
      name: 'Cole-Cole',
      layout: layoutMreal_Mimg,
      plotData: dataMreal_Mimg
    }
  ],
  plotsTTS: [
    {
      name: 'Master Curve',
      layout: layoutHz_MmodMphzTTS,
      plotData: dataHz_MmodMphzTTS
    },
    {
      name: 'Hz Vs \u03B7*/\u03B4º',
      layout: layoutHz_nmodMphzTTS,
      plotData: dataHz_nmodMphzTTS
    },
    {
      name: "G'/G''",
      layout: layoutHz_MrealMimgTTS,
      plotData: dataHz_MrealMimgTTS
    },
    {
      name: 'Cole-Cole',
      layout: layoutMreal_MimgTTS,
      plotData: dataMreal_MimgTTS
    }
  ]
};
