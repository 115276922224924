import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Radio, Slider } from 'antd'

import {
  LoadingOutlined
} from '@ant-design/icons';

const RadioGroup = Radio.Group;

const Plot = lazy(() => import('react-plotly.js'));

class PlotModule extends Component {
  state = {
    name: 'Bode',
    dataFunc: this.props.rules.plotsPC[0].plotData,
    layout: this.props.rules.plotsPC[0].layout,
    rangeX: this.props.expert? [-2,6] : null,
    rangeY: [1,12],//this.props.expert? [-1,12] : null,
    rangeY2: this.props.expert? [-100,0]: null,
  }
  onChange = (e) => {
   const plotObj = e.target.value;
   console.log({plotObj})
   this.setState({
     name: plotObj.name,
     dataFunc: plotObj.plotData,
     layout: plotObj.layout,
   });
 }
 onChangeRange = (value) => {


}

 onAfterChangeRangeX =(value) =>{
   //console.log('onAfterChange: ', value);
   this.setState({
     rangeX: value
   })//u
}
onAfterChangeRangeY =(value) =>{
  //console.log('onAfterChange: ', value);
  this.setState({
    rangeY: value
  })//u
}
onAfterChangeRangeY2 =(value) =>{
  //console.log('onAfterChange: ', value);
  this.setState({
    rangeY2: value
  })//u
}
    render() {
      // make a selector to select which plot to makes
      const { expData, modelData, size } = this.props;
      const rangeXbase = [-2,6]
      const rangeYbase = [1,10]
      const rangeY2base = [-100,0]

      const { rangeX, rangeY, rangeY2 } = this.state

      const { plotsPC } = this.props.rules;
      
      const { varNames } = this.props.rules;
      const plotList = plotsPC.map(item => {
        return (<Radio key={item.name} value={item} checked={item.name === this.state.name}>{item.name}</Radio>)
      });

        return (
          <div className = "columnDiv">
            <div className = "centerDiv">
              <RadioGroup onChange={this.onChange}>
                {plotList}
              </RadioGroup>
            </div>
            <div className="centerDiv">
              <div className="rowDiv">
              {this.props.expert ? (
                <div style={{marginBottom:90}}>
                  <Slider
                    vertical
                    range
                    step={1}
                    max={rangeYbase[1]}
                    min={rangeYbase[0]}
                    defaultValue={rangeYbase}
                    onChange={this.onChangeRange}
                    onAfterChange={this.onAfterChangeRangeY}
                   />
                </div>) : ''
              }
                <Suspense fallback={<div style={{width:'650px', height:'464px', textAlign:'center', paddingTop: '200px'}}> <LoadingOutlined /> Loading Plot...</div>}>
                  <Plot
                      data={ this.state.dataFunc(expData, modelData, varNames) }
                      layout={ this.state.layout(expData, modelData, varNames, size, rangeX, rangeY, rangeY2) }
                      config={{ displaylogo: false, modeBarButtonsToRemove: ['sendDataToCloud','editInChartStudio','zoom2d','pan2d','select2d','lasso2d','zoomIn2d','zoomOut2d','autoScale2d','resetScale2d','hoverClosestCartesian','drawclosedpath','toggleSpikelines','hoverCompareCartesian']}}
                      useResizeHandler= {true}
                  />
                </Suspense>
                {this.props.expert ? (
                  <div style={{marginBottom:90}}>
                    <Slider
                      vertical
                      reverse
                      range
                      step={1}
                      max={rangeY2base[1]}
                      min={rangeY2base[0]}
                      defaultValue={rangeY2base}
                      onChange={this.onChangeRange}
                      onAfterChange={this.onAfterChangeRangeY2}
                     />
                  </div>) : ''
                }
              </div>
            </div>
              {this.props.expert ? (
                <div style={{marginRight:90, marginLeft:90}}>
                  <Slider
                     range
                     step={0.5}
                     max={rangeXbase[1]}
                     min={rangeXbase[0]}
                     defaultValue={rangeXbase}
                     onChange={this.onChangeRange}
                     onAfterChange={this.onAfterChangeRangeX}
                   />
                </div>) : ''
              }
           </div>

        );
    }
}

export default PlotModule;
