import React from 'react';
import { Collapse } from 'antd';

const PanelModule = (props) => {
  const Panel = Collapse.Panel;
  const panelCollapse ={
    minWidth: '358px',
    margin: '2px'
  }
  return (
    <div style={panelCollapse}>
      <Collapse defaultActiveKey={props.id} >
        <Panel header={props.title} key={props.id}>
          {props.content}
        </Panel>
      </Collapse>
    </div>
  )
}

export default PanelModule
