/*
Information for the nyquist plot
 */

// Plot Nyquist

export const dataMreal_MimgCoating = (arrayData, varNames) => {// todo finalize this func and test to plot it
  const color = (data,opacity) => {
    const colorST = (data.time<360) ? 230-data.time : 230-(data.time-360)
    const greyTone = (opacity === '50%') ? '0%' : '50%'
    const colorTime = (data.time === 0) ? 'hsl(0, 0%, '+greyTone+')' : 'hsl('+colorST+', 100%, '+opacity+')' //'rgb(31, '+data.temp*2.55+', 180)'
    return colorTime
  }
  const buildMrealVsMimgObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
    const colorTime = color(data,'50%')
    const dataX = data.expData.Mreal ? data.expData.Mreal : []
    const dataY = data.expData.Mimg ? data.expData.Mimg : []
    const trace = {
      x: dataX,
      y: dataY,
      mode: 'markers',
      marker: {color: colorTime}, // ToDo: conditional if is the ref temperature rgb(31, 119, 180)
      type: 'scatter',
      name: data.temp+'ºC'
    }
    return trace
  }

  const buildMrealVsMimgObjModel = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
    const colorTime = color(data,'50%')
    const dataX = []//data.modelData.Mreal ? data.modelData.Mreal : []
    const dataY = []//data.modelData.Mimg ? data.modelData.Mimg : []
    const trace = {
      x: dataX,
      y: dataY,
      mode: 'lines',
      line: {shape: 'spline', color: colorTime}, // ToDo: conditional if is the ref temperature rgb(31, 119, 180)
      type: 'scatter',
      name: data.temp+'ºC'
    }
    return trace
  }

  const dataArray1 = arrayData.map(time =>{return buildMrealVsMimgObj(time)})
  const dataArray2 = arrayData.map(time =>{return buildMrealVsMimgObjModel(time)})

  const data = dataArray1.concat(dataArray2)

  return data
}

export const layoutMreal_MimgCoating= (arrayData, varNames) =>{
  const maxMrealArray = arrayData.map(time =>{return time.expData.Mreal ? Math.max(...time.expData.Mreal) : 1000}) // gives  a array with the maximum Mreal value of each temperature
  //const minMrealArray = ttsData.data.map(temp =>{return temp.expData.Mreal ? Math.min(...temp.expData.Mreal) : 0})// gives  a array with the minimum Mreal value of each temperature

  const xMax = Math.max(...maxMrealArray) // gives the maximum Mreal of all temperatures
  //const xMin = 0

  //-------------------------------------------------------------------------------
  const maxMimgArray = arrayData.map(time =>{return time.expData.Mimg ? Math.min(...time.expData.Mimg) : 1000}) // gives  a array with the maximum Mimg value of each temperature

  const yMax = -Math.min(...maxMimgArray) // gives the maximum Mimg of all temperatures
  console.log('yMax: ',yMax)
  const yMaxCeil = Math.ceil(yMax)
  const xMaxCeil = Math.ceil(xMax)


  const maxTotal = (xMaxCeil > yMaxCeil) ? xMaxCeil : yMaxCeil

//  const MmaxY = (-MmaxX/10).toFixed(0)*10
  return{
        margin: {b: 75,t: 10,l:50,r:20},
        showlegend: false,
        autosize: true,

        xaxis: {
          //fixedrange: true,
            title: varNames.Mreal,
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            linewidth: 2,
            constrain: 'domain',
            range:[0, maxTotal]
        },
        yaxis: {
          //fixedrange: true,
            title: {
              "text": varNames.Mimg,
              "font": {"color": '#1f77b4'}
            },
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            scaleanchor: 'x',
            autorange: varNames.Mmod === 'Zmod' ? 'reversed' : '',
            linewidth: 2,
            range: varNames.Mmod === 'Zmod' ? [0, -maxTotal] : [0, Math.abs(maxTotal)]
        }
      }
  };


    //Plotly.newPlot('plotCont', dataNy, layoutNy,{displayModeBar: false});
