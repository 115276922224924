import React from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import PlotSin from '../../Common/componentsHTM/plotSin'


function Intro(props) {

  const explanation = ()=>{
    return (<div>Find this presentation at <Link to="/nvvt">http://sinmode.com/nvvt</Link></div>)
  }
  const description = ""

  return (
    <div className ="centerDiv columnDiv"  >

      <div className ="centerDiv columnDiv" ></div>
      <div className ="centerDiv columnDiv" >
      <div className="preH1" style={{marginTop:"50px"}} >A practical approach to Electrochemical Impedance Spectroscopy (EIS) data</div>
      <div className="ulPresentation">Alejandro Gutierrez, <a target="_blank" href="https://www.sftec.es"> Survey and Foresee Technologies</a></div>

      <div style={{marginTop:"100px", marginBottom:"50px"}}>
      <div className="preIniUl">Contents:</div>
        <ul className="ulPresentation">
          <li>EIS for protective coatings</li>
          <li>Why EIS data is so difficult to interpret</li>
          <li>EIS and Rheology, from waves to equivalent elements</li>
          <li>Current fitting software solutions</li>
          <li>Understanding the physical meaning of equivalent circuits</li>
          <li>Automated solutions for the coating industry</li>
        </ul>
      </div>


    </div>

      <Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation()}
        description={description}
        type="info"

        />
    </div>



  )
}

export default Intro
