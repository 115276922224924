import React, { useState } from 'react'
import { Modal, Button } from 'antd';

import {
  InfoCircleOutlined
} from '@ant-design/icons';


function InfoHowTo(props) {
  const [visible , setVisible] = useState(false)

  const showModal = ( ) => {
    setVisible(true)
  }

  const handleOk = e => {
    console.log(e)
    setVisible(false)
  }

  return(
    <div>
      <InfoCircleOutlined onClick={showModal} />
      <Modal
        title={props.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
            <Button key="OK" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
      >
      {props.content}
        </Modal>
    </div>

  )
}

export default InfoHowTo
