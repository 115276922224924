import errorNLS from './fittingError/nls'
const area = (w,h) => { return w*h }
function areaSimpson(coordArray) {

  var x = coordArray,
      a = 0;

  // Must have even number of elements
  if (x.length % 2) { x = x.push(x[x.length-1])};

  // Process pairs, increment by 2 and stop at length - 2
  for (var i=0, iLen=x.length-2; i<iLen; i+=2) {
     a += x[i]*x[i+3] - x[i+2]*x[i+1];
  }
  return Math.abs(a/2);
}

const areaTotal = (xArray,yArray) => { //returns the total area of one data set
  let totalArea = 0
  for (let i = 0; i < xArray.length-1; i++) {
    let singleArea = area(Math.abs(xArray[i]-xArray[1+i]),yArray[i])
    totalArea = totalArea + singleArea
  }
  return totalArea
}

const areaTotal2 = (yArray) => { //returns the total area of one data set
  let totalArea = 0
  for (let i = 0; i < yArray.length; i++) {
    totalArea = totalArea + yArray[i]
  }
  return totalArea
}

const checkAreas = (refArea, area) => {
  return refArea - area
}


const log10 = num => { return Math.log(num) / Math.log(10)}



export const ttsFit = (ref, toFit, range) => { 
  // takes two set of data and returns the log shift factor
  // creates the working object taking the Hz and Minmg data and transforms it to log

      const dataPre = {
        logHzRef: ref.fHz.map(item => {return log10(item)}),
        logHz: toFit.fHz.map(item => {return log10(item)}),
        logZiRef: ref.Mimg.map(item => {return Math.abs(item)}),
        logZi: toFit.Mimg.map(item => {return Math.abs(item)}),
        logZrRef: ref.Mreal.map(item => {return Math.abs(item)}),
        logZr: toFit.Mreal.map(item => {return Math.abs(item)}),
        logZphzRef: ref.Mphz.map(item => {return Math.abs(item)}),
        logZphz: toFit.Mphz.map(item => {return Math.abs(item)})
      }

      let logHzRef = []
      let logHz = []
      let logZiRef = []
      let logZi = []

      for (let i = 0; i < dataPre.logHzRef.length; i++ ){
        if (dataPre.logHzRef[i] > range[0] && dataPre.logHzRef[i] < range[1] ) {
          logHzRef.push(dataPre.logHzRef[i])
          logZiRef.push(dataPre.logZiRef[i])
          logHz.push(dataPre.logHz[i])
          logZi.push(dataPre.logZi[i])
        }
      }
      const data = {
        logHzRef: logHzRef,
        logHz: logHz,
        logZiRef: logZiRef,
        logZi: logZi,
        logZrRef: ref.Mreal.map(item => {return Math.abs(item)}),
        logZr: toFit.Mreal.map(item => {return Math.abs(item)}),
        logZphzRef: ref.Mphz.map(item => {return Math.abs(item)}),
        logZphz: toFit.Mphz.map(item => {return Math.abs(item)})
      }


// adjust the size of the arrays and gives adjusted arrays to work with the loop
     const adjustSize = (data) => {


     let minHighHz = Math.max(...data.logHzRef) >=  Math.max(...data.logHz) ? Math.max(...data.logHz) : Math.max(...data.logHzRef) //gets the minimim high frequency

     let maxLowHz = Math.min(...data.logHzRef) <=  Math.min(...data.logHz) ? Math.min(...data.logHz) : Math.min(...data.logHzRef) //gets the minimim high frequency

    let logHzRefAd = []
    let logHzAd = []
    let logZiRefAd = []
    let logZiAd = []
    let logZrRefAd = []
    let logZrAd = []
    let logZphzRefAd = []
    let logZphzAd = []
    for (let i = 0; i < data.logHzRef.length; i++ ){
      if (data.logHzRef[i] <= minHighHz) {
        logHzRefAd.push(data.logHzRef[i])
        logZiRefAd.push(data.logZiRef[i])
        logZrRefAd.push(data.logZrRef[i])
        logZphzRefAd.push(data.logZphzRef[i])
      }
    }

    for (let i = 0; i < data.logHz.length; i++ ){
      if (data.logHz[i] <= minHighHz) {
        logHzAd.push(data.logHz[i])
        logZiAd.push(data.logZi[i])
        logZrAd.push(data.logZr[i])
        logZphzAd.push(data.logZphz[i])
      }
    }


    let logHzRefAd2 = []
    let logHzAd2 = []
    let logZiRefAd2 = []
    let logZiAd2 = []
    let logZrRefAd2 = []
    let logZrAd2 = []
    let logZphzRefAd2 = []
    let logZphzAd2 = []
    for (let i = 0; i < logHzRefAd.length; i++ ){
      if (logHzRefAd[i] >= maxLowHz) {
        logHzRefAd2.push(logHzRefAd[i])
        logZiRefAd2.push(logZiRefAd[i])
        logZrRefAd2.push(logZrRefAd[i])
        logZphzRefAd2.push(logZphzRefAd[i])
      }
    }

    for (let i = 0; i < logHzAd.length; i++ ){
      if (logHzAd[i] >= maxLowHz) {
        logHzAd2.push(logHzAd[i])
        logZiAd2.push(logZiAd[i])
        logZrAd2.push(logZrAd[i])
        logZphzAd2.push(logZphzAd[i])
      }
    }



     const dataAdjusted = {
       logHzRef: logHzRefAd2,
       logHz: logHzAd2,
       logZiRef: logZiRefAd2,
       logZi: logZiAd2,
       logZrRef: logZrRefAd2,
       logZr: logZrAd2,
       logZphzRef: logZphzRefAd2,
       logZphz: logZphzAd2,
     }

     return dataAdjusted

   }

/// calculate areas
    const calcArea = (data)=> {
      const areaRef = areaTotal2(data.logZiRef)
      const areaToFit = areaTotal2(data.logZi)
  //  console.log('area ref = ', areaRef)
    //console.log('area to fit = ', areaToFit)
    //console.log('area erro i = ', areaRef-areaToFit)
    //console.log('area erro / = ', areaRef/areaToFit)
      return areaRef/areaToFit
    }

    const shift = (factor,data) => {
      const shiftedData = {
        logHzRef: data.logHzRef,
        logHz: data.logHz.map(item => {return -factor+item}),
        logZiRef: data.logZiRef,
        logZi: data.logZi.map(item => {return  Math.pow(10,factor)*item}),
        logZrRef: data.logZrRef,
        logZr: data.logZr.map(item => {return  Math.pow(10,factor)*item}),
        logZphzRef: data.logZphzRef,
        logZphz: data.logZphz.map(item => {return  Math.abs(item)}),
      }
      return shiftedData
    }
//logZi: data.logZi.map(item => {return Math.pow(10,factor)*item}),


    const fitTo =(factor,data) => { //retuns the fit
      return calcArea(adjustSize(shift(factor,data)))
    }

    const error = (factor, data) => {
      return 1-fitTo(factor,data)
    }


    let factor = 0
    let iter = 1
    if (error(factor,data) < 0){
      while (iter > 0.001){
        //console.log('Factor: ', factor, ', error: ',(error(factor,data).toFixed(3) ))
        //console.log('iteration :', iter)
        if (error(factor+iter,data) <= 0) {
            factor = factor + iter
        } else { iter = iter/2}
      }
      //console.log('Factor: ', factor, ', error: ',(error(factor,data).toFixed(3) ))
    } else if (error(factor,data) > 0){
      while (iter > 0.001){
        if (error(factor-iter,data) >= 0) {
            factor = factor - iter
        } else { iter = iter/2}
      }
    //  console.log('Factor: ', factor, ', error: ',(error(factor,data).toFixed(3) ))
    }


    return factor

}
