import React, {useState}  from 'react'
import { Button, Popover, Switch, Radio } from 'antd';
import { ReadOutlined, LineChartOutlined} from '@ant-design/icons';


const SampleDataContainer = (props) =>{
  const [showAbstract, setShowAbstract] = useState(false)
  const [showGraph, setShowGraph] = useState(1)

  const { active, list} =  props
  console.log(props)
  const data = list[active].data
  const ref = list[active].ref

  const abstractChange =(e)=> {setShowAbstract(e)}

  const autholList = ref.author.map((name)=> {
    return (<div key={name}>{name}</div>)
  })

  const pub = active >= 0 ? (
      <div style={{margin:'10px'}}>
        <b><u>Data source</u></b><br />
        <Popover content={autholList} title="List of authors"><u>{ref.author[0]} et al.</u></Popover>
        <i> "{ref.title}"</i>, {ref.journal}, {ref.year}, {ref.volume}, {ref.pages}.<br />
        DOI: <a target="_blank" href={ref.URL}>{ref.doi}</a><br />
        <Switch size="small" checkedChildren="Abstract" unCheckedChildren="Abstract" onChange={abstractChange}/>
        <p style={{textAlign: 'justify', marginTop:'5px'}}>{showAbstract ? ref.abstract : ""}</p>
      </div>
  ):""

  // to generate menu of data---------------------------------------------------------
  const handleTypeChange = (e)=>{
    setShowGraph(e.target.value)
    props.updateSampleData(active,e.target.value-1)
  }

  const dataMenu = data.length > 1 ? (
    <div className="centerDiv" style={{marginTop:'10px'}}>
      <Radio.Group size="small" value={showGraph} onChange={handleTypeChange} >
        {data.map((item,index)=>{ return (
          <Radio.Button key={index} value={index+1}>
            <LineChartOutlined /> {item.description}
          </Radio.Button>
        )
      })}
      </Radio.Group>
    </div>
  ):""

  return (
    <div className="columnDiv">
      {dataMenu}
      {pub}
    </div>
  )
}



export default SampleDataContainer
