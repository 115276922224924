import React from "react";

function Damage(prop) {
  const colorA = '#b4d4f3'
  const colorB = '#ff7f0e'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 300">
      <circle cx="300" cy="150" r="150"  fill={colorB} clipPath="url(#cut-off-bottom)"></circle>
      <circle cx="300" cy="150" r="148"  fill={colorA}></circle>

      <path
         fill="#aeb6bf"
         d="M350 10 V290 M350,290 C480,240 480,60 350,10"
       ></path>

       <g
         stroke={colorB}
         strokeWidth="3"
         >
         <path d="M0 210 L150 150"></path>
         <path d="M0 210 L20 220"></path>
         <path d="M0 210 L10 190"></path>
       </g>


       <g
         fill="none"
         stroke="#000"
         strokeLinejoin="bevel"
         strokeWidth="4"
         >
         <path d="M190 200 h50l10-20 20 40 20-40 20 40 20-40 20 40 10-20h50v-100"></path>
         <path d="M190 100 h90 M310 100 h102"></path>
         <path d="M280 135 V65 M310 135 V65"></path>

         <path d="M150 150 h40 M410 150 h40"></path>
         <path d="M190 200 V100"></path>

       </g>
    </svg>
  );
}

export default Damage;
