import React, { Component } from 'react'
import Relect from './Relect.js'
import Diffus from './DiffusW.js'
import Pores from './Pores.js'
import Conector from './conector.js'
import Damage from './Damage.js'

import { Icon } from 'antd'

function CircuitPic (props) {
  const orange = "#ff7f0e"

  const screenWide = window.innerWidth
  const drawSpaceW = screenWide> 800 ? 800 : screenWide-20
  const drawSpaceH = drawSpaceW*300/800
  const metal = {
    width: drawSpaceW/4,
    height: drawSpaceH,
    backgroundImage: "linear-gradient(-90deg, white,   #aeb6bf)"
  }

  const coating = {
    width: drawSpaceW/8,
    height: drawSpaceH,
    backgroundColor: "white",
    border: "2px solid #ff7f0e",

  }
  const roughness ={
    width: '1px',
    height: drawSpaceH,
    borderLeft: "2px dashed #ff7f0e",
  }

  const waterUptake = {
    width: drawSpaceW/8 - 4,
    height: drawSpaceH-4,
    backgroundImage: "linear-gradient(-90deg, white, rgb(24,144,255,0.3))"
  }

  const water = {
    width: drawSpaceW*5/8,
    height: drawSpaceH,
    background: 'radial-gradient(1.5em 6.28571em at 1.95em,rgba(255, 255, 255, 0) 50%,rgba(255, 255, 255, 0) 55%) 0 0,radial-gradient(1.5em 6.28571em at -0.45em, rgba(255, 255, 255, 0) 50%,rgba(255, 255, 255, 0) 55%) 1.5em 5.5em,radial-gradient(2.3em 4.57143em at 2.99em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 55%, rgba(255, 255, 255, 0) 55%) 0 0,radial-gradient(2.3em 4.57143em at -0.69em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 55%, rgba(255, 255, 255, 0) 55%) 2.3em 4em,radial-gradient(3.5em 6.28571em at 4.55em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 0 0,radial-gradient(3.5em 6.28571em at -1.05em, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0) 55%) 3.5em 5.5em, linear-gradient(-90deg, rgb(24,144,255,0.5), white)',
    backgroundColor: '#1890ff',
    backgroundSize: '1.5em 11em, 1.5em 11em, 2.3em 8em, 2.3em 8em, 3.5em 11em, 3.5em 11em, 100% 100%',
    backgroundRepeat: 'repeat',
    textAlign:'center'
  }

  const capacitorLine = {
    width: drawSpaceW/53,
    height: drawSpaceH*1/2,
    backgroundColor: "#000"
  }

  const resistance = {
    width: drawSpaceW*1/3,
    height: drawSpaceH*1/2//drawSpaceH*1/10,
  }

  const conector= {
    width: drawSpaceW*1/5,
    height: drawSpaceH*1/2//drawSpaceH*1/10,
  }



  const Diffusion = () =>{
    const ion = {
      color: '#ff7f0e',
    }

    const ions = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      zIndex:'1'
    }

    return (
      <div style= {{paddingTop: drawSpaceH*3/4}}>
        <div style={{position:'absolute', width: drawSpaceW/8 - 4, zIndex:'2'}}> <Diffus /></div>
        <div style={ions}>
          <div className="ion" style={ion}> <Icon type="minus-circle" /></div>
          <div className="ionB" style={ion}><Icon type="minus-circle" /></div>
          <div className="ionC" style={ion}><Icon type="minus-circle" /></div>
        </div>

      </div>
    )
  }

  const PoreRes = () =>{

    return (
      <div style= {{paddingTop: drawSpaceH*3/4}}>
        <div style={{position:'absolute', width: drawSpaceW/8 - 4, zIndex:'2'}}> <Pores /></div>
      </div>
    )
  }

  const Damaged = () =>{

    return (
      <div>
        <div style={{position:'absolute', width: drawSpaceW/4, zIndex:'2'}}> <Damage /></div>
      </div>
    )
  }

  return (
    <div className= "centerDiv rowDiv" style={{flexWrap: 'nowrap', marginTop: '15px', marginBottom: '15px'}}>
      <div style ={water} className= "rightDiv rowDiv" >
        <div style ={resistance} className= "centerDiv" ><Relect /></div>
        <div style ={capacitorLine} className= "centerDiv" ></div>
        {props.roughness ? <div style ={roughness} className= "centerDiv" ></div> : ''}
        <div style ={coating}>
          <div style ={waterUptake}>
          {props.diffusion ? <Diffusion /> : (props.pores ? <PoreRes /> : '')}
    
          <div style = {{position:'absolute', top:'300px'}}>{props.diffusionPores ? <PoreRes />  : ''}</div>
          </div>
        </div>
      </div>
      <div style ={metal} className= "leftDiv rowDiv">
        <div style ={capacitorLine} className= "centerDiv">{props.damaged ? <Damaged /> : ''}</div>
        <div style ={conector} className= "centerDiv" ><Conector /></div>
      </div>
    </div>
  )
}

export default CircuitPic
