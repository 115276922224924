
/**
 * Info: Functions to obtain parametres (Rc, Cc, alpha, Wc) at a certain frequency (w) knowing the impedance value (or angle).
 * Req: the frequency (w), the model (data.model, that contains all other parametres) and impedance data (Z or phz and Zimg)
 * zKnowingAngle (Zimg, phz) --> returns the value of Z at an specific phz
 * getRc(w, Z, model) --> returns values of Rc at a certain point
 * getWc(w, Z, model) -->
 *
 */
import { create, all } from 'mathjs'
import {secDegEq} from '../../../math/operations'
const config = { }
const math = create(all, config)


const zKnowingAngle = (Zimg, phz)=> {
  const a = Zimg/Math.tan(phz*Math.PI/180)
  return Math.sqrt(a*a + Zimg*Zimg)
}

export const getRcFormAngle = (w, phz, model) => {
  const angle = Math.tan(phz*Math.PI/180)
  const R1 = model.param.R1.valueSci
  const Q = model.param.Q1.valueSci
  const alpha = model.param.a1.valueSci
  const W = model.param.W1.valueSci

  const complexAlpha = math.eval(eval("'i^'+alpha"))
  const a = complexAlpha.re
  const b = complexAlpha.im
  const d = (W*math.sqrt(2*w)/2) + b*Q*math.pow(w,alpha)
  const e = (W*math.sqrt(2*w)/2) + a*Q*math.pow(w,alpha)

  
  // taking into acount R1
  const rFromc = (c) =>{return 1/(c-e)}
  const Rc = rFromc(secDegEq(R1,1,(d/angle)+R1*d*d)) //with R1

  //const Rc = angle/(-d-e*angle) //with no R1
  return Rc
}

export const getWcFormAngle = (w, phz, model) => {
  const angle = Math.tan(phz*Math.PI/180)
  const R1 = model.param.R1.valueSci
  const R2 = model.param.R2.valueSci
  const Q = model.param.Q1.valueSci
  const alpha = model.param.a1.valueSci

  const complexAlpha = math.eval(eval("'i^'+alpha"))
  const a = complexAlpha.re
  const b = complexAlpha.im

  //const Wc = (-b*Q*math.pow(w,alpha) - a*Q*math.pow(w,alpha)*angle - angle/R2)/((angle*math.sqrt(2*w)/2) + (math.sqrt(2*w)/2))


  // taking into acount R1

  const f= a*Q*math.pow(w,alpha)
  const g = math.sqrt(2*w)/2
  const h = b*Q*math.pow(w,alpha)

  const i = 2*R1*g*g
  const j = (2*g*R1/R2) + R1*2*f*g + R1*2*h*g + g + (g/angle)
  const k = (R1/(R2*R2)) + R1*f*f + (R1*f/R2) + R1*h*h + (1/R2) + f + (h/angle)
  const Wc = secDegEq(i,j,k)

  return Wc
}

export const getAngleAtFeq = (w, model)=>{
  const R1 = model.param.R1.valueSci
  const W = model.param.W1.valueSci
  const Q = model.param.Q1.valueSci
  const alpha = model.param.a1.valueSci
  const R2 = model.param.R2.valueSci

  const complexAlpha = math.eval(eval("'i^'+alpha"))
  const a = complexAlpha.re
  const b = complexAlpha.im
  const d = (W*math.sqrt(2*w)/2) + b*Q*math.pow(w,alpha)
  const c = (1/R2) + (W*math.sqrt(2*w)/2) + a*Q*math.pow(w,alpha)

  const angle= Math.atan(-d/(R1*c*c+R1*d*d+c))
  return angle*180/Math.PI

}
export const getQFromZ = (w, Z, model) => {
     const R1 = model.param.R1.valueSci
     const W = model.param.W1.valueSci
     const alpha = model.param.a1.valueSci
     const R2 = model.param.R2.valueSci

     const eqString = "'((('+Z+'-'+R1+')*'+R2+'*'+W+'*('+w+'i)^0.5)+'+Z+'-'+R1+'-'+R2+')/((1-'+Z+')*'+R2+'*('+w+'*i)^'+alpha+')'"
     const z = math.eval(eval(eqString))
     return math.sqrt(z.re*z.re + z.im*z.im)
    }
