import React, { Component, lazy, Suspense } from 'react'
import {Helmet} from 'react-helmet'
import { Alert, Pagination, Divider  } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'


import Intro from './0_Intro'
import WhyEIS from './1_WhyEis'
import SinBase from './2_Sinusoidal'
import Sin from './3_1sinusoidal'
import Spectrum from './3_2spectrum'
import Software from './4_Software'
import Monograph from './5_monograph'
import Automation from './6_automation'
import Conclusion from './7_conclusion'


const styleBox={
    justifyContent: 'space-between',
    margin: '10px',
    padding:'10px',
    backgroundColor: '#fafafa',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    position: 'fixed',
    right: 0,
    left: 0,
    zIndex: 100
  }



class PresentationNvvt extends Component {
  screen = window.innerWidth
  state = {
    current:this.props.current ? this.props.current : 1
  }

  updateCurrent = (page, pageSize) => {
    console.log('Page: ', page)
   this.setState({ current: page });
  // this.props.history.push(loc)
   window.scrollTo(0, 0);
  }



  render() {
    console.log(this.state.current)
    const fallback= <div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>
    const content = [
      (<Suspense fallback={fallback}><Intro /></Suspense>),
      (<Suspense fallback={fallback}><WhyEIS /></Suspense>),
      (<Suspense fallback={fallback}><SinBase /></Suspense>),
      (<Suspense fallback={fallback}><Sin /></Suspense>),
      (<Suspense fallback={fallback}><Spectrum /></Suspense>),
      (<Suspense fallback={fallback}><Software /></Suspense>),
      (<Suspense fallback={fallback}><Monograph /></Suspense>),
      (<Suspense fallback={fallback}><Automation /></Suspense>),
      (<Suspense fallback={fallback}><Conclusion /></Suspense>)
    ];

    return (
      <div>
        <Helmet>
          <title>Protective Coatings Monograph</title>
          <meta name="description" content="Interactive monograph for protective coating modellig" />
          <meta name="keywords" content="EIS, circuit, Impedance, modelling, coating, corrosion" />
        </Helmet>
        <div className='rowDivColMenu' style ={styleBox}>

            <div  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
              <div style={{paddingRight: '5px',  fontSize: '1.2em'}}>SINMODE</div>
              <div style={{fontSize: '1.1em', borderStyle: 'none none none solid', borderWidth: '2px', borderColor: 'grey', paddingLeft: '5px', textAlign:'justify' }}>
              A practical approach to sinusoidal data</div>
            </div>
            <div style={{fontSize: '1.8em', padding: '5px'}} >NVVT: Measuring is knowing, Meten is weten</div>
          <div className='centerDiv'><Pagination current={this.state.current} total={90} onChange={this.updateCurrent}/></div>
        </div>
        <div style={{height: '100px'}}></div>
        <div className = "centerDiv columnDiv preBody">
          <div className = "columnDiv">
            <div className = "rowDivCol">
              <div> {content[this.state.current-1]}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default PresentationNvvt
