import React from 'react'
import { Divider } from 'antd';
import { Link } from 'react-router-dom'

import monograph from './pic/monograph.png';
import modelingTool from './pic/modelingTool.png';




function Monograph(props) {

  const explanation = "ddddd"
  const description = ()=>{
    return (
      <div>

    </div>
  )
  }
  return (
    <div className ="centerDiv columnDiv"  >
      <div className="preH1">Understanding the physical meaning of equivalent circuits</div>
      <div className="centerDiv rowDiv">
        <div className ="columnDiv" >
          <ul className="ulPresentation">
          	<li>Protective coating interactive monograph</li>
            <Link to="/coatingsmonograph">http://sinmode.com/coatingsmonograph</Link>
          	<li>Coating Modelling tool</li>
            <Link to="/coatingsfit">http://sinmode.com/coatingsfit</Link>
          </ul>
          <img src={modelingTool} alt="WebApp - Modelling Tool for Coatings" style={{height: 0.4*568+"px", width: 0.4*1104+"px", margin:"25px"}}/>
        </div>
        <img src={monograph} alt="WebApp - Interactive Monograph" style={{height: 0.6*724+"px", width: 0.6*1040+"px", margin:"25px"}}/>
      </div>

      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"

        />**/}
    </div>
    )
}

export default Monograph
