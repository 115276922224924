import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Radio, Slider } from 'antd'

import {
  LoadingOutlined
} from '@ant-design/icons';

import {errorNLS} from '../sinTools/algorithms/fittingError/nls';

const RadioGroup = Radio.Group;

const Plot = lazy(() => import('react-plotly.js'));

class PlotModuleError extends Component {
    render() {

      const fHz = this.props.expData.fHz ? this.props.expData.fHz : new Array(10)
      const errorData = this.props.expData.fHz ? (
        errorNLS(this.props.expData,this.props.modelData)
      ) :new Array(fHz.length)

      const errorPoints = this.props.expData.fHz ? (
        errorData.errorPoints
      ) :new Array(fHz.length)

      const zRealRes = this.props.expData.fHz ? (
        errorData.resZreal
      ) :new Array(fHz.length)
      const zImgRes = this.props.expData.fHz ? (
        errorData.resZimg
      ) :new Array(fHz.length)

      // to use the ranges for fixing the 0 in the middle of the graph
      const zRealResMax = Math.max(...zRealRes.map(Math.abs))
      const zImgResMax = Math.max(...zImgRes.map(Math.abs))


      //console.log(errorData)
      // make a selector to select which plot to makes
      let data = [
      {
        type: 'scatter',  // all "scatter" attributes: https://plot.ly/javascript/reference/#scatter
        x: fHz ,     // more about "x": #scatter-x
        y: zRealRes,     // #scatter-y
        mode: 'markers',
      },

        {
          type: 'scatter',  // all "scatter" attributes: https://plot.ly/javascript/reference/#scatter
          x: fHz ,     // more about "x": #scatter-x
          y: zImgRes,     // #scatter-y
          mode: 'markers',
          yaxis: 'y2',
      }
    ];
    let layout = {
      margin: {b: 55,t: 10,l:55,r:55},
      showlegend: false,
      autosize: false,
      width: 360,
      height: 300,

      xaxis: {                  // all "layout.xaxis" attributes: #layout-xaxis
        title: 'Frequency (Hz)',        // more about "layout.xaxis.title": #layout-xaxis-title

        mirror: 'ticks',
        showgrid: false,
        hoverformat: '.2e',
        linewidth: 2,
        type: 'log',
        exponentformat: 'power',
        dtick:'1',
        ticks: 'inside',
        //range:[-2,6]
      },
      yaxis: {
        title: {
          "text":'Zreal residual',
          "font": {"color": '#1f77b4'}
        },
        ticks: 'inside',
        linewidth: 2,
        range:[-zRealResMax,zRealResMax]
      },

      yaxis2: {
        title: {
          "text":'Zimg residual',
          "font": {"color": '#ff7f0e'}
        },
        ticks: 'inside',
        linewidth: 2,
        side: 'right',
        overlaying: 'y',
        range:[-zImgResMax,zImgResMax]
      },

      /*annotations: [            // all "annotation" attributes: #layout-annotations
        {
          text: 'simple annotation',    // #layout-annotations-text
          x: 0,                         // #layout-annotations-x
          xref: 'paper',                // #layout-annotations-xref
          y: 0,                         // #layout-annotations-y
          yref: 'paper'                 // #layout-annotations-yref
        }
      ]*/
    };



        return (
          <div className = "columnDiv">
            <div className="centerDiv">
            {errorData.totalError? 'χ² = '+Math.abs((errorData.totalError)).toExponential(3): 'Insert experimental data to get the error'}
            </div>
            <div className="centerDiv">
              <div className="rowDiv">
                <Suspense fallback={<div style={{width:'650px', height:'464px', textAlign:'center', paddingTop: '200px'}}> <LoadingOutlined /> Loading Plot...</div>}>
                  <Plot
                      data={data}
                      layout={layout}
                      config={{ displaylogo: false, modeBarButtonsToRemove: ['sendDataToCloud','editInChartStudio','zoom2d','pan2d','select2d','lasso2d','zoomIn2d','zoomOut2d','autoScale2d','resetScale2d','hoverClosestCartesian','drawclosedpath','toggleSpikelines','hoverCompareCartesian']}}
                  />
                </Suspense>
              </div>
            </div>
           </div>

        );
    }
}

export default PlotModuleError;
//config={{displayModeBar: false}}
