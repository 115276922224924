import React, { Component } from 'react';
import './circuit.css'
import R from  './circuitPics/R.js'
import C from  './circuitPics/C.js'
import Q from  './circuitPics/Q.js'
import W from  './circuitPics/W.js'
import L from  './circuitPics/L.js'

import S from  './circuitPics/S.js'
import D from  './circuitPics/D.js'
import P from  './circuitPics/P.js'

import { strToArray } from '../../utils/string'


//<img src={R} alt="R" />

// the circit component
class Circuit extends Component {
  state = {
    serialNum:0,
    parallelNum:0,
  }

  elementTransform = (eqArr) => {
    // Transform a smile array in and jxs elements array on the props 'name' is included the element string.
    for (let i = 0; i < eqArr.length; i++) {
      if (eqArr[i] !== '-' && eqArr[i] !== '(' && eqArr[i] !== ')'){
        eqArr[i] =  <ElementDraw name={eqArr[i]}/>
      }
    }
    return eqArr
  }

  parallelCalc = (eqArr) => { // recursion???
    let paralelNum = 0
    for (let i = 0; i < eqArr.length - 1; i++) {
      if (eqArr[i] !== '-' && eqArr[i] !== '(' && eqArr[i] !== ')' && eqArr[i + 1] !== '-' && eqArr[i + 1] !== '(' && eqArr[i + 1] !== ')') { // revise!!!
        eqArr[i] = (<ParalelDraw top={eqArr[i]} down={eqArr[i + 1]}/>)
        eqArr.splice(i + 1, 1)
        i--
      }
    }
    return eqArr
  };

  serialCalc = (eqArr) => {
    for (let i = 0; i < eqArr.length - 1; i++) {
      if (eqArr[i] === '-') {
        eqArr[i - 1] = (<SerieDraw left={eqArr[i - 1]} right={eqArr[i + 1]}/>)
        eqArr.splice(i, 2)
        i--
      }
    }
    return eqArr
  };

  solveParenthesis = (eqArr) => {
    let parentStart
    let parentEnd
    for (let i = 0; i < eqArr.length; i++) {
      if (eqArr[i] === '(') {
        parentStart = i
      }
      if (eqArr[i] === ')') {
        parentEnd = i
        break
      }
    }
    if (typeof parentStart !== 'undefined') {
      const newSection = eqArr.slice(parentStart + 1, parentEnd)
      const newSecResult = this.parallelCalc(this.serialCalc(newSection))
      eqArr[parentStart] = newSecResult[0]
      eqArr.splice(parentStart + 1, parentEnd - parentStart)
    }
    return eqArr
  };


  render(){
    let circuitComponent = (<div className='Circuit'></div>)

    if (this.props.smiles && this.props.smiles !== "add smile"){
      let smileArray = strToArray(this.props.smiles)  //transform the string into a array of strings
      smileArray = this.elementTransform(smileArray) // transform the array of strings into a array of react elements (excluding - and parenthesys )
      smileArray = this.parallelCalc(smileArray)

      while (smileArray.find(a => a === '(')) {
        smileArray = this.solveParenthesis(smileArray)
        smileArray = this.parallelCalc(smileArray)
      }
      smileArray = this.serialCalc(smileArray)[0]
      circuitComponent = (
        <div className='Circuit'>
        <div className='innerDashGlobal'></div>
        <div className="centerDivCirc">
          {smileArray}
        </div>
        </div>
      )
    }
    return circuitComponent
  }
}
// to draw the single elements of the circuit
function ElementDraw(props) {
  const element = element => {
    return (element === 'R') ? <R /> : (
           (element === 'C') ? <C /> : (
           (element === 'Q') ? <Q /> : (
           (element === 'W') ? <W /> : (
           (element === 'L') ? <L /> : (
           (element === 'S') ? <S /> : (
           (element === 'D') ? <D /> : (
           (element === 'P') ? <P /> :
           element)))))))
  }
  return (
    <div className='elementConteiner'>
      <div className='innerDash'></div>
      <div className = 'centerDivCirc'>
        <div className='element'>{element(props.name)}</div>
      </div>
    </div>
  )
}

// To draw paralel sections. Requires 'top' and 'down' props with the sections
const topBox = {
//  outline: '2px solid #04003c',
  boxShadow: '0 17px 0px 0px white, 2px 17px 0 0 #000, -2px 17px 0 0 #000',

}
const bottomBox = {
  borderLeft: '0px solid #04003c',
}

function ParalelDraw(props) {
  return (
      <div className='paralelConteiner'>
        <div style={topBox}> {props.top} </div>
        <div style={bottomBox}> {props.down} </div>
      </div>
  )
}

function SerieDraw(props) {
  return (
      <div className='serieConteiner'>
        <div className = 'centerDivCirc'>
          <div className='serieItem' > {props.left} </div>
          <div className='serieItem' > {props.right} </div>
        </div>
      </div>
  )
}

export default Circuit;
