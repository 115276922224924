/*
Information for the nyquist plot
 */

// Plot Nyquist

export const dataMreal_MimgTTS = (ttsData, varNames) => {// todo finalize this func and test to plot it
  const buildMrealVsMimgObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
    const colorST = (data.temp<360) ? 360-data.temp : 360-(data.temp-360)
    const colorTemp = data.aE ? 'rgb(0,0,0)' : 'hsl('+colorST+', 100%, 50%)'
    const trace = {
      x: data.expData.Mreal ? (varNames.Mmod === 'Zmod' ? data.expData.Mreal.map(x => x * Math.pow(10,data.logcT)): data.expData.Mreal) : [],
      y: data.expData.Mimg ? (varNames.Mmod === 'Zmod' ? data.expData.Mimg.map(x => x * Math.pow(10,data.logcT)): data.expData.Mimg)  : [],
      mode: 'markers',
      marker: {color: colorTemp}, // ToDo: conditional if is the ref temperature rgb(31, 119, 180)
      type: 'scatter',
      name: data.temp+'ºC'
    }
    return trace
  }

  let data = ttsData.map(temp =>{return buildMrealVsMimgObj(temp)})

  return data
}

export const layoutMreal_MimgTTS = (ttsData, varNames) =>{
  console.log(ttsData)
  const maxMrealArray = ttsData.map(temp =>{
    if(temp.expData?.Mreal?.length){
      const mreal = Math.max(...temp.expData.Mreal)*Math.pow(10,temp.logcT)
      console.log(mreal)
      //(Math.max(...temp.expData.Mreal.map(x => x * Math.pow(10,ttsData.logcT))))

      return mreal
    } else { return 10000}
  }) // gives  a array with the maximum Mreal value of each temperature
  //const minMrealArray = ttsData.data.map(temp =>{return temp.expData.Mreal ? Math.min(...temp.expData.Mreal) : 0})// gives  a array with the minimum Mreal value of each temperature

  const xMax = Math.max(...maxMrealArray) // gives the maximum Mreal of all temperatures
  const xMin = 0

  //-------------------------------------------------------------------------------
  const maxMimgArray = ttsData.map(temp =>{return temp.expData.Mimg ? Math.min(...temp.expData.Mimg.map(x => x * Math.pow(10,temp.logcT))) : 1000}) // gives  a array with the maximum Mimg value of each temperature
  console.log('maxMimgArray',maxMimgArray)
  const yMax = Math.min(...maxMimgArray) // gives the maximum Mimg of all temperatures

  const yMaxCeil = Math.ceil(yMax)
  const xMaxCeil = Math.ceil(xMax)
  console.log('yMaxCeil',yMaxCeil)
  console.log('xMaxCeil',xMaxCeil)


  const maxTotal = (xMaxCeil > -yMaxCeil) ? xMaxCeil : -yMaxCeil

  

//  const MmaxY = (-MmaxX/10).toFixed(0)*10
  return{
        margin: {b: 75,t: 10,l:50,r:20},
        showlegend: false,
        autosize: true,

        xaxis: {
          ////fixedrange: true,
            title: varNames.Mreal,
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            linewidth: 2,
            constrain: 'domain',
            range:[0, maxTotal]
        },
        yaxis: {
          ////fixedrange: true,
            title: {
              "text": varNames.Mimg,
              "font": {"color": '#1f77b4'}
            },
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            scaleanchor: 'x',
            autorange: varNames.Mmod === 'Zmod' ? 'reversed' : '',
            linewidth: 2,
            range:  [0, -maxTotal]
        }
      }
  };


    //Plotly.newPlot('plotCont', dataNy, layoutNy,{displayModeBar: false});
