import React from 'react';
import { InputNumber } from 'antd';

const NumImputEaTTS = (props) => {

    const OnCahngeNormal = val => {
      props.updateEa(Number(val));
    }

    return(
      <div>
        <InputNumber
          step={1}
          precision={0}
          min={0}
          max={1000}
          value={props.aH}
          style={{ width: 72 }}
          onChange={OnCahngeNormal}
          //disabled={props.notActive}
        />
      </div>
    )
}



export default NumImputEaTTS
