import React from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import eisRules from '../../Common/sinTools/rules/eisRules'

import PlotSin from '../../Common/componentsHTM/plotSin'
import EisForCoatings from './eisForCoatings'
import RheoForCoatings from './rheoForCoatings'

function Spectrum (props) {

  const explanation = "ddddd"
  const description = "aaaa"

  return (
    <div className ="centerDiv columnDiv"  >
    <div className="preH1">EIS and Rheology, from waves to equivalent elements</div>
      <div className="rowDiv">
        <div className ="centerDiv columnDiv" style={{marginRight: "5px"}} >
          <Divider>EIS</Divider>

          <EisForCoatings />
        </div>
        <div className ="centerDiv columnDiv" style={{marginLeft: "5px"}} >
          <Divider>Rheology</Divider>

          <RheoForCoatings  />
        </div>
      </div>
      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description}
        type="info"

        />**/}
    </div>



  )
}

export default Spectrum
