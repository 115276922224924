import React, { Component } from 'react'

import eisRules from '../../../Common/sinTools/rules/eisRules'
import PlotModule from '../../../Common/componentsHTM/plotPC'
import ParamPanel from '../../../Common/componentsHTM/param/paramPanel'
import CircuitPic from '../../../Common/images/circuit'
import UpdateSinData from '../../../Common/componentsHTM/stateManagement'

import { Radio } from 'antd';

import { coatingExamples } from './examples/coatingExamples'

import Circuit from '../../../Common/componentsHTM/circuitDraw/circuit'

class DifusionMini extends Component {
  screen = window.innerWidth
  state = {
    sinData:{
      rules: eisRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
          }
        }
      }
    }
  }


  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }
    handleExampleChange = (e)=>{
      const example = e.target.value
      console.log(e.target.value)
      this.updateData('example',example)
      this.updateData('expData',coatingExamples[example])
       
    }
    componentDidMount() {
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }
      UpdateSinData('R-QWR', mainState);//execute the function to make the magic
    }


  render() {
    const { expData, modelData, rules } =  this.state.sinData

    const plotWidth = this.screen> 700 ? 650 : this.screen-25

    const infiniteResistance = (this.state.sinData.model.param.R2?.valueSci >= 1000000000000000000)
    console.log({infiniteResistance})
    return (

    <div className="centerDiv columnDiv">
      <div className="preH2"> Coating Difusion </div>
      <div className="centerDiv" >
        <div style={{width:'250px', marginTop:'4px'}}>
          <Circuit smiles={"R-QWR"}/>
        </div>
      </div>
      <div className="rowDiv">
        <div style={{ marginTop:'40px'}}><PlotModule size={500} expData={expData} modelData={modelData} rules={rules}/></div>
        <CircuitPic roughness='true' diffusion='true' diffusionPores={!infiniteResistance}/>
      </div>
      <Radio.Group value={this.state.sinData.example} onChange={this.handleExampleChange}>
            <Radio.Button value="h">A</Radio.Button>
            <Radio.Button value="g">B</Radio.Button>
            <Radio.Button value="i">?</Radio.Button>
      </Radio.Group>

      <div style={{ marginTop:'10px'}}>
      <ParamPanel sinData={this.state.sinData} updateData = {this.updateData} />
      </div>
    </div>
  )

}
}

export default DifusionMini
