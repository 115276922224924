import React from 'react'
import { Alert } from 'antd';
import { Link } from 'react-router-dom'
import InfoTip from './Common/componentsHTM/alerts/infoTip'

function App(){
  return (
    <div className = "centerDiv">
      <div style={{maxWidth: '850px', margin: '10px', textAlign: 'justify'}}>
      <h1>About</h1>
      SINMODE is a dissemination project created and maintained by <a target="_blank" href="https://www.linkedin.com/in/alejandro-guti%C3%A9rrez-99404123/">Alejandro Gutierrez</a>.
      The goal of SINMODE is to promote sinusoidal techniques in different fields using a practical perspective. It tries to use a common language from each specific field and targets
      a wide audience, from experienced professionals and technicians to students and academia.
      For each technical field the site contains interactive monographs (to help on the signal understanding) and modelling tools (to be used to fit user data).<br />
      This is the first prototype of the app and will be constantly upgraded with more features (the possibilities in terms of different applications and functionalities are huge).


      <h2>Interactive Monographs</h2>
      In this section sinusoidal models are explained gradually. The reader will integrate each element and its physical correlation for the specific field. On the introduction of each
      new element the user can interact adjusting its value and obtaining a direct response on the signal graph.
          <InfoTip
            title="Informational Tip"
            description="An informational Tip contains interesting addition to the current topic and a link to an specific app to get more details"
          />
      <h2>Modelling tools</h2>
      Although the final aim is to create specific fitting algorithms for data modelling, SINMODE encourages the understanding of manual modelling. Every topic on the Interactive monographs
      includes graphical support with slicers where you can adjust the signal to experiment with it. Further modelling sections include:
      <ul>
        <li>Specific modelling tools for each field including typical models (related to their physical equivalence)</li>
        <li>General modelling section for experienced users, where you can select models and build models through "smile" codes</li>
      </ul>
      The modelling Tools contain an input where you can introduce your data* with a copy/paste from an excel/text file. Future updates will include more features.
      <br />
      <i>* Your data is completely safe, all the app is written in JavaScript using React.js on the client side and all the render happens in your browser. Of course I am open to help with
      your specific data, but you will need to contact me and send it separately.</i>

      </div>
    </div>


  )

}

export default App
