import React from 'react';
import { Slider } from 'antd';

const ParamRangeTTS = (props) => {

  const handleOnInput = (e) => {
    const val = e.target.value;
    props.updateParamData(Number(props.id-1),Number(val));
  }


  return (
      <div className="centerContent">

        <input
        className="inputRange"
        type="range"
        min={-10}
        max={10}
        step={0.1}
        value= {props.logcT}
        onChange={handleOnInput}
        disabled={props.notActive}
      />
      </div>
    )

}

export default ParamRangeTTS
