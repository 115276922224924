import React from "react";

function Conector() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 200 50">
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="5"
        d="M0 20 h200"
      ></path>
    </svg>
  );
}

export default Conector;
