/*
General layout for bode plot Modulus and Angle vs Frequency
 */

export const dataHz_MmodMphzTTS = (ttsData, varNames) => {// todo finalize this func and test to plot it
   /*-----------------
    ttsData: {
     refTemp:1,
     data: [
       {temp:25, expData:{}},
       {temp:40, expData:{}}
     ],
     shiftedData: []
   } ------------------*/

   const buildHzVsMmodObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorST = (data.temp<360) ? 360-data.temp : 360-(data.temp-360)

     const colorTemp = data.aE ? 'rgb(0,0,0)' : 'hsl('+colorST+', 100%, 50%)' //'rgb(31, '+data.temp*2.55+', 180)'
     const dataX = data.expData.fHz ? data.expData.fHz.map(x => x * Math.pow(10,-data.logcT)) : []
     const dataY = data.expData.Mmod ? (varNames.Mmod === 'Zmod' ? data.expData.Mmod.map(x => x * Math.pow(10,data.logcT)) : data.expData.Mmod) : []
     const trace = {
       x: dataX,
       y: dataY,
       mode: 'markers',
       marker: {color: colorTemp}, // ToDo: conditional if is the ref temperature '#1f77b4'
       type: 'scatter',
       name: varNames.Mmod + ' '+data.temp+'ºC'
     }
     return trace
   }

   const buildHzVsMphzObj = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
      const colorST = (data.temp<360) ? 360-data.temp : 360-data.temp+360
     const colorTemp = data.aE ? 'rgb(150,150,150)' :  'hsl('+colorST+', 100%, 80%)'
     const dataX = data.expData.fHz ? data.expData.fHz.map(x => x * Math.pow(10,-data.logcT)) : []
    const trace = {
       x: dataX,
       y: data.expData.Mphz ? data.expData.Mphz : [],
       mode: 'markers',
       marker: {color: colorTemp}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       yaxis: 'y2',
       name: varNames.Mphz + ' '+data.temp+'ºC'
     }
     return trace
    }

    let dataArray1 = ttsData.map(temp =>{return buildHzVsMmodObj(temp)}) //gives a array of Hz Vs Mmod data objects
    let dataArray2 = ttsData.map(temp =>{return buildHzVsMphzObj(temp)}) //gives a array of Hz Vs Mphz data objects

    const data = dataArray1.concat(dataArray2)

    return data
  }

export const layoutHz_MmodMphzTTS = (ttsData, varNames,size) =>{
  /*-----------------
   ttsData: {
    refTemp:1,
    data: [
      {temp:25, expData:{}},
      {temp:40, expData:{}}
    ],
    shiftedData: []
  } ------------------*/
  const maxHzArray = ttsData.map(temp =>{return temp.expData.fHz ? Math.max(...temp.expData.fHz.map(x => x * Math.pow(10,-ttsData.logcT))) : 100000}) // gives  a array with the maximum Hz value of each temperature
  const minHzArray = ttsData.map(temp =>{return temp.expData.fHz ? Math.min(...temp.expData.fHz.map(x => x * Math.pow(10,-ttsData.logcT))) : 0.01})// gives  a array with the minimum Hz value of each temperature

  const xMax = Math.max(...maxHzArray) // gives the maximum Hz of all temperatures
  const xMin = Math.min(...minHzArray) // gives the minimum Hz of all temperatures

  const xMaxLog = Math.log(xMax)/Math.log(10);
  const xMinLog = Math.log(xMin)/Math.log(10);

  const xMaxLogCeil = Math.ceil(xMaxLog);
  const xMinLogFloor = Math.floor(xMinLog);

//---------------------------------------------------------
  const maxMmodArray = ttsData.map(temp =>{return temp.expData.Mmod ? Math.max(...temp.expData.Mmod.map(x => x * Math.pow(10,ttsData.logcT))) : 1000}) // gives  a array with the maximum Mmod value of each temperature
  const minMmodArray = ttsData.map(temp =>{return temp.expData.Mmod ? Math.min(...temp.expData.Mmod.map(x => x * Math.pow(10,ttsData.logcT))) : 0})// gives  a array with the minimum Mmod value of each temperature

  const yMax = Math.max(...maxMmodArray) // gives the maximum Mmod of all temperatures
  const yMin = Math.min(...minMmodArray) // gives the minimum Mmod of all temperatures

   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

   const graphWidth = size
   const graphHeigth = graphWidth/1.4

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: true,
       width: graphWidth,
       height: graphHeigth,

       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         //fixedrange: true,
         title: {
           "text":varNames.Mmod,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[yMinLogFloor, yMaxLogCeil]
       },
       yaxis2: {
         //fixedrange: true,
        title: {
          "text":varNames.Mphz,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2f',
 				dtick:'10',
 				ticks: 'inside',
 				linewidth: 2,
 				overlaying: 'y',
 				side: 'right',
 				range:varNames.Mmod === 'Zmod' ? [0,-100] : [0,100]
       }
       }
   };
