import React from 'react'
import { Link } from 'react-router-dom'
import { Alert } from 'antd';

const Description = (props) => {
  return (
    <div>
      <div>
        {props.description}
      </div>
      {props.link ? (<Link to={props.link} target="_blank">Learn more...</Link>) : <i>coming soon!</i>}
    </div>
  )
}

const InfoTip = (props) => {
  return (
    <div className = "rightDiv">
      <div className = "rightDiv" style= {{textAlign:'right'}}>
        <Alert
          style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
          message={props.title}
          description={<Description description= {props.description} link={props.link} />}
          type="info"
          showIcon
          />
      </div>
    </div>
  )
}

export default InfoTip
