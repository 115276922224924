import React, { Component,lazy, Suspense } from 'react'
import { InlineMath } from 'react-katex'
import {Helmet} from 'react-helmet'

import { Radio, Button, Divider, Popover, Switch } from 'antd';
import { ReadOutlined, LoadingOutlined } from '@ant-design/icons';

// panels for modules
import PanelTab from './Common/componentsHTM/panelTab' /// Needed props: type ('eis' or 'rheo' for DataFromUser)
import PanelModule from './Common/componentsHTM/panelModule' /// Needed props: title and content
import TTSrefSelector from './Common/componentsHTM/ttsRefSelector'/// Needed props: ttsData and updateData
import PlotModuleTTS from './Common/componentsHTM/plotTTS'
import PlotModuleEa from './Common/componentsHTM/plotEa'
import ParamPanelTTS from './Common/componentsHTM/param/paramPanelTTS'
import SampleDataContainer from './Common/componentsHTM/sampleDataContainer'

//import SmileInput from './Common/componentsHTM/smileInput'
//import ParamPanel from './Common/componentsHTM/param/paramPanel'

//rules
import eisRules from './Common/sinTools/rules/eisRules'
//import rheoRules from './Common/sinTools/rules/rheoRules'

import InfoHowTo from './Common/componentsHTM/alerts/modalHowTo.js'

const Biblio = lazy(()=> import('./Monographs/eis/coatings/coatingTTS/bibliography'))

class FitToolTTS extends Component {
  screen = window.innerWidth
  state = {
    sampleDataList: [],
    sampleDataActive: -1, // the active index of sampleDataList array, -1 to present no data
    showGraph: "spectrum",
    sinData:{
      rules: eisRules,//this.props.location.pathname == "/eistts" ? eisRules : rheoRules,
      modelData: {},
      expData: {},
      ttsData: {
        predefined25:[],
        geometryK: 1,
        avgEa:0,
        refTemp:1,
        data: [
          {temp:25, expData:{}, key:1, logcT:0 , aH:0}
        ],
        dataSource: ""
      },
      model: {
        param : {
          R1: {
          maxRange: 16,
          minRange: 0,
          stepRange: 0.05,
          subType: "Generic Resistance",
          subTypeAbr: "R",
          type: "Resistance",
          units: "(Ohm)",
          valueSci: 30,
          valueSciTex: "3.00e+1",
          valueStep: 1
          }
        }
      },
    }
  };


  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      console.log(item,data)
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData.ttsData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }

    // Function that update the sinData object in the state of the App
      addSampleData = (data) => { //item to change with the new data
        this.setState({
          sampleDataList: data
        })//updates the state
      }

    updateSampleData = (index, subIndex = 0) => { //change the active data index
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      const k = this.state.sampleDataList[index].data[subIndex].geometryK? this.state.sampleDataList[index].data[subIndex].geometryK :1
      sinData.ttsData.data = this.state.sampleDataList[index].data[subIndex].spectrums //changes the selected item from the object with the new data
      sinData.ttsData.geometryK = k //changes the selected item from the object with the new data
      sinData.ttsData.dataSource = (<SampleDataContainer active={index} list={this.state.sampleDataList} updateSampleData={this.updateSampleData}/>)
      sinData.ttsData.predefined25 = this.state.sampleDataList[index].data[subIndex].predefined25
      this.setState({
        sampleDataActive: index,
        sinData: sinData
      })//updates the state
    }

    // to change the graph between spectrum and Ea
    handleTypeChange = (e) => {
          this.setState({ showGraph: e.target.value });
        }

    render() {
      const { modelData, rules, ttsData} =  this.state.sinData
      const showGraph = this.state.showGraph
    //  console.log(ttsData)

      // to adjust the plot size to screen width
      const plotWidth = this.screen> 700 ? 650 : this.screen-25
    
      return (
        <div>
          <Helmet>
            <title>Time Temperature Superposition (TTS)</title>
            <meta name="description" content="Time Temperature Superposition (TTS) for EIS Data" />
            <meta name="keywords" content="EIS, circuit, Impedance, TTS, Time, Temperature, Superposition, coating, corrosion, liquid glass" />
            <meta name="og:title" property="og:title" content="SINMODE - Time Temperature Superposition (TTS) for EIS Data" />
            <meta property="og:url" content="http://sinmode.com/eisTTS" />
            <meta property="og:image" content="http://sinmode.com/ttspic.png" />
            <meta property="og:description" content="Time Temperature Superposition (TTS) applied to Electrochemical Impedance Spectroscopy (EIS) data using Arrhenius shift factors to get Activation Energy (Ea)" />
          </Helmet>
          <div className = "columnDiv">
            <div className = "centerDiv">
              <div className="rowDiv">
                <h1>Time Temperature Superposition (TTS) for EIS Data</h1>
                <h1 style= {{color:'#1890ff', marginLeft: '5px'}}><InfoHowTo title='Time Temperature Superposition (TTS) for EIS Data' content={
                  <div>
                  <p>TTS can be a useful technique to simulate frequencies that where too slow to wait for, simulate working temperatures that we didn’t measure, get
                   activation energies without the need of circuit modelling and identify material transitions like Tg.</p>

                   <p>You can include your temperature data using the 'TTS Experimental Data' card or use the Sample Data to experiment with it. On the 'TTS Modelling'
                   card you can manual find shift factors for each temperature. An automatic fit, although experimental, is also available together with a log frequency
                   delimiter to cover different relaxations from poly-structured materials.</p>
                   <p>You can check this video for visual guidance:</p>
                   <a target="_blank" href="https://youtu.be/OEBUHFZROu0">https://youtu.be/OEBUHFZROu0</a>
                  </div>
                }/></h1>
              </div>
            </div>
            <div className = "centerDiv">
              <div className= "rowDivCol">
                <div >
                    <div className="centerDiv" style={{marginTop:"15px", marginBottom:"15px"}}>
                      <Radio.Group value={showGraph} onChange={this.handleTypeChange} >
                        <Radio.Button value='spectrum'>Impedance Spectrum</Radio.Button>
                        <Radio.Button value='Ea'>Arrhenius / Activation Energy</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div style={showGraph === 'spectrum' ? {display: 'block'} : {display: 'none'}}>
                      <PlotModuleTTS size={plotWidth} ttsData={ttsData.data} geometry= {ttsData.geometryK} refTemp={ttsData.refTemp} modelData={modelData} rules={rules}/>
                    </div>
                    <div style={showGraph === 'Ea' ? {display: 'block'} : {display: 'none'}}>
                        <PlotModuleEa size={plotWidth} ttsData={ttsData.data} refTemp={ttsData.refTemp} modelData={modelData} rules={rules}/>
                    </div>
                    <div style={{maxWidth:"650px"}}>
                    {ttsData.dataSource}
                   </div>
                </div>
                <div >
                  <div className= "columnDiv">
                    <div className = "centerDiv">
                      <PanelModule  title="TTS Experimental Data " content={
                        <PanelTab  type={rules.type} ttsData={ttsData} updateData = {this.updateData}/>
                      }/>
                    </div>
                    <div className = "centerDiv">
                      <PanelModule  title="TTS Sample Data" content={
                        <Suspense fallback={<div style={{width:'300px', height:'150px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
                            <Biblio addSampleData={this.addSampleData} updateSampleData = {this.updateSampleData}/>
                        </Suspense>
                      }/>
                    </div>
                    <div className = "centerDiv">
                      <PanelModule  title="TTS Modelling" content={
                        <div>
                          <TTSrefSelector  ttsData={ttsData} updateData = {this.updateData}/>
                          <br />
                          <ParamPanelTTS ttsData={ttsData} updateData = {this.updateData} rules={rules.varNames}/>
                        </div>
                      }/>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}
export default FitToolTTS
