import React from "react";

function Q() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="32" version="1">
    <g

      fillOpacity="0.75"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeOpacity="1"
    >
      <path
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeWidth="2"
        d="M 27 5 V27 M32 27 V5"
      ></path>
      <path
        strokeWidth="2"
        d="M 0 15 H27 M 32 15 H55"></path>
        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M 3 2 M 19 27 l20-20"
        ></path>
        <path

          fillRule="evenodd"
          strokeLinejoin="round"
          strokeWidth="1.8"
          d="M 38 5 l6-3-3 6z"
        ></path>

    </g>
    </svg>



  );
}

export default Q;
