import React from 'react';
import { InputNumber } from 'antd';

const NumImputTTS = (props) => {

    const OnCahngeNormal = val => {
      props.updateParamData(Number(props.id-1),Number(val));
    }

    return(
      <div>
        <InputNumber
          step={0.01}
          precision={2}
          min={-10}
          max={10}
          value={props.logcT}
          style={{ width: 72 }}
          onChange={OnCahngeNormal}
          disabled={props.notActive}
        />
      </div>
    )
}



export default NumImputTTS
