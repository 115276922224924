/*
General layout
 */

 export const dataHz_nmodMphzTTS = (expData,modelData, varNames) => {// todo finalize this func and test to plot it
     var traceHzVsnmod = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.nmod ? modelData.nmod : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#1f77b4'},
         type: 'scatter',
         name: varNames.nmod + ' Model'
     };
     var traceHzVsnmodR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.nmod ? expData.nmod : [],
         mode: 'markers',
         marker: {
             color: '#1f77b4',
         },
         type: 'scatter',
         name: varNames.nmod + ' Real'
     };

     var traceHzVsMphz = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.Mphz ? modelData.Mphz : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#ff7f0e'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mphz + ' Model'
     };
     var traceHzVsMphzR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.Mphz ? expData.Mphz : [],
         mode: 'markers',
         marker: {color: '#ff7f0e'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mphz + ' Real'
     };

   return [traceHzVsnmod, traceHzVsnmodR, traceHzVsMphz, traceHzVsMphzR];
 }

 export const layoutHz_nmodMphzTTS = (expData ,modelData, varNames) =>{
   const xMax = expData.fHz ? Math.max(...expData.fHz) : (
                   modelData.fHz ? Math.max(...modelData.fHz) : 100000
                 );
   const xMin = expData.fHz ? Math.min(...expData.fHz) : (
                   modelData.fHz ? Math.min(...modelData.fHz) : 0.01
                 );
   const xMaxLog = Math.log(xMax)/Math.log(10);
   const xMinLog = Math.log(xMin)/Math.log(10);

   const xMaxLogCeil = Math.ceil(xMaxLog);
   const xMinLogFloor = Math.floor(xMinLog);

   const yMax = expData.nmod ? Math.max(...expData.nmod) : (
                   modelData.nmod ? Math.max(...modelData.nmod) : 1000
                 );
   const yMin = expData.nmod ? Math.min(...expData.nmod) : (
                   modelData.nmod ? Math.min(...modelData.nmod) : 0
                 );
   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

   return {
       margin: {b: 50,t: 10,l:50,r:50},
       showlegend: false,
       autosize: true,
       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         ////fixedrange: true,
         title: {
           "text": varNames.nmod,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[yMinLogFloor, yMaxLogCeil]
       },
       yaxis2: {
         //fixedrange: true,
        title: {
          "text": varNames.Mphz,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2f',
 				dtick:'10',
 				ticks: 'inside',
 				linewidth: 2,
 				overlaying: 'y',
 				side: 'right',
 				range:varNames.Mmod === 'Zmod' ? [0,-100] : [0,100]
       }
       }
   };
