

export const extractData = userData => {
    if (userData === '') {
        alert("Fill the box with valid data")
    }

    userData = userData.split("\n");
    const numPoints = userData.length;

    const fHz = [];
    const Mreal = [];
    const Mimg = [];

    for (let i = 0; i < numPoints; i++) {
        // tab or comma delimited data
        if (userData[i].split(",", 2)[1] != null) {
            Mimg.push(Number(userData[i].split(",")[2]));
            Mreal.push(Number(userData[i].split(",")[1]));
            fHz.push(Number(userData[i].split(",")[0]));
        }
        if (userData[i].split("\t", 2)[1] != null) {
            Mimg.push(Number(userData[i].split("\t")[2]));
            Mreal.push(Number(userData[i].split("\t")[1]));
            fHz.push(Number(userData[i].split("\t")[0]));
        }
    }
    return {
        fHz: fHz,
        Mreal: Mreal,
        Mimg: Mimg
    }
};
