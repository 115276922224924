import React, { Component } from 'react'

import eisRules from '../../../Common/sinTools/rules/eisRules'
import PlotModule from '../../../Common/componentsHTM/plotPC'
import ParamPanel from '../../../Common/componentsHTM/param/paramPanel'
import CircuitPic from '../../../Common/images/circuit'
import UpdateSinData from '../../../Common/componentsHTM/stateManagement'

import { Radio } from 'antd';

import { coatingExamples } from './examples/coatingExamples'

import Circuit from '../../../Common/componentsHTM/circuitDraw/circuit'



class DamagedCoatingMini extends Component {
  screen = window.innerWidth
  state = {
    sinData:{
      rules: eisRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          R1: {
            maxRange: 16,
            minRange: 0,
            stepRange: 0.05,
            subType: "Generic Resistance",
            subTypeAbr: "R",
            type: "Resistance",
            units: "(Ohm)",
            valueSci: 30,
            valueSciTex: "3.00e+1",
            valueStep: 1
        },
          R2: {
            maxRange: 16,
            minRange: 0,
            stepRange: 0.05,
            subType: "Generic Resistance",
            subTypeAbr: "R",
            type: "Resistance",
            units: "(Ohm)",
            valueSci: 10000,
            valueSciTex: "1.00e+4",
            valueStep: 1
        },
          R3: {
            maxRange: 16,
            minRange: 0,
            stepRange: 0.05,
            subType: "Generic Resistance",
            subTypeAbr: "R",
            type: "Resistance",
            units: "(Ohm)",
            valueSci: 100000,
            valueSciTex: "1.00e+5",
            valueStep: 1
        },
          Q2: {
            type:"Capacitance",
            valueSci:0.000001,
            valueSciTex:"1.00e-6",
            valueStep: -2,
            subType:"Generic Capacitance",
            subTypeAbr:"Q",
            units:"(F.s^{\\alpha})",
            maxRange: 0,
            minRange: -15,
            stepRange:0.05
        },
          a2: {
            type:"Constant",
            valueSci:0.75,
            valueSciTex:"0.750",
            valueStep:1,
            subType:"Surface Roughness",
            subTypeAbr:"\\alpha",
            units:"",
            maxRange: 1,
            minRange: 0.5,
            stepRange: 0.005
        }
      }
    }
  }
}


  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }
    handleExampleChange = (e)=>{
      const example = e.target.value
      console.log(e.target.value)
      this.updateData('example',example)
      this.updateData('expData',coatingExamples[example])
       
    }
    componentDidMount() {
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }
      UpdateSinData('R-Q(R-QR)', mainState);//execute the function to make the magic
    }


  render() {
    const { expData, modelData, rules } =  this.state.sinData

    const plotWidth = this.screen> 700 ? 650 : this.screen-25

    return (

    <div className="centerDiv columnDiv">
      <div className="preH2"> Damaged Coating </div>
      <div className="centerDiv" ><div style={{width:'250px', marginTop:'4px'}}><Circuit smiles={"R-Q(R-QR)"}/></div></div>
      <div className="rowDiv">
        <div style={{ marginTop:'40px'}}><PlotModule size={500} expData={expData} modelData={modelData} rules={rules}/></div>
        <CircuitPic roughness='true' pores='true' damaged='true'/>
      </div>
      <Radio.Group value={this.state.sinData.example} onChange={this.handleExampleChange}>
            <Radio.Button value="j">A</Radio.Button>
            <Radio.Button value="k">B</Radio.Button>
      </Radio.Group>

      <div style={{ marginTop:'10px'}}>
      <ParamPanel sinData={this.state.sinData} updateData = {this.updateData} />
      </div>
    </div>
  )

}
}

export default DamagedCoatingMini
