import React from "react";

function R() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="30">
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="bevel"
        strokeWidth="2"
        d="M0 15h15l2.5-5 5 10 5-10 5 10 5-10 5 10 2.5-5h10"
      ></path>
    </svg>
  );
}

export default R;
