import React from "react";

function L() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="24"
      version="1.1"
      viewBox="0 0 14.552 3.969"
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="0.5"
        d="M14.552 295.877c-.557 0-1.32.193-1.622-.596-.266-.675-.476-1.479-.954-1.943-.782-.373-1.195.84-1.253 1.67-.217.55-.107 2.214.55 1.48.42-.778.106-1.789-.192-2.54-.222-.686-.97-.938-1.305-.262-.387.713-.486 1.65-.437 2.505.03.953.83.384.785-.284.08-.779-.205-1.514-.45-2.194-.463-.888-1.427-.279-1.486.68-.113.687-.462 1.936.126 2.322.725-.254.517-1.527.318-2.217-.118-.965-1.01-1.726-1.551-.783-.33.865-.518 1.943-.274 2.894.508.494.86-.926.654-1.502-.15-.732-.415-1.778-1.09-1.835-.617.092-.851 1.008-.944 1.704-.203.547-.128 2.206.546 1.552.441-.859.105-1.943-.256-2.74-.26-.758-1.13-.597-1.37.104-.158.695-.388 1.418-.318 2.16.032 1.03.95.506.828-.303.024-.764-.196-1.549-.573-2.128-.438-.743-1.153-.209-1.318.539-.243.706-.27 1.507-.213 2.269.504.922 1.03-.77.72-1.363-.114-.578-.312-1.41-.86-1.408-.694.225-.728 1.255-1.164 1.86-.38.484-.981.36-1.449.36"
        transform="translate(0 -293.031)"
      ></path>
    </svg>
  );
}




export default L;
