/*
General layout for bode plot Modulus and Angle vs Frequency
 */

export const dataHz_MmodMphzCoating = (dataArray, varNames) => {// todo finalize this func and test to plot it
   /*-----------------
    dataArray: {
     refTemp:1,
     data: [
       {temp:25, expData:{}},
       {temp:40, expData:{}}
     ],
     shiftedData: []
   } ------------------*/
   const color = (data,opacity) => {
     const colorST = (data.time<360) ? 230-data.time : 230-(data.time-360)
     const greyTone = (opacity == '50%') ? '0%' : '50%'
     const colorTime = (data.time == 0) ? 'hsl(0, 0%, '+greyTone+')' : 'hsl('+colorST+', 100%, '+opacity+')' //'rgb(31, '+data.temp*2.55+', 180)'
     return colorTime
   }

   const buildHzVsMmodObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorTime = color(data,'50%')
     const dataX = data.expData.fHz ? data.expData.fHz : []
     const dataY = data.expData.Mmod ? data.expData.Mmod : []
     const trace = {
       x: dataX,
       y: dataY,
       mode: 'markers',
       marker: {color: colorTime}, // ToDo: conditional if is the ref temperature '#1f77b4'
       type: 'scatter',
       name: varNames.Mmod + ' '+data.time+'h'
     }
     return trace
   }

   const buildHzVsMmodObjModel = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorTime = color(data,'50%')
     const dataX = data.modelData.fHz ? data.modelData.fHz : []
     const dataY = data.modelData.Mmod ? data.modelData.Mmod : []
     const trace = {
       x: dataX,
       y: dataY,
       mode: 'lines',
       line: {shape: 'spline', color: colorTime}, // ToDo: conditional if is the ref temperature '#1f77b4'
       type: 'scatter',
       name: varNames.Mmod + ' '+data.time+'h Fit'
     }
     return trace
   }

   const buildHzVsMphzObj = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorTime = color(data,'80%')
     const dataX = data.expData.fHz ? data.expData.fHz : []
    const trace = {
       x: dataX,
       y: data.expData.Mphz ? data.expData.Mphz : [],
       mode: 'markers',
       marker: {color: colorTime}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       yaxis: 'y2',
       name: varNames.Mphz + ' '+data.time+'h'
     }
     return trace
    }

    const buildHzVsMphzObjModel = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
      const colorTime = color(data,'80%')
      const dataX = data.modelData.fHz ? data.modelData.fHz : []
     const trace = {
        x: dataX,
        y: data.modelData.Mphz ? data.modelData.Mphz : [],
        mode: 'lines',
        line: {shape: 'spline', color: colorTime}, // ToDo: conditional if is the ref temperature
        type: 'scatter',
        yaxis: 'y2',
        name: varNames.Mphz + ' '+data.time+'h Fit'
      }
      return trace
     }

    let dataArray1 = dataArray.map(time =>{return buildHzVsMmodObj(time)}) //gives a array of Hz Vs Mmod data objects
    let dataArray2 = dataArray.map(time =>{return buildHzVsMphzObj(time)}) //gives a array of Hz Vs Mphz data objects

    let dataArray3 = dataArray.map(time =>{return buildHzVsMmodObjModel(time)}) //gives a array of Hz Vs Mmod data objects for the modeled data
    let dataArray4 = dataArray.map(time =>{return buildHzVsMphzObjModel(time)}) //gives a array of Hz Vs Mphz data objects for the modeled data

    const data = dataArray1.concat(dataArray2).concat(dataArray3).concat(dataArray4)

    return data
  }

export const layoutHz_MmodMphzCoating = (dataArray, varNames,size) =>{
  /*-----------------
   ttsData: {
    refTemp:1,
    data: [
      {temp:25, expData:{}},
      {temp:40, expData:{}}
    ],
    shiftedData: []
  } ------------------*/
  const maxHzArray = dataArray.map(time =>{return time.expData.fHz ? Math.max(...time.expData.fHz) : 100000}) // gives  a array with the maximum Hz value of each temperature
  const minHzArray = dataArray.map(time =>{return time.expData.fHz ? Math.min(...time.expData.fHz) : 0.01})// gives  a array with the minimum Hz value of each temperature

  const xMax = Math.max(...maxHzArray) // gives the maximum Hz of all temperatures
  const xMin = Math.min(...minHzArray) // gives the minimum Hz of all temperatures

  const xMaxLog = Math.log(xMax)/Math.log(10);
  const xMinLog = Math.log(xMin)/Math.log(10);

  const xMaxLogCeil = Math.ceil(xMaxLog);
  const xMinLogFloor = Math.floor(xMinLog);

//---------------------------------------------------------
  const maxMmodArray = dataArray.map(time =>{return time.expData.Mmod ? Math.max(...time.expData.Mmod) : 1000}) // gives  a array with the maximum Mmod value of each temperature
  const minMmodArray = dataArray.map(time =>{return time.expData.Mmod ? Math.min(...time.expData.Mmod) : 0})// gives  a array with the minimum Mmod value of each temperature

  const yMax = Math.max(...maxMmodArray) // gives the maximum Mmod of all temperatures
  const yMin = Math.min(...minMmodArray) // gives the minimum Mmod of all temperatures

   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

   const graphWidth = size
   const graphHeigth = graphWidth/1.4

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: false,
       width: graphWidth,
       height: graphHeigth,

       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         //fixedrange: true,
         title: {
           "text":varNames.Mmod,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[yMinLogFloor, yMaxLogCeil]
       },
       yaxis2: {
         //fixedrange: true,
        title: {
          "text":varNames.Mphz,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2f',
 				dtick:'10',
 				ticks: 'inside',
 				linewidth: 2,
 				overlaying: 'y',
 				side: 'right',
 				range:varNames.Mmod === 'Zmod' ? [0,-100] : [0,100]
       }
       }
   };
