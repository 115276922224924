import React from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import compacStat from './pic/compactstat.jpg';
import pocketStat2 from './pic/pocketstat2.gif';
import octoStat30 from './pic/OctoStat30.jpg';


import {
  DownOutlined
} from '@ant-design/icons';





function WhyEIS(props) {

  const explanation = "ddddd"
  const description = ()=>{
    return (
      <div>
    EIS is a potent technique for the characterization of protective coatings, quantifying the barrier performance of your coating and its evolution during time, It is a versatile and affordable technique and you can use it in the lab (as a fast analysis versus salt spray for coating development) and on the field with new smaller devices"
    It requires a deep understanding on both: electrochemistry and coating technology.

    So lets have a deeper look to EIS data, how it looks and where complexity comes from.
    I will do that in a general way, with no complicated equations. Just to get the general understanding about the technology.

    </div>
  )
  }
  return (
    <div className ="centerDiv columnDiv"  >
      <div className="preH1">EIS for protective coatings</div>
      <div className="rowDiv">
        <div className ="columnDiv" >
          <div className="preIniUl">Why to use Electrochemical Impedance Spectroscopy (EIS)</div>
          <ul className="ulPresentation">
          	<li> Quantification of barrier performance (faster than Salt spray analysis)</li>
          	<li> Versatility: lab or field measurements, non-destructive analysis or accelerated tests</li>
          	<li> Affordable. Competitive price of measuring devices.</li>
          </ul>

          <div className="preIniUl">The roadblock to implement EIS in your dayly workflow</div>
          <ul className="ulPresentation">
          	<li>EIS data is difficult to interpret (mainly due to its sinusoidal nature)</li>
            <li>It requires a deep understanding of both: electrochemistry and coating technology</li>
          </ul>
        </div>
        <div className ="centerDiv columnDiv">
          <img src={pocketStat2} alt="PocketStat2, portable device" style={{height: "300px", width: "295px"}}/>
          <img src={octoStat30} alt="PocketStat2, portable device" style={{height: 255*0.9+"px", width: 600*0.9+"px"}}/>
        </div>
      </div>


      {/**<Alert
        style= {{maxWidth: '700px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"

        />**/}
    </div>
    )
}

export default WhyEIS
