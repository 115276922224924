export const coatingData = [
  {
    "time": 0,
    "expData": {
      "fHz": [
        1000078,
        794390.6,
        630984.4,
        501234.4,
        398109.4,
        316265.6,
        251203.1,
        199546.9,
        158578.1,
        125953.1,
        100078.1,
        79453.13,
        63140.62,
        50203.12,
        39890.62,
        31640.63,
        25171.88,
        20015.62,
        15890.62,
        12609.37,
        10078.13,
        8015.625,
        6328.125,
        5015.625,
        3984.375,
        3170.956,
        2527.573,
        1976.103,
        1577.524,
        1265.625,
        998.264,
        796.875,
        627.7902,
        505.5147,
        397.9953,
        315.5048,
        252.4038,
        198.6229,
        158.3615,
        125.558,
        100.4464,
        79.00281,
        63.3446,
        50.22321,
        38.42213,
        31.25,
        24.93351,
        19.86229,
        15.625,
        12.40079,
        9.93114,
        7.944915,
        6.317385,
        5.008013,
        3.945707,
        3.158693,
        2.504006,
        1.998082,
        1.584686,
        1.266892,
        0.999041
      ],
      "Mreal": [
        62.17576,
        80.80359,
        93.97376,
        118.5514,
        180.8937,
        250.8486,
        405.3647,
        275.1122,
        412.7156,
        443.6248,
        606.6925,
        749.6804,
        905.5914,
        1128.932,
        1338.959,
        1763.34,
        2196.25,
        2516.564,
        3496.542,
        3663.454,
        4706.748,
        5942.866,
        7508.509,
        9870.58,
        11865.85,
        15022.57,
        19714.87,
        24631.9,
        31219.62,
        37632.5,
        48560.11,
        62878.26,
        81690.56,
        102260.5,
        131459.1,
        169035.2,
        215670.2,
        280801,
        360701.3,
        431003.7,
        564393.8,
        754672.2,
        979015,
        1400288,
        1803829,
        2281751,
        2986899,
        3918344,
        5198890,
        6626668,
        8759566,
        11600000,
        15200000,
        20100000,
        26500000,
        34100000,
        44100000,
        56000000,
        70600000,
        88000000,
        109000000
      ],
      "Mimg": [
        -729.2095,
        -910.7966,
        -1141.455,
        -1390.114,
        -1745.242,
        -2145.315,
        -2617.035,
        -3297.814,
        -4096.739,
        -5106.743,
        -6311.351,
        -7854.533,
        -9721.21,
        -12068.35,
        -15022.09,
        -18695.3,
        -23191.08,
        -28803.85,
        -35738.88,
        -44433.63,
        -54888.52,
        -68170.31,
        -85149.16,
        -105955.7,
        -131690.9,
        -163263,
        -201832.1,
        -254129.3,
        -314309.8,
        -385829.6,
        -481989.9,
        -594781.4,
        -742702.1,
        -910515.7,
        -1135745,
        -1408587,
        -1731625,
        -2159950,
        -2660137,
        -3286457,
        -4029841,
        -5013677,
        -6119561,
        -7602522,
        -9560000,
        -11500000,
        -14000000,
        -17000000,
        -20900000,
        -25400000,
        -30500000,
        -36400000,
        -43500000,
        -51800000,
        -61500000,
        -71600000,
        -83100000,
        -95100000,
        -108000000,
        -120000000,
        -133000000
      ],
      "fRad": [
        6283675.395633547,
        4991303.346081575,
        3964591.911139527,
        3149348.617532976,
        2501395.1327300807,
        1987155.371086336,
        1578355.6270379643,
        1253790.150173234,
        996375.5879604552,
        791386.6673137211,
        628809.2474904493,
        499218.73902542965,
        396724.21587020956,
        315435.50595857366,
        250640.15747828415,
        198803.94152590563,
        158159.5865700877,
        125761.84949808987,
        99843.71010597408,
        79227.00831679106,
        63322.7583398458,
        50363.65722786137,
        39760.782021995816,
        31514.101306322613,
        25034.566458293662,
        19923.70414891295,
        15881.209536423827,
        12416.221335073502,
        9911.875618523169,
        7952.156404399164,
        6272.277697486323,
        5006.913291658733,
        3944.522160631334,
        3176.2425356032963,
        2500.6782212865314,
        1982.3751237046338,
        1585.8998476362947,
        1247.9844869494002,
        995.0146500229201,
        788.9041807988546,
        631.1233446390836,
        496.38929501790045,
        398.00586000916803,
        315.5617351513949,
        241.41336268654402,
        196.34954084936206,
        156.66186368841528,
        124.79844869494003,
        98.17477042468103,
        77.91646152541955,
        62.39919293154347,
        49.9193731947907,
        39.69330061179671,
        31.46627369976436,
        24.791608248835644,
        19.846653447491008,
        15.733133708289527,
        12.55431946494,
        9.95687579169319,
        7.96011720018336,
        6.27715973247
      ],
      "Mmod": [
        731.8553955678865,
        914.3739206300933,
        1145.3168053397005,
        1395.1599791557812,
        1754.5917386285876,
        2159.930894576713,
        2648.243329309278,
        3309.2693908451815,
        4117.475513054615,
        5125.975715044312,
        6340.443772698977,
        7890.228726103773,
        9763.299629113813,
        12121.037875657514,
        15081.64444521157,
        18778.274948610164,
        23294.84289770807,
        28913.575863054295,
        35909.51614282994,
        44584.39603927607,
        55089.9546607719,
        68428.85956809492,
        85479.57157185968,
        106414.46664822598,
        132224.39842567823,
        163952.69067144004,
        202792.68401381472,
        255320.25304722693,
        315856.47856104583,
        387660.52841682243,
        484429.92061310797,
        598095.8028331144,
        747181.2075644861,
        916240.1703738709,
        1143327.6870599303,
        1418693.1434979306,
        1745003.9472141718,
        2178126.076263952,
        2684480.263773733,
        3314598.588405946,
        4069171.764224194,
        5070156.525175711,
        6197378.252369788,
        7730404.080216506,
        9728689.483236732,
        11724179.614199068,
        14315081.754436508,
        17445727.835270617,
        21536909.1847484,
        26250194.833224077,
        31732948.121918265,
        38203664.74567591,
        46079170.99948739,
        55563027.27533841,
        66966409.49013169,
        79305548.3557109,
        94076670.8594644,
        110363082.59558538,
        129028523.97822739,
        148808601.9019062,
        171959297.50961417
      ],
      "Mphz": [
        -85.12648541679218,
        -84.93013562578689,
        -85.29356452582589,
        -85.1255083168133,
        -84.08244412902636,
        -83.33077134912044,
        -81.19516203848086,
        -85.23127933064241,
        -84.2472908411346,
        -85.03515676836211,
        -84.50918817149008,
        -84.54788747505575,
        -84.67790019344525,
        -84.65582695499819,
        -84.90653427049025,
        -84.6118047329336,
        -84.59008897600957,
        -85.00680335742214,
        -84.41220466171998,
        -85.28675073063837,
        -85.09881670837429,
        -85.0177361158459,
        -84.96065515239509,
        -84.67782241896808,
        -84.8513362034132,
        -84.74275891675025,
        -84.42107215130889,
        -84.46380629434319,
        -84.32755690171487,
        -84.42918673412092,
        -84.24690748510885,
        -83.9653003544627,
        -83.72320999279647,
        -83.59193370189942,
        -83.39756471302547,
        -83.15703571788697,
        -82.90049184459417,
        -82.59289222965617,
        -82.2780735769968,
        -82.52856172446398,
        -82.0273767656783,
        -81.43994713638942,
        -80.9107707290445,
        -79.56381175114217,
        -79.31476689895135,
        -78.77751811507348,
        -77.9565154130422,
        -77.02052915190896,
        -76.03114440655175,
        -75.37789884810873,
        -73.97599340864002,
        -72.32389130134653,
        -70.73929409958679,
        -68.79220869948203,
        -66.6890649646158,
        -64.53354880241024,
        -62.04573716472552,
        -59.50813276902169,
        -56.82720619693408,
        -53.74616226255521,
        -50.66370625802057
      ],
      "tanDelta": [
        -11.728196004359257,
        -11.27173433754614,
        -12.146528988517645,
        -11.725833689015904,
        -9.647887129291954,
        -8.552230309437645,
        -6.456001225563054,
        -11.987160147750627,
        -9.926300338538208,
        -11.511401075864109,
        -10.402882844274489,
        -10.477175340318356,
        -10.734653619722977,
        -10.69005927726382,
        -11.219230760613282,
        -10.602209443442558,
        -10.559398975526467,
        -11.445705334734185,
        -10.22120712406715,
        -12.128889840025286,
        -11.661665336661322,
        -11.47094852887479,
        -11.340355322208444,
        -10.734495845228953,
        -11.09831154110325,
        -10.867847512110112,
        -10.237556727485396,
        -10.317080696170413,
        -10.067701016219928,
        -10.252563608583007,
        -9.925634435342095,
        -9.459253484431661,
        -9.091651471112451,
        -8.903884686658094,
        -8.639531230626103,
        -8.333098668206384,
        -8.029041564388589,
        -7.692102236103148,
        -7.374902724220845,
        -7.625124795912424,
        -7.140122729909506,
        -6.643516218034797,
        -6.250732624117097,
        -5.429255981626636,
        -5.299837179688319,
        -5.039989025971721,
        -4.687135386901264,
        -4.338567517298124,
        -4.020088903592883,
        -3.832997216700761,
        -3.48190766528844,
        -3.1379310344827585,
        -2.861842105263158,
        -2.5771144278606966,
        -2.3207547169811322,
        -2.099706744868035,
        -1.8843537414965987,
        -1.6982142857142857,
        -1.5297450424929178,
        -1.3636363636363635,
        -1.2201834862385321
      ],
      "nimg": [
        0.00000989480774949088,
        0.00001618887581004967,
        0.00002370326180002458,
        0.000037643149234099896,
        0.00007231712320578813,
        0.0001262350210003289,
        0.00025682722768932085,
        0.000219424438740397,
        0.00041421689269285884,
        0.0005605664314586418,
        0.0009648275727834533,
        0.0015017072505401526,
        0.0022826723546824504,
        0.003578962985061876,
        0.00534215671371819,
        0.008869743660339971,
        0.013886290724633007,
        0.020010551769423703,
        0.03502015295994882,
        0.0462399638435367,
        0.07432948474448059,
        0.11799909552065618,
        0.18884208554666415,
        0.3132115335943178,
        0.47397864947123863,
        0.7540048721723084,
        1.2413960003980558,
        1.9838483331816494,
        3.149718701237254,
        4.732364164666273,
        7.742021693245652,
        12.558288178217913,
        20.70987477654965,
        32.195431820377856,
        52.56937853138412,
        85.2690280354757,
        135.9923202725858,
        225.0035981508039,
        362.5085319012049,
        546.3321281471219,
        894.2686161019068,
        1520.323277666142,
        2459.80046619778,
        4437.445494867093,
        7471.951759116698,
        11620.86241775601,
        19065.897275042265,
        31397.377459218926,
        52955.45869382552,
        85048.36937234513,
        140379.475894983,
        232374.71261378957,
        382936.1571278004,
        638779.163741607,
        1068910.0817509324,
        1718173.801453206,
        2803001.666271001,
        4460616.137448884,
        7090577.554346926,
        11055113.610384146,
        17364541.392211724
      ],
      "nreal": [
        -0.00011604824471148196,
        -0.000182476707354407,
        -0.0002879123565764215,
        -0.00044139730744986174,
        -0.0006977074422045438,
        -0.0010795909727115106,
        -0.0016580768967202168,
        -0.002630275887511436,
        -0.004111641282165369,
        -0.006452905022186314,
        -0.01003698820459198,
        -0.015733650173736565,
        -0.02450369705483354,
        -0.038259326461444525,
        -0.05993488893056388,
        -0.0940388799965712,
        -0.14663088405155245,
        -0.22903487913826748,
        -0.3579482369201501,
        -0.5608394276650088,
        -0.8668055757366058,
        -1.3535615511712267,
        -2.141536349886055,
        -3.3621679060459932,
        -5.260362715663179,
        -8.194409974156724,
        -12.70886197534858,
        -20.467523342398245,
        -31.710426169253214,
        -48.51886461721974,
        -76.84447711764456,
        -118.79203120830473,
        -188.2869634787723,
        -286.66441236580704,
        -454.17478759649845,
        -710.5552239616754,
        -1091.8879919062363,
        -1730.7506804670527,
        -2673.465159471495,
        -4165.850657138223,
        -6385.187672473942,
        -10100.292351830834,
        -15375.55502288091,
        -24092.027496149334,
        -39600.127737804214,
        -58569.01905781749,
        -89364.441800875,
        -136219.64197291553,
        -212885.65187971923,
        -325990.1630891372,
        -488788.3731679151,
        -729175.8223398224,
        -1095902.8180960077,
        -1646206.9990952855,
        -2480678.114252164,
        -3607661.1197668486,
        -5281846.677258962,
        -7575082.047703371,
        -10846775.862173766,
        -15075154.923251107,
        -21187926.652882196
      ],
      "nmod": [
        0.00011646931922620387,
        0.0001831934180774492,
        0.00028888643043478,
        0.00044299953691651696,
        0.0007014452517597991,
        0.001086946157308235,
        0.0016778495821497012,
        0.0026394124968902853,
        0.004132453226280803,
        0.006477207573440551,
        0.010083254656326089,
        0.015805153351228375,
        0.02460978997134858,
        0.03842635862701321,
        0.06017249828179775,
        0.09445625073868677,
        0.1472869485997617,
        0.2299073683986609,
        0.359657269393491,
        0.5627423903349261,
        0.86998665416801,
        1.358695204728695,
        2.1498463366382494,
        3.3767254098049198,
        5.281673187588748,
        8.229026562833468,
        12.769347545519516,
        20.563442464254,
        31.86646914442488,
        48.74910762599779,
        77.23349379241421,
        119.45399650309744,
        189.42248950247938,
        288.4666898398047,
        457.2070398052729,
        715.653221498612,
        1100.3241786138096,
        1745.3150251796876,
        2697.9303909866007,
        4201.522401680697,
        6447.506337372459,
        10214.073059317032,
        15571.072878743624,
        24497.279673366425,
        40298.885591799895,
        59710.756457504394,
        91375.66359422206,
        139791.2235104406,
        219373.1555631328,
        336901.7832600135,
        508547.41273227136,
        765307.3806956899,
        1160880.306985427,
        1765796.2237757596,
        2701172.4619872863,
        3995915.4104006696,
        5979525.287444609,
        8790845.485794146,
        12958735.920545796,
        18694272.72986363,
        27394443.480562806
      ]
    },
    "modelData": {},
    "explanation": [],
    "z01": 0,
    "key": 1,
    "model": {
      "eq": "R1 + 1/(1/(1/(1/(1/(Q1*(w*i)^a1)) + 1/(1/(W1*(w*i)^0.5)))) + 1/(R2))",
      "param": {
        "R1": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        },
        "Q1": {
          "type": "Capacitance",
          "valueSci": 1e-8,
          "valueSciTex": "1.00e-8",
          "valueStep": -2,
          "subType": "Generic Capacitance",
          "subTypeAbr": "Q",
          "units": "(F.s^{\\alpha})",
          "maxRange": 3,
          "minRange": -15,
          "stepRange": 0.05
        },
        "a1": {
          "type": "Constant",
          "valueSci": 1,
          "valueSciTex": "1.000",
          "valueStep": 1,
          "subType": "Surface Roughness",
          "subTypeAbr": "\\alpha",
          "units": "",
          "maxRange": 1,
          "minRange": 0.5,
          "stepRange": 0.01
        },
        "W1": {
          "type": "Capacitance",
          "valueSci": 1e-15,
          "valueSciTex": "1.00e-15",
          "valueStep": -2,
          "subType": "Generic W",
          "subTypeAbr": "Wb",
          "units": "(F.s^{1/2})",
          "maxRange": 0,
          "minRange": -15,
          "stepRange": 0.05
        },
        "R2": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        }
      },
      "smiles": "R-QWR",
      "paramList": [
        "R1",
        "Q1",
        "a1",
        "W1",
        "R2"
      ],
      "eqTeX": "R_{1} + \\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{Q_{1}(\\omega i)^{\\alpha1}}} + \\frac{1}{\\frac{1}{W_{1}(\\omega i)^{0.5}}}}} + \\frac{1}{R_{2}}}",
      "eqT": "''+model.param.R1.valueSci+'+1/(1/(1/(1/(1/('+model.param.Q1.valueSci+'*('+w+'*i)^'+model.param.a1.valueSci+'))+1/(1/('+model.param.W1.valueSci+'*('+w+'*i)^0.5))))+1/('+model.param.R2.valueSci+'))'"
    },
    "analysis": false
  },
  {
    "time": 24,
    "expData": {
      "fHz": [
        1000078,
        794390.6,
        630984.4,
        501234.4,
        398109.4,
        316265.6,
        251203.1,
        199546.9,
        158578.1,
        125953.1,
        100078.1,
        79453.13,
        63140.62,
        50203.12,
        39890.62,
        31640.63,
        25171.88,
        20015.62,
        15890.62,
        12609.37,
        10078.13,
        8015.625,
        6328.125,
        5015.625,
        3984.375,
        3170.956,
        2527.573,
        1976.103,
        1577.524,
        1265.625,
        998.264,
        796.875,
        627.7902,
        505.5147,
        397.9953,
        315.5048,
        252.4038,
        198.6229,
        158.3615,
        125.558,
        100.4464,
        79.00281,
        63.3446,
        50.22321,
        38.42213,
        31.25,
        24.93351,
        19.86229,
        15.625,
        12.40079,
        9.93114,
        7.944915,
        6.317385,
        5.008013,
        3.945707,
        3.158693,
        2.504006,
        1.998082,
        1.584686,
        1.266892,
        0.999041
      ],
      "Mreal": [
        86.88188,
        111.0957,
        150.2825,
        195.2751,
        251.8422,
        287.9404,
        343.6488,
        432.6266,
        570.5812,
        628.5499,
        762.2568,
        958.627,
        1130.657,
        1379.217,
        1707.71,
        2139.566,
        2569.058,
        3409.163,
        4159.003,
        4841.038,
        6145.105,
        7965.78,
        10218.57,
        13294.27,
        17009.31,
        22287.09,
        29051.03,
        38982.51,
        49652.38,
        64765.57,
        87228.84,
        116583.1,
        157897.6,
        209887.5,
        284381.4,
        379953.3,
        496655.9,
        651307.6,
        825394,
        1027867,
        1239651,
        1473673,
        1688474,
        1914156,
        2137856,
        2301822,
        2461648,
        2605854,
        2743545,
        2862277,
        2961372,
        3048278,
        3126936,
        3198238,
        3258541,
        3305541,
        3348179,
        3383989,
        3410069,
        3414077,
        3451574
      ],
      "Mimg": [
        -648.1047,
        -795.5074,
        -966.607,
        -1201.313,
        -1434.361,
        -1823.069,
        -2256.948,
        -2729.34,
        -3293.648,
        -4080.352,
        -5050.293,
        -6215.688,
        -7657.863,
        -9455.112,
        -11658.28,
        -14353,
        -17756.17,
        -21790.31,
        -26853.86,
        -33216.11,
        -40728.01,
        -50078.07,
        -61977.56,
        -76333.91,
        -93829.42,
        -114932.2,
        -140331.8,
        -174035,
        -211760.3,
        -255726.7,
        -312776.3,
        -376430.7,
        -456105.6,
        -538469.1,
        -639205.8,
        -745859.7,
        -851796.4,
        -962337,
        -1055711,
        -1132321,
        -1181547,
        -1205397,
        -1199982,
        -1166623,
        -1114284,
        -1061232,
        -995710,
        -924197.7,
        -850022.4,
        -780000,
        -713000,
        -649000,
        -588000,
        -529000,
        -471000,
        -421000,
        -373000,
        -332000,
        -287000,
        -251000,
        -219000
      ],
      "fRad": [
        6283675.395633547,
        4991303.346081575,
        3964591.911139527,
        3149348.617532976,
        2501395.1327300807,
        1987155.371086336,
        1578355.6270379643,
        1253790.150173234,
        996375.5879604552,
        791386.6673137211,
        628809.2474904493,
        499218.73902542965,
        396724.21587020956,
        315435.50595857366,
        250640.15747828415,
        198803.94152590563,
        158159.5865700877,
        125761.84949808987,
        99843.71010597408,
        79227.00831679106,
        63322.7583398458,
        50363.65722786137,
        39760.782021995816,
        31514.101306322613,
        25034.566458293662,
        19923.70414891295,
        15881.209536423827,
        12416.221335073502,
        9911.875618523169,
        7952.156404399164,
        6272.277697486323,
        5006.913291658733,
        3944.522160631334,
        3176.2425356032963,
        2500.6782212865314,
        1982.3751237046338,
        1585.8998476362947,
        1247.9844869494002,
        995.0146500229201,
        788.9041807988546,
        631.1233446390836,
        496.38929501790045,
        398.00586000916803,
        315.5617351513949,
        241.41336268654402,
        196.34954084936206,
        156.66186368841528,
        124.79844869494003,
        98.17477042468103,
        77.91646152541955,
        62.39919293154347,
        49.9193731947907,
        39.69330061179671,
        31.46627369976436,
        24.791608248835644,
        19.846653447491008,
        15.733133708289527,
        12.55431946494,
        9.95687579169319,
        7.96011720018336,
        6.27715973247
      ],
      "Mmod": [
        653.9022581658702,
        803.2274136340529,
        978.2197719609076,
        1217.0806418019351,
        1456.302156841718,
        1845.6679692493879,
        2282.9605280086294,
        2763.415026851298,
        3342.7054990976158,
        4128.480037579691,
        5107.493985801181,
        6289.1766582338105,
        7740.881795920798,
        9555.175689940661,
        11782.689251715841,
        14511.593698431472,
        17941.05994851653,
        22055.384881172875,
        27174.015214053463,
        33567.03163000184,
        41188.992631783614,
        50707.65963770464,
        62814.3066219671,
        77482.92347815099,
        95358.6738803162,
        117073.16079062741,
        143307.279770781,
        178347.4623057477,
        217503.52524902762,
        263800.53857093415,
        324712.0021949229,
        394070.66765759164,
        482663.4131950753,
        577928.8315277756,
        699611.9177226758,
        837061.0505004877,
        986014.295057516,
        1162021.5537530964,
        1340074.9869902802,
        1529268.2618592463,
        1712538.4419072175,
        1903862.9253541338,
        2071449.0698542409,
        2241651.716138125,
        2410820.837265183,
        2534679.048224449,
        2655400.022596219,
        2764889.954049038,
        2872207.7305666385,
        2966652.9329749714,
        3045996.244643778,
        3116600.6746588503,
        3181740.5218050075,
        3241692.043461871,
        3292404.9642595607,
        3332242.833690396,
        3368891.7489348035,
        3400236.1024083314,
        3422125.010101326,
        3423291.217809113,
        3458514.72130393
      ],
      "Mphz": [
        -82.36471651362797,
        -82.04984016990379,
        -81.16273380662746,
        -80.76724500646978,
        -80.04162614105712,
        -81.02469339476235,
        -81.34249116129773,
        -80.99300433326725,
        -80.1718030433499,
        -81.24281713426666,
        -81.41695063302443,
        -81.23252182839371,
        -81.6011556381034,
        -81.70079905687224,
        -81.66655393014292,
        -81.52149348112586,
        -81.76726940115665,
        -81.1079780358945,
        -81.19623726810777,
        -81.70788506205658,
        -81.41984352363889,
        -80.96184028743704,
        -80.6375693835793,
        -80.12049134653472,
        -79.72505675287142,
        -79.02568586349435,
        -78.30402806309705,
        -77.37458006371614,
        -76.80398801519831,
        -75.78804731818211,
        -74.41697336760997,
        -72.791897947396,
        -70.90483742573109,
        -68.704879724731,
        -66.0158129989487,
        -63.00495218135817,
        -59.75487296282212,
        -55.909943193073474,
        -51.98038557991814,
        -47.76833104820908,
        -43.62527760710813,
        -39.28159350603437,
        -35.401036808976535,
        -31.361105375423765,
        -27.52923076673986,
        -24.751591425455867,
        -22.02279973238094,
        -19.52766799356777,
        -17.21435610072835,
        -15.243547766747383,
        -13.537266555156174,
        -12.019214283000341,
        -10.649735156747871,
        -9.39189395662525,
        -8.224752778981598,
        -7.258222637876493,
        -6.356758845316644,
        -5.603303334389828,
        -4.810819684559948,
        -4.204771554962195,
        -3.630511160095925
      ],
      "tanDelta": [
        -7.459607227652072,
        -7.160559769640049,
        -6.431933192487482,
        -6.151900575137332,
        -5.695475182475376,
        -6.331410944764958,
        -6.567600410651805,
        -6.308766035190624,
        -5.772443957144049,
        -6.491691431340614,
        -6.62544827412494,
        -6.483948397030336,
        -6.772932020940038,
        -6.855420140558011,
        -6.826849992094677,
        -6.708369828273585,
        -6.91154890236032,
        -6.391689103747753,
        -6.456802267274153,
        -6.861361137838621,
        -6.62771588117697,
        -6.286649894925544,
        -6.065189160518546,
        -5.741865480391176,
        -5.516356630574667,
        -5.156895763421783,
        -4.8305275234647445,
        -4.464438026181485,
        -4.264856991749439,
        -3.948497635394856,
        -3.5856982621802604,
        -3.228861644612298,
        -2.8886164197555884,
        -2.5655129533678753,
        -2.24770607360397,
        -1.9630299302572183,
        -1.7150634876178859,
        -1.4775460934280515,
        -1.2790388590176327,
        -1.10162209702228,
        -0.9531287434931283,
        -0.8179541865800622,
        -0.7106902445640264,
        -0.6094712238709906,
        -0.5212156478266076,
        -0.46103999353555575,
        -0.40448918773114595,
        -0.3546621184456228,
        -0.3098263013728588,
        -0.2725103125937846,
        -0.24076677972237193,
        -0.21290709049502704,
        -0.1880435032888425,
        -0.16540357534367361,
        -0.14454321734788667,
        -0.12736190535830594,
        -0.11140384071460935,
        -0.0981090659573657,
        -0.08416251987863002,
        -0.0735191385548715,
        -0.06344931326982994
      ],
      "nimg": [
        0.000013826602192145891,
        0.000022257853770241337,
        0.000037906171270173654,
        0.00006200491711615198,
        0.00010068069482694386,
        0.0001449007984929679,
        0.00021772583701235425,
        0.0003450550316894934,
        0.0005726567439974709,
        0.0007942386774514998,
        0.001212222630379776,
        0.0019202544397099817,
        0.0028499823171114426,
        0.00437242153767285,
        0.006813393420996229,
        0.010762191049020015,
        0.01624345419530756,
        0.027108085747830704,
        0.04165513276285141,
        0.06110338005750507,
        0.09704417749807966,
        0.1581652413358357,
        0.25700123288186455,
        0.4218514712121204,
        0.6794329763344079,
        1.118621810152506,
        1.8292706190527215,
        3.139643612012755,
        5.009382876759505,
        8.144403443092672,
        13.90704369402487,
        23.284425594951205,
        40.02958877399943,
        66.08043864639383,
        113.72170860659293,
        191.66569205627883,
        313.16977597307994,
        521.8875769778758,
        829.5294948481281,
        1302.9047443495213,
        1964.1976652106114,
        2968.7848122245614,
        4242.334522313581,
        6065.868534667737,
        8855.582707639243,
        11723.083181365528,
        15713.128530731454,
        20880.499936099404,
        27945.51989408346,
        36735.20259985379,
        47458.498433607056,
        61064.02794973597,
        78777.42469898524,
        101640.18881028007,
        131437.25761127417,
        166554.0746577546,
        212810.6874370425,
        269547.78468481265,
        342483.8344217318,
        428897.83079090307,
        549862.3815713926
      ],
      "nreal": [
        -0.00010314102164640148,
        -0.0001593786922657212,
        -0.0002438099611927453,
        -0.000381448085268198,
        -0.0005734243987412358,
        -0.0009174265014835586,
        -0.0014299362965718456,
        -0.0021768714641943005,
        -0.003305628961405988,
        -0.005155952416851204,
        -0.008031518334304882,
        -0.012450830696247922,
        -0.019302736494676974,
        -0.029974786672372085,
        -0.04651401482226603,
        -0.0721967577193619,
        -0.11226742801411808,
        -0.17326645629786927,
        -0.26895895566678485,
        -0.4192523573171487,
        -0.6431812363797793,
        -0.994329497824805,
        -1.5587610919149875,
        -2.4222144004051063,
        -3.7479946040333925,
        -5.7686160736466645,
        -8.836342073199564,
        -14.016744330127532,
        -21.364301586297696,
        -32.15815773675314,
        -49.866462405729926,
        -75.18218872036684,
        -115.63012740863871,
        -169.53022131155453,
        -255.61297513565975,
        -376.24549010993854,
        -537.1060481969025,
        -771.112950572292,
        -1061.000458612023,
        -1435.3086566905972,
        -1872.1332526143262,
        -2428.3299662143836,
        -3014.9857591854516,
        -3696.972319664478,
        -4615.668277844292,
        -5404.810194153545,
        -6355.790596110661,
        -7405.522341540705,
        -8658.257068725525,
        -10010.721543682164,
        -11426.429838318803,
        -13000.964524685296,
        -14813.582920470173,
        -16811.650627826373,
        -18998.364094516575,
        -21212.644293601163,
        -23707.9279256028,
        -26445.081386304097,
        -28824.302522628437,
        -31532.199047800234,
        -34888.39050361805
      ],
      "nmod": [
        0.00010406365972059271,
        0.00016092538520317082,
        0.00024673908283280073,
        0.0003864547211528866,
        0.0005821959664774257,
        0.0009287990240241759,
        0.0014464170741374479,
        0.002204049079879501,
        0.0033548649118752627,
        0.005216767236670012,
        0.00812248548535978,
        0.012598038027401545,
        0.019511997216860765,
        0.03029201059945214,
        0.047010380819508986,
        0.0729944969252056,
        0.11343643681419228,
        0.17537420902439785,
        0.2721655193422898,
        0.42368167551882396,
        0.6504611250622901,
        1.0068303699289924,
        1.5798056131596705,
        2.458674696923873,
        3.8090802986014953,
        5.876074043039582,
        9.023700584146521,
        14.36406918761584,
        21.943730290820053,
        33.17345951910587,
        51.76939189491792,
        78.70531097754252,
        122.36296147917278,
        181.95362131500568,
        279.76886900816226,
        422.2515912811699,
        621.7380602735548,
        931.1185883356343,
        1346.7892025101455,
        1938.4714887816788,
        2713.4766229991947,
        3835.423012668873,
        5204.569273946181,
        7103.686747896297,
        9986.277521826501,
        12909.014389644213,
        16949.881484095855,
        22154.842331474752,
        29256.067706011858,
        38074.7902932826,
        48814.673740820035,
        62432.688457395954,
        80158.12423669816,
        103021.16082738283,
        132803.20224542878,
        167899.48202132055,
        214127.1924206549,
        270841.9290989089,
        343694.65700840944,
        430055.37879897776,
        550968.0920518871
      ]
    },
    "modelData": {},
    "explanation": [],
    "z01": 0,
    "key": 2,
    "model": {
      "eq": "R1 + 1/(1/(1/(1/(1/(Q1*(w*i)^a1)) + 1/(1/(W1*(w*i)^0.5)))) + 1/(R2))",
      "param": {
        "R1": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        },
        "Q1": {
          "type": "Capacitance",
          "valueSci": 1e-8,
          "valueSciTex": "1.00e-8",
          "valueStep": -2,
          "subType": "Generic Capacitance",
          "subTypeAbr": "Q",
          "units": "(F.s^{\\alpha})",
          "maxRange": 3,
          "minRange": -15,
          "stepRange": 0.05
        },
        "a1": {
          "type": "Constant",
          "valueSci": 1,
          "valueSciTex": "1.000",
          "valueStep": 1,
          "subType": "Surface Roughness",
          "subTypeAbr": "\\alpha",
          "units": "",
          "maxRange": 1,
          "minRange": 0.5,
          "stepRange": 0.01
        },
        "W1": {
          "type": "Capacitance",
          "valueSci": 1e-15,
          "valueSciTex": "1.00e-15",
          "valueStep": -2,
          "subType": "Generic W",
          "subTypeAbr": "Wb",
          "units": "(F.s^{1/2})",
          "maxRange": 0,
          "minRange": -15,
          "stepRange": 0.05
        },
        "R2": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        }
      },
      "smiles": "R-QWR",
      "paramList": [
        "R1",
        "Q1",
        "a1",
        "W1",
        "R2"
      ],
      "eqTeX": "R_{1} + \\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{Q_{1}(\\omega i)^{\\alpha1}}} + \\frac{1}{\\frac{1}{W_{1}(\\omega i)^{0.5}}}}} + \\frac{1}{R_{2}}}",
      "eqT": "''+model.param.R1.valueSci+'+1/(1/(1/(1/(1/('+model.param.Q1.valueSci+'*('+w+'*i)^'+model.param.a1.valueSci+'))+1/(1/('+model.param.W1.valueSci+'*('+w+'*i)^0.5))))+1/('+model.param.R2.valueSci+'))'"
    }
  },
  {
    "time": 168,
    "expData": {
      "fHz": [
        1000078,
        794390.6,
        630984.4,
        501234.4,
        398109.4,
        316265.6,
        251203.1,
        199546.9,
        158578.1,
        125953.1,
        100078.1,
        79453.13,
        63140.62,
        50203.12,
        39890.62,
        31640.63,
        25171.88,
        20015.62,
        15890.62,
        12609.37,
        10078.13,
        8015.625,
        6328.125,
        5015.625,
        3984.375,
        3170.956,
        2527.573,
        1976.103,
        1577.524,
        1265.625,
        998.264,
        796.875,
        627.7902,
        505.5147,
        397.9953,
        315.5048,
        252.4038,
        198.6229,
        158.3615,
        125.558,
        100.4464,
        79.00281,
        63.3446,
        50.22321,
        38.42213,
        31.25,
        24.93351,
        19.86229,
        15.625,
        12.40079,
        9.93114,
        7.944915,
        6.317385,
        5.008013,
        3.945707,
        3.158693,
        2.504006,
        1.998082,
        1.584686,
        1.266892,
        0.999041
      ],
      "Mreal": [
        96.0382,
        124.9169,
        166.6908,
        203.7003,
        286.6931,
        291.8674,
        396.2633,
        516.7347,
        695.4598,
        839.6821,
        1080.67,
        1373.977,
        1741.616,
        2223.996,
        2806.769,
        3522.042,
        4486.404,
        5605.487,
        7220.536,
        8981.354,
        11522.71,
        14775.53,
        19033.5,
        24208.81,
        30272.39,
        37129.53,
        44649.99,
        53120.04,
        60759.53,
        67897.65,
        75268.72,
        81091.39,
        86431.22,
        90734.89,
        95169.1,
        98670.5,
        101601.9,
        104375,
        106694.4,
        108683,
        110394,
        112050.7,
        113567.9,
        114981.3,
        116534.5,
        117615.6,
        118606.5,
        119493,
        120281.8,
        121000,
        122000,
        123000,
        123000,
        124000,
        124000,
        125000,
        125000,
        126000,
        126000,
        127000,
        127000
      ],
      "Mimg": [
        -534.2557,
        -663,
        -801,
        -990,
        -1220,
        -1490,
        -1800,
        -2230,
        -2660,
        -3220,
        -3880,
        -4680,
        -5620,
        -6730,
        -8050,
        -9670,
        -11500,
        -13700,
        -16200,
        -19100,
        -22200,
        -25700,
        -29400,
        -33000,
        -36200,
        -38800,
        -40500,
        -41300,
        -40700,
        -39800,
        -37500,
        -35100,
        -32000,
        -29400,
        -26700,
        -24100,
        -21800,
        -19700,
        -17800,
        -16000,
        -14400,
        -12900,
        -11700,
        -10600,
        -9460,
        -8720,
        -7940,
        -7230,
        -6620,
        -6080,
        -5660,
        -5400,
        -5230,
        -5140,
        -5140,
        -4880,
        -5060,
        -5080,
        -5590,
        -5500,
        -6330
      ],
      "fRad": [
        6283675.395633547,
        4991303.346081575,
        3964591.911139527,
        3149348.617532976,
        2501395.1327300807,
        1987155.371086336,
        1578355.6270379643,
        1253790.150173234,
        996375.5879604552,
        791386.6673137211,
        628809.2474904493,
        499218.73902542965,
        396724.21587020956,
        315435.50595857366,
        250640.15747828415,
        198803.94152590563,
        158159.5865700877,
        125761.84949808987,
        99843.71010597408,
        79227.00831679106,
        63322.7583398458,
        50363.65722786137,
        39760.782021995816,
        31514.101306322613,
        25034.566458293662,
        19923.70414891295,
        15881.209536423827,
        12416.221335073502,
        9911.875618523169,
        7952.156404399164,
        6272.277697486323,
        5006.913291658733,
        3944.522160631334,
        3176.2425356032963,
        2500.6782212865314,
        1982.3751237046338,
        1585.8998476362947,
        1247.9844869494002,
        995.0146500229201,
        788.9041807988546,
        631.1233446390836,
        496.38929501790045,
        398.00586000916803,
        315.5617351513949,
        241.41336268654402,
        196.34954084936206,
        156.66186368841528,
        124.79844869494003,
        98.17477042468103,
        77.91646152541955,
        62.39919293154347,
        49.9193731947907,
        39.69330061179671,
        31.46627369976436,
        24.791608248835644,
        19.846653447491008,
        15.733133708289527,
        12.55431946494,
        9.95687579169319,
        7.96011720018336,
        6.27715973247
      ],
      "Mmod": [
        542.8190203389432,
        674.6652739734053,
        818.1606338639375,
        1010.7392404671394,
        1253.2329925387419,
        1518.317021963055,
        1843.1018970547693,
        2289.0860075986857,
        2749.411634043917,
        3327.681780017496,
        4027.6851476871925,
        4877.521173355273,
        5883.6745568952065,
        7087.951622860867,
        8525.28311666897,
        10291.437210116184,
        12344.141154864361,
        14802.41482012881,
        17736.294430553862,
        21106.27204584732,
        25012.253911714954,
        29644.66708838033,
        35023.33682346672,
        40927.57605351311,
        47189.592033753586,
        53703.277348974705,
        60281.60255832703,
        67286.16982412954,
        73131.46030143867,
        78702.800938229,
        84092.98550080381,
        88361.88959122649,
        92164.8294670391,
        95379.13956055642,
        98843.55110380243,
        101571.04690929399,
        103914.32087835632,
        106217.84513442175,
        108169.0112340868,
        109854.424075683,
        111329.22004577235,
        112790.82130426216,
        114168.98839181328,
        115468.86744785367,
        116917.83991440314,
        117938.40665092945,
        118871.9708015729,
        119711.5280539013,
        120463.83611374826,
        121152.65742029763,
        122131.22287113972,
        123118.47952277513,
        123111.14043822355,
        124106.48492323034,
        124106.48492323034,
        125095.22133159204,
        125102.37247950176,
        126102.36476767594,
        126123.93944053604,
        127119.03869995242,
        127157.65372166947
      ],
      "Mphz": [
        -79.80930519906175,
        -79.32989534389004,
        -78.24433944932676,
        -78.37320800430003,
        -76.77575589898379,
        -78.91699614797044,
        -77.58458753708312,
        -76.95369523253667,
        -75.34793650826884,
        -75.38442234072474,
        -74.43624672515097,
        -73.63856134855547,
        -72.7819958337262,
        -71.71334402674307,
        -70.77802459484293,
        -69.98718350725613,
        -68.68814505308836,
        -67.74760323817866,
        -65.97688873765628,
        -64.81577853740718,
        -62.56883721487065,
        -60.10439892514127,
        -57.08106268076842,
        -53.73621655400556,
        -50.095815990995554,
        -46.26031918747,
        -42.20975993931592,
        -37.86450703793188,
        -33.81630210684991,
        -30.377825523713884,
        -26.48317114622069,
        -23.405130880605405,
        -20.316396004809747,
        -17.9534076091872,
        -15.671636434112983,
        -13.725607402479381,
        -12.109941457593216,
        -10.68841282945957,
        -9.471517866443177,
        -8.374764550452777,
        -7.431807359008141,
        -6.567347131748306,
        -5.881978754955473,
        -5.267147304312176,
        -4.640961775198134,
        -4.240141472860545,
        -3.8298973710143613,
        -3.4624963932733746,
        -3.150233663321401,
        -2.8765751904191768,
        -2.656243828476617,
        -2.513810236177737,
        -2.434768548408764,
        -2.3736436022556067,
        -2.3736436022556067,
        -2.2356918713667824,
        -2.3180675554946646,
        -2.3087698920225384,
        -2.540266044339309,
        -2.4797637918170983,
        -2.8534046992292477
      ],
      "tanDelta": [
        -5.562949951165265,
        -5.307528444910177,
        -4.805304191953005,
        -4.860081207538722,
        -4.255421564034851,
        -5.105057981809548,
        -4.542434285486443,
        -4.315560770352755,
        -3.824807702760102,
        -3.8347846167019637,
        -3.5903652363811336,
        -3.406170554528933,
        -3.226888131482485,
        -3.0260845792888116,
        -2.868066449358676,
        -2.7455663504296655,
        -2.5633001397110022,
        -2.444033854685596,
        -2.243600752077131,
        -2.1266281231092776,
        -1.9266301069800422,
        -1.7393623105228713,
        -1.5446449680825913,
        -1.3631401130414917,
        -1.1958091184739625,
        -1.0449903351860366,
        -0.9070550743684378,
        -0.77748435430395,
        -0.6698537661499356,
        -0.5861763993304628,
        -0.4982149291232799,
        -0.43284496664812383,
        -0.3702365881217458,
        -0.32402089207360035,
        -0.280553246799644,
        -0.2442472674203536,
        -0.21456291663836996,
        -0.1887425149700599,
        -0.16683162377781777,
        -0.1472171360746391,
        -0.13044187184086092,
        -0.11512645614886832,
        -0.10302206873597206,
        -0.09218890376087242,
        -0.08117767699694083,
        -0.0741398249892021,
        -0.0669440544995426,
        -0.06050563631342422,
        -0.055037420457625344,
        -0.05024793388429752,
        -0.04639344262295082,
        -0.04390243902439024,
        -0.04252032520325203,
        -0.041451612903225805,
        -0.041451612903225805,
        -0.03904,
        -0.04048,
        -0.040317460317460314,
        -0.044365079365079364,
        -0.04330708661417323,
        -0.04984251968503937
      ],
      "nimg": [
        0.00001528376212220253,
        0.00002502691007511416,
        0.00004204488223154567,
        0.00006468013698641196,
        0.00011461327970487274,
        0.0001468769902176508,
        0.0002510608466253269,
        0.0004121381077436313,
        0.0006979896019166639,
        0.0010610263410807926,
        0.0017185974988645724,
        0.002752254458000246,
        0.004389991662545194,
        0.00705055695376309,
        0.011198401039319418,
        0.01771615780334542,
        0.028366310871784373,
        0.044572237307031165,
        0.0723183863293554,
        0.11336227620873734,
        0.18196791014944372,
        0.2933768279207913,
        0.47870034320428095,
        0.7681897625664812,
        1.209223656835931,
        1.863585692825388,
        2.811498072460695,
        4.278277469969533,
        6.129973007979789,
        8.53826893576172,
        12.000221232259006,
        16.195884625182984,
        21.911708561973548,
        28.56673852293392,
        38.05731548741128,
        49.773879232103155,
        64.06577322737789,
        83.63485371131212,
        107.22897396288818,
        137.76451265595549,
        174.91667981815868,
        225.73149970117566,
        285.3422811347149,
        364.37022361040135,
        482.7176868055591,
        599.0113319910121,
        757.0859761753915,
        957.4878634276233,
        1225.1803541754073,
        1552.9452651096692,
        1955.1534926716604,
        2463.973245818631,
        3098.759692547332,
        3940.727179301456,
        5001.692458004363,
        6298.291061045476,
        7945.016060858847,
        10036.386309259988,
        12654.571839202728,
        15954.538960440756,
        20232.08033771458
      ],
      "nreal": [
        -0.00008502280375132811,
        -0.00013283103711187748,
        -0.00020203844883741686,
        -0.00031435071826869097,
        -0.00048772782198087337,
        -0.000749815551254781,
        -0.0011404273974541382,
        -0.0017786070497458324,
        -0.002669676005857313,
        -0.004068807490692194,
        -0.006170392715254925,
        -0.009374648093411425,
        -0.014166011993174151,
        -0.021335581673179983,
        -0.03211775830733535,
        -0.04864088672376713,
        -0.0727113686207306,
        -0.10893605695746453,
        -0.1622535859575463,
        -0.2410794046851826,
        -0.3505848541981575,
        -0.5102885972661784,
        -0.739422076349902,
        -1.0471502797821899,
        -1.446000675118836,
        -1.9474290377935044,
        -2.550183593202555,
        -3.3262937962723997,
        -4.106185505792712,
        -5.0049317412799486,
        -5.9786893706935995,
        -7.01030714042419,
        -8.112516217903131,
        -9.256220099834334,
        -10.67710342447161,
        -12.157133991351884,
        -13.746139160358595,
        -15.785452628626096,
        -17.889183842257978,
        -20.28129700592814,
        -22.81645913166916,
        -25.987667601765686,
        -29.396552100339658,
        -33.59089147774685,
        -39.18590046021212,
        -44.41059532036248,
        -50.68240484992482,
        -57.93341243906937,
        -67.43076628917423,
        -78.03229100716354,
        -90.70630138132458,
        -108.17443518228136,
        -131.76026985384186,
        -163.34949759362487,
        -207.32821963018085,
        -245.8852830232154,
        -321.6142501435661,
        -404.64160675429156,
        -561.4210839773274,
        -690.9446006490091,
        -1008.4178625018369
      ],
      "nmod": [
        0.0000863855922150501,
        0.0001351681569310051,
        0.00020636692305331795,
        0.0003209359658820166,
        0.0005010136048241744,
        0.0007640655804045271,
        0.0011677354998338642,
        0.0018257329643899393,
        0.0027594128833202977,
        0.004204874705955006,
        0.006405257498615854,
        0.009770308668454887,
        0.014830641341087386,
        0.022470367124085684,
        0.03401403511090442,
        0.051766766449019994,
        0.07804864328849337,
        0.11770194919369117,
        0.1776405785775445,
        0.266402486907664,
        0.3949962788651298,
        0.588612279570134,
        0.8808513073030525,
        1.298706748946761,
        1.8849774016406111,
        2.695446436445142,
        3.7957815757087103,
        5.419214752080707,
        7.378165658654116,
        9.897038857873861,
        13.407089028361245,
        17.64797679608971,
        23.365271055363475,
        30.02892206480702,
        39.52669730252223,
        51.23704676009024,
        65.52388603431388,
        85.11151079615013,
        108.71097348324986,
        139.24938763088184,
        176.39851384270608,
        227.22250950273772,
        286.85253123957375,
        365.91530146218764,
        484.30558529691507,
        600.6553727640796,
        758.7805226037481,
        959.2389112666511,
        1227.0345588041607,
        1554.9045098868185,
        1957.256450498113,
        2466.346663495828,
        3101.5596723048866,
        3944.1112763269366,
        5005.987658306076,
        6303.088914338172,
        7951.5228688094985,
        10044.540058092158,
        12667.019462646966,
        15969.493350804465,
        20257.195792536284
      ]
    },
    "modelData": {},
    "explanation": [],
    "z01": 0,
    "key": 3,
    "model": {
      "eq": "R1 + 1/(1/(1/(1/(1/(Q1*(w*i)^a1)) + 1/(1/(W1*(w*i)^0.5)))) + 1/(R2))",
      "param": {
        "R1": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        },
        "Q1": {
          "type": "Capacitance",
          "valueSci": 1e-8,
          "valueSciTex": "1.00e-8",
          "valueStep": -2,
          "subType": "Generic Capacitance",
          "subTypeAbr": "Q",
          "units": "(F.s^{\\alpha})",
          "maxRange": 3,
          "minRange": -15,
          "stepRange": 0.05
        },
        "a1": {
          "type": "Constant",
          "valueSci": 1,
          "valueSciTex": "1.000",
          "valueStep": 1,
          "subType": "Surface Roughness",
          "subTypeAbr": "\\alpha",
          "units": "",
          "maxRange": 1,
          "minRange": 0.5,
          "stepRange": 0.01
        },
        "W1": {
          "type": "Capacitance",
          "valueSci": 1e-15,
          "valueSciTex": "1.00e-15",
          "valueStep": -2,
          "subType": "Generic W",
          "subTypeAbr": "Wb",
          "units": "(F.s^{1/2})",
          "maxRange": 0,
          "minRange": -15,
          "stepRange": 0.05
        },
        "R2": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        }
      },
      "smiles": "R-QWR",
      "paramList": [
        "R1",
        "Q1",
        "a1",
        "W1",
        "R2"
      ],
      "eqTeX": "R_{1} + \\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{Q_{1}(\\omega i)^{\\alpha1}}} + \\frac{1}{\\frac{1}{W_{1}(\\omega i)^{0.5}}}}} + \\frac{1}{R_{2}}}",
      "eqT": "''+model.param.R1.valueSci+'+1/(1/(1/(1/(1/('+model.param.Q1.valueSci+'*('+w+'*i)^'+model.param.a1.valueSci+'))+1/(1/('+model.param.W1.valueSci+'*('+w+'*i)^0.5))))+1/('+model.param.R2.valueSci+'))'"
    }
  },
  {
    "time": 504,
    "expData": {
      "fHz": [
        1000078,
        794390.6,
        630984.4,
        501234.4,
        398109.4,
        316265.6,
        251203.1,
        199546.9,
        158578.1,
        125953.1,
        100078.1,
        79453.13,
        63140.62,
        50203.12,
        39890.62,
        31640.63,
        25171.88,
        20015.62,
        15890.62,
        12609.37,
        10078.13,
        8015.625,
        6328.125,
        5015.625,
        3984.375,
        3170.956,
        2527.573,
        1976.103,
        1577.524,
        1265.625,
        998.264,
        796.875,
        627.7902,
        505.5147,
        397.9953,
        315.5048,
        252.4038,
        198.6229,
        158.3615,
        125.558,
        100.4464,
        79.00281,
        63.3446,
        50.22321,
        38.42213,
        31.25,
        24.93351,
        19.86229,
        15.625,
        12.40079,
        9.93114,
        7.944915,
        6.317385,
        5.008013,
        3.945707,
        3.158693,
        2.504006,
        1.998082,
        1.584686,
        1.266892,
        0.999041
      ],
      "Mreal": [
        102.0665,
        123.2549,
        150.6005,
        195.3394,
        248.7466,
        326.8127,
        418.8678,
        511.6272,
        700.9791,
        836.5432,
        1122.334,
        1452.888,
        1881.205,
        2428.619,
        3113.959,
        3976.764,
        5051.273,
        6397.319,
        8086.191,
        10122.65,
        12446.04,
        15470.09,
        18890.39,
        22766.33,
        26650.52,
        30395.94,
        34158,
        37599.35,
        40890.27,
        43134.21,
        45927.46,
        48559.59,
        50085.37,
        51794.45,
        53099.59,
        54454.75,
        55616.84,
        56725.14,
        57676.15,
        58550,
        59295.96,
        60028,
        60632.56,
        61186.25,
        61771.7,
        62140.91,
        62526.24,
        62910.04,
        63274.64,
        63687.75,
        63979.86,
        64317.05,
        64583.83,
        64812.11,
        64972.39,
        65464.87,
        65792.07,
        66424.4,
        66577.27,
        67154.34,
        67280.76
      ],
      "Mimg": [
        -506.3167,
        -611.4554,
        -750.3153,
        -925.7644,
        -1122.591,
        -1351.895,
        -1677.273,
        -2031.587,
        -2353.458,
        -2871.427,
        -3584.93,
        -4301.446,
        -5134.186,
        -6065.625,
        -7172.104,
        -8427.602,
        -9794.62,
        -11279.5,
        -12929.67,
        -14776.33,
        -16396.26,
        -18057.92,
        -19176.17,
        -20188.65,
        -20431.74,
        -20252.56,
        -19932.59,
        -18652.77,
        -17824.66,
        -16192.37,
        -15179.86,
        -14008.63,
        -12931.36,
        -11960.99,
        -10542.32,
        -9600.16,
        -8737.557,
        -7880.859,
        -7144.97,
        -6471.17,
        -5886.095,
        -5327.416,
        -4856.218,
        -4422.865,
        -3989.169,
        -3713.352,
        -3441.941,
        -3227.377,
        -2998.979,
        -2830,
        -2730,
        -2750,
        -2630,
        -2760,
        -2810,
        -2810,
        -3260,
        -3490,
        -3740,
        -4210,
        -4440
      ],
      "fRad": [
        6283675.395633547,
        4991303.346081575,
        3964591.911139527,
        3149348.617532976,
        2501395.1327300807,
        1987155.371086336,
        1578355.6270379643,
        1253790.150173234,
        996375.5879604552,
        791386.6673137211,
        628809.2474904493,
        499218.73902542965,
        396724.21587020956,
        315435.50595857366,
        250640.15747828415,
        198803.94152590563,
        158159.5865700877,
        125761.84949808987,
        99843.71010597408,
        79227.00831679106,
        63322.7583398458,
        50363.65722786137,
        39760.782021995816,
        31514.101306322613,
        25034.566458293662,
        19923.70414891295,
        15881.209536423827,
        12416.221335073502,
        9911.875618523169,
        7952.156404399164,
        6272.277697486323,
        5006.913291658733,
        3944.522160631334,
        3176.2425356032963,
        2500.6782212865314,
        1982.3751237046338,
        1585.8998476362947,
        1247.9844869494002,
        995.0146500229201,
        788.9041807988546,
        631.1233446390836,
        496.38929501790045,
        398.00586000916803,
        315.5617351513949,
        241.41336268654402,
        196.34954084936206,
        156.66186368841528,
        124.79844869494003,
        98.17477042468103,
        77.91646152541955,
        62.39919293154347,
        49.9193731947907,
        39.69330061179671,
        31.46627369976436,
        24.791608248835644,
        19.846653447491008,
        15.733133708289527,
        12.55431946494,
        9.95687579169319,
        7.96011720018336,
        6.27715973247
      ],
      "Mmod": [
        516.5018597460613,
        623.7543399152988,
        765.2800533231871,
        946.1486170257398,
        1149.8197355640405,
        1390.8366661496561,
        1728.784240559197,
        2095.0198400847758,
        2455.63357535297,
        2990.8021569129646,
        3756.508580112123,
        4540.189558978787,
        5467.979346579594,
        6533.758251403705,
        7818.939598212599,
        9318.751385357376,
        11020.432834736075,
        12967.374855295924,
        15250.011514663882,
        17911.1130667918,
        20584.976406816695,
        23778.39690421749,
        26917.881235732504,
        30428.397435477935,
        33581.3373125312,
        36525.05106139073,
        39548.41473571475,
        41971.859014051304,
        44606.41977102063,
        46073.342819259386,
        48371.062957838745,
        50539.84067490716,
        51727.79097822079,
        53157.59901070213,
        54136.004370017,
        55294.51030245317,
        56299.00348952767,
        57269.96984980419,
        58117.02741127939,
        58906.523757296185,
        59587.38949367244,
        60263.937352591354,
        60826.72262597356,
        61345.895737781226,
        61900.37471777179,
        62251.76044662516,
        62620.904228421045,
        62992.77018120197,
        63345.67027170871,
        63750.59529182845,
        64038.07762276754,
        64375.813942058245,
        64637.357599679926,
        64870.850176732696,
        65033.126653361054,
        65525.15016477948,
        65872.78705873087,
        66516.02074207386,
        66682.23512040445,
        67286.17600098552,
        67427.10335004462
      ],
      "Mphz": [
        -78.60270276109141,
        -78.60325378273399,
        -78.65061129568336,
        -78.08517180297855,
        -77.50612165775206,
        -76.40981467554857,
        -75.97823971326764,
        -75.8647801756347,
        -73.41380059092266,
        -73.75738340540735,
        -72.61622767935158,
        -71.33671092834206,
        -69.87677971976922,
        -68.17930213845949,
        -66.53064728875172,
        -64.73860634796247,
        -62.71897582880607,
        -60.43968150204752,
        -57.97821759001884,
        -55.58657656532444,
        -52.79866693465157,
        -49.413573833737566,
        -45.43013291096341,
        -41.565862995630305,
        -37.47573904874766,
        -33.675188098006686,
        -30.265309589430927,
        -26.385682457711074,
        -23.55308337454957,
        -20.575888018092094,
        -18.289659114018438,
        -16.09196656781129,
        -14.476839827245945,
        -13.003464702221077,
        -11.229400112368513,
        -9.998283956103304,
        -8.92834597647215,
        -7.909508585068323,
        -7.061871130059936,
        -6.306950990023639,
        -5.668972678556542,
        -5.0716470545955845,
        -4.579191779327241,
        -4.1344502152186555,
        -3.694986569401019,
        -3.4197550621000756,
        -3.150834834957421,
        -2.9367826563628214,
        -2.713572880696252,
        -2.5442955910104614,
        -2.4433103135827317,
        -2.4483007545319295,
        -2.331925615071002,
        -2.4384465262069677,
        -2.47644990999416,
        -2.45784297249886,
        -2.836688393502627,
        -3.007608239426444,
        -3.2152300004586385,
        -3.587258333900175,
        -3.7755954625880497
      ],
      "tanDelta": [
        -4.960655063120613,
        -4.960901351589268,
        -4.982156765747789,
        -4.739260999061121,
        -4.512990328310015,
        -4.136604850423499,
        -4.004301595873447,
        -3.970834623335116,
        -3.3573868322179647,
        -3.4324909938900947,
        -3.194173926834614,
        -2.9606177489249,
        -2.729200698488469,
        -2.4975613712978446,
        -2.3032108001421987,
        -2.119210996679713,
        -1.9390399212238183,
        -1.7631604739422873,
        -1.5989815229444866,
        -1.4597294186798913,
        -1.3173876992199927,
        -1.1672795698021148,
        -1.0151283271547067,
        -0.8867766565801339,
        -0.7666544592750911,
        -0.6662916165777404,
        -0.5835408981790503,
        -0.496092884584441,
        -0.4359144608240543,
        -0.3753950750460018,
        -0.33051816930437694,
        -0.2884832841463447,
        -0.2581863725874442,
        -0.23093188555916705,
        -0.19853863278417028,
        -0.17629609905471974,
        -0.15710272284437593,
        -0.1389306222955113,
        -0.12388084156102652,
        -0.11052382578992315,
        -0.09926637497731718,
        -0.08874885053641635,
        -0.08009257732149196,
        -0.07228527651228829,
        -0.06457923288496188,
        -0.05975696203998299,
        -0.055047944670909364,
        -0.05130146157910565,
        -0.04739622382679696,
        -0.0444355468673332,
        -0.04266967761417421,
        -0.04275693614679155,
        -0.04072226747778817,
        -0.04258463426048002,
        -0.043249140134755705,
        -0.04292378492464737,
        -0.049550044557041595,
        -0.05254093375325935,
        -0.056175328306492585,
        -0.06269140609527248,
        -0.06599212018413586
      ],
      "nimg": [
        0.000016243121035648155,
        0.000024693930914209273,
        0.00003798638129106042,
        0.00006202533403654055,
        0.00009944314544520288,
        0.00016446258040776066,
        0.0002653823972396336,
        0.00040806445953440405,
        0.0007035289789012985,
        0.001057060011940254,
        0.0017848560664131242,
        0.002910323444260756,
        0.00474184565687174,
        0.007699256913452705,
        0.012424022675894616,
        0.02000344645823734,
        0.03193782374842989,
        0.0508685187561365,
        0.08098848682022453,
        0.1277676667977206,
        0.1965492395830827,
        0.30716772473469,
        0.4751010679203886,
        0.7224172372458686,
        1.0645488926040894,
        1.5256169120368321,
        2.1508437327558734,
        3.028244180360161,
        4.1253816708095945,
        5.424215496583793,
        7.3222937846654785,
        9.69850827672567,
        12.69744926264622,
        16.306830923464773,
        21.234075439214923,
        27.46944780977465,
        35.06957900455956,
        45.453401539197124,
        57.96512644177795,
        74.21687123106828,
        93.95304500090897,
        120.92927990688298,
        152.340872565553,
        193.89629091323476,
        255.87523123235567,
        316.4810558313149,
        399.1158953933959,
        504.0931250177527,
        644.5101906150505,
        817.3850397354407,
        1025.3315306528186,
        1288.418621544546,
        1627.0712942628388,
        2059.7326082651266,
        2620.7412342057914,
        3298.5344442680334,
        4181.752422617196,
        5290.959831434992,
        6686.562270420608,
        8436.350660572323,
        10718.344421279477
      ],
      "nreal": [
        -0.00008057652060636895,
        -0.00012250415524835278,
        -0.00018925410655553192,
        -0.0002939542465531149,
        -0.0004487859536109267,
        -0.0006803167078279075,
        -0.0010626711567833858,
        -0.0016203564844717428,
        -0.0023620189298469703,
        -0.003628348970986278,
        -0.005701140710489392,
        -0.00861635524419064,
        -0.012941448478858865,
        -0.019229366654737345,
        -0.02861514320833206,
        -0.04239152370579042,
        -0.06192871524521569,
        -0.08968936163881176,
        -0.1294990939967721,
        -0.18650622198072273,
        -0.2589315505177964,
        -0.35855060958540336,
        -0.4822885523074387,
        -0.6406227423007488,
        -0.8161411556312852,
        -1.0165057585993613,
        -1.2551052836551437,
        -1.5022903906609184,
        -1.7983135267244006,
        -2.0362237834057586,
        -2.4201511368164517,
        -2.797857518990328,
        -3.2783083662357453,
        -3.7657672126502537,
        -4.215784306137661,
        -4.842756492050485,
        -5.509526350622265,
        -6.314869361288408,
        -7.180768644798763,
        -8.20273254661575,
        -9.326378195320984,
        -10.732334587932414,
        -12.201373115180106,
        -14.015847003370267,
        -16.52422614724777,
        -18.911946439685625,
        -21.970509726896108,
        -25.860714085389542,
        -30.54734925304251,
        -36.320951241821184,
        -43.75056586060355,
        -55.08883273171735,
        -66.25803245040231,
        -87.71295979735498,
        -113.34480489509887,
        -141.5855830523023,
        -207.20601886719874,
        -277.9919699945822,
        -375.6198307826841,
        -528.8866852240596,
        -707.3262732240372
      ],
      "nmod": [
        0.00008219741269655216,
        0.00012496822907086532,
        0.00019302870773986564,
        0.00030042676500097973,
        0.00045967137319448626,
        0.0006999133970029303,
        0.001095307173455919,
        0.0016709493528843805,
        0.0024645661786832445,
        0.003779191993548398,
        0.0059740033962671305,
        0.009094589613847638,
        0.013782822242362115,
        0.020713452125651916,
        0.031195877296279005,
        0.0468740776155239,
        0.06967919601795633,
        0.10311056100914673,
        0.15273883050296833,
        0.22607332331890903,
        0.32508022307461004,
        0.472134038968544,
        0.6769957698729726,
        0.9655486329661936,
        1.3413987962793776,
        1.8332460062846074,
        2.490264651757776,
        3.3804051878077153,
        4.500300597765856,
        5.793817484999595,
        7.711881598804837,
        10.094011565789247,
        13.113829475847485,
        16.736001238836554,
        21.648528750798448,
        27.893061026270143,
        35.49972186039273,
        45.88996934552939,
        58.4082127935912,
        74.66879399428036,
        94.41480813508537,
        121.40458699943994,
        152.8287111766958,
        194.40220059745116,
        256.4082370127311,
        317.04561252009375,
        399.7201536742071,
        504.75603535091074,
        645.2336990215531,
        818.1916124493204,
        1026.2645174437118,
        1289.5957986262563,
        1628.4198240866353,
        2061.5993744826064,
        2623.191121794827,
        3301.571740451945,
        4186.882809241212,
        5298.257777160345,
        6697.104243887026,
        8452.912728399979,
        10741.658046594383
      ]
    },
    "modelData": {},
    "explanation": [],
    "z01": 0,
    "key": 4,
    "model": {
      "eq": "R1 + 1/(1/(1/(1/(1/(Q1*(w*i)^a1)) + 1/(1/(W1*(w*i)^0.5)))) + 1/(R2))",
      "param": {
        "R1": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        },
        "Q1": {
          "type": "Capacitance",
          "valueSci": 1e-8,
          "valueSciTex": "1.00e-8",
          "valueStep": -2,
          "subType": "Generic Capacitance",
          "subTypeAbr": "Q",
          "units": "(F.s^{\\alpha})",
          "maxRange": 3,
          "minRange": -15,
          "stepRange": 0.05
        },
        "a1": {
          "type": "Constant",
          "valueSci": 1,
          "valueSciTex": "1.000",
          "valueStep": 1,
          "subType": "Surface Roughness",
          "subTypeAbr": "\\alpha",
          "units": "",
          "maxRange": 1,
          "minRange": 0.5,
          "stepRange": 0.01
        },
        "W1": {
          "type": "Capacitance",
          "valueSci": 1e-15,
          "valueSciTex": "1.00e-15",
          "valueStep": -2,
          "subType": "Generic W",
          "subTypeAbr": "Wb",
          "units": "(F.s^{1/2})",
          "maxRange": 0,
          "minRange": -15,
          "stepRange": 0.05
        },
        "R2": {
          "type": "Resistance",
          "valueSci": 10000000000000000,
          "valueSciTex": "1.00e+16",
          "valueStep": 1,
          "subType": "Generic Resistance",
          "subTypeAbr": "R",
          "units": "(Ohm)",
          "maxRange": 16,
          "minRange": -2,
          "stepRange": 0.05
        }
      },
      "smiles": "R-QWR",
      "paramList": [
        "R1",
        "Q1",
        "a1",
        "W1",
        "R2"
      ],
      "eqTeX": "R_{1} + \\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{\\frac{1}{Q_{1}(\\omega i)^{\\alpha1}}} + \\frac{1}{\\frac{1}{W_{1}(\\omega i)^{0.5}}}}} + \\frac{1}{R_{2}}}",
      "eqT": "''+model.param.R1.valueSci+'+1/(1/(1/(1/(1/('+model.param.Q1.valueSci+'*('+w+'*i)^'+model.param.a1.valueSci+'))+1/(1/('+model.param.W1.valueSci+'*('+w+'*i)^0.5))))+1/('+model.param.R2.valueSci+'))'"
    }
  }

]
