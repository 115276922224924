import React, { Component, lazy, Suspense  } from 'react';
//import Plot from 'react-plotly.js';
import { Radio, Checkbox } from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';


const RadioGroup = Radio.Group;
const Plot = lazy(() => import('react-plotly.js'));

const dataForGraph = (coatingData) => {
  // get the CC inmersion times and z01Hz for each analized sample
  let cc = []
  let z01Hz = []
  let inmersionTime = []
  for (let i=0; i< coatingData.length; i++){
    if (coatingData[i].analysis){
      cc.push(coatingData[i].model.param.Q1.valueSci)
      z01Hz.push(coatingData[i].z01)
      inmersionTime.push(coatingData[i].time)
    }
  }

  //asumimos que estan en orden ojo!
  let waterUptake = []
  for (let i=0; i< cc.length; i++){
    waterUptake.push((Math.log(cc[i]/cc[0])/Math.log(10))/(Math.log(80)/Math.log(10)))
  }
  const timeScale = (hours) => { //returns a temporal time string (h, days, weeks)
    //how many days?
    const days = Math.floor(hours/24)
    if (days === 1 ) {return " 1 day"}
    else if (Math.floor(hours) === 0){ return "initial"}
    else if (days >= 7) {
      const weeks = Math.floor(days/7)
      return weeks+" week"+ (weeks ===1 ? "" : "s")
    } else {
      return days+" days"
    }

  }
  const inmersionTimeText = inmersionTime.map(hours => hours+" h<br>"+ timeScale(hours))
  const maxZ01Range = z01Hz.length ? Math.ceil(Math.log(Math.max(...z01Hz))/Math.log(10)) : 10
  const maxWaterRange =  waterUptake.length ? (
    Math.max(...waterUptake) < 1 ? Math.ceil(100*Math.max(...waterUptake))/100 : Math.ceil(10*Math.max(...waterUptake))/10
  ) : 0.5

  const result = {
    inmersionTime:inmersionTime,
    inmersionTimeText:inmersionTimeText,
    waterUptake: waterUptake,
    z01Hz: z01Hz,
    maxZ01Range: maxZ01Range,
    maxWaterRange: maxWaterRange
  }
  //console.log("properties=",result)

  return result
}

class PlotModule extends Component {
  state = {
    realTime: false
  }

  onChangeTime = (e) => {
    this.setState({
      realTime: e.target.checked
    })//updates the state
  }

    render() {
      // make a selector to select which plot to makes
    //  console.log("propertiesGraph props:",this.props)
      const { coatingData, size } = this.props;

      const { plotsCoating } = this.props.rules;
      const { varNames } = this.props.rules;
      const graphWidth = size
      const graphHeigth = graphWidth/1.4

      const data = dataForGraph(coatingData)
      const inmersion = this.state.realTime ? data.inmersionTime : data.inmersionTimeText


        return (
          <div className = "columnDiv">
            <div className = "centerDiv">
              <Checkbox onChange={this.onChangeTime}>Time scale</Checkbox>
            </div>
            <div className = "centerDiv">
            <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
            <Plot
              config={{displayModeBar: false}}
              data={[
                {

                  type: 'bar',

                  x: inmersion,
                  y: data.z01Hz,
                },
                {
                  x: inmersion,
                  y: data.waterUptake,
                  mode: 'lines+markers',
                  line: {shape: 'spline', color: '#ff7f0e'},
                  type: 'scatter',
                  yaxis: 'y2',
                },

                ]}
                layout = {
                  {
                      margin: {b: 75,t: 10,l:60,r:60},
                      showlegend: false,
                      autosize: false,
                      width: graphWidth,
                      height: graphHeigth,

                      xaxis: {

                        title: this.state.realTime ? "Inmersion Time (h)" : "Inmersion Time",
                        mirror: 'ticks',
                        showgrid: false,
                        linewidth: 2,

                        ticks: 'inside',
                      },
                      yaxis: {
                        ////fixedrange: true,
                        title: {
                          "text":"Impedance (Z = 0.1Hz, Ohm)",
                          "font": {"color": '#1f77b4'}
                        },
                        showgrid: false,
                        hoverformat: '.2e',
                        linewidth: 2,
                        type: 'log',
                        exponentformat: 'power',
                        dtick:'1',
                        ticks: 'inside',
                        range: [4, data.maxZ01Range]


                      },
                      yaxis2: {
                        ////fixedrange: true,
                       title: {
                         "text":"Water Uptake",
                         "font": {"color": '#ff7f0e'}
                          },
                				showgrid: false,
                				ticks: 'inside',
                				linewidth: 2,
                				overlaying: 'y',
                				side: 'right',
                        range: [0,data.maxWaterRange]
                      }
                      }
                }

    />
              </Suspense>
            </div>
          </div>
        );
    }
}

export default PlotModule;
