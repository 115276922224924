

import {getModel, fHzGen, getModelData} from '../sinTools/algorithms/getModelFromStr';
import { checkParent } from '../utils/string';
import {extendData} from '../sinTools/algorithms/extendData';


const UpdateSinData = (smile, mainState) => { // mainState: this.props that contain SinData and updateData function of the main statate
  let sinData = mainState.sinData;// creates a copy of the sinData objet from the props (the main state)
  let paramIni = sinData.model.param;// to get the initial parametres (to be used later for update only the new ones)
  sinData.model = getModel(smile, sinData.rules); //modify the model object on the sinData copy acoording to entered component(s)

  const minHz = 0.01;
  const maxHz = 1000000

  sinData.modelData.fHz = sinData.expData.fHz ? sinData.expData.fHz : fHzGen(minHz,maxHz,10);
  sinData.modelData.fRad = sinData.modelData.fHz.map(a => a*2*Math.PI);

  // to keep the old values if they exist already:
for (var i = 0; i < Object.keys(sinData.model.param).length; i++) {
 if (paramIni[Object.keys(sinData.model.param)[i]]) {
   sinData.model.param[Object.keys(sinData.model.param)[i]] = paramIni[Object.keys(sinData.model.param)[i]];
   }
}

  sinData.modelData = getModelData(sinData.model, sinData.modelData.fRad);// generates modeled data using the expData frequency points

  sinData.modelData = extendData(sinData.modelData); //generates all the derived data points




  mainState.updateData('model',sinData.model); // update the App state model
  mainState.updateData('expData',sinData.expData); // update the App state expData
  mainState.updateData('modelData',sinData.modelData); // update the App state expData

}

export default UpdateSinData
