import React, { Component } from 'react';
import { InlineMath } from 'react-katex'

import { Checkbox } from 'antd';

import ParamRange from './paramRange'
import NumImput from './numberInput'
import {extendData} from "../../sinTools/algorithms/extendData";
import {fHzGen, getModelData} from '../../sinTools/algorithms/getModelFromStr';

class ParamPanel extends Component {

  updateParamData = (item,data) => { //item to change with the new data
    let sinData = this.props.sinData;// creates a copy of the sinData objet from the props
    let param = sinData.model.param; //gets a copy of the initial param object from the copy
    param[item].valueStep = data; //changes the selected item from the object with the new data
    if (param[item].type === "Constant") {
      param[item].valueSci = data;
      param[item].valueSciTex = data.toFixed(3);
    } else {
      param[item].valueSci = Number(Math.pow(10,data));
      param[item].valueSciTex = Number.parseFloat(Math.pow(10,data)).toExponential(2);// Number.parseFloat(Math.pow(10,data)).toExponential(2)
    }

    sinData.model.param = param; //updates the copy
    const minHz = 0.01;
    const maxHz = 1000000

    sinData.modelData.fHz = sinData.expData.fHz ? sinData.expData.fHz : fHzGen(minHz,maxHz,10);
    sinData.modelData.fRad = sinData.modelData.fHz.map(a => a*2*Math.PI);

    sinData.modelData = getModelData(sinData.model, sinData.modelData.fRad);// generates modeled data using the expData frequency points
    sinData.modelData = extendData(sinData.modelData); //generates all the derived data points

    this.props.updateData('model',sinData.model); // update the App state model
    this.props.updateData('modelData',sinData.modelData); // update the App state expData
  }

  fixValue = (e) => {
    console.log(`${e.target.id} = ${e.target.checked}`);
    let sinData = this.props.sinData;// creates a copy of the sinData objet from the props
    let param = sinData.model.param; //gets a copy of the initial param object from the copy
    param[e.target.id].fixed = e.target.checked
    sinData.model.param = param; //updates the copy

    this.props.updateData('model',sinData.model);

}

  render() {
    const { param } = this.props.sinData.model;
    const paramList = Object.keys(param).map(key => { //it draws the parametres list including slicers names and values
      //define ranges of the slide
      const maxRange = param[key].maxRange;
      const minRange = param[key].minRange;
      const stepRange = param[key].stepRange;

      const baseVal = param[key].valueSciTex.slice(0, 4);
      const expVal = param[key].valueSciTex.slice(5);


//<InlineMath>{param[key].subTypeAbr + "_{" + key[1] + "}"}</InlineMath> <InlineMath>{param[key].units}</InlineMath>
      return (
        <tr key={key+"_tr"}>

          <td key={key+"_td1"}>
          <Checkbox id={key} onChange={this.fixValue}></Checkbox>
            <InlineMath>{param[key].subTypeAbr + "_{" + key[1] + "}"}</InlineMath> <InlineMath>{param[key].units}</InlineMath>
          </td>
          <td key={key+"_td2"} className="centerContent">
            <ParamRange id={key} key={key} paramObj= {param[key]} maxRange={maxRange} minRange={minRange} stepRange={stepRange} updateParamData = {this.updateParamData}/>
          </td>
          <td key={key+"_td3"} className="centerContent">
            <NumImput id={key} key={key} paramObj= {param[key]} updateParamData = {this.updateParamData} />
          </td>
        </tr>
      )
    });
    /*<table>
      <thead className="centerContent">
        <tr>
          <th>Element</th>
          <th>Range</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {paramList}
      </tbody>
    </table>*/
    return(
      <div>
        <table>
          <tbody>
            {paramList}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ParamPanel
