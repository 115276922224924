import React, { lazy, Suspense } from 'react'
import {Helmet} from 'react-helmet'
import { Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'

import NavBarCoating from '../../../../Common/componentsHTM/navBars/navBarCoatings'

//Tools
import Electrolite from './electrolite'
import CoatingThickness from './thickness'
//import Epoxy from './epoxy'
import WaterUptake from './waterUptake'

const Epoxy = lazy(()=>import('./epoxy'))

function ToolBoxMenu(props) {
  return (
    <div style={{maxWidth: '850px', margin: '10px', textAlign: 'justify'}}>
      <ul>
        <li><Link to="/coating_thickness">Thinkness and Coating Capacitance</Link></li>
        <li><Link to="/epoxy_selector">Epoxy-Amine Selector</Link></li>
      </ul>
    </div>
  )
}

function CoatingToolBox(props) {
  const state = {
    tool: props.tool ? props.tool : 0,
    toolList: [
      <ToolBoxMenu />,
      <Electrolite />,
      <Suspense fallback={<div style={{ textAlign:'center', paddingTop: '100px'}}><LoadingOutlined /> Loading...</div>}><CoatingThickness /></Suspense>,
      <Suspense fallback={<div style={{ textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}><Epoxy /></Suspense>,
      <WaterUptake />
    ],
  }

  return (
    <div>
      <Helmet>
        <title>Protective Coatings ToolBox</title>
        <meta name="description" content="Interactive monograph for protective coating modellig" />
        <meta name="keywords" content="EIS, circuit, Impedance, modelling, coating, corrosion" />
      </Helmet>
      <div style ={{textAlign:'center'}} ><NavBarCoating current='/coatingToolBox'/></div>
      <div className = "centerDiv">
        <div style={{ margin: '10px', textAlign: 'justify'}}>
          {state.toolList[state.tool]}
        </div>
      </div>
    </div>
  )
}

export default CoatingToolBox
