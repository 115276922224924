/*
General layout
 */

 export const dataHz_MrealMimgPC = (expData,modelData, varNames) => {// todo finalize this func and test to plot it
     const traceHzVsMmod = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.Mreal ? modelData.Mreal : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#1f77b4'},
         type: 'scatter',
         name: varNames.Mreal + ' Model'
     };
     const traceHzVsMmodR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.Mreal ? expData.Mreal : [],
         mode: 'markers',
         marker: {color: '#1f77b4'},
         type: 'scatter',
         name: varNames.Mreal + ' Real'
     };

     const traceHzVsMphz = {
         x: modelData.fHz ? modelData.fHz : [],
         y: modelData.Mimg ? (varNames.Mmod === 'Zmod' ? modelData.Mimg.map(a=> -a) : modelData.Mimg) : [],
         mode: 'lines',
         line: {shape: 'spline', color: '#ff7f0e'},
         type: 'scatter',
         //yaxis: 'y2',
         name: varNames.Mimg + ' Model'
     };
     const traceHzVsMphzR = {
         x: expData.fHz ? expData.fHz : [],
         y: expData.Mimg ? (varNames.Mmod === 'Zmod' ? expData.Mimg.map(a=> -a) : expData.Mimg) : [],
         mode: 'markers',
         marker: {color: '#ff7f0e'},
         type: 'scatter',
         //yaxis: 'y2',
         name: varNames.Mimg + ' Real'
     };
  const MimgDivMrealModel = modelData.Mreal ? modelData.Mreal.map((point,index)=>{return -modelData.Mimg[index]/point}) : []
  const MimgDivMrealExp = expData.Mreal ? expData.Mreal.map((point,index)=>{return -expData.Mimg[index]/point}) : []
  console.log({MimgDivMrealModel,MimgDivMrealExp})
  const traceHzVsMimgDivMreal = {
         x: modelData.fHz ? modelData.fHz : [],
         y: MimgDivMrealModel,
         mode: 'lines',
         line: {shape: 'spline', color: 'black'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mimg+'/'+varNames.Mreal+ ' Model'
     };
     const traceHzVsMimgDivMrealR = {
         x: expData.fHz ? expData.fHz : [],
         y: MimgDivMrealExp,
         mode: 'markers',
         marker: {color: 'black'},
         type: 'scatter',
         yaxis: 'y2',
         name: varNames.Mimg+'/'+varNames.Mreal+ ' Real'
     };


   return [traceHzVsMmod, traceHzVsMmodR, traceHzVsMphz, traceHzVsMphzR,traceHzVsMimgDivMreal,traceHzVsMimgDivMrealR];
 }

 export const layoutHz_MrealMimgPC = (expData ,modelData, varNames) =>{
   const xMax = expData.fHz ? Math.max(...expData.fHz) : (
                   modelData.fHz ? Math.max(...modelData.fHz) : 100000
                 );
   const xMin = expData.fHz ? Math.min(...expData.fHz) : (
                   modelData.fHz ? Math.min(...modelData.fHz) : 0.01
                 );
   const xMaxLog = Math.log(xMax)/Math.log(10);
   const xMinLog = Math.log(xMin)/Math.log(10);

   const xMaxLogCeil = Math.ceil(xMaxLog);
   const xMinLogFloor = Math.floor(xMinLog);

   const yMax = expData.Mreal ? Math.max(...expData.Mreal) : (
                   modelData.Mreal ? Math.max(...modelData.Mreal) : 1000
                 );
   const yMin = expData.Mreal ? Math.min(...expData.Mreal) : (
                   modelData.Mreal ? Math.min(...modelData.Mreal) : 0
                 );
   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

   const y2Max = expData.Mimg ? Math.abs(Math.max(...expData.Mimg)) : (
                   modelData.Mimg ? Math.abs(Math.max(...modelData.Mimg)) : 1000
                 );
   const y2Min = expData.Mimg ? Math.abs(Math.min(...expData.Mimg)) : (
                   modelData.Mimg ? Math.abs(Math.min(...modelData.Mimg)) : 0
                 );
   const y2MaxLog = Math.log(y2Max)/Math.log(10);
   const y2MinLog = Math.log(y2Min)/Math.log(10);

   const y2MaxLogCeil = Math.ceil(y2MaxLog);
   const y2MinLogFloor = Math.floor(y2MinLog);

   const yMaxTotal = (y2MaxLogCeil > yMaxLogCeil) ? y2MaxLogCeil : yMaxLogCeil
   const yMinTotal = (y2MinLogFloor < yMinLogFloor) ? y2MinLogFloor : yMinLogFloor

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: true,
       xaxis: {
         ////fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         ////fixedrange: true,
         title: {
           "text": varNames.Mmod === 'Zmod' ? ("["+varNames.Mreal+"] [-"+varNames.Mimg+"]"):("["+varNames.Mreal+"] ["+varNames.Mimg+"]"),
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
        // range:[-1, yMaxTotal]
       },
       yaxis2: {
        ////fixedrange: true,
 				title: {
          "text": varNames.Mmod === 'Zmod' ? ("-"+varNames.Mimg+" / "+varNames.Mreal) :varNames.Mimg+" / "+varNames.Mreal,
        },
 				showgrid: false,
 				//hoverformat: '.2e',
        linewidth: 2,
        //type: 'log',
        //exponentformat: 'power',
 				//dtick:'1',
 				ticks: 'inside',
 				overlaying: 'y',
 				side: 'right',
      //  range:[-1, yMaxTotal]
       }
       }
   };
