//Nonlinear Least Squares (NLS) strategy
//Macdonald and Garber (J.R. Macdonald and J.A. Garber, J. Electrochem. Soc., 124 (1977) 1022).

export const errorNLS = (dataA,dataB) => { //requires two datasets with the same frequency
  const dataPoints = dataA.fHz.length //takes the frequency of the first one to get the number of points
  let error = 0
  let errorPoints = []
  let resZreal = []
  let resZimg = []

  for (let i=0; i < dataPoints; i++){
    let imgDif = dataA.Mreal[i]-dataB.Mreal[i]
    let realDif = dataA.Mimg[i]-dataB.Mimg[i]
    let wZmod = 1/Math.sqrt((dataA.Mreal[i]**2)+(dataA.Mimg[i]**2)) // weigthening
    let zRealRes = imgDif/Math.sqrt(((dataB.Mreal[i])**2)+((dataB.Mimg[i])**2))// the Zreal residual
    let zImgRes = realDif/Math.sqrt(((dataB.Mreal[i])**2)+((dataB.Mimg[i])**2)) // the Zimg residual
    let errorPoint = ((wZmod**2) * (realDif**2 + imgDif**2))// /dataA.fHz[i] //gets the error of the individual point
    error = error + errorPoint // sum the error to the total error variable
    errorPoints.push(errorPoint) // populate the errorPoints array that will containg all the errors of the individual points
    resZreal.push(zRealRes)
    resZimg.push(zImgRes)
  }

  return { // returns an object containing the total error and a array of individual point errors
    errorPoints: errorPoints,
    totalError: error/dataPoints,
    resZreal: resZreal,
    resZimg: resZimg
  }
}
//Math.sqrt((((dataA.Mreal[i]-dataB.Mreal[i])/dataA.Mreal[i])**2)+(((dataA.Mimg[i]-dataB.Mimg[i])/dataA.Mimg[i])**2))
