import React, {useState, useEffect} from 'react'
import { InlineMath } from 'react-katex'
import { LoadingOutlined } from '@ant-design/icons'
import {fit, coatingFit} from '../sinTools/algorithms/fittingError/fit'
import {fHzGen, getModelData} from '../sinTools/algorithms/getModelFromStr';
import {extendData} from '../sinTools/algorithms/extendData';

import { Button } from 'antd'
import PlotModuleError from './plotError'

const colorZ01 = (z01) => {
  if(z01 > 100000000) return "green"
}

function DataFitter (props){
  const [computing, setComputing] = useState(false)
  const [explanation, setExplanation] = useState(["explanation"])
  const {expData, expDataClean, model, modelData } = props.sinData
//console.log(props)

  const fitLoader = (action)=>{
    setComputing(true)
    setTimeout(() => action(),1)
  }

  const updateParams = (result) =>{
    let sinData = props.sinData;// creates a copy of the sinData objet from the props
    let param = sinData.model.param; //gets a copy of the initial param object from the copy
    //console.log(result)
    for(let i=0; i < result.length; i++){ //updates the fitted parametres
      let item = result[i][0]
      let data = result[i][1]
      //console.log("Parametro: ",item,data)
      param[item].valueStep = Math.log(data)/Math.log(10); //changes the selected item from the object with the new data
      if (param[item].type === "Constant") {
        param[item].valueSci = data;
        param[item].valueSciTex = data.toFixed(3);
      } else {
        param[item].valueSci = data;
        param[item].valueSciTex = Number.parseFloat(data).toExponential(2);// Number.parseFloat(Math.pow(10,data)).toExponential(2)
      }
    }
    //console.log(param)
    sinData.model.param = param; //updates the copy


    const minHz = 0.01;
    const maxHz = 1000000

    sinData.modelData.fHz = sinData.expDataClean.fHz ? sinData.expDataClean.fHz : fHzGen(minHz,maxHz,10);
    sinData.modelData.fRad = sinData.modelData.fHz.map(a => a*2*Math.PI);

    sinData.modelData = getModelData(sinData.model, sinData.modelData.fRad);// generates modeled data using the expData frequency points
    sinData.modelData = extendData(sinData.modelData); //generates all the derived data points

    props.updateData('model',sinData.model); // update the App state model
    props.updateData('modelData',sinData.modelData); // update the App state expData

  }

  const fitModel = () => {

    const result = fit(expDataClean,model) //obtains the results of the fit as an array of arrays [[R1.value],[]...]
    updateParams(result)
    setComputing(false)
  }
  const coatingSeed = () => { //gets seed parametres related to coatings and updates the info
    const result = coatingFit(expData,model) //ojo necesitamos tambien el maximo de Zimg para tener solo el fit del coating
    props.updateData('expDataClean',result.expDataClean); // update the App state expDataClean
    props.updateData('explanation',result.explanation); // update the App state explanation
    props.updateData('z01',result.z01); // update the App state z01
    props.updateData('z45',result.z45); // update the App state z45
    props.updateData('freq45',result.freq45); // update the App state freq45
    props.updateData('zCoating',result.z45*2); // update the App state zCoating
    updateParams(result.param)
    setExplanation(result.explanation)
    setComputing(false)
  }

  const param = model.param
  const paramList = Object.keys(param).map(key => {
    return (

      <tr key={key+"_trFit"}>
        <td key={key+"_td1Fit"}>
          <InlineMath>{param[key].subTypeAbr + "_{" + key[1] + "} ="}</InlineMath>
        </td>
        <td key={key+"_td2Fit"} className="centerContent">
        {param[key].valueSciTex+ " "} <InlineMath>{param[key].units}</InlineMath>
        </td>
      </tr>

    )
  })

  const paramListV1 = (z01,cc,n)=> {
    return (
      <tbody>
        <tr key="_z01">
          <td key="_z01Param">
            <InlineMath>{"|Z|_{0.1Hz} ="}</InlineMath>
          </td>
          <td key="_z01Value" className="centerContent">
          {z01} <InlineMath>Omh</InlineMath>
          </td>
        </tr>
        <tr key="_Cc">
          <td key="_CcParam">
            <InlineMath>{"C_{coating} ="}</InlineMath>
          </td>
          <td key="_CcValue" className="centerContent">
          {cc} <InlineMath>{"F.s^{\\alpha}"}</InlineMath>
          </td>
        </tr>
        <tr key="_n">
          <td key="_nParam">
            <InlineMath>{"\\alpha ="}</InlineMath>
          </td>
          <td key="_nValue" className="centerContent">
          {n}
          </td>
        </tr>
      </tbody>

    )
  }


  const traficStyle = {
    margin:'10px',
    borderRadius: '15px',
    border: '1px solid #d9d9d9',
    backgroundColor: '#f7f7f7',
    padding: '10px',
    width: '60px',
    height: '140px'
  }
  const traficCircleGreen = {
    height: '30px',
    width: '30px',
    backgroundColor: props.sinData.z01 >= 100000000 ? "green" : '#bbb',
    borderRadius: '50%'
  }
  const traficCircleOrange = {
    height: '30px',
    width: '30px',
    backgroundColor: (props.sinData.z01 >= 1000000 && props.sinData.z01 < 100000000) ? "orange" : '#bbb',
    borderRadius: '50%'
  }
  const traficCircleRed = {
    height: '30px',
    width: '30px',
    backgroundColor: (props.sinData.z01 && props.sinData.z01 < 1000000) ? "red" : '#bbb',
    borderRadius: '50%'
  }
  const colorBox = (z01) =>{
    if(z01 >= 100000000) {return "green"}//green
    else if(z01 >= 1000000 && z01 < 100000000) {return "orange"}
    else if(z01 && z01 < 1000000) {return "red"}
    else {return "#d9d9d9"}

  }
  const infoBox = {
    margin:'10px',
    borderRadius: '15px',
    border: '1px solid #d9d9d9',
    color:colorBox(props.sinData.z01),
    padding: '10px',
    width: '250px',
    height: '90px'
  }

  const explanationText = (z01,ccFreq) => {
    let text = []
    if(z01 >= 100000000) {
      text.push("The coating presents good barrier performance") }//green
    else if(z01 >= 1000000 && z01 < 100000000) {text.push("The coating presents suficient barrier performance")}
    else if(z01 && z01 < 1000000) {text.push("The coating presents bad barrier performance")}
    return text

  }
//console.log("props",props.sinData)
const explanationList = explanation.map(info => <div key={info.slice(0, 4)}>{info}</div>)

   useEffect(()=> {fitLoader(coatingSeed)}, []) // get the seeds automatically on mount and once (,[])
  return (
    <div>
    <div className='rowDivCol centerDiv'>
     <div style={traficStyle} className='centerDiv'>
      <div style={traficCircleGreen}></div>
      <div style={traficCircleOrange}></div>
      <div style={traficCircleRed}></div>
     </div>
     <div style={infoBox} >
      {computing ? <div><LoadingOutlined /> Calculating best fit... </div> : (explanationText(props.sinData.z01)[0])}
     </div>
    </div>
      {/**computing ? <div><LoadingOutlined /> Calculating best fit... </div> : (
        <div>
          <Button onClick={()=> fitLoader(coatingSeed)}>Coating Seed</Button>
          <Button onClick={()=> fitLoader(fitModel)}>Rock & Roll</Button>}
        </div>
      )**/}
      <p style={{color:'red'}}> --> <i> experimental </i> </p>
      <table>
        {/**<tbody>**/}
          {paramListV1(props.sinData.z01.toExponential(2),props.sinData.model.param.Q1.valueSciTex, props.sinData.model.param.a1.valueSciTex)}
        {/**</tbody>**/}
      </table>
    </div>
  )
}

export default DataFitter
