import React, { Component, lazy, Suspense  } from 'react'
import {Helmet} from 'react-helmet'
import { LoadingOutlined } from '@ant-design/icons'

//grid
//import { Row, Col } from 'antd'

// panels for modules
import PanelModule from './Common/componentsHTM/panelModule'

//components
//import SmileInput from './Common/componentsHTM/smileInput'
import DataFromUser from './Common/componentsHTM/dataFromUser'
import PlotModule from './Common/componentsHTM/plot'
import ParamPanel from './Common/componentsHTM/param/paramPanel'
import DataFitter from './Common/componentsHTM/dataFitter'

//rules
import eisRules from './Common/sinTools/rules/eisRules'

const SmileInput = lazy(()=> import('./Common/componentsHTM/smileInput'))

class FitToolEis extends Component {
  screen = window.innerWidth
  state = {
    sinData:{
      rules: eisRules,
      modelData: {},
      expData: {},
      expDataClean:{},
      model: {
        param : {
          R1: {
            maxRange: 16,
            minRange: 0,
            stepRange: 0.05,
            subType: "Generic Resistance",
            subTypeAbr: "R",
            type: "Resistance",
            units: "(Ohm)",
            valueSci: 30,
            valueSciTex: "3.00e+1",
            valueStep: 1
          }
        }
      },
    }
  };
// Function that update the sinData object in the state of the App
  updateData = (item,data) => { //item to change with the new data
    let sinData = this.state.sinData; //gets a copy of the initial sinData object
    sinData[item] = data; //changes the selected item from the object with the new data
    this.setState({
      sinData: sinData
    })//updates the state
  }


  render() {
    const { expData, modelData, rules, path } =  this.state.sinData

    const plotWidth = this.screen> 700 ? 650 : this.screen-25
    console.log('renderFitToolExpert',this.state.sinData)
    return (
      <div className = "columnDiv">
      <Helmet>
        <title>EIS modelling Tool</title>
        <meta name="description" content="Electrochemical Impedance Spectroscopy modelling tool" />
        <meta name="keywords" content="EIS, circuit, Impedance, modelling, randles" />
      </Helmet>
        <div className = "centerDiv"> <h1>General Fitting Tool for {this.props.match.path.substr(1).toUpperCase()} data</h1></div>
        <div className = "centerDiv">
          <div className= "rowDivCol">
            <div className = "columnDiv">
              <div className = "centerDiv">
                <PlotModule size={plotWidth} expData={expData} modelData={modelData} rules={rules} expert/>
              </div>
              <div className = "centerDiv">
                <ParamPanel sinData={this.state.sinData} updateData = {this.updateData} />
              </div>
            </div>
            <div className = "columnDiv">
              <div className = "centerDiv">
                <PanelModule id="panel-1" title="Select Model" content={
                  <Suspense fallback={<div style={{width:'360px', height:'300px', textAlign:'center', paddingTop: '100px'}}> <LoadingOutlined /> Loading...</div>}>
                    <SmileInput sinData ={this.state.sinData} updateData = {this.updateData}/>
                  </Suspense>
                } />
              </div>
              <div className = "centerDiv">
                <PanelModule id="panel-2" title="Insert Data" content={<DataFromUser updateData = {this.updateData} type='eis'/>} />
              </div>
             <div className = "centerDiv">
                <PanelModule id="panel-3" title="Automatic Fit" content={<DataFitter sinData={this.state.sinData} updateData={this.updateData} type='eis'/>} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FitToolEis;
