import React, { Component } from 'react'
import { Alert,Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import rheoRules from '../../Common/sinTools/rules/rheoRules'

import PlotSin from '../../Common/componentsHTM/plotSin'
import PlotModule from '../../Common/componentsHTM/plot'
//import ParamPanel from '../../Common/componentsHTM/param/paramPanel'
import Circuit from '../../Common/componentsHTM/circuitDraw/circuit'
import UpdateSinData from '../../Common/componentsHTM/stateManagement'

class RheoForCoatings extends Component {
  state = {
    sinData:{
      rules: rheoRules,
      modelData: {},
      expData: {},
      model: {
        param : {
          E1: {
          type:"Spring",
          valueSci:500000000,
          valueSciTex:"5.00e-8",
          valueStep: 1,
          subType:"Generic Spring (modulus)",
          subTypeAbr:"E",
          units:"(Pa)",
          maxRange: 15,
          minRange: -3,
          stepRange:0.05
          }
        }
      }
    }
  }
  // Function that update the sinData object in the state of the App
    updateData = (item,data) => { //item to change with the new data
      let sinData = this.state.sinData; //gets a copy of the initial sinData object
      sinData[item] = data; //changes the selected item from the object with the new data
      this.setState({
        sinData: sinData
      })//updates the state
    }

    componentDidMount() {
      const mainState = {
        sinData: this.state.sinData,
        updateData: this.updateData
      }
      UpdateSinData('S-DS', mainState);//execute the function to make the magic
    }
  render() {
    const {expData, modelData, rules} = this.state.sinData
  return (
    <div className ="columnDiv"  >


      <PlotModule size={500} expData={expData} modelData={modelData} rules={rules}/>

      <DownOutlined />
      <DownOutlined />
      <DownOutlined />

      <div className="centerDiv rowDiv" >
        <div style={{width:'90px', margin:'5px'}}><Circuit smiles={"S"}/></div>
        <div style={{width:'90px', margin:'5px'}}><Circuit smiles={"D"}/></div>
      </div>

      <div className="centerDiv columnDiv" >
        <div style={{width:'250px', margin:'20px'}}><Circuit smiles={"S-SD-D"}/></div>
        Representation for Pressure Sensitive Adhesive (PSA)
      </div>

      </div>




  )
}
}

export default RheoForCoatings
