/*
Information for the nyquist plot
 */

// Plot Nyquist

export const dataMreal_MimgPC = (expData, modelData, varNames) => {// todo finalize this func and test to plot it
    var traceZiRevVsZr = {
        x: modelData.Mreal ? modelData.Mreal : [],
        y: modelData.Mimg ? modelData.Mimg : [],
        mode: 'lines',
        line: {shape: 'spline'},
        type: 'scatter',
        name: 'Model'
    };
    var traceZiRevVsZrR = {
        x: expData.Mreal ? expData.Mreal : [],
        y: expData.Mimg ? expData.Mimg : [],
        mode: 'markers',
        marker: {
            color: '#1f77b4',
        },
        type: 'scatter',
        name: 'Real'
    };
  return [traceZiRevVsZr, traceZiRevVsZrR];
}

export const layoutMreal_MimgPC = (expData ,modelData, varNames) =>{
  const xMax = expData.Mreal ? Math.max(...expData.Mreal) : (
                  modelData.Mreal ? Math.max(...modelData.Mreal) : 1000
                );
  const xMin = 0
  const yMax = expData.Mimg ? Math.max(...expData.Mimg) : (
                  modelData.Mimg ? Math.abs(Math.max(...modelData.Mimg)) : 1000
                );
  const yMin = expData.Mimg ? Math.min(...expData.Mimg) : (
                  modelData.Mimg ? Math.min(...modelData.Mimg) : 1000
                              );

  const yMaxCeil = Math.ceil(-yMin)
  const xMaxCeil = Math.ceil(xMax)
  console.log({yMaxCeil,xMaxCeil})


  const maxTotal = (xMaxCeil > yMaxCeil) ? xMaxCeil : yMaxCeil
  console.log({maxTotal,yMin,yMax,xMax})

//  const MmaxY = (-MmaxX/10).toFixed(0)*10
  return{
        margin: {b: 75,t: 10,l:50,r:20},
        showlegend: false,
        autosize: true,

        xaxis: {
          ////fixedrange: true,
            title: varNames.Mreal,
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            linewidth: 2,
            constrain: 'domain',
            range:[0, maxTotal]
        },
        yaxis: {
          ////fixedrange: true,
            title: {
              "text": varNames.Mimg,
              "font": {"color": '#1f77b4'}
            },
            showgrid: false,
            hoverformat: '.2e',
            ticks: 'inside',
            exponentformat: 'power',
            mirror: 'ticks',
            scaleanchor: 'x',
            autorange:varNames.Mmod === 'Zmod' ? 'reversed' :true,
            linewidth: 2,
           range: [0, -maxTotal], 
        }
      }
  };


    //Plotly.newPlot('plotCont', dataNy, layoutNy,{displayModeBar: false});
