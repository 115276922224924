/*
General layout
 */

 export const dataHz_MrealMimgCoating = (dataArray, varNames) => {// todo finalize this func and test to plot it

   const color = (data,opacity) => {
     const colorST = (data.time<360) ? 230-data.time : 230-(data.time-360)
     const greyTone = (opacity === '50%') ? '0%' : '50%'
     const colorTime = (data.time === 0) ? 'hsl(0, 0%, '+greyTone+')' : 'hsl('+colorST+', 100%, '+opacity+')' //'rgb(31, '+data.temp*2.55+', 180)'
     return colorTime
   }
   const buildHzVsMrealObj = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorTime = color(data,'50%')
     const dataX = data.expData.fHz ? data.expData.fHz : []
     const dataY = data.expData.Mreal ? data.expData.Mreal : []
     const trace = {
       x: dataX,
       y: dataY,
       mode: 'markers',
       marker: {color: colorTime}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       name: varNames.Mreal+ ' '+data.time+'h'
     }
     return trace
   }

   const buildHzVsMimgObj = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
     const colorTime = color(data,'80%')
     const dataX = data.expData.fHz ? data.expData.fHz : []
     const dataY = data.expData.Mimg ? (varNames.Mmod === 'Zmod' ? data.expData.Mimg.map(x => -x ) : data.expData.Mimg) : []
     const trace = {
       x: dataX,
       y: dataY,
       mode: 'markers',
       marker: {color: colorTime}, // ToDo: conditional if is the ref temperature
       type: 'scatter',
       yaxis: 'y2',
       name: varNames.Mimg + ' '+data.time+'h'
     }
     return trace
    }
    const buildHzVsMrealObjModel = data => { //to build a trace of a TTS data. Requires the data array of the ttsData objet from props
      const colorTime = color(data,'50%')
      const trace = {
        x: [],//data.modelData.fHz ? data.modelData.fHz : [],
        y: [],//data.modelData.Mreal ?  data.modelData.Mreal : [],
        mode: 'lines',
        line: {shape: 'spline', color: colorTime}, // ToDo: conditional if is the ref temperature
        type: 'scatter',
        name: varNames.Mreal+ ' '+data.time+'h'
      }
      return trace
    }

    const buildHzVsMimgObjModel = data => {//to build a trace of a TTS data. Requires the data array of the ttsData objet from props
      const colorTime = color(data,'80%')
      const trace = {
        x: [],//data.modelData.fHz ? data.modelData.fHz : [],
        y: [],//data.modelData.Mimg ? (varNames.Mmod === 'Zmod' ? data.modelData.Mimg.map(x => -x ) : data.modelData.Mimg) : [],
        mode: 'lines',
        line: {shape: 'spline', color: colorTime}, // ToDo: conditional if is the ref temperature
        type: 'scatter',
        yaxis: 'y2',
        name: varNames.Mimg + ' '+data.timr+'h'
      }
      return trace
     }

    let dataArray1 = dataArray.map(time =>{return buildHzVsMrealObj(time)}) //gives a array of Hz Vs Mreal data objects
    let dataArray2 = dataArray.map(time =>{return buildHzVsMimgObj(time)}) //gives a array of Hz Vs Mimg data objects

    let dataArray3 = dataArray.map(time =>{return buildHzVsMrealObjModel(time)}) //gives a array of Hz Vs Mreal data objects for the modeled data
    let dataArray4 = dataArray.map(time =>{return buildHzVsMimgObjModel(time)}) //gives a array of Hz Vs Mimg data objects for the modeled data

    const data = dataArray1.concat(dataArray2).concat(dataArray3).concat(dataArray4)

    return data
 }

 export const layoutHz_MrealMimgCoating = (dataArray, varNames) =>{


   const maxHzArray = dataArray.map(time =>{return time.expData.fHz ? Math.max(...time.expData.fHz) : 100000}) // gives  a array with the maximum Hz value of each temperature
   const minHzArray = dataArray.map(time =>{return time.expData.fHz ? Math.min(...time.expData.fHz) : 0.01})// gives  a array with the minimum Hz value of each temperature

   const xMax = Math.max(...maxHzArray) // gives the maximum Hz of all temperatures
   const xMin = Math.min(...minHzArray) // gives the minimum Hz of all temperatures

   const xMaxLog = Math.log(xMax)/Math.log(10);
   const xMinLog = Math.log(xMin)/Math.log(10);

   const xMaxLogCeil = Math.ceil(xMaxLog);
   const xMinLogFloor = Math.floor(xMinLog);


//-------------------------------------------------------------------

   const maxMrealArray = dataArray.map(time =>{return time.expData.Mreal ? Math.max(...time.expData.Mreal) : 1000}) // gives  a array with the maximum Mreal value of each temperature
   const minMrealArray = dataArray.map(time =>{return time.expData.Mreal ? Math.min(...time.expData.Mreal) : 0})// gives  a array with the minimum Mreal value of each temperature

   const yMax = Math.max(...maxMrealArray) // gives the maximum Mreal of all temperatures
   const yMin = Math.min(...minMrealArray) // gives the minimum Mreal of all temperatures
   console.log('yMax: ',yMax)
   const yMaxLog = Math.log(yMax)/Math.log(10);
   const yMinLog = Math.log(yMin)/Math.log(10);

   const yMaxLogCeil = Math.ceil(yMaxLog);
   const yMinLogFloor = Math.floor(yMinLog);

//---------------------------------------------------------------------

  const maxMimgArray = dataArray.map(temp =>{return temp.expData.Mimg ? Math.min(...temp.expData.Mimg) : 1000}) // gives  a array with the maximum Mimg value of each temperature
  const minMimgArray = dataArray.map(temp =>{return temp.expData.Mimg ? Math.max(...temp.expData.Mimg) : 0})// gives  a array with the minimum Mimg value of each temperature

  const y2Max = -Math.min(...maxMimgArray) // gives the maximum Mimg of all temperatures
  const y2Min = Math.max(...minMimgArray) // gives the minimum Mimg of all temperatures
console.log('y2Max: ',y2Max)
  const y2MaxLog = Math.log(y2Max)/Math.log(10);
  const y2MinLog = Math.log(y2Min)/Math.log(10);

  const y2MaxLogCeil = Math.ceil(y2MaxLog);
  const y2MinLogFloor = Math.floor(y2MinLog);

  const yMaxTotal = (y2MaxLogCeil > yMaxLogCeil) ? y2MaxLogCeil : yMaxLogCeil
  const yMinTotal = (y2MinLogFloor < yMinLogFloor) ? y2MinLogFloor : yMinLogFloor

   return {
       margin: {b: 75,t: 10,l:60,r:60},
       showlegend: false,
       autosize: true,
       xaxis: {
         //fixedrange: true,
         title: varNames.fHz,
         mirror: 'ticks',
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[xMinLogFloor,xMaxLogCeil]
       },
       yaxis: {
         //fixedrange: true,
         title: {
           "text":varNames.Mreal,
           "font": {"color": '#1f77b4'}
         },
         showgrid: false,
         hoverformat: '.2e',
         linewidth: 2,
         type: 'log',
         exponentformat: 'power',
         dtick:'1',
         ticks: 'inside',
         range:[yMinTotal, yMaxTotal]
       },
       yaxis2: {
         //fixedrange: true,
 				title: {
          "text": varNames.Mmod === 'Zmod' ? ("-"+varNames.Mimg) : varNames.Mimg,
          "font": {"color": '#ff7f0e'}
        },
 				showgrid: false,
 				hoverformat: '.2e',
        linewidth: 2,
        type: 'log',
        exponentformat: 'power',
 				dtick:'1',
 				ticks: 'inside',
 				overlaying: 'y',
 				side: 'right',
        range:[yMinTotal, yMaxTotal]
       }
       }
   };
