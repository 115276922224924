/**
 * Info: Addition (sum) operations, simple and factorial additions
 *  simple R = A + B
 *  factorial 1/R = 1/A + 1/B --> R = 1/(1/A + 1/B)
 * @param A
 * @param B
 * @returns {string}
 */
export const simpleSum = (A, B) => `${A} + ${B}`;
export const facSum = (A, B) => `1/(1/(${A}) + 1/(${B}))`;

export const linearReg = (y,x) => {
    let lr = {};
    let n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr['intercept'] = (sum_y - lr.slope * sum_x)/n;
    lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

        return lr;
}

export const toLog = (array) => {
  return array.map(item => Math.log(item)/Math.log(10))
}
export const unLog = (array) => {
  return array.map(item => Math.pow(10,item))
}

const getArraySum = (arrayToSum) => { //sum all the elements of a array of numbers
  return arrayToSum.reduce((prev, cur)=> {return prev + cur}, 0)
}
export const average = (arr) => {// requires getArraySum. get the average of an array of numbers
  return getArraySum(arr)/arr.length
}
/**
 * Info: noise reduction throug the average of consecutive points
 *  gets an array of data and apply the average of each number of points to each value starting from points-1
 *  )
 * @param array
 * @param points
 * @returns [array]
 */
export const avgSmooth = (array,points) =>{

  let result = []
  for (let i = 0; i<array.length-points+1; i++){
    let section = array.slice(i,i+points)
    result.push(average(section))
  }

return result
}

export const avg2 = (array) =>{

  let a =[...array]
  for (let i = 0; i<array.length-1; i++){
    let value = (a[i]+a[i+1])/2
    a[i] = value
    a[i+1] = value
  }

return a
}

/**
 * Info: resolution of second grade equations
 *
 * @param array
 * @param points
 * @returns [array]
 **/

 export const secDegEq = (a,b,c) => {
   const result = {
     x1: (-b+Math.sqrt(b*b-4*a*c))/(2*a),
     x2: (-b-Math.sqrt(b*b-4*a*c))/(2*a),
   }
   console.log(result)
   return result.x1 > result.x2 ? result.x1 : result.x2
 }
