import React, { useState } from 'react'
import { Alert, Divider } from 'antd';
import { Link } from 'react-router-dom'

import {
  DownOutlined
} from '@ant-design/icons';


import eisRules from '../../Common/sinTools/rules/eisRules'

import PlotSin from '../../Common/componentsHTM/plotSin'


function Sin(props) {
  const [angleEis, setAngleEis] = useState(0);
  const [angleRheo, setAngleRheo] = useState(-90);

  const updateAngle = (angle,type) => {
    type === "rheo" ? setAngleRheo(angle) : setAngleEis(angle)
  }
  const sizeConv = (angle) => {
    return 20 + angle*20/90
  }

  const explanation = "ddddd"
  const description = () => {
    return (
      <div>
        <div>Here we are going to make some parallelism with another sinusoidal technique that is Rheology.
        Rheology is also a potent technique, really important in the coating industry and also difficult to interpret due to its sinusoidal nature.</div>
         My prefered definition of rheology says: <i>“ Rheology is something that a rheologist understand, and anybody else does”…</i>.

         <div>So lets put it  side by side:</div>
         <div className="centerDiv rowDiv">
         <div className="centerDiv columnDiv" style={{borderRadius: "15px", padding: "10px", margin: "10px", borderStyle: "solid", borderWidth: "1px" }}>
         <h2>EIS</h2>
            <ul>
              <li>We apply an electrical input (Voltage)</li>
              <li>We get back (measure) a current (I)</li>
              <li>Voltage/Current = Impedance (|Z|) </li>
            </ul>
         </div>
         <div className="centerDiv columnDiv" style={{borderRadius: "15px", padding: "10px", margin: "10px", borderStyle: "solid", borderWidth: "1px" }}>
         <h2>RHEO</h2>
            <ul>
              <li>We apply a mechanical input (streess)</li>
              <li>We get back (measure) a strain (elongation)</li>
              <li>Stress/Strain = Complex Modulus (G*) </li>
            </ul>
         </div>
         The measured current (for EIS) and the strain (for Rheology) may present a delay.
         This delay will also changes depending on how fast we measure (frequency).
         </div>
       </div>
)
}

  return (
    <div className ="centerDiv columnDiv"  >
    <div className="preH1">EIS and Rheology, from waves to equivalent elements</div>
      <div className="rowDiv">
        <div className ="centerDiv columnDiv" style={{marginRight: "5px"}} >
          <Divider>EIS</Divider>
          <div style={{color: "black", margin:"15px"}} >
            <span style={{fontSize:"20px" }}>Voltage = Current x </span>
            <span style={{fontSize: sizeConv(angleEis) }}>Resistance</span>
            <span style={{fontSize: 20-sizeConv(angleEis) }}>Capacitance</span>
          </div>
          <PlotSin  size={500} type="eis" updateAngle= {updateAngle}/>
          <DownOutlined />
          <DownOutlined />
          <DownOutlined />
          <div><h3>|Z| , Zreal, Zimg, pahse angle</h3></div>
        </div>
        <div className ="centerDiv columnDiv" style={{marginLeft: "5px"}} >
          <Divider>Rheology</Divider>
          <div style={{color: "black", margin:"15px"}} >
            <span style={{fontSize:"20px" }}>Stress = Strain x </span>
            <span style={{fontSize: sizeConv(angleRheo) }}>Elasticity</span>
            <span style={{fontSize: 20-sizeConv(angleRheo) }}>Viscosity</span>
          </div>
          <PlotSin  size={500} type="rheo" updateAngle= {updateAngle}/>
          <DownOutlined />
          <DownOutlined />
          <DownOutlined />
          <div> <h3>G* , G', G'', &eta;*, &delta;º...</h3></div>

        </div>
      </div>
    {/**  <Alert
        style= {{maxWidth: '900px', align:'right', marginTop:'20px', marginBottom:'20px', textAlign: 'justify'}}
        message={explanation}
        description={description()}
        type="info"

        />**/}
    </div>



  )
}

export default Sin
