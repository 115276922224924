import React from 'react'
import {errorNLS} from './nls'
import {getModelData} from '../getModelFromStr';
import {genVectors} from './genVectors';
import {getResistances, getCapacitance, extractCoating, getZat01} from './coatingSeeds';
import {getRcFormAngle, getWcFormAngle, getAngleAtFeq, getQFromZ} from './getParamCoatings';
import {linearReg, toLog, unLog, avgSmooth, average} from '../../../math/operations'

function genSeed (model){ // returns the seed with the initial parametres and the seed list (not fixed)
  // we generate the seed empty array (s0)
  let s0 = []
  let seedList = [] //list fo not fixed parametres
  //get the number of parametres in the model
  const numElements = model.paramList.length
  //polulate the seed array with each initial parameter value
  for (let i=0; i< numElements; i++){
    if (!model.param[model.paramList[i]].fixed){
      s0.push(model.param[model.paramList[i]].valueSci)
      seedList.push(model.paramList[i])
    }
    //s0.push(Math.log(model.param[model.paramList[i]].valueSci)/Math.log(10))
  }

  return {s0,seedList}
}

//Is there a resistance plateau?
// get the Zreal values of when the phase angle is les than 10:
    // average the Zphz using 5 dataPoints using avgSmooth([Zphz],5)
      //Function that returns the


export const coatingFit = (expData, model) => {
        /** First we look at the fase angle (maximum in the first 10 points) to get the initial n.
            With the obtained 'n' we calculate back the Cc with the slope of Zimg
            getCapacitance(expData) -->
              retuns {
                        explanation:'',
                        phzMax: ,
                        seed: {
                                Cc: cpe2,
                                n: nFromZphzMax
                              }
                      }

        **/
        const capacitance = getCapacitance(expData)
        console.log('capacitance = ',capacitance)
        /**
            getResistances(expData) -->
              retuns {
                        explanation:vervose,
                        }
                        seed: {
                              Re:seed.Re, //fixed
                              Rc:seed.Rc // value form from phz near 0, (ya no se usa, ahora estrapolamos aqui el semicirculo, limpiar)
                            }
                     }
        **/
        //const resistances = getResistances(expData)

        model.param.R1.valueSci = 30
        model.param.Q1.valueSci = capacitance.seed.Cc
        model.param.a1.valueSci = capacitance.seed.n
        model.param.W1.valueSci = 0.0000000000000001

        for (let i=0; i< 4; i++){
          let Rc = getRcFormAngle(capacitance.angle45.freq*2*Math.PI, capacitance.angle45.angle, model)
          model.param.R2.valueSci = Rc
          let Wc = getWcFormAngle(capacitance.angleForWc.freq*2*Math.PI, capacitance.angleForWc.angle, model)
          if (Wc < 0)  {i=4}
          model.param.W1.valueSci = (Wc > 0) ? Wc : 0.0000000000000001
        }


        const angle = getAngleAtFeq(capacitance.angleIni.freq*2*Math.PI, model)
        console.log('angle real=', capacitance.angleIni.angle, 'angle model=',angle)
        const nCorrection = (capacitance.angleIni.angle/(-90))*(capacitance.angleIni.angle/angle)
        console.log('n real=', nCorrection, 'angle n=',capacitance.angleIni.angle/(-90))
        const freq45 = capacitance.angle45.angle == -45? capacitance.angle45.freq : false

        if ( freq45 > 1000){

            model.param.a1.valueSci = nCorrection
            let newIntercept = capacitance.phz10first.w0*nCorrection + capacitance.phz10first.Zimg0
            let cpe2 = 1/(Math.pow(10,newIntercept)*Math.pow(1,nCorrection))
            model.param.Q1.valueSci = cpe2

            for (let i=0; i< 4; i++){
              let Rc = getRcFormAngle(capacitance.angle45.freq*2*Math.PI, capacitance.angle45.angle, model)
              model.param.R2.valueSci = Rc
              let Wc = getWcFormAngle(capacitance.angleForWc.freq*2*Math.PI, capacitance.angleForWc.angle, model)
              if (Wc < 0)  {i=4}
              model.param.W1.valueSci = (Wc > 0) ? Wc : 0.0000000000000001
            }

            newIntercept = capacitance.phz10first.w0*nCorrection + capacitance.phz10first.Zimg0
            cpe2 = 1/(Math.pow(10,newIntercept)*Math.pow(1,nCorrection))
            model.param.Q1.valueSci = cpe2

        }



        console.log('Rc=', model.param.R2.valueSci.toExponential(2))
        console.log('Wc=', model.param.W1.valueSci.toExponential(2))
        console.log('angleFrWc.freq*2*Math.PI:',capacitance.angleForWc.freq, ', phz:',capacitance.angleForWc.angle)
        const expDataClean = extractCoating(expData)
        const z01 = getZat01(expData)
        const z45 = capacitance.angle45.angle == -45? capacitance.angle45.z : false

      //  console.log(expDataClean)
      //  console.log(resistances)
        const result = {
                        param:[
                                ['R1', model.param.R1.valueSci],
                                ['R2', model.param.R2.valueSci],//resistances.angle45.z * (90/(90+resistances.angle45.angle))],//z45 ? z45*2 : resistances.seed.Rc],
                                ['Q1', model.param.Q1.valueSci],
                                ['a1', model.param.a1.valueSci],
                                ['W1', model.param.W1.valueSci],
                              ],
                        expDataClean: expDataClean,
                        explanation: ['to be defined'],
                        z01: z01,
                        z45: z45,
                        freq45: freq45,

                      }
        console.log('result=', result)

        return result
}

export const fit = (expData,model) => {

  const modelToFit = model
//  console.log(model)
  const seed = genSeed(model)

  let v = 0.5; //variation index. Variacion en porcentaje del valor inicial
  let iterationsMax = 1000

  //first generates modelData with the seed values

  let s0 = seed.s0//transform to log10

  for (var iterations=0; iterations < iterationsMax; iterations++){
//get the error of the seed
  //first generates modelData with the seed values


    // we populate the seed to get variations to test
    let vectors = genVectors(toLog(s0),v)//this returns a array of arrays containing the seed and some modification on each vertex
  //  console.log(genVectors(s0,iteration)) //this returns a array of arrays containing the seed and some modification on each vertex
    vectors = vectors.map(item => unLog(item)) // transform antiLog
    //console.log(vectors)

    // Calculate the error of each vector and get the minimum
    let bestFit = 100
    let bestLoc = 0

    for (let i=0; i< vectors.length; i++){

      //add the values to the model object
      for(let n=0; n < seed.seedList.length; n++){
      //  console.log(i, seed.seedList[n],": ", vectors[i][n])
        let value = vectors[i][n]
        modelToFit.param[seed.seedList[n]].valueSci = value
      }
      //ojo! punto para seleccionar el rango
      let modelData = getModelData(model,expData.fRad)//getModelData(model,expData.fRad)
      let vectorError = errorNLS(expData, modelData).totalError
      if (vectorError < bestFit) {
        bestFit = vectorError
        bestLoc = i
      }
    }
    s0 = vectors[bestLoc]
    if (bestLoc == 0) {
			v = v/2;
			iterations = 0;
//			console.log("v = "+v)
		}


    //console.log(vectors[bestLoc], 'Fit: ', bestFit)
    if (bestLoc == 0 && v<=0.0001) {
			iterations = iterationsMax;
		}
  }
  //console.log(v)

  // function output
  let result =[]
  for(let n=0; n < seed.seedList.length; n++){
    result.push([seed.seedList[n], s0[n]])
  }

  return result
}
